<template>
  <modal :show.sync="showModalConfirmPerformance" headerCLasses="justify-content-center" :required="true">
    <h4 slot="header" class="title title-up">
      {{ $t('confirmYourAction') }}
    </h4>
    <div class="col-sm-8 move-left">
      <el-radio-group v-model="action" class="move-dot-up">
        <el-radio class="row mt-2 mb-2" label="0" size="large"><b>{{ $t('noDebitorCredit') }}</b></el-radio>
        <el-radio class="row mt-2 mb-2" label="1" size="large"><b>{{ $t('onlyDebitedFollower') }}</b></el-radio>
        <el-radio class="row mt-2 mb-2" label="2" size="large"><b>{{ $t('debitAndCreditSuccessful') }}</b></el-radio>
      </el-radio-group>
    </div>
    <template slot="footer">
      <div class="py-3 mx-auto" v-if="modalinprogress">
        <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
      </div>
      <div class="left-side" v-if="modalinprogress === false">
        <p-button type="success" @click.prevent="performAction" link>{{ $t('confirm') }}</p-button>
      </div>
      <div class="divider" v-if="modalinprogress === false"></div>
      <div class="right-side" v-if="modalinprogress === false">
        <p-button type="danger" @click.prevent="closeModalAction" link>{{ $t('cancel') }}</p-button>
      </div>
    </template>
  </modal>
</template>

<script>
import {Modal} from "../../../../UIComponents";
import Vue from "vue";
import {Radio, RadioGroup} from "element-ui";

Vue.use(RadioGroup);
Vue.use(Radio);

export default {
  name: "BrokerPerformanceModal",
  components: {
    Modal,
  },
  props: {
    entries: {
      type: Array,
    },
    showModalConfirmPerformance: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      modalinprogress: false,
      action: '0',
    }
  },
  methods: {
    closeModalAction() {
      this.action = '0'
      this.$emit('on-close-modal-broker-performance')
    },
    performAction() {
      this.modalinprogress = true
      let param = {
        'entries': this.entries,
        'action': this.action
      }
      let success = resp => {
        this.$toast.success(this.$t(resp.msg))
        this.modalinprogress = false
        this.closeModalAction()
      }
      let fail = resp => {
        this.modalinprogress = false
        this.$toast.warning(this.$t('rdpdownmsg'))
        this.closeModalAction()
      }

      this.$resolveInternalBrokerPaymentBulk(param).then(success, fail)
    }
  }
}
</script>

<style scoped>
.move-left {
  left: 10%;
      font-size: 15px;
}
.move-dot-up {
  font-size: 15px;
}

</style>
