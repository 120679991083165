<template>

  <div>
    <modal :show.sync="modals.classic" :required="true" headerClasses="justify-content-center" modalClasses="modal-lg" id="langModal" ref="langModal">
      <h4 slot="header" class="title title-up">{{txt.info}}</h4>

      <div class="alert alert-danger" v-if="modalErrorAlert" v-on:click="modalErrorAlert=false">
        <a class="danger">
          <i class="fa fa-info-circle"></i>
        </a>
        {{txt.emptyfields}}
      </div>

      <div class="form-group">
        <div class="row">
          <h5><img class="lift-abit" src="/static/img/flags/GB.png" alt="flag"/> English  </h5>
          <fg-input v-model="modalData.title.en"
                    placeholder="Title"
                    class="col-md-12 input-modal"
                    type="text"
                    name="detail"/>
          <textarea v-model="modalData.description.en" class="form-control" placeholder="Content" rows="2"></textarea>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <h5><img class="lift-abit" src="/static/img/flags/ES.png" alt="flag"/> Spanish </h5>
          <fg-input v-model="modalData.title.es"  placeholder="Title" class="col-md-12 input-modal" type="text" name="initial" />
          <textarea v-model="modalData.description.es" class="form-control" placeholder="Content" rows="2"></textarea>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <h5><img class="lift-abit" src="/static/img/flags/PT.png" alt="flag"/> Portuguese </h5>
          <fg-input v-model="modalData.title.pt" placeholder="Title" class="col-md-12 input-modal" type="text" name="initial" />
          <textarea v-model="modalData.description.pt" class="form-control" placeholder="Content" rows="2"></textarea>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <h5><img class="lift-abit" src="/static/img/flags/AE.png" alt="flag"/> Arabic </h5>
          <fg-input v-model="modalData.title.ar" placeholder="Title" class="col-md-12 input-modal" type="text" name="initial" />
          <textarea v-model="modalData.description.ar" class="form-control" placeholder="Content" rows="2"></textarea>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <h5><img class="lift-abit" src="/static/img/flags/FR.png" alt="flag"/> French </h5>
          <fg-input v-model="modalData.title.fr" placeholder="Title" class="col-md-12 input-modal" type="text" name="initial" />
          <textarea v-model="modalData.description.fr" class="form-control" placeholder="Content" rows="2"></textarea>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <h5><img class="lift-abit" src="/static/img/flags/IT.png" alt="flag"/> Italian </h5>
          <fg-input v-model="modalData.title.it" placeholder="Title" class="col-md-12 input-modal" type="text" name="initial" />
          <textarea v-model="modalData.description.it" class="form-control" placeholder="Content" rows="2"></textarea>
        </div>
      </div>
       <div class="form-group">
        <div class="row">
          <h5><img class="lift-abit" src="/static/img/flags/DE.png" alt="flag"/> Germany </h5>
          <fg-input v-model="modalData.title.de" placeholder="Title" class="col-md-12 input-modal" type="text" name="initial" />
          <textarea v-model="modalData.description.de" class="form-control" placeholder="Content" rows="2"></textarea>
        </div>
      </div>
       <div class="form-group">
        <div class="row">
          <h5><img class="lift-abit" src="/static/img/flags/ZH.png" alt="flag"/> Chinesse </h5>
          <fg-input v-model="modalData.title.zh" placeholder="Title" class="col-md-12 input-modal" type="text" name="initial" />
          <textarea v-model="modalData.description.zh" class="form-control" placeholder="Content" rows="2"></textarea>
        </div>
      </div>
       <div class="form-group">
        <div class="row">
          <h5><img class="lift-abit" src="/static/img/flags/VI.png" alt="flag"/> Vietnam </h5>
          <fg-input v-model="modalData.title.vi" placeholder="Title" class="col-md-12 input-modal" type="text" name="initial" />
          <textarea v-model="modalData.description.vi" class="form-control" placeholder="Content" rows="2"></textarea>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <h5><img class="lift-abit" src="/static/img/flags/RO.png" alt="flag"/> Romanian </h5>
          <fg-input v-model="modalData.title.ro" placeholder="Title" class="col-md-12 input-modal" type="text" name="initial" />
          <textarea v-model="modalData.description.ro" class="form-control" placeholder="Content" rows="2"></textarea>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <h5><img class="lift-abit" src="/static/img/flags/RU.png" alt="flag"/> Russian </h5>
          <fg-input v-model="modalData.title.ru" placeholder="Title" class="col-md-12 input-modal" type="text" name="initial" />
          <textarea v-model="modalData.description.ru" class="form-control" placeholder="Content" rows="2"></textarea>
        </div>
      </div>
      <div>

      </div>


      <template slot="footer">
        <p-button type="success" v-if="newEntryFlag" link @click.native="createNewEntry()">
          <i slot="label" class="fa fa-floppy-o"></i>
          {{ $t('savechanges')}}
        </p-button>
        <p-button type="success" v-if="!newEntryFlag" link @click.native="modals.classic = false">
          <i slot="label" class="fa fa-floppy-o"></i>
          {{ $t('upentrybtn')}}
        </p-button>
        <p-button type="danger" link @click.native="modals.classic = false" >
          <i slot="label" class="fa fa-trash-o"></i>
          {{ $t('discardchanges')}}
        </p-button>
      </template>
    </modal>

    <modal :show.sync="modals.iconPicker">
      <h4 slot="header" class="title title-up"> {{txt.selecticontxt}} </h4>
      <div class="text-center">
        <i :class="modalData.icon"></i>
      </div>
      <hr>
      <div class="row">
        <font-awesome-picker v-on:selectIcon="saveIcon" style="max-width: 100%;"></font-awesome-picker>
      </div>
      <template slot="footer">
        <p-button type="success" link @click.native="modals.iconPicker = false" >
          <i slot="label" class="fa fa-floppy-o"></i>
          {{ $t('savechanges')}}
        </p-button>
      </template>
    </modal>

    <form ref="formValidation">
      <div class="text-center">
        <div class="card-title">
          <h4>{{txt.registerpage}}</h4>
        </div>

        <p-button type="primary" @click.native="newEntry()" v-if="this.tableData.length < 3">
          <i slot="label" class="fa fa-plus"></i>
          {{txt.newentrybtn}}
        </p-button>
        <p-button type="primary" disabled v-if="this.tableData.length >= 3">
          <i slot="label" class="fa fa-plus"></i>
          {{txt.newentrybtn}}
        </p-button>

        <p-button type="primary" @click.native="restoreToDefault()">
          <i slot="label" class="fa fa-repeat"></i>
          {{txt.resettodefaulttxt}}
        </p-button>

        <el-table
          :data="tableData"
          :emptyText="txt.emptyrow" >
          <el-table-column label="Icon" class="success" width="80px">
                        <span slot-scope="scope">
                            <i :class="scope.row.icon"></i>
                        </span>
          </el-table-column>

          <el-table-column :label="txt.description" >
                        <span slot-scope="scope" class="allupper">
                            <table>
                                <tr>
                                    <td width="280px"> <b> EN: </b> {{scope.row.title.en}} <br>  </td>
                                    <td>  <b> EN: </b> {{scope.row.description.en}} <br> </td>
                                </tr>
                                <tr>
                                    <td> <b> ES: </b> {{scope.row.title.es}} <br> </td>
                                    <td> <b> ES: </b> {{scope.row.description.es}} <br> </td>
                                </tr>
                                <tr>
                                    <td> <b> PT: </b> {{scope.row.title.pt}} <br> </td>
                                    <td> <b> PT: </b> {{scope.row.description.pt}} <br> </td>
                                </tr>
                                <tr>
                                    <td> <b> AR: </b> {{scope.row.title.ar}} <br> </td>
                                    <td> <b> AR: </b> {{scope.row.description.ar}} <br> </td>
                                </tr>
                                <tr>
                                    <td> <b> FR: </b> {{scope.row.title.fr}} <br> </td>
                                    <td> <b> FR: </b> {{scope.row.description.fr}} <br> </td>
                                </tr>
                                <tr>
                                    <td> <b> IT: </b> {{scope.row.title.it}} <br> </td>
                                    <td> <b> IT: </b> {{scope.row.description.it}} <br> </td>
                                </tr>
                                <tr>
                                    <td> <b> DE: </b> {{ scope.row.title.de }} <br> </td>
                                    <td> <b> DE: </b> {{ scope.row.description.de }} <br> </td>
                                </tr>
                                <tr>
                                    <td> <b> ZH: </b> {{ scope.row.title.zh }} <br> </td>
                                    <td> <b> ZH: </b> {{ scope.row.description.zh }} <br> </td>
                                </tr>
                                <tr>
                                    <td> <b> VI: </b> {{ scope.row.title.vi }} <br> </td>
                                    <td> <b> VI: </b> {{ scope.row.description.vi }} <br> </td>
                                </tr>
                                <tr>
                                    <td> <b> RO: </b> {{scope.row.title.ro}} <br> </td>
                                    <td> <b> RO: </b> {{scope.row.description.ro}} <br> </td>
                                </tr>
                                <tr>
                                    <td> <b> RU: </b> {{scope.row.title.ru}} <br> </td>
                                    <td> <b> RU: </b> {{scope.row.description.ru}} <br> </td>
                                </tr>
                            </table>
                        </span>
          </el-table-column>

          <el-table-column :label="txt.action" align="center" width="150px">
            <div slot-scope="scope" class="text-center">
              <div class="btn-group">
                <p-button type="success" link v-on:click.native="selectIcon(scope.$index)">
                  <i class="fa fa-info"  style="font-size: x-large;"></i>
                </p-button>
                <p-button type="warning" link v-on:click="editRow(scope.$index)">
                  <i class="fa fa-pencil" style="font-size: x-large;"></i>
                </p-button>
                <p-button type="danger" link v-on:click="removerow(`${scope.$index}`)">
                  <i class="fa fa-trash" style="font-size: x-large;"></i>
                </p-button>
              </div>
            </div>
          </el-table-column>

        </el-table>

      </div>
    </form>


  </div>
</template>

<script>
import Vue from "vue";
import {TabPane, Tabs, Modal} from "../../../../UIComponents";
import {Table, TableColumn, Upload} from "element-ui";
import { fontAwesomePicker } from 'font-awesome-picker';

Vue.use(Table);
Vue.use(TableColumn);

export default {
  name: 'whitelabelCustomText',
  props: {
    reference: {
      type:[Array],
      description: "Custom content text for Registration page"
    }
  },
  components: {
    TabPane,
    Tabs,
    Modal,
    fontAwesomePicker
  },
  data(){
    return {
      modals: {
        classic: false,
        iconPicker: false
      },
      txt: {
        emptyfields: this.$t('emptyfields'),
        emptyrow: this.$t('norecordsfounded'),
        registerpage: this.$t('registerpage'),
        newentrybtn: this.$t('newentrybtn'),
        description: this.$t('description'),
        action: this.$t('action'),
        info: this.$t('infotxt'),
        resettodefaulttxt: this.$t('resettodefaulttxt'),
        selecticontxt: this.$t('selecticontxt')
      },
      tableData: [],
      modalData: {
        seq: '',
        icon: "",
        title: {
          en: "",
          es: "",
          pt: "",
          ar: "",
          fr: "",
          it: "",
          de: "",
          zh: "",
          vi: "",
          ro: "",
          ru: ""
        },
        description: {
          en: "",
          es: "",
          pt: "",
          ar: "",
          fr: "",
          it: "",
          de: "",
          zh: "",
          vi: "",
          ro: "",
          ru: ""
        }
      },
      modalValidations:{
        title:{
          en: { required: true }
        },
        description: {
          en: { required: true }
        }
      },
      modalErrorAlert: false,
      newEntryFlag: true
    }
  },
  methods: {
    newEntry(){
      this.clearModalData()
      this.modalErrorAlert = false
      this.newEntryFlag = true
      const self = this;
      new Promise(function (resolve) {
        self.modals.classic = true
        resolve()
      }).then(function () {
        document.getElementById('langModal').scrollTo(0,0)
      })
    },
    editRow(idx){
      this.newEntryFlag = false
      this.modalErrorAlert = false
      this.modalData = this.tableData[idx]
      this.modals.classic = true
      const self = this;
      new Promise(function (resolve) {
        self.modals.classic = true
        resolve()
      }).then(function () {
        document.getElementById('langModal').scrollTo(0,0)
      })
    },
    isFormModalValid(){
      return (this.modalData.title.en && this.modalData.description.en) ||
             (this.modalData.title.es && this.modalData.description.es) ||
             (this.modalData.title.pt && this.modalData.description.pt) ||
             (this.modalData.title.ar && this.modalData.description.ar) ||
             (this.modalData.title.fr && this.modalData.description.fr) ||
             (this.modalData.title.it && this.modalData.description.it) ||
             (this.modalData.title.de && this.modalData.description.de) ||
             (this.modalData.title.zh && this.modalData.description.zh) ||
             (this.modalData.title.vi && this.modalData.description.vi) ||
             (this.modalData.title.ro && this.modalData.description.ro) ||
             (this.modalData.title.ru && this.modalData.description.ru)
    },
    createNewEntry() {
      if (!this.isFormModalValid()) {
        document.getElementById('langModal').scrollTo(0,0)
        this.modalErrorAlert = true
        return
      }
      const self = this
      let data = self.modalData
      new Promise(function (resolve){
        self.modals.classic = false
        self.clearModalData()
        resolve()
      }).then(function(){
        self.tableData.push(data)
      })
    },
    removerow(idx){
      console.log("idx", idx)
      console.log("this.tableData", this.tableData)
      this.tableData.splice((idx), 1)
    },
    clearModalData() {
      this.modalData = {
        seq: '',
        icon: "",
        title: {
          en: "",
          es: "",
          pt: "",
          ar: "",
          fr: "",
          it: "",
          de: "",
          zh: "",
          vi: "",
          ro: "",
          ru: ""
        },
        description: {
          en: "",
          es: "",
          pt: "",
          ar: "",
          fr: "",
          it: "",
          de: "",
          zh: "",
          vi: "",
          ro: "",
          ru: ""
        }
      }
    },
    restoreToDefault() {
      const self = this;
      new Promise(function (resolve) {
        self.tableData = [];
        resolve()
      }).then(function () {
        self.tableData = [
          {
            seq: '',
            icon: 'nc-icon nc-trophy',
            title: {
              en: self.$t('unbiasedTxt', 'en'),
              es: self.$t('unbiasedTxt', 'es'),
              pt: self.$t('unbiasedTxt', 'pt'),
              ar: self.$t('unbiasedTxt', 'ar'),
              fr: self.$t('unbiasedTxt', 'fr'),
              it: self.$t('unbiasedTxt', 'it'),
              de: self.$t('unbiasedTxt', 'de'),
              zh: self.$t('unbiasedTxt', 'zh'),
              vi: self.$t('unbiasedTxt', 'vi'),
              ro: self.$t('unbiasedTxt', 'ro'),
              ru: self.$t('unbiasedTxt', 'ru'),
            },
            description: {
              en: self.$t('unbiasedDescriptionTxt', 'en'),
              es: self.$t('unbiasedDescriptionTxt', 'es'),
              pt: self.$t('unbiasedDescriptionTxt', 'pt'),
              ar: self.$t('unbiasedDescriptionTxt', 'ar'),
              fr: self.$t('unbiasedDescriptionTxt', 'fr'),
              it: self.$t('unbiasedDescriptionTxt', 'it'),
              de: self.$t('unbiasedDescriptionTxt', 'de'),
              zh: self.$t('unbiasedDescriptionTxt', 'zh'),
              vi: self.$t('unbiasedDescriptionTxt', 'vi'),
              ro: self.$t('unbiasedDescriptionTxt', 'ro'),
              ru: self.$t('unbiasedDescriptionTxt', 'ru'),
            }
          }
          ,
          {
            seq: '',
            icon: 'fa fa-line-chart',
            title: {
              en: self.$t('brokerAgTxt', 'en'),
              es: self.$t('brokerAgTxt', 'es'),
              pt: self.$t('brokerAgTxt', 'pt'),
              ar: self.$t('brokerAgTxt', 'ar'),
              fr: self.$t('brokerAgTxt', 'fr'),
              it: self.$t('brokerAgTxt', 'it'),
              de: self.$t('brokerAgTxt', 'de'),
              zh: self.$t('brokerAgTxt', 'zh'),
              vi: self.$t('brokerAgTxt', 'vi'),
              ro: self.$t('brokerAgTxt', 'ro'),
              ru: self.$t('brokerAgTxt', 'ru'),
            },
            description: {
              en: self.$t('brokerAgDescriptionTxt', 'en'),
              es: self.$t('brokerAgDescriptionTxt', 'es'),
              pt: self.$t('brokerAgDescriptionTxt', 'pt'),
              ar: self.$t('brokerAgDescriptionTxt', 'ar'),
              fr: self.$t('brokerAgDescriptionTxt', 'fr'),
              it: self.$t('brokerAgDescriptionTxt', 'it'),
              de: self.$t('brokerAgDescriptionTxt', 'de'),
              zh: self.$t('brokerAgDescriptionTxt', 'zh'),
              vi: self.$t('brokerAgDescriptionTxt', 'vi'),
              ro: self.$t('brokerAgDescriptionTxt', 'ro'),
              ru: self.$t('brokerAgDescriptionTxt', 'ru'),
            }
          },
          {
            seq: '',
            icon: 'fa fa-connectdevelop',
            title: {
              en: self.$t('tradeCopyTxt', 'en'),
              es: self.$t('tradeCopyTxt', 'es'),
              pt: self.$t('tradeCopyTxt', 'pt'),
              ar: self.$t('tradeCopyTxt', 'ar'),
              fr: self.$t('tradeCopyTxt', 'fr'),
              it: self.$t('tradeCopyTxt', 'it'),
              de: self.$t('tradeCopyTxt', 'de'),
              zh: self.$t('tradeCopyTxt', 'zh'),
              vi: self.$t('tradeCopyTxt', 'vi'),
              ro: self.$t('tradeCopyTxt', 'ro'),
              ru: self.$t('tradeCopyTxt', 'ru'),
            },
            description: {
              en: self.$t('tradeCopyDescriptionTxt', 'en'),
              es: self.$t('tradeCopyDescriptionTxt', 'es'),
              pt: self.$t('tradeCopyDescriptionTxt', 'pt'),
              ar: self.$t('tradeCopyDescriptionTxt', 'ar'),
              fr: self.$t('tradeCopyDescriptionTxt', 'fr'),
              it: self.$t('tradeCopyDescriptionTxt', 'it'),
              de: self.$t('tradeCopyDescriptionTxt', 'de'),
              zh: self.$t('tradeCopyDescriptionTxt', 'zh'),
              vi: self.$t('tradeCopyDescriptionTxt', 'vi'),
              ro: self.$t('tradeCopyDescriptionTxt', 'ro'),
              ru: self.$t('tradeCopyDescriptionTxt', 'ru'),
            }
          },
        ];
      });
    },
    selectIcon(idx){
      this.clearModalData()
      this.modalData = this.tableData[idx]
      this.modals.iconPicker = true
    },
    saveIcon(selectedIcon) {
      this.modalData.icon ="fa "+ selectedIcon.className
    },
    getData(){
      return this.tableData
    },
  },
  mounted(){
    if(this.reference){
      this.tableData = this.reference
    }
  }
}
</script>

<style scoped>
.lift-abit{
  padding-bottom: 5px;
}
</style>
