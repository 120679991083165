<template>
    <div :class="`fg-autocomplete form-group ${hasLabel}`">
      <label v-if="label">{{ label }}</label>
      <autocomplete :source.sync="source" :error="error" :class="`form-control valid ${inputClasses}`"
                       v-on="$listeners"
                       v-bind="$attrs"
      ></autocomplete>
      <slot name="helpBlock">
        <div class="text-danger invalid-feedback" style="display: block;" v-if="error">
          {{ error }}
        </div>
      </slot>
    </div>
  </template>
  
  <script>
  import Vue from 'vue';
  import Autocomplete from 'vuejs-auto-complete'
  
  export default {
    components: {
    Autocomplete
   },
    name: "fg-autocomplete",
    props: {
      label: String,
      inputClasses: {
        type: String,
        default: '',
      },
      source: Array,
      error: String,
    },
    computed: {
      hasLabel() {
        return this.label ? 'has-label' : '';
      },
    }
  }
  </script>
  
  <style scoped lang="scss">
  @import '../../../assets/sass/paper/variables';
  .fg-autocomplete {
    .form-control.autocomplete::v-deep {
        padding: 0;
        border: none;
        .autocomplete__box {
            padding: 10px 5px 10px 5px;
        }
    }
  }

  </style>
  