<template>
  <module
      ref="module"
      id="debitPerformance"
      :title="$t('debit_your_followers')"
      titleIcon="fa fa-arrow-down"
      :filters.sync="filters"
      :visibilityFields.sync="tableColumns"
      :showVisibilityButton="true"
      apiUrl="MAINAPIURLV4"
      :url="`/${broker}/billing/debit`"
      :export-data-with-checkbox="true"
      :useExports="true"
      :exportHeader="headers"
      :export-data="exportData"
      @before-export="prepareExportData"
  >
    <template slot="toolbar-global">
      <div class="mr-1" v-if="isAdmin">
        <select class="form-control"
                v-model="brokerFiltered"
                @change="filterByBroker">
          <option selected disabled value="undefined">{{ $t('choosebroker') }}</option>
          <option v-for="broker in brokers" :value="broker">{{ broker }}</option>
          <option value="Your Broker">Your Broker</option>
        </select>
      </div>
      <p-button type="success" v-on:click="openModal">
        <i class="fa fa-credit-card"></i>
      </p-button>
    </template>
    <template slot="global">
      <approve-credit-debit-modal
          performance-type="debit"
          :entries="selectedRow"
          :selected-broker="broker"
          @on-close-modal-broker-performance="closeModalBrokerPerformance"
          :show-modal-confirm-performance="modalConfirmPerformance">
      </approve-credit-debit-modal>
    </template>
    <template slot="table-columns">

      <el-table-column type="selection" disabled="">
<!--         <template slot-scope="row"/>-->
      </el-table-column>

      <el-table-column
          :prop="tableColumns.id.prop"
          v-if="tableColumns.id.isVisible"
          :label="tableColumns.id.label"
          :min-width="tableColumns.id.width"
          :align="tableColumns.id.position"
          sortable
      >
        <template slot-scope="{row}">
          <b :class="statusIsSuccess(row.status) ? 'text-gray-dark' : 'text-bolder'">
            {{row.id}}
          </b>
        </template>
      </el-table-column>

      <el-table-column
          :prop="tableColumns.status.prop"
          v-if="tableColumns.status.isVisible"
          :label="tableColumns.status.label"
          :minWidth="tableColumns.status.width"
          :align="tableColumns.status.position"
          sortable
      >
        <template slot-scope="{row}">
          <b v-if="row.status === '20' ">
            <el-tooltip :content="$t('successtxt')" placement="top">
              <p-button link type="success" v-on:click="confirmAction(row, 'DEBITSUCCESS')">
                <i class="fa fa-check" ></i>
              </p-button>
            </el-tooltip>
            <el-tooltip :content="$t('failedtxt')" placement="top">
              <p-button link type="danger" v-on:click="confirmAction(row, 'DEBITFAILED')">
                <i class="fa fa-times"></i>
              </p-button>
            </el-tooltip>
          </b>
          <b :class="statusIsSuccess(row.status) ? 'text-gray-dark' : 'text-bolder'" v-else>
            <span class="p-badge badge-pill" :class="getBadgeType(row.status)" >
              {{ getStatusValue(row.status) }}
            </span>
          </b>

        </template>
      </el-table-column>

      <el-table-column
          :prop="tableColumns.date_reference.prop"
          v-if="tableColumns.date_reference.isVisible"
          :label="tableColumns.date_reference.label"
          :min-width="tableColumns.date_reference.width"
          sortable
      >
        <template slot-scope="{row}">
          <b :class="statusIsSuccess(row.status) ? 'text-gray-dark' : 'text-bolder'">
            {{ row.date_reference }}
          </b>
        </template>
      </el-table-column>

      <el-table-column
          :prop="tableColumns.follower_account.prop"
          v-if="tableColumns.follower_account.isVisible"
          :label="tableColumns.follower_account.label"
          :min-width="tableColumns.follower_account.width"
          sortable
      >
        <template slot-scope="{row}">
          <b :class="statusIsSuccess(row.status) ? 'text-gray-dark' : 'text-bolder'">
            {{row.follower_account}}
          </b>
        </template>
      </el-table-column>

      <el-table-column
          :prop="tableColumns.follower_currency.prop"
          v-if="tableColumns.follower_currency.isVisible"
          :label="tableColumns.follower_currency.label"
          :min-width="tableColumns.follower_currency.width"
          :align="tableColumns.follower_currency.position"
          sortable>
        <template slot-scope="{row}">
          <b :class="statusIsSuccess(row.status) ? 'text-gray-dark' : 'text-bolder'">
            {{ row.follower_currency }}
          </b>
        </template>
      </el-table-column>

      <el-table-column
          :prop="tableColumns.watermark_billable_profit_bc.prop"
          v-if="tableColumns.watermark_billable_profit_bc.isVisible"
          :label="tableColumns.watermark_billable_profit_bc.label"
          :align="tableColumns.watermark_billable_profit_bc.position"
          :min-width="tableColumns.watermark_billable_profit_bc.width"
          sortable
      >
        <template slot-scope="{row}">
          <b :class="statusIsSuccess(row.status) ? 'text-gray-dark' : 'text-bolder'">
            {{row.watermark_billable_profit_bc }} ({{row.follower_currency}})
          </b>
        </template>
      </el-table-column>

      <el-table-column
          :prop="tableColumns.effective_performance_value_bc.prop"
          v-if="tableColumns.effective_performance_value_bc.isVisible"
          :label="tableColumns.effective_performance_value_bc.label"
          :min-width="tableColumns.effective_performance_value_bc.width"
          :align="tableColumns.effective_performance_value_bc.position"
          sortable
      >
        <template slot-scope="{row}">
          <b :class="statusIsSuccess(row.status) ? 'text-gray-dark' : 'text-bolder'" class="text-success">
            {{ row.effective_performance_value_bc }} ({{row.follower_currency}})
            <small>
              ({{ parseFloat(row.effective_performance_percent * 100).toFixed(0)}}%)
            </small>
          </b>
        </template>
      </el-table-column>

      <el-table-column
          :prop="tableColumns.effective_performance_value_usd.prop"
          v-if="tableColumns.effective_performance_value_usd.isVisible"
          :label="tableColumns.effective_performance_value_usd.label"
          :min-width="tableColumns.effective_performance_value_usd.width"
          :align="tableColumns.effective_performance_value_usd.position"
          sortable
      >
        <template slot-scope="{row}">
          <b :class="statusIsSuccess(row.status) ? 'text-gray-dark' : 'text-bolder'" class="text-success">
            {{ row.effective_performance_value_usd }} (USD)
          </b>
        </template>
      </el-table-column>

      <el-table-column
          :prop="tableColumns.strategy_name.prop"
          v-if="tableColumns.strategy_name.isVisible"
          :label="tableColumns.strategy_name.label"
          :min-width="tableColumns.strategy_name.width"
          :align="tableColumns.strategy_name.position"
          sortable
      >
        <template slot-scope="{row}">
          <b :class="statusIsSuccess(row.status) ? 'text-gray-dark' : 'text-bolder'">
            {{ row.strategy_name }}
          </b>
        </template>
      </el-table-column>

      <el-table-column
          :prop="tableColumns.trader_account.prop"
          v-if="tableColumns.trader_account.isVisible"
          :label="tableColumns.trader_account.label"
          :align="tableColumns.trader_account.position"
          :min-width="tableColumns.trader_account.width"
          show-overflow-tooltip
          sortable
      >
        <template slot-scope="{row}">
          <b :class="statusIsSuccess(row.status) ? 'text-gray-dark' : 'text-bolder'">
            {{ row.trader_account }}
          </b>
        </template>
      </el-table-column>

      <el-table-column
          :prop="tableColumns.billing_type.prop"
          v-if="tableColumns.billing_type.isVisible"
          :label="tableColumns.billing_type.label"
          :align="tableColumns.billing_type.position"
          :min-width="tableColumns.billing_type.width"
          sortable
      >
        <template slot-scope="{row}">
          <b :class="statusIsSuccess(row.status) ? 'text-gray-dark' : 'text-bolder'">
            {{ row.billing_type }}
          </b>
        </template>
      </el-table-column>

      <el-table-column
          :prop="tableColumns.date_last_update.prop"
          v-if="tableColumns.date_last_update.isVisible"
          :label="tableColumns.date_last_update.label"
          :min-width="tableColumns.date_last_update.width"
          :align="tableColumns.date_last_update.position"
          sortable>
        <template slot-scope="{row}">
          <b :class="statusIsSuccess(row.status) ? 'text-gray-dark' : 'text-bolder'">
            {{ row.date_last_update }}
          </b>
        </template>
      </el-table-column>

      <el-table-column
          :prop="tableColumns.follower_debit_date.prop"
          v-if="tableColumns.follower_debit_date.isVisible"
          :label="tableColumns.follower_debit_date.label"
          :min-width="tableColumns.follower_debit_date.width"
          :align="tableColumns.follower_debit_date.position"
          sortable
      >
        <template slot-scope="{row}">
          <b :class="statusIsSuccess(row.status) ? 'text-gray-dark' : 'text-bolder'">
            {{ row.follower_debit_date }}
          </b>
        </template>
      </el-table-column>

       <el-table-column
          :prop="tableColumns.currency_exchange.prop"
          v-if="tableColumns.currency_exchange.isVisible"
          :label="tableColumns.currency_exchange.label"
          :min-width="tableColumns.currency_exchange.width"
          :align="tableColumns.currency_exchange.position"
          sortable
      >
        <template slot-scope="{row}">
          <b :class="statusIsSuccess(row.status) ? 'text-gray-dark' : 'text-bolder'">
            {{ row.currency_exchange }}
          </b>
        </template>
      </el-table-column>


      <el-table-column
          :prop="tableColumns.comment.prop"
          v-if="tableColumns.comment.isVisible"
          :label="tableColumns.comment.label"
          :align="tableColumns.comment.position"
          :min-Width="tableColumns.comment.width"
          show-overflow-tooltip
          sortable
      >
        <template slot-scope="{row}">
          <b :class="statusIsSuccess(row.status) ? 'text-gray-dark' : 'text-bolder'">
            {{ row.comment }}
          </b>
        </template>
      </el-table-column>

    </template>
    <template slot="filter-field">
      <div class="form-group">
        <label>{{ $t('status') }}</label>
        <el-select v-model="filters.status" clearable>
          <el-option value="pending" :label="$t('pendingtxt')"></el-option>
          <el-option value="fail" :label="$t('failedtxt')"></el-option>
          <el-option value="success" :label="$t('successtxt')"></el-option>
        </el-select>
      </div>
      <fg-input v-model="filters.follower_account" :label="$t('account')"></fg-input>
      <fg-input v-model="filters.strategy" :label="$t('strategytxt')"></fg-input>
      <fg-input :label="$t('date_begin')">
        <date-picker
            id="rebrand-datapick"
            v-model="filters.date_reference">
        </date-picker>
      </fg-input>

      <fg-input :label="$t('date_end')">
        <date-picker
            id="rebrand-datapick"
            v-model="filters.date_close">
        </date-picker>
      </fg-input>


    </template>
    <template slot="visibility-field"></template>
  </module>
</template>

<script>
import Vue from "vue";
import {Option, Select, Table, TableColumn, Tooltip} from "element-ui";
import DatePicker from "@/components/UIComponents/Inputs/DatePicker";
import Constants from "../../../../../../assets/constants";
import {Badge, Modal} from "src/components/UIComponents";
import {getCurrency} from "../../../../../../util/CurrencyUtils";
import ta from "vue2-datepicker/locale/es/ta";
import swal from "sweetalert2";
import ApproveCreditDebitModal from "@/components/Dashboard/Views/Dashboard/Billing/Broker/ApproveCreditDebitModal.vue";


const rules = Constants.methods.getRules();
const visibility = localStorage.getItem('visibility_items');
const json = visibility ? JSON.parse(visibility) : null;


Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
export default {
  name: "BrokerPerformance",
  components: {
    ApproveCreditDebitModal,
    Modal,
    Badge,
    DatePicker,
    [Tooltip.name]: Tooltip,
  },
  computed: {
    ta() {
      return ta
    },
    broker() {
      if (rules.isAdmin) {
        return this.brokerFiltered || 'a';
      }
      return rules.broker || 'a';
    },
    isAdmin() {
      return rules.isAdmin;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to)
    },
    queriedData() {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length
        return this.pagedData
      }
      let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              //let rowValue = row[key].toString()
              let searchRegex = new RegExp(this.searchQuery, 'gi');
              //if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
              if (row[key].toString().match(searchRegex) !== null) {
                isIncluded = true
              }
            }
            return isIncluded
          })
      this.pagination.total = result.length
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    }
  },
  data() {
    let self = this;
    return {
      inprogress: true,
      searchQuery: '',
      propsToSearch: ['userid'],
      filters: {},
      brokerFiltered: undefined,
      brokers: [],
      exportData: [],
      headers: [
        "id",
        self.$t('followertxt'),
        self.$t('strategytxt'),
        self.$t('currency'),
        self.$t('profit'),
        self.$t('percent_pf'),
        self.$t('perf_fee'),
        self.$t('perf_fee')+'(USD)',
        self.$t('provider'),
        self.$t('billing_type'),
        self.$t('status'),
        self.$t('lastupdate'),
        self.$t('date_reference'),
        self.$t('paid_on'),
        self.$t('description'),
        self.$t('helper_excentry')
      ],
      tableColumns: {
        id: {
          prop: 'id',
          label: self.$t('id'),
          isVisible: false,
          width: '45px',
          position: 'center'
        },
        follower_account: {
          prop: 'follower_account',
          label: self.$t('followertxt'),
          isVisible: true,
          width: '160px',
          position: 'center',
        },
        strategy_name: {
          prop: 'strategy_name',
          label: self.$t('strategytxt'),
          isVisible: true,
          width: '100px',
          position: 'left',
        },
        follower_currency: {
          prop: 'follower_currency',
          label: self.$t('currency'),
          isVisible: false,
          width: '80px',
          position: 'center',
        },
        watermark_billable_profit_bc: {
          prop: 'watermark_billable_profit_bc',
          label: self.$t('profit'),
          isVisible: false,
          width: '100px',
          position: 'right'
        },
        effective_performance_percent: {
          prop: 'effective_performance_percent',
          label: self.$t('percent_pf'),
          isVisible: true,
          width: '100px',
          position: 'right'
        },
        effective_performance_value_bc: {
          prop: 'effective_performance_value_bc',
          label: self.$t('perf_fee'),
          isVisible: true,
          width: '100px',
        },
        effective_performance_value_usd: {
          prop: 'effective_performance_value_usd',
          label: self.$t('perf_fee')+'(USD)',
          isVisible: false,
          width: '100px',
        },
        trader_account: {
          prop: 'trader_account',
          label: self.$t('provider'),
          isVisible: false,
          width: '100px',
          position: 'center'
        },
        billing_type: {
          prop: 'billing_type',
          label: self.$t('billing_type'),
          isVisible: false,
          width: '100px',
          position: 'center'
        },
        status: {
          prop: 'status',
          label: self.$t('status'),
          isVisible: true,
          width: '60px',
          position: 'center',
        },
        date_last_update: {
          prop: 'date_last_update',
          label: self.$t('lastupdate'),
          isVisible: false,
          width: '100px',
          position: 'center',
        },
        date_reference: {
          prop: 'date_reference',
          label: self.$t('date_reference'),
          isVisible: true,
          width: '100px',
          position: 'center',
        },
        follower_debit_date: {
          prop: 'follower_debit_date',
          label: self.$t('paid_on'),
          isVisible: true,
          width: '100px',
          position: 'center'
        },
        comment: {
          prop: 'comment',
          label: self.$t('description'),
          isVisible: false,
          width: '330px',
          position: 'left'
        },
        currency_exchange: {
          prop: 'currency_exchange',
          label: self.$t('helper_excentry'),
          isVisible: false,
          position: 'center',
          width: '110px'
        },
      },
      selectedRow: [],
      allRows: [],
      tableData: [],
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      modalConfirmPerformance: false,
      pickerOptions1: {
        shortcuts: [{
          text: 'Today',
          onClick(picker) {
            picker.$emit('pick', new Date())
          }
        },
          {
            text: 'Yesterday',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: 'A week ago',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }]
      },
      datePicker: '',
    }
  },
  methods: {
    canSelectRow(row, index) {
      return row.status != '30'
    },
    openModal() {
      if (this.$refs.module.checkedRows.length === 0) {
        return Vue.$toast.warning(this.$t('mustSelectAccount'));
      } else {
        this.allRows = this.$refs.module.checkedRows
        this.selectedRow = this.$refs.module.checkedRows
        for (let i = 0; i < this.selectedRow.length; i++) {
          if (this.statusIsSuccess(this.selectedRow[i].status)) {
            this.selectedRow = []
            return Vue.$toast.warning(this.$t('cantSelectClosed'));
          }
        }
        this.modalConfirmPerformance = true
      }
    },
    closeModalBrokerPerformance() {
      this.modalConfirmPerformance = false
      this.selectedRow = []
      setTimeout(this.$refs.module.refreshList(), 4500);
    },
    async fillCheck() {
      return this.$refs.module.checkedRows.length === 0 ? [] : this.$refs.module.checkedRows
    },
    getCurrencySymbol(code) {
      return getCurrency(code);
    },
    getBadgeType(type){
      if (type === "21") {
        return 'badge-danger'
      }
      else if ( type >= "30") {
        return 'badge-success'
      }
      return 'badge-default'
    },
    getStatusValue(type) {
      if (type === '20') {
        return this.$t('pendingtxt')
      } else if (type === '21') {
        return this.$t('failedtxt')
      } else if (type >= '30') {
        return this.$t('successtxt')
      }
    },
    statusIsSuccess(status){
      if (parseInt(status) >= 30) {
        return true
      }
      return false
    },
    filterByBroker() {
      this.$refs.module.tableLoading = true;
      this.$refs.module.search();
    },
    confirmAction(row, action) {
      swal({
            text: this.$t('sure_wanna_proceed'),
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCancelButton: true,
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger btn-fill',
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
            }).then( () => { this.performAction(row, action) }).catch(swal.noop);
    },
    performAction(row, action) {
      // let entries = [row] TODO: bulk
      let param = {
        'performance_id': row.id,
        'status': action,
        'type': 'debit',
        'broker': this.broker
      }
      let success = resp => {
        if(!resp.success) {
          return fail(resp)
        }
        this.$toast.success(this.$t(resp.msg))
        this.refreshTable()
      }
      let fail = resp => {
        let msg = resp.data.msg ? resp.data.msg : 'rdpdownmsg'
        this.$toast.warning(this.$t(msg))
        this.refreshTable()
      }
      this.$resolveInternalBrokerPayment(param).then(success, fail)
    },
     refreshTable(){
      this.$refs.module.refreshList()
    },
    prepareExportData(resultList) {
      this.exportData = resultList.map(obj => {
        return [
            obj.id,
            obj.follower_account,
            obj.strategy_name,
            obj.follower_currency,
            obj.watermark_billable_profit_bc + " (" + obj.follower_currency + ")",
            parseFloat(obj.effective_performance_percent * 100).toFixed(0) + "%",
            obj.effective_performance_value_bc + " (" + obj.follower_currency + ")",
            obj.effective_performance_value_usd + " (USD)",
            obj.trader_account,
            obj.billing_type,
            this.getStatusValue(obj.status),
            obj.date_last_update,
            obj.date_reference,
            obj.follower_debit_date,
            `"${obj.comment}"`,
            obj.currency_exchange
        ];
      });
    }
  },
  beforeMount() {
    const self = this;
    this.$listBrokersInternalBilling()
        .then(resp => {
          self.brokers = resp.data.map(broker => {
            return broker.name
          });
        });
  },
  mounted() {
  },
}
</script>

<style scoped lang="scss">
#brokerPerformance::v-deep {
  .cell {
    white-space: nowrap;
  }
}

#rebrand-datapick::v-deep {
  width: 100%;
}
</style>
