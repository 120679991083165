import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '../components/locales/en.json'
import fr from '../components/locales/fr.json'
import pt from '../components/locales/pt.json'
import it from '../components/locales/it.json'
import es from '../components/locales/es.json'
import ru from '../components/locales/ru.json'
import ro from '../components/locales/ro.json'
import ar from '../components/locales/ar.json'
import de from '../components/locales/de.json'
import vi from '../components/locales/vi.json'
import zh from '../components/locales/zh_Hant.json'
import zhs from '../components/locales/zh_Hans.json'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en, fr, pt, it, es, ru, ro, ar, de, vi, zh, zhs},
  silentTranslationWarn: true,
  missingWarn: false,
});
