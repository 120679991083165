<template>
  <div class="container rebrand">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <card type="lock" class="text-center">
        <div class="col-md-12">
          <RemoteImg :src="headerlogo" size="150px"></RemoteImg>
        </div>
        <h6 class="card-title">
          {{ appname }}
        </h6>

        <div v-if="!submitting">
          <div class="text-danger invalid-feedback mt-2 pb-2" style="display: block;" v-if="error">
            {{ $t(error_msg) }}
          </div>

          <div class="text-left">
            <label>{{ passwordTxt }}</label>
            <fg-pass v-model="form.password"
                     v-validate="modelValidations.password"
                     :placeholder="passwordTxt"
                     type="password"
                     :name="passwordTxt"
                     :error="getError('createpassword')"></fg-pass>
          </div>
          <div class="text-left">
            <label>{{ confirmpasswordTxt }}</label>
            <fg-pass v-model="form.confirmpassword"
                     v-validate="modelValidations.confirmpassword"
                     :placeholder="confirmpasswordTxt"
                     type="password"
                     :name="confirmpasswordTxt"
                     :error="getError('confirmpassword')"></fg-pass>
          </div>

          <div class="row text-center">
            <div class="col-md-12">
              <vue-hcaptcha
                  sitekey="7736ab28-5f0f-4eb9-810c-ca6f46e64b30"
                  ref="recaptcha"
                  @verify="verify"
              ></vue-hcaptcha>
            </div>
          </div>

          <p-button slot="footer" type="default" round outline @click.prevent="validate">
            {{ $t('resettxt') }}
          </p-button>
        </div>
        <div v-if="submitting">
          <div class="board">
            <h2>{{ $t('submitting') }}</h2>
            <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
          </div>
        </div>

      </card>
    </div>
    <app-footer></app-footer>
  </div>
</template>
<script>
import AppNavbar from './Layout/AppNavbar'
import AppFooter from './Layout/AppFooter2'
import {Card, Button, FgInput} from 'src/components/UIComponents';
import Constants from 'src/assets/constants'
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";

export default {
  components: {
    Card,
    AppNavbar,
    AppFooter,
    FgInput,
    VueHcaptcha,
    [Button.name]: Button
  },
  /**
   * This component is used for set a new password(after validating token)
   */
  data() {
    return {
      submitting: false,
      nocache: Math.round(new Date().getTime() / 1000),
      appname: '',
      headerlogo: "/static/img/hoko-new-logo.png",
      token: null,
      captcha_token: null,
      form: {
        password: '',
        confirmpassword: ''
      },
      passwordTxt: this.$t('createpassword'),
      confirmpasswordTxt: this.$t('confirmpassword'),
      modelValidations: {
        password: {
          required: true,
          min: 8
        },
        confirmpassword: {
          required: true,
          min: 8
        }
      },
      error: false,
      error_msg: '',
      msg: ''
    }
  },
  methods: {
    verify(resp) {
      this.captcha_token = resp;
    },
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    },
    goLogin() {
      const test = function (error) {
        console.log(JSON.stringify(error))
      }
      this.auth.login({
        username: this.userid,
        password: this.form.password,
      }, test);
    },
    getError(fieldName) {
      fieldName = this.$t(fieldName);
      return this.errors.first(fieldName);
    },
    reset_success(resp) {
      this.submitting = true;
      if (!resp.success) {
        return this.reset_fail(resp)
      }
      const self = this;
      this.msg = this.$t('resetsuccess');
      setTimeout(function () {
        self.goLogin();
      }, 3)
    },
    reset_fail(error) {
      this.submitting = false;
      if (!error.msg) {
        this.error = 'passwordrule';
        this.error_msg = this.$t('passwordrule');
      } else {
        this.error = error.msg;
        this.error_msg = error.msg;
      }
    },
    validate() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          return
        }
        if (this.form.password != this.form.confirmpassword) {
          this.reset_fail({
            data: {
              error: true,
              msg: this.$t('passwordmismatch'),
            }
          });
          return;
        }
        this.submitting = true;
        // Reset the password for user
        this.$resetPassword(
            this.form.password,
            this.userid,
            this.token,
            this.captcha_token,
        ).then(this.reset_success, this.reset_fail);
      });
    }
  },
  mounted() {
    try {
      let wlSettings = localStorage.getItem('wlSettings');
      if (wlSettings) {
        let settings = JSON.parse(wlSettings);
        this.headerlogo = settings.logo_login;
        this.appname = settings.fullname;
        const bodyEl = document.getElementsByTagName('body')[0];
        bodyEl.style.backgroundColor = settings.bgcolor;
        if (settings.bgimg_login) {
          bodyEl.style.backgroundImage = `url('${settings.bgimg_login}')`;
        }
        const rootEl = document.querySelector(':root');
        if (settings.bgcolor_btn1) {
          rootEl.style.setProperty('--btnColor1', settings.bgcolor_btn1);
        }
        if (settings.bgcolor_btn2) {
          rootEl.style.setProperty('--btnColor2', settings.bgcolor_btn2);
        }
      } else {
        document.getElementsByTagName('body')[0].style.backgroundColor = '#210896';
      }
    } catch (e) {
      console.error(e)
    }
    this.userid = this.$route.params.userid;
    this.token = this.$route.params.token;
  },
  beforeDestroy() {
    this.closeMenu()
  }
}
</script>
<style scoped lang="scss">
.content::v-deep .remoteImg {
  margin-bottom: 15px;
}

.board {
  padding-top: 50px;
  padding-bottom: 50px;
}

.rebrand {
  margin-top: 18vh;
}
</style>
