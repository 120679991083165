import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

import messenger from './modules/messenger';
import marketplace from "@/store/modules/marketplace";
import marketplaceFollowStrategy from "@/store/modules/marketplace_follow_strategy";
import removeAccountsFromStrategy from "@/store/modules/remove_accounts_from_strategy";
import strategy_params from './modules/strategy_params';
import blacklist from "@/store/modules/blacklist";
import users_manager from "@/store/modules/users_manager";
import deleteMultipleAccounts from "@/store/modules/delete_multiple_accounts";
import becomeSellerBuyer from "@/store/modules/become_seller_buyer";
import whitelabel from "@/store/modules/whitelabel";
import forceRevalidation from "@/store/modules/force_revalidation";
import parameter_preview from "@/store/modules/parameter_preview";
import copy_all_trades_confirmation from "@/store/modules/copy_all_trades_confirmation";


export default new Vuex.Store({
  modules: {
    messenger,
    marketplace,
    marketplaceFollowStrategy,
    removeAccountsFromStrategy,
    deleteMultipleAccounts,
    strategy_params,
    blacklist,
    users_manager,
    becomeSellerBuyer,
    whitelabel,
    forceRevalidation,
    parameter_preview,
    copy_all_trades_confirmation
  },
  state: {
    appVersion: process.env.VUE_APP_VERSION || '0',
    vpsList: [],
    vpsApprovalList: [],
    whitelabelLogos: []
  },
  mutations: {
  },
  getters: {
    appVersion: (state) => {
      return state.appVersion
    },
  },
});
