<template>
  <div class="row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4 text-center">

      <p-button type="white" outline round v-on:click="goLogin">
        {{ $t('logintofollow' )}}
      </p-button>

    </div>

    <div class="col-md-4 text-center">

      <p-button type="white" outline round v-on:click="register">
        {{ $t('registertofollowtxt')}}
      </p-button>

    </div>
    <div class="col-md-2">&nbsp;</div>
  </div>
</template>

<script>
  export default {
    name: 'login-register-follow',
    components: {},
    props: [ 'strategy','wlId' ],
    methods: {
      goLogin () {
        location.href = "/"+this.wlId;
      },
      register() {
        location.href = "/register/"+ this.strategy;
      }
    }
  }
</script>

<style scoped>
</style>
