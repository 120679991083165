import Vue from 'vue'
import './pollyfills'
import {default as store} from './store/index.js'
import VueRouter from 'vue-router'
import VueToast  from 'vue-toast-notification'
import Utils from './plugins/utils'
import VeeValidate from 'vee-validate'
// i18n
//import VueI18n from 'vue-i18n'// 'src/assets/i18n-plugin.js'
import { i18n } from 'src/assets/i18n-plugin.js'
import DashAPI from './assets/dashapi.js'
import Helper from './assets/helper.js'
//import axios from 'axios'

//import lang from 'element-ui/lib/locale/lang/en'
import lang from './components/locales/en'
import locale from 'element-ui/lib/locale'
import App from './App.vue'

// Plugins
import AuthService from 'src/assets/authService.js'
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import SideBar from './components/UIComponents/SidebarPlugin'
import initProgress from './progressbar';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.plugins.unregister(ChartDataLabels);

// router setup
import routes from './routes/main-routes'

// library imports

import './assets/sass/paper-dashboard.scss'
//import './assets/sass/element_variables.scss'
import './assets/sass/demo.scss'
import './assets/sass/custom.scss'

import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import sidebarLinks from './sidebarLinks'
import './registerServiceWorker'
// Cookies
import VueCookies from 'vue-cookies'
// plugin setup
Vue.use(VueRouter)
Vue.use(GlobalDirectives)
Vue.use(GlobalComponents)
Vue.use(VueCookies)
Vue.use(SideBar, {sidebarLinks: sidebarLinks})
Vue.use(VeeValidate)
Vue.use(DashAPI)
Vue.use(Helper)
Vue.use(VueToast,  {
    position: 'top',
    duration: 4000
})
Vue.use(Utils)

Vue.config.productionTip = false;

locale.use('lang')

// Configure Cookies
//VueCookies.config('15min');
VueCookies.config('2h');

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: 'active'
})

const auth = new AuthService()
/*Mixins are flexible way to distribute reusable funcionalities for components.
When a component uses a mixin, all options in the mixin will be “mixed” into the component’s own options.
In this case, every single component will contain this mixin
*/
Vue.mixin({
  data () {
    return {
      auth,
      authenticated: auth.authenticated
    }
  }
})

router.beforeEach((to, from, next) => {
  //console.log(from);
  //console.log(to);
  //console.log(window.location.search);
  const datetime = Date.now();

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // Check for Login Access Token
    const atoken = VueCookies.get('dash-at');
    let uinfo = localStorage.getItem('user-info');
    // if not, redirect to login page.
    if (!atoken || !uinfo) {
      localStorage.removeItem('user-info');
      localStorage.removeItem('user-lang');
      localStorage.removeItem('needtoverify');
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      // Get Lang for user
      let lang = localStorage.getItem('user-lang');
      // Set proper Language for User
      if (!['en', 'fr', 'pt', 'it', 'es', 'ru', 'ro','ar', 'zh', 'zhs', 'vi', 'de'].includes(lang)) {
        lang = 'en';
      }
      if (i18n.locale !== lang) {
        i18n.locale = lang;
      }

      // Email has not been verified
      if (uinfo) {
        uinfo = JSON.parse(uinfo);
        if (uinfo.email_verified === false) {
          //localStorage.removeItem('dash-at');
          VueCookies.remove('dash-at');
          localStorage.removeItem('user-info');
          localStorage.setItem('needtoverify', true);
          next({ path: '/login' })
        }
        else {
          localStorage.setItem('needtoverify', false);
        }
      }
      // Proceed
      //loadLanguageAsync(lang).then(() => next())
      next()
    }
  } else {
    //loadLanguageAsync('en').then(() => next())
    next() // make sure to always call next()!
  }
});

initProgress(router);
// Inactivity
const timeoutInMiliseconds = 60000 * 15; // 15 minute timeout of inactivity
let timeoutId;

let resetTimer = function() {
    VueCookies.remove('dash-inactive'); // restart counter if there is activity on any tab using same session
    window.clearTimeout(timeoutId);
    startTimer();
};
let startTimer = function() {
    // window.setTimeout returns an Id that can be used to start and stop a timer
    timeoutId = window.setTimeout(doInactive, timeoutInMiliseconds)
};
let doInactive = function() {
    VueCookies.set('dash-inactive', true);
    window.clearTimeout(timeoutId);
    // does whatever you need it to actually do - probably signs them out or stops polling the server for info
    console.log("Inactivity");
};
let setupTimers = function() {
    document.addEventListener("mousemove", resetTimer, false);
    document.addEventListener("mousedown", resetTimer, false);
    document.addEventListener("keypress", resetTimer, false);
    document.addEventListener("touchmove", resetTimer, false);
    // Start Timer
    startTimer();
};
//console.log(VueCookies.get('dash-at'));
// Only run this after login
if (VueCookies.get('dash-at') !== null) {
  setupTimers();
}

/* eslint-disable no-new */
new Vue({
  i18n,
  el: '#app',
  ...App,
  store,
  router
})
