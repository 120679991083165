<template>
    <div :id="id">

      <div v-if="useCardTop" class="row">
        <div class="card module col-md-12">
          <div class="card-header row">
            <div class="card-body">
              <slot name="global-top"></slot>
            </div>
          </div>
        </div>
      </div>

      <!-- In progress -->
      <div v-if="inProgress" class="text-center">
        <h2>{{ $t('loadingtxt') }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>
      <!-- Module -->
      <div v-else class="row">
        <div class="card module nested col-md-12">
          <div class="card-header row">
            <div class="col-md-5">
              <h5 v-if="title">
                <i v-if="!nested" :class="`${titleIcon}`"></i>
                {{ title }}
              </h5>
              <slot v-else name="title"></slot>
            </div>
            <div class="col-md-7" style="margin-top: auto; margin-bottom: 0.5rem;">
              <div class="toolbar float-right">
                <div class="row mr-0" v-if="currentStep === 0">
                  <slot name="toolbar-global"></slot>

                  <slot name="toolbar-buttons-slot"></slot>

                  <div class="btn-group mr-1" role="group">
                    <slot name="toolbar-lst" v-bind:module="this"></slot>
                  </div>
                  <div class="btn-group mr-1" role="group" v-if="useExports">
                    <p-button type="warning" :title="$t('exportcsv')" @click="refreshList(true)">
                      <i class="fa fa-download"></i>
                    </p-button>
                  </div>
                  <div class="btn-group mr-1" role="group" v-if="useDefaultCrud">
                    <p-button type="info" v-if="!hideInsertButton" @click="add">
                      <i class="fa fa-plus-square"></i>
                    </p-button>
                    <p-button type="info" v-if="!hideEditButton" @click="edt">
                      <i class="fa fa-edit"></i>
                    </p-button>
                    <p-button slot="reference" type="danger" v-if="!hideDeleteButton" @click="del">
                      <i class="fa fa-trash"></i>
                    </p-button>
                  </div>
                  <div class="btn-group mr-1" role="group" v-if="useDefaultList">
                    <p-button @click="refreshList">
                      <i class="fa fa-refresh"></i>
                    </p-button>
                    <p-button v-if="!hideSearchButton" @click="toggleSide">
                      <i class="fa fa-search"></i>
                    </p-button>
                    <p-button v-if="showVisibilityButton" @click="toggleVisibilitySide">
                      <i class="fa fa-eye"></i>
                    </p-button>
                  </div>
                  <VideoTutorial></VideoTutorial>
                </div>
                <div v-if="useDefaultCrud && (currentStep === 1 || currentStep === 2)">
                  <div class="btn-group mr-1" role="group">
                    <slot name="toolbar-edt"></slot>
                    <p-button type="success" style="filter:hue-rotate(45deg);" @click="save">
                      <i class="fa fa-save"></i> {{ $t('save') }}
                    </p-button>
                    <p-button type="warning" @click="back">
                      <i class="fa fa-ban"></i> {{ $t('back') }}
                    </p-button>
                  </div>
                </div>
              </div>
            </div>
            <hr class="w-100">
          </div>
          <div class="card-body">
            <slot name="global"></slot>
            <div class="list" v-if="useDefaultList && currentStep === 0">
              <el-container class="module-body">
                <el-main>
                  <data-tables-server ref="datatable"
                                      v-loading="tableLoading"
                                      :total="total"
                                      :data="data"
                                      :table-props="configTable"
                                      :pagination-props="configPaginator"
                                      :filters="listFilters"
                                      @query-change="loadData"
                                      @current-change="onSelect"
                                      @selection-change="onCheck"
                  >
                    <div slot="empty">{{ $t('norecordsfounded') }}</div>
                    <span slot="pagination" class="el-pagination__total ml-2">{{$t('total_records_found',[total])}}</span>
                    <el-table-column v-if="useMultiSelect" type="selection"  width="40" />
                    <slot name="table-columns"></slot>
                  </data-tables-server>
                  <slot name="after-tc"></slot>
                </el-main>
                <el-aside :width="expandedSide">
                  <fieldset>
                    <label>{{ $t('filterbyelement') }}</label>
                    <slot name="filter-field"></slot>
                    <p-button type="primary" @click="search">{{$t('searchtxt')}}</p-button>
                    <p-button type="default" @click="clearFilters" style="float: right;">{{$t('clearfilter')}}</p-button>
                  </fieldset>
                  <slot name="side"></slot>
                </el-aside>
                <el-drawer
                  :title="advancedFilterTitle"
                  :visible.sync="openAdvancedFilter"
                  :size="getMenuDrawerSize"
                  :before-close="handleClose"
                  direction="rtl"
                  custom-class="demo-drawer"
                  ref="drawer"
                  class="marketplace-drawer">
                    <div>
                      <el-row class="fixed-header">
                        <el-col class="padding">
                          <p-button type="info" @click="search" :loading="advancedFilterLoading" round>
                            <i class="fa fa-filter"></i>
                            {{$t('applyfilter')}}
                          </p-button>
                          <p-button type="danger" @click="clearFilters" round><i class="fa fa-trash"></i></p-button>
                        </el-col>
                      </el-row>
                    </div>

                    <div class="padding">
                      <el-row>
                        <el-col>
                          <slot name="advanced-filter-field"></slot>
                        </el-col>
                      </el-row>
                    </div>

                </el-drawer>
                <el-aside :width="expandedVisibilitySide">
                  <fieldset>
                    <label>{{$t('visibilitycolumnstxt')}}</label>
                    <slot name="visibility-field">
                      <div class="form-group has-label">
                        <div class="row margin-top" v-for="column in listVisibilityItems">
                          <div class="col text-left-center margin-top"><span>{{column.label}}</span></div>
                          <div class="col text-right margin-top">
                            <p-switch class="" v-model="column.isVisible" @input="onChangeSwitch"></p-switch>
                          </div>
                        </div>
                      </div>
                    </slot>
                  </fieldset>
                  <slot name="side"></slot>
                </el-aside>
              </el-container>
            </div>
            <div class="edt" v-if="useDefaultCrud && (currentStep === 1 || currentStep === 2)">
              <slot name="edt"></slot>
            </div>
            <slot name="steps"></slot>
          </div>
          <div class="card-footer"></div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import Vue from 'vue';
  import { Loading, Pagination, Container, Main, Aside, Drawer, Form, FormItem } from 'element-ui';
  import { DataTablesServer } from 'vue-data-tables';
  import swal from "sweetalert2";
  import { ListPayload, exportCSVFile } from "src/models/commons";
  import Constants from 'src/assets/constants.js';
  import VideoTutorial from "src/components/UIComponents/VideoTutorial";
  import PSwitch from "src/components/UIComponents/PSwitch";

  const CONST = Constants.methods;

  Vue.use(Aside);
  Vue.use(Container);
  Vue.use(DataTablesServer);
  Vue.use(Loading);
  Vue.use(Main);
  Vue.use(Pagination);
  Vue.use(Drawer);
  Vue.use(Form);
  Vue.use(FormItem);

  export default {
    name: 'Module',
    components: {VideoTutorial, PSwitch},
    props: {
      id: {
        type: String,
        description: 'ID for component reference',
      },
      title: {
        type: String,
        description: 'Title of the page',
      },
      titleIcon: {
        type: String,
        description: 'Icon of the page title',
      },
      value: {
        type: Object,
        description: 'Model used to edit form',
        default: () => {},
      },
      rules: {
        type: Object,
        description: 'Rules used to validate edit form',
        default: () => {},
      },
      filters: {
        type: Object,
        description: 'Filters used in filters form (not passed directly to list to avoid continuous refresh)',
        default: () => {},
      },
      visibilityFields: {
        type: Object,
        description: 'Used for show or not the fields on header in table',
        default: () => {},
      },
      fixedFilters: {
        type: Object,
        description: 'Filters to send fixed into list requests)',
        default: () => {},
      },
      apiUrl: {
        type: 'MAINAPIURL' | 'ACCAPIURL' | 'ACCAPIURL_V4' | 'MESSENGERURL' | 'WALLETURL' | 'MAINAPIURLV4' | 'WLADMINAPIURL',
        description: 'API base url',
        default: '',
      },
      url: {
        type: String,
        description: 'Url used to consume list endpoint',
        default: '',
      },
      useDefaultList: {
        type: Boolean,
        description: 'If false will deactivate the "R" default flow of a CRUD',
        default: true,
      },
      useMultiSelect: {
        type: Boolean,
        description: 'If true will activate the multiselect by checkbox function',
        default: false,
      },
      useExports: {
        type: Boolean,
        description: 'If true will show export buttons on list',
        default: false,
      },
      useDefaultCrud: {
        type: Boolean,
        description: 'If true will active the "C-U-D" default flows of a CRUD else it will mount only list and custom steps',
        default: false,
      },
      hideInsertButton: {
        type: Boolean,
        description: 'If true will hide insert button on toolbar-list',
        default: false,
      },
      hideEditButton: {
        type: Boolean,
        description: 'If true will hide edit button on toolbar-list',
        default: false,
      },
      hideDeleteButton: {
        type: Boolean,
        description: 'If true will hide delete button on aside',
        default: false,
      },
      hideSearchButton: {
        type: Boolean,
        description: 'If true will hide advanced-search button and search button on aside',
        default: false,
      },
      showVisibilityButton: {
        type: Boolean,
        description: 'If true will hide advanced-visibility fields',
        default: false,
      },
      labelWidth: {
        type: String,
        description: 'Default with to be passed to the edition form',
        default: 'auto',
      },
      exportHeader: {
        type: Array,
        description: 'Headers array to use in export function',
        default: () => [],
      },
      exportData: {
        type: Array,
        description: 'Data array to use in export function',
        default: () => [],
      },
      exportSeparator: {
        type: String,
        description: 'Character to be used as separator in export function',
        default: ',',
      },
      nested: {
        type: [Boolean],
        default: false,
        description: "Flag defined to inform if the card is nested inside another card",
      },
      useCardTop: {
        type: Boolean,
        description: 'If true will show a card at top',
        default: false,
      },
      showAdvancedFilter: {
        type: Boolean,
        default: false,
        description: 'If true should show advanced filter'
      },
      advancedFilterTitle: {
        type: String,
        default: '',
        description: 'Title of advanced filter'
      },
      exportDataWithCheckbox: {
        type: Boolean,
        default: true,
        description: 'If true should export data only selected checkbox value'
      },
    },
    computed: {
      configTable() {
        if (this.listFilters.length === 0) {
          this.listFilters = this.$noBlankProps({...this.filters, ...this.fixedFilters});
        }
        return {
          height: document.querySelector('.content').clientHeight - 190,
          highlightCurrentRow: true,
          stripe: true,
        };
      },
      configPaginator() {
        return {
          layout: "sizes, prev, pager, next, jumper, slot, ->"
        };
      },
      getMenuDrawerSize() {
        if (this.windowSize >= 1920) {
          return '20%'
        } else  if (this.windowSize > 1280 && this.windowSize < 1920) {
          return '25%'
        } else if (this.windowSize >= 1000 && this.windowSize <= 1280) {
          return '35%'
        } else if (this.windowSize > 600 && this.windowSize < 1000) {
          return '40%'
        } else {
          return '100%'
        }
      },
    },
    data() {
      return {
        exporting: false,
        expandedSide: '0%',
        expandedVisibilitySide: '0%',
        currentStep: 0,
        selectedRow: undefined,
        checkedRows: [],
        sendRows: [],
        data: [],
        listFilters: [],
        listVisibilityItems: [],
        total: 0,
        inProgress: true,
        tableLoading: true,
        advancedFilterLoading: false,
        openAdvancedFilter: false,
        closingDrawer: false,
        windowSize: window.innerWidth,
        apis: {
          MAINAPIURL: CONST.mainapiurl(),
          MAINAPIURLV4: CONST.mainapiurlV4(),
          ACCAPIURL: CONST.accapiurl(),
          ACCAPIURL_V4: CONST.accapiurlV4(),
          MESSENGERURL: CONST.messengerurl(),
          WALLETURL: CONST.walleturl(),
          WLADMINAPIURL: CONST.wladminapiurl()
        }
      }
    },
    methods: {
      setValue(newValue) {
        return this.$emit('input', newValue);
      },
      toggleSide() {
        if (this.showAdvancedFilter) {
          this.openAdvancedFilter = !this.advancedFilterLoading;
        } else {
          this.expandedSide = this.expandedSide === '0%' ? '20%' : '0%';
          this.expandedVisibilitySide = '0%';
        }
      },
      toggleVisibilitySide() {
        this.expandedVisibilitySide = this.expandedVisibilitySide === '0%' ? '20%' : '0%';
        this.expandedSide = '0%';
        this.listVisibilityItems = this.$noBlankProps({...this.visibilityFields});
      },
      onChangeSwitch() {
        localStorage.setItem("visibility_items", JSON.stringify(this.listVisibilityItems));
      },
      beforeRefresh() {
        return this.$emit('before-refresh', this);
      },
      beforeClearFilters() {
        this.$emit('before-clear-filters');
      },
      clearFilters() {
        this.$emit('update:filters', {...this.fixedFilters});
        this.beforeClearFilters();
      },
      handleClose(done) {
        if (this.advancedFilterLoading) {
          return;
        }
        if (this.closingDrawer) {
          this.advancedFilterLoading = true;
          setTimeout(() => {
            // animation takes time
            setTimeout(() => {
              this.advancedFilterLoading = false;
              this.cancelForm()
              done()
            }, 400);
          }, 400);

        } else {
          this.cancelForm()
        }
      },
      cancelForm() {
        this.openAdvancedFilter = false
        this.closingDrawer = false
      },
      search() {
        this.listFilters = this.$noBlankProps({...this.filters, ...this.fixedFilters});
        this.closingDrawer = true
        // this.$refs.drawer.closeDrawer()
      },
      closeVisibilityFields() {
        this.expandedVisibilitySide = '0%';
      },
      refreshList(exporting) {
        this.tableLoading = true;
        this.data = [];
        this.total = 0;
        this.beforeRefresh();
        this.$nextTick(() => {
          this.exporting = typeof exporting === 'boolean' ? exporting : false;
          this.$refs.datatable.queryChange('refresh');
        });
      },
      onSelect(rowData) {
        this.selectedRow = rowData;
        return this.$emit('on-select', this);
      },
      onCheck(val) {
        this.checkedRows = val;
        if (this.checkedRows.length !== 0) {
          this.sendRows = this.checkedRows
        }
      },
      doStep(nextStep) {
        this.currentStep = nextStep;
      },
      async onAdd() {
        return this.$emit('on-add', this);
      },
      async add() {
        this.doStep(1);
        await this.onAdd();
      },
      async onEdt() {
        return this.$emit('on-edt', this.selectedRow, this);
      },
      async edt() {
        if (!this.selectedRow) {
          this.$toast.warning(this.$t('selectarow'));
          return;
        }
        this.model = this.selectedRow;
        await this.onEdt();
        this.doStep(2);
      },
      async onDel() {
        return this.$emit('on-del', this);
      },
      async del() {
        if (!this.selectedRow) {
          this.$toast.warning(this.$t('selectarow'));
          return;
        }
        swal(this.$t('attentiontxt'), this.$t('areyousurestatement'), 'warning')
          .then(async () => {
            const resp = await this.onDel();
            if (!resp.success) {
              this.$toast.warning(this.$t('statementdeletefailed'));
              return;
            }
            this.$toast.success(this.$t('statementdeleted'));
            this.refreshList();
          })
          .catch(err => {
              this.$toast.warning(this.$t('statementdeletefailed'));
          });
      },
      back() {
        this.clean();
        this.doStep(0);
        this.refreshList();
      },
      async onClean() {
        return this.$emit('on-clean', this);
      },
      clean() {
        this.selectedRow = undefined;
        this.setValue({});
        this.onClean();
      },
      async save() {
        if (this.currentStep === 1) {
          await this.onInsert();
        }
        if (this.currentStep === 2) {
          await this.onUpdate();
        }
        const result = await this.onSave();
        if (result) {
          this.back();
        }
      },
      async onInsert() {
        return this.$emit('on-clean', this);
      },
      async onUpdate() {
        return this.$emit('on-clean', this);
      },
      async onSave() {
        return this.$emit('on-save', this);
      },
      async validateAndSave() {
        const isValidForm = await this.$refs.form.validate();
        if (!isValidForm) {
          this.$toast.warning(this.$t('needed'));
          return false;
        }
        return false;
      },
      loadData(payload) {
        if (payload.page === null) {
          return;
        }
        this.tableLoading = true;
        this.$search({
          apiUrl: this.apis[this.apiUrl],
          url: this.url,
          payload: this.$serializeToQueryString(new ListPayload({...payload, paginate: !this.exporting}))
        })
        .then(response => {
          if(this.exporting) {
            this.exportCSV(response)
          } else {
            this.fillList(response);
          }
        },)
        .catch((err) => {
          this.$toast.error(this.$t('unkownerror'));
          console.error(err);
        })
      },
      fillList(response) {
        if (!response.success && !response.data) {
          this.$toast.warning(this.$t('datatablerefresherror'));
          return;
        }
        this.data = response.data.results || [];
        this.total = response.data.total || 0;
        this.tableLoading = false;
      },
      handleExportData() {
        if (this.exportDataWithCheckbox && this.sendRows.length > 0) {
          return this.sendRows.map(obj => Object.values(obj).join(this.exportSeparator)).join('\r\n');
        } else {
          return this.exportData.map(obj => Object.values(obj).join(this.exportSeparator)).join('\r\n');
        }
      },
      async exportCSV(response) {
        this.exporting = false;
        if (!response.success && !response.data) {
          this.$toast.warning(this.$t('exportingerror'));
          return;
        }
        await this.beforeExport(response.data.results || []);
        const headers = this.exportHeader.join(this.exportSeparator);
        const data = this.handleExportData();
        exportCSVFile(`${headers}\r\n${data}`);
        this.refreshList();
        this.sendRows = [];
      },
      async beforeExport(resultList) {
        await this.$emit('before-export', resultList);
      },
      async getDatatableRef() {
        return new Promise((resolve) => {
          this.$nextTick(() => {
            resolve(this.$refs.datatable);
          });
        });
      },
      async getTableRef() {
        const datatable = await this.getDatatableRef();
        return datatable.$refs.elTable;
      },
    },
    mounted() {
      if (this.fixedFilters && Object.keys(this.fixedFilters).length) {
        this.search();
      }
      this.inProgress = false;
      window.addEventListener('resize', e => {
        this.windowSize = window.innerWidth;
      });
    }
  }
  </script>

  <style scoped lang="scss">
  @import './src/assets/sass/paper/variables';
  .card.module::v-deep {
    .margin-top {
      margin-top: 10px;
    }
    .text-left-center {
      text-align: left !important;
      display: flex;
    }
    > .card-header {
      h5 {
        display: inline-block;
        padding: 0.5rem 1rem;
        margin: 0;
        i {
          padding-right: 1rem;
        }
      }
      hr {
        margin: 0 0 1rem 0 !important;
      }
      .input-group.form-group {
        margin: 0;
      }
      .toolbar {
        .btn {
          margin: 0;
        }
      }
    }
    > .card-body {
      .list {
        margin-top: -1rem;
      }
      .el-main {
        padding: 0;
      }
      .el-aside {
        border-left: $border $light-gray;
        margin-top: -0.75rem;
        fieldset {
          padding: $padding-input-vertical;
          > label {
            font-weight: bold;
            font-size: $font-size-medium;
            color: $font-color;
          }
        }
      }
    }
  }
  </style>
