<template>
  <div class="dark-background-rebrand">
    <login-banner></login-banner>
    <div class="container">
      <card v-if="step == 0">
        <h2 slot="header" class="mt-4 text-center"> {{ $t('account_deletion_request_title') }}</h2>
        <h6 class="mr-5 ml-5 card-just-text">
          <p>{{ $t('account_deletion_request_1') }}</p>


          <p>{{ $t('account_deletion_request_2') }}</p>
          <ul>
            <li><p>{{ $t('account_deletion_item_1') }}</p></li>
            <li><p>{{ $t('account_deletion_item_2') }}</p></li>
            <li><p>{{ $t('account_deletion_item_3') }}</p></li>
          </ul>
          <br>

          <p v-if="!hasUser">{{ $t('account_deletion_situation_1') }}</p>

          <p v-if="hasUser">{{ $t('account_deletion_situation_2') }}</p>

        </h6>
        <h5 slot="footer" align="center" class="mt-3">
          <div class="row mb-3">
            <div class="col-md-3"></div>
            <div class="col-md-6">
              <fg-input
                :disabled="hasUser == 1"
                v-model="userid"
                :error="getError(txt.email)"
                :name="txt.email"
                v-validate="modelValidations.email"
                addon-left-icon="nc-icon nc-email-85"
                placeholder="email@domain.com"
                type="email"
              ></fg-input>
            </div>
            <div class="col-md-3"></div>
          </div>

<!--          <div class="mt-2 mb-5" v-if="showCaptcha">-->
<!--            <vue-hcaptcha-->
<!--              sitekey="7736ab28-5f0f-4eb9-810c-ca6f46e64b30"-->
<!--              ref="recaptcha"-->
<!--              @verify="verify"-->
<!--            ></vue-hcaptcha>-->
<!--          </div>-->

          <p-button
            @click.prevent="sendToken"
            type="default">
            <span v-if="!sendingToken">{{$t('account_deletion_button_1')}}</span>
            <span v-else>{{$t('submitting')}} <i class="fa fa-cog fa-spin"></i></span>
          </p-button>
        </h5>
      </card>
      <card v-if="step == 1">
        <div class="row">
          <div class="col-md-2">
            <p-button icon link type="default" v-on:click="step = 0">
              <i class="fa fa-arrow-left"></i>
            </p-button>
          </div>
          <div class="col-md-8">
            <h2 slot="header" class="text-center mt-3"> {{ $t('verification_code') }}</h2>
            <h5 slot="header" class="text-center mt-3"> {{ $t('a_code_was_sent') }}</h5>
          </div>
          <div class="col-md-2"></div>
        </div>
      <div class="text-center mt-4 mb-4">
        <input id="first_input" v-model="verificationCode[1]" class="digit-box" type="text" maxlength="1" v-on:keyup.prevent="focusNext($event)">
        <input class="digit-box" v-model="verificationCode[2]" type="text" maxlength="1" v-on:keyup.prevent="focusNext($event)">
        <input class="digit-box" v-model="verificationCode[3]" type="text" maxlength="1" v-on:keyup.prevent="focusNext($event)">
        <input class="digit-box" v-model="verificationCode[4]" type="text" maxlength="1" v-on:keyup.prevent="focusNext($event)">
        <input class="digit-box" v-model="verificationCode[5]" type="text" maxlength="1" v-on:keyup.prevent="focusNext($event)">
      </div>
        <h5 slot="footer" align="center">
          <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6"></div>
            <div class="col-md-3"></div>
          </div>

        </h5>

          <p-button
              style="white-space: normal;"
              @click.prevent="validateToken"
              type="danger">{{$t('account_deletion_button_2')}}
          </p-button>

      </card>
    </div>
    <login-footer-disclaimer></login-footer-disclaimer>
  </div>
</template>
<script>
import LoginBanner from "@/components/Dashboard/Views/Pages/login-banner.vue";
import LoginFooterDisclaimer from "@/components/Dashboard/Views/Pages/login-footer-disclaimer.vue";
import swal from "sweetalert2";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
import VueCookies from "vue-cookies";
export default {
  components: {
    LoginFooterDisclaimer,
    LoginBanner,
    VueHcaptcha
  },
  data() {
    return {
      step: 0,
      modelValidations: {
        email: {
          required: true
        }
      },
      txt:{
        email: this.$t('email')
      },
      userid: '',
      hasUser: false,
      showCaptcha: false,
      verificationCode: {
        1: '',
        2: '',
        3: '',
        4: '',
        5: ''
      },
      captcha_token: '',
      captchaValid: false,
      sendingToken: false,
      allowCreateToken: true,
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    sendToken() {
      const fail = error => {
        this.sendingToken = false;
        this.$toast.warning(emptyfields)
      }
      this.$validator.validateAll()
        .then(isValid =>  {
          if(isValid) {
            this.creteToken()
          }
        }, fail)
    },
    creteToken() {
      // if(!this.captchaValid) {
      //   this.allowCreateToken = false
      //   return this.showCaptcha = true
      // }
      const success = resp => {
        if(!resp.success) {
          return fail(resp)
        }
        this.step = 1
        this.$nextTick(() => {
          document.getElementById("first_input").focus();
        });
      }

      const fail = resp => {
        this.captcha_token = this.sendingToken = false;
        let msg = resp.msg || this.$t('unkownerror')
        this.$toast.warning(msg)
      }
      this.$createUserToken({userid: this.userid}).then(success,fail)
    },
    focusNext(event) {
      if (event.keycode == 8 || event.key == 'Backspace') {
        var next = event.srcElement.previousSibling
      } else {
        var next = event.srcElement.nextSibling
      }

      if(next && next.tagName && next.tagName.toLowerCase() === 'input') {
        next.focus();
      }
    },
    validateToken() {
      let param = {
        token: '',
        userid: this.userid
      }
      for (let i in this.verificationCode) {
        param.token+=this.verificationCode[i]
      }
      let success = resp => {
        if(!resp.success) {
          return fail(resp)
        }
        this.deleteUser()
      }
      let fail = resp => {
        this.$toast.error(this.$t('invalid_token'))
      }
      this.$validateUserVerificationCode(param).then(success, fail)
    },
    redirectLogin() {
      location.href = '/login'
    },
    deleteUser() {
      const ticket_created = resp => {
        swal(this.$t('successtxt'),this.$t('account_deletion_request_sent') ,'success').then(this.redirectLogin)
      }

      const ticket_failed = resp => {
        swal(this.$t('attentiontxt'), this.$t('somethingwentwrong'), "warning").then(this.closeRemoveUserModal)
      }

      const ticket = {
        "userid": this.userid,
        "name": 'User from public page',
        "subject": "DELETE ACCOUNT",
        "type": "Support",
        "text64": btoa("Dear HokoTeam,\n" +
          "\n" +
          "This user has requested the deletion of his client profile directly from his profile page.\n" +
          "\n" +
          "Best Regards"),
        "captcha_token": this.captcha_token,
      }

      this.$createTicket_v2(ticket).then(ticket_created, ticket_failed);
    },
    verify(resp) {
      this.allowCreateToken = true
      this.captcha_token = resp
      this.captchaValid = true
    },
    changeLanguage(resp) {
      VueCookies.set('language', JSON.stringify(resp), null, null, null, false, 'Lax');
      if ((['IT', 'ES', 'FR', 'RU', 'RO', 'PT', 'AE']).includes(resp.country_code2)) {
        this.$i18n.locale = resp.country_code2
      } else if (resp.country_code2.toUpperCase() == 'BR') {
        this.$i18n.locale = 'pt'
      } else {
        this.$i18n.locale = 'en'
      }
    },
    getUser(){
      this.hasUser = Object.keys(this.$route.query).length
      if(this.hasUser) {
        this.userid = atob(this.$route.query.u)
      }
    }
  },
  mounted() {
    this.$getVisitorLocation().then(this.changeLanguage);
    this.getUser()
  }
}
</script>
<style scoped>
.digit-box {
    display: inline-block;
    width: 40px;
    height: 40px;
    border: 1px solid #ccc;
    text-align: center;
    margin-right: 5px;
}
</style>
