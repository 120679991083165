import {i18n} from 'src/assets/i18n-plugin.js'

export default {
  install(Vue, options) {
    /**
     * Retrieves a new object only with properties that isn't blank (no nested steps)
     * @param object
     */
    Vue.prototype.$noBlankProps = function (object) {
      if(typeof object !== 'object') {
        return object;
      }
      return Object.fromEntries(Object.entries(object).filter(([_, v]) => v !== ''));
    }
    /**
     * Copy a given value to clipboard
     * @param value
     */
    Vue.prototype.$copyToClipboard = function (value) {
        const el = document.createElement('textarea');
        el.value = value;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        Vue.$toast.success(i18n.t('copied'));
    }
    /**
     * Serializes an Object to QueryString
     * @param value
     */
    Vue.prototype.$serializeToQueryString = function (obj, rootName, ignoreList) {
      let params = [];
      const appendFormData = (data, root) => {
        if (ignore(root)) {
          return;
        }
        root = root || '';
        if (Array.isArray(data)) {
          data.forEach((data, index) => appendFormData(data, `${root}[${index}]`));
        } else if (typeof data === 'object' && data) {
          Object.keys(data).forEach(key => {
            if (data.hasOwnProperty(key)) {
              if (root === '') {
                appendFormData(data[key], key);
              } else {
                appendFormData(data[key], `${root}.${key}`);
              }
            }
          });
        } else {
          if (data !== null && typeof data !== 'undefined') {
            params.push(`${encodeURIComponent(root)}=${encodeURIComponent(data)}`);
          }
        }
      };
      const ignore = root => Array.isArray(ignoreList) && ignoreList.some(x => x === root);
      appendFormData(obj, rootName);
      return params.join('&');
    }
    /**
     * Open a pop-up window, centered on screen and returns its reference
     * @param url
     * @param width
     * @param height
     */
    Vue.prototype.$openPopUp = function (url, width, height) {
      const winConf = {
        width: width,
        height: height,
        top: function () {
          return (screen.height/2)-(this.height/2);
        },
        left: function () {
          return (screen.width/2)-(this.width/2);
        },
        features: function () {
          return `
          toolbar=no,
          location=no,
          directories=no,
          status=no,
          menubar=no,
          scrollbars=no,
          resizable=no,
          width=${this.width},
          height=${this.height},
          top=${this.top()},
          left=${this.left()}
          `;
        }
      };
      return window.open(url,'_blank', winConf.features());
    }
    /**
     * Compare two strings forcing lower case
     * @param a the first string
     * @param b the second string
     * returns true if both are equals
     */
    Vue.prototype.$toLowerEquals = function (a, b) {
      if (typeof a === 'string') {
        a = a.toLowerCase();
      }
      if (typeof b === 'string') {
        b = b.toLowerCase();
      }
      return a === b;
    }
  }
}
