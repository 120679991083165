<template>
  <div>
    <!-- Modal to notify user where to change setting -->
    <modal :show.sync="modalnotice" headerClasses="justify-content-center">
        <h4 slot="header" class="title title-up">{{ $t('fyitxt') }}</h4>

        <div class="row">
          <div class="col-12">
            {{ modalmsg }}
          </div>
        </div>

        <template slot="footer">
          <p-button type="success" @click.prevent="redirectToAddAccount()" link> + {{ $t('addaccount') }}</p-button>
          <p-button type="info" @click.prevent="redirectToFollow()" link>
            {{ $t('addstrategy') }}
          </p-button>
        </template>
      </modal>
    <!-- End Modal -->
  </div>
</template>
<script>
  import {Modal} from "@/components/UIComponents";
  import Vue from "vue";

  const user_data = JSON.parse(localStorage.getItem('user-info'));
  export default {
    components: {
      Modal,
    },
    data() {
      return {
        modalnotice: false,
        modalmsg: null,
      }
    },
    methods: {
      redirectToFollow() {
        window.location = '/strategies/watchlist'
      },
      redirectToAddAccount() {
        window.location = '/accounts/main'
      },
      watchlistProcedure() {
        let strat_id = Vue.$sessionStorage.get('strat_id');
        if (strat_id !== null && strat_id !== undefined) {
          this.$delsertStrategy({}, {userid: user_data.email, sid: strat_id}).then(this.notifyStrategyAdded())
        }
      },
      notifyStrategyAdded() {
        Vue.$sessionStorage.remove('strat_id')
        if (user_data.user_metadata.strat_id) {
          delete user_data.user_metadata.strat_id;
          localStorage.setItem('user-info', JSON.stringify(user_data));
        }
        if (user_data.user_metadata.acc_id) {
          delete user_data.user_metadata.acc_id;
          localStorage.setItem('user-info', JSON.stringify(user_data));
        }
        this.modalnotice = true;
        this.modalmsg = this.$t('addedstrategytowatchlist');
      }
    },
    mounted() {
      this.watchlistProcedure
      ()
    }
  }
</script>
<style scoped>

</style>
