<template>
  <module
    ref="module"
    id="whitelabelVps"
    titleIcon="fa fa-cog"
    :title="`${this.$t('whitelabel')} - ${this.$t('vps')}`"
    :filters.sync="filters"
    api-url="MAINAPIURLV4"
    :url="`/${wlId}/billplan`"
    :useDefaultCrud="true"
    :hideSearchButton="true"
    :hideEditButton="true"
    :hideDeleteButton="true"
    :nested="is_nested"
    @on-save="validateAndSave"
    @on-clean="onClean"
  >
    <template slot="table-columns">
      <el-table-column
        prop="active"
        :label="txt.active"
      >
        <span slot-scope="scope">
          <i v-if="scope.row.active" class="fa fa-circle" aria-hidden="true" style="color: green"></i>
          <i v-else class="fa fa-circle" aria-hidden="true" style="color: red"></i>
        </span>
      </el-table-column>

      <el-table-column prop="whitelabel" :label="txt.whitelabel">
        <span slot-scope="scope">
          {{ scope.row.whitelabel }}
        </span>
      </el-table-column>

      <el-table-column prop="name" :label="txt.name" class="success">
        <span slot-scope="scope">
            {{ scope.row.name }}
        </span>
      </el-table-column>

      <el-table-column prop="location" :label="txt.location">
        <span slot-scope="scope">
          <img :src="`/static/img/flags/${getLocationFlag(scope.row.location)}.png`">
        </span>
      </el-table-column>

      <el-table-column
        prop="approval_required"
        :label="txt.approvalrequired"
      >
      <span slot-scope="scope">
          <i v-if="scope.row.approval_required" class="fa fa-check" style="font-size: x-large; color: green;"></i>
          <i v-else class="fa fa-times" style="font-size: x-large; color: red;"></i>
        </span>
      </el-table-column>

      <el-table-column prop="vpscounting" :label="txt.vpscounting">
        <template slot-scope="scope">
          <el-tooltip
          :content="txt.activevps"
          :open-delay="100"
          placement="top">
            <p-button class="p-badge badge-success badge-pill counter" type="success" round style="margin-right: 5px">
              <span>{{ scope.row.vpss }}</span>
            </p-button>
          </el-tooltip>

          <el-tooltip
          :content="txt.pendingapproval"
          :open-delay="100"
          placement="top">
            <p-button class="p-badge badge-primary badge-pill counter" type="primary" round>
              <span>{{ scope.row.pending }}</span>
            </p-button>
          </el-tooltip>
        </template>
      </el-table-column>


      <el-table-column
        prop="action"
        :label="txt.action"
      >
        <div slot-scope="scope" class="text-center">
          <div class="btn-group">
            <p-button type="warning" link v-on:click="updaterow(`${scope.row.id}`)">
              <i class="fa fa-pencil" style="font-size: x-large;"></i>
            </p-button>
            <p-button type="danger" link v-on:click="confirmRemove(scope.row)">
              <i class="fa fa-trash-o" style="font-size: x-large;"></i>
            </p-button>
          </div>
        </div>
      </el-table-column>
    </template>

    <template slot="edt">
        <whitelabelVpsForm
          ref="wlform"
          :is_manage="true"
          :refAddons="addons"
          :location="location"
          :plans="plans"
          :reference="vps_data"
          :whitelabel="wlId">
        </whitelabelVpsForm>
    </template>
  </module>
</template>

<script>
  import WhitelabelVpsForm from "./WhitelabelVpsForm";
  import swal from "sweetalert2";
  import Constants from "src/assets/constants";
  import { Tooltip } from "element-ui";

  const rules = Constants.methods.getRules();

  export default {
    name: "whitelabelvps",
    props: {
      reference: {
        type: [String],
        description: "Whitelabel reference",
      },
      is_manage: {
        type: [Boolean],
        description:
          "Flag defined to inform if the form is being used for manage whitelabel page",
      },
    },
    components: {
      [Tooltip.name]: Tooltip,
      WhitelabelVpsForm,
    },
    data() {
      return {
        whitelabel: null,
        is_nested: false,
        formData: undefined,
        filters: {},
        tableList: [],
        inprogress: true,
        submitting: false,
        list: true,
        form: false,
        vps_data: null,
        wlId: "",
        inprogresstxt: this.$t("loadingvps"),
        addons: [],
        location: null,
        plans: null,
        txt: {
          emptyrow: this.$t("norecordsfounded"),
          vps: this.$t("whitelabel") + " - " + this.$t("vps"),
          createnew: this.$t("createnewvps"),
          back: this.$t("back"),
          active: this.$t("active"),
          name: this.$t("nametxt"),
          whitelabel: this.$t("whitelabel"),
          location: this.$t("vpslocation"),
          cost: this.$t("cost"),
          usercost: this.$t("clienttxt") + " " + this.$t("cost"),
          discount: this.$t("discount"),
          discountpercent: this.$t("discount") + " %",
          addons: this.$t("addons"),
          approvalrequired: this.$t("approval"),
          action: this.$t("action"),
          vpscounting: this.$t("vpscounting"),
          activevps: this.$t("activevpss"),
          pendingapproval: this.$t("pendingapproval"),
        },
        isAdmin: false,
      };
    },
    methods: {
      loadTable(response) {
        this.tableList = response.data;
        this.inprogress = false;
      },
      onClean() {
        this.showHideVpsForm()
      },
      showHideVpsForm(data = null) {
        this.vps_data = data;
        if(data) {
          this.$refs.module.doStep(2)
        }
      },
      retrieveHandler(response) {
        if (!response.success) {
          return;
        }
        this.showHideVpsForm(response.data);
        this.inprogress = false;
      },
      updaterow(vpsid) {
        this.inprogress = true;
        this.vps_reference = vpsid;
        this.$retrieveWhitelabelBillingPlan(this.vps_reference).then(
          this.retrieveHandler,
          () => {}
        );
      },
      confirmRemove(billplan) {
        swal({
          title: this.$t('needconfirmation'),
          text: this.$t('confirmYourAction'),
          type: 'warning',
          showCancelButton: true,
        })
          .then(() => {
            this.removerow(billplan)
          }).catch(swal.noop);
      },
      removerow(billplan) {
        this.submitting = true;
        this.$deleteWhitelabelBillingPlan(billplan.id).then((response) => {
          this.deleteBillplanSuccessHandler(response, billplan.name);
        }, this.deleteBillplanErrorHandler);
      },
      deleteBillplanSuccessHandler(response, billplan) {
        if (!response.success) {
          this.deleteBillplanErrorHandler(response);
          return;
        }
        if (response.msg == "inactivated") {
          swal(
            this.$t("attentiontxt"),
            this.$t("wlbillplaninactivated", [billplan]),
            "warning"
          );
        } else {
          swal(
            this.$t("successtxt"),
            this.$t("wlbillplandeleted", [billplan]),
            "success"
          );
        }
        this.submitting = false;
        this.$refs.module.refreshList();
      },
      deleteBillplanErrorHandler(response) {
        swal(this.$t("attentiontxt"), this.$t("rdpdownmsg"), "error");
        this.submitting = false;
      },
      createNew() {
        this.showHideVpsForm();
      },
      getLocationFlag(row) {
        if (row == "lon") {
          return "GB";
        } else if (row == "ams") {
          return "NL";
        } else if (row == "tyo") {
          return "JP";
        } else if (row == "fra") {
          return "DE";
        } else if (row == "nyc") {
          return "US";
        } else if (row == "sin") {
          return "SG";
        }
      },
      async validateAndSave() {
        const form = this.$refs.wlform;
        this.formData = form.getData();
        const isValid = await form.validate();
        await this.submit(isValid);
      },
      async submit(isValid) {
        this.inprogress = true;
        this.$persistWhitelabelBillingPlan(this.formData).then(
          this.persistSuccessHandler,
          this.persistErrorHandler
        );
      },
      persistSuccessHandler(response) {
        this.inprogress = false;
        if (!response.success) {
          this.persistErrorHandler();
          return;
        }
        this.showHideVpsForm();
        swal(this.$t("successtxt"), null, "success");
        this.whitelabel_reference = null;
      },
      persistErrorHandler() {
        console.log("whenterror")
        this.inprogress = false;
        swal(this.$t("attentiontxt"), this.$t("rdpdownmsg"), "error");
      },
      loadAddons(response) {
        if (!response.success) {
          return;
        }
        this.addons = {};
        response.data.forEach((item) => {
          this.addons[item.product] = item.cost;
        });
      },
      loadLocations(resp) {
        if (!resp.success) {
          return;
        }
        this.location = resp.data;
      },
      loadPlans(resp) {
        if (!resp.success) {
          return;
        }
        this.plans = resp.data;
      },
    },
    mounted() {
      this.wlId = this.reference ? this.reference : ( rules.whitelabel ? rules.whitelabel : rules.userWhitelabel);
      this.isAdmin = rules.isAdmin;
      this.is_nested = rules.isAdmin;

      this.$getVPSPlans_v2({ type: "Addons" }).then(this.loadAddons, () => {});

      this.$getVPSLocations().then(this.loadLocations, this.failop);

      this.$getVPSPlans_v2({ type: "VPS" }).then(this.loadPlans, this.failop);
    },
    created() {
      this.$setTranslatingInProgress();
    },
    beforeDestroy() {
      this.$root.$off("showTranslatingProgress");
    },
  };
</script>
<style scoped lang="scss">
#whitelabelVps::v-deep {
  .counter {
    width: 4rem;
  }
}


</style>
