<template>
  <footer class="footer footer-black footer-white">
    <div class="container-fluid">
      <div class="row">
        <div class="credits ml-auto">
          <div class="copyright">
            Copyright &copy; 2018-{{year}} All Rights Reserved by Caprico Ltd. v{{ appVersion }}
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    name: 'main-footer',
    computed: {
      appVersion() {
        return this.$store.getters.appVersion
      }
    },
    data () {
      return {
        year: this.$moment().format("YYYY")
      }
    }
  }
</script>
<style>
</style>
