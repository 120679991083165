import VueCookies from 'vue-cookies'
import auth0 from 'auth0-js'
import CONSTANTS from './constants.js'
import jwt_decode from "jwt-decode";

export default class AuthService {
  accessToken
  expiresAt
  authenticated = this.isAuthenticated()

  auth0 = new auth0.WebAuth({
    domain: CONSTANTS.methods.auth0domain(),
    clientID: CONSTANTS.methods.auth0clientid(),
    audience: CONSTANTS.methods.auth0audience(),
    connection: CONSTANTS.methods.auth0conn(),
    redirectUri: window.location.origin +"/authcallback",
    responseType: 'token id_token',
    scope: 'openid'
  });

  handleAuthentication () {
    const parseHash = (resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult) {
          resolve(authResult);
        } else if (err) {
          const params = new URL(window.location.href.replace(/#/g,"")).searchParams;
          const accessToken = params.get("access_token");
          const idToken = params.get("id_token");
          if(err['error'] === 'invalid_token' && accessToken.length > 0 && idToken.length > 0) {
            resolve({
              accessToken: accessToken,
              scope: params.get("scope"),
              expiresIn: params.get("expires_in"),
              tokenType: params.get("token_type"),
              state: params.get("state"),
              idToken: idToken,
              idTokenPayload: jwt_decode(idToken),
            });
          }
          reject(err);
        }
      })
    };

    return new Promise(function(resolve, reject) {
      parseHash(resolve, reject);
    });
  }

  setSession (authResult) {
    this.accessToken = authResult.accessToken
    this.idToken = authResult.idToken
    this.expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
  }

  setStorage(authResult) {
    const self = this;
    return new Promise(function (resolve, reject) {
      self.auth0.client.userInfo(authResult.accessToken, (err, user) => {
        if (err) {
          reject(err)
        } else {
          resolve({
            result: authResult,
            user: user,
          })
        }
      })
    })
  };

  login(form, errorLoginHandler) {
    this.auth0.login({
      realm: CONSTANTS.methods.auth0conn(),
      email: form.username,
      password: form.password,
    }, errorLoginHandler);
  };

  logout () {
    // Clear access token and ID token from local storage
    this.accessToken = null;
    this.expiresAt = null;
    // Clear out all the localstorage login info
    VueCookies.remove('dash-at');
    VueCookies.remove('dash-it');
    VueCookies.remove('dash-inactive');
    localStorage.removeItem('user-info');
    localStorage.removeItem('user-lang');
    localStorage.removeItem('needtoverify');
    localStorage.removeItem('hc_cuzofdashmsg');
    this.redirect();
  };

  // redirect back to login
  redirect () {
    const settings = localStorage.getItem('wlSettings');
    if (settings) {
      let json = JSON.parse(settings);
      location.href = "/" + json.whitelabel;
      return;
    }
    location.href = "/";
  };

  isAuthenticated () {
    return new Date().getTime() < this.expiresAt && this.getAuthenticatedFlag() === 'true'
  };

}
