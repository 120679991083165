import {AllocationOptions} from "src/models/parameters/AllocationOptions";
import {SyncOptions} from "src/models/parameters/SyncOptions";
import {SignalOptions} from "src/models/parameters/SignalOptions";
import {RiskReplicationOptions} from "src/models/parameters/RiskReplication";
import {RiskMaxLossOptions} from "src/models/parameters/RiskMaxLoss";
import {getDefaultPassedDate} from "src/util/DateUtils";
import moment from "moment";

function getAccountInfo(accountInfo) {
  return !accountInfo ? {} : Object.assign(
    {},
    accountInfo,
    {
      defaultCopyTradesSince: moment(accountInfo.followed_date)
        .set({hour: 0, minute: 1})
        .add(1, 'day')
        .format("YYYY.MM.DD HH:mm")
    }
  )
}

export default {
  namespaced:true,
  state: {
    syncManually: false,
    inProgress: true,
    expanded: '0',
    accId: {},
    stratId: {},
    accountInfo: {
      account_currency: 0,
      followed_date: moment(new Date()).format("YYYY.MM.DD HH:mm"),
    },
    settings: {
      copyOpenTrades: false,
      copyTradesSince: getDefaultPassedDate(),
      blockSignalsWithPriceDeviation: false,
      maxPip: 5.00,
      maxNumberOpenPositions: 1000,
      minLotSize: 0.01,
      maxLotSize: 100.00,
      maxAccountMarginUsed: 100,
      noNewTradesDrawdownAbove: 100,
      assetsPairsToReceiveSignals: 'all',
      allButTheseAssetsPairsReceiveSignal: null,
      onlyTheseAssetsPairsReceiveSignal: null,
      presets: [],
    },
    allocation: {
      method: AllocationOptions.PROPORTIONAL,
      advancedMethod: AllocationOptions.ADVANCED.BAL_RATIO,
      value: 1.00,
    },
    sync: {
      option: SyncOptions.NONE,
    },
    signal: {
      option: SignalOptions.REPLICATEALL,
      openCloseOption: SignalOptions.ADVANCED.OPENCLOSE.BOTH,
      assetOption: SignalOptions.ADVANCED.ASSETS.ALL,
      assetOptionOnlyValue: "",
      assetOptionExceptValue: "",
      replicationOption: SignalOptions.ADVANCED.REPLICATION.BOTH,
    },
    riskReplication: {
      option: RiskReplicationOptions.DISABLED,
    },
    riskMaxLoss: {
      option: RiskMaxLossOptions.NONELOSS,
      advancedOption: RiskMaxLossOptions.ADVANCED.PERCENT,
      value: 100.0,
    },
    showConfirmationModal: false,
    adminFields: {
      tradedAssetsMatching: '',
      tradeComment: '',
      assetPrefix: '',
      assetSuffix: null,
      defaultSuffix: true,
    }
  },
  getters: {
    getAccountInfo(state) {
      return state.accountInfo;
    },
    isValidSettings(state) {
      if (state.sync.option === SyncOptions.ADVANCED) {
        if(state.settings.blockSignalsWithPriceDeviation && !state.settings.maxPip) {
          return false;
        }
      }
      if (state.signal.option === SignalOptions.ADVANCED) {
        if(state.settings.assetsPairsToReceiveSignals === 'onlyTheseAssetsPairsReceiveSignal' && !state.settings.onlyTheseAssetsPairsReceiveSignal) {
          return false;
        }
        if (state.settings.assetsPairsToReceiveSignals === 'allButTheseAssetsPairsReceiveSignal' && !state.settings.allButTheseAssetsPairsReceiveSignal) {
          return false;
        }
      }
      return true;
    },
  },
  mutations: {
    ['TOGGLE_SYNC_MANUALLY'](state) {
      state.syncManually = !state.syncManually
    },
    ['SET_INPROGRESS'](state, value) {
      state.inProgress = value
    },
    ['TOGGLE_EXPANDED'](state) {
      state.expanded = state.expanded === '0' ? '350px' : '0'
    },
    ['SET_ACCOUNT_INFO'](state, value) {
      state.accountInfo = value
    },
    ['SET_CURRENT_SETTINGS'](state, value) {
      state.settings = {...state.settings, ...value}
    },
    ['SET_CURRENT_METHOD'](state, value) {
      state.allocation.method = value
      Object.keys(AllocationOptions).forEach(key => {
        if(AllocationOptions[key] === value) {
          state.settings.presets[0] = key
        }
      })
    },
    ['SET_CURRENT_METHOD_ADVANCED'](state, value) {
      state.allocation.advancedMethod = value
    },
    ['SET_CURRENT_METHOD_VALUE'](state, value) {
      state.allocation.value = value
    },
    ['SET_CURRENT_SYNC'](state, value) {
      state.sync.option = value
      Object.keys(SyncOptions).forEach(key => {
        if(SyncOptions[key] === value) {
          state.settings.presets[1] = key
        }
      })
    },
    ['SET_CURRENT_SIGNAL'](state, value) {
      state.signal.option = value
      Object.keys(SignalOptions).forEach(key => {
        if(SignalOptions[key] === value) {
          state.settings.presets[2] = key
        }
      })
    },
    ['SET_CURRENT_SIGNAL_OPENCLOSE'](state, value) {
      state.signal.openCloseOption = value
    },
    ['SET_CURRENT_SIGNAL_ASSET'](state, value) {
      state.signal.assetOption = value
    },
    ['SET_CURRENT_SIGNAL_ASSET_ONLY_VALUE'](state, value) {
      state.signal.assetOptionOnlyValue = value
    },
    ['SET_CURRENT_SIGNAL_ASSET_EXCEPT_VALUE'](state, value) {
      state.signal.assetOptionExceptValue = value
    },
    ['SET_CURRENT_SIGNAL_REPLICATION'](state, value) {
      state.signal.replicationOption = value
    },
    ['SET_CURRENT_RISK_REPLICATION'](state, value) {
      state.riskReplication.option = value
      Object.keys(RiskReplicationOptions).forEach(key => {
        if(RiskReplicationOptions[key] === value) {
          state.settings.presets[3] = key
        }
      })
    },
    ['SET_CURRENT_RISK_MAX_LOSS'](state, value) {
      state.riskMaxLoss.option = value
      Object.keys(RiskMaxLossOptions).forEach(key => {
        if(RiskMaxLossOptions[key] === value) {
          state.settings.presets[4] = key
        }
      })
    },
    ['SET_CURRENT_RISK_MAX_LOSS_ADVANCED'](state, value) {
      state.riskMaxLoss.advancedOption = value
    },
    ['SET_CURRENT_RISK_MAX_LOSS_ADVANCED_VALUE'](state, value) {
      state.riskMaxLoss.value = value
    },
    ['SET_CONFIRMATION_MODAL'](state, value) {
      state.showConfirmationModal = value
    },
    ['SET_ADMIN_FIELDS'](state, value) {
      let assertedValue = value
      let isDefaultSuffix = value.hasOwnProperty('defaultSuffix') ? value.defaultSuffix : state.adminFields.defaultSuffix
      if(isDefaultSuffix) {
        assertedValue.defaultSuffix = true
        assertedValue.assetSuffix = null
      } else {
        assertedValue.defaultSuffix = false
        assertedValue.assetSuffix = assertedValue.assetSuffix || state.adminFields.assetSuffix || ''
      }
      state.adminFields = Object.assign({}, state.adminFields, assertedValue)
    },
    ['SET_STRAT_ID'](state, value) {
      state.stratId = value
    },
    ['SET_ACC_ID'](state, value) {
      state.accId = value
    },
    ['REFRESH_VIEW_STATE'](state) {
      // set allocation form values
      state.allocation.method = AllocationOptions[state.settings.presets[0]] || AllocationOptions.ADVANCED
      if(state.allocation.method === AllocationOptions.ADVANCED) {
        // set allocation.advancedMethod by current settings.allocationMethod field
        Object.keys(AllocationOptions.ADVANCED)
          .forEach(key => {
            if(AllocationOptions.ADVANCED[key].value.allocationMethod === state.settings.allocationMethod) {
              state.allocation.advancedMethod = AllocationOptions.ADVANCED[key]
            }
          })
        // set allocation.value by allocation.advancedMethod corresponding field
        if(state.allocation.advancedMethod === AllocationOptions.ADVANCED.MONEY_RATIO) {
          state.allocation.value = state.settings['lotMoneyRatioY']
        } else {
          state.allocation.value = state.settings[state.allocation.advancedMethod.value.allocationMethod]
        }
      }
      // set sync form values
      state.sync.option = SyncOptions[state.settings.presets[1]] || SyncOptions.ADVANCED
      // set signals form values
      state.signal.option = SignalOptions[state.settings.presets[2]] || SignalOptions.ADVANCED
      if(state.signal.option === SignalOptions.ADVANCED) {
        // set openCloseOption
        Object.values(SignalOptions.ADVANCED.OPENCLOSE)
          .forEach(option => {
            if (
              option.value.receiveNewTradeSignals === state.settings.receiveNewTradeSignals &&
              option.value.receiveCloseTradeSignals === state.settings.receiveCloseTradeSignals
            ) {
              state.signal.openCloseOption = option
            }
          })
        // set assetOption
        Object.values(SignalOptions.ADVANCED.ASSETS)
          .forEach(option => {
            if (option.value.assetsPairsToReceiveSignals === state.settings.assetsPairsToReceiveSignals) {
              state.signal.assetOption = option
              if(state.settings.assetsPairsToReceiveSignals === 'onlyTheseAssetsPairsReceiveSignal') {
                state.signal.assetOptionOnlyValue = state.settings.onlyTheseAssetsPairsReceiveSignal;
              }
              if(state.settings.assetsPairsToReceiveSignals === 'allButTheseAssetsPairsReceiveSignal') {
                state.signal.assetOptionExceptValue = state.settings.allButTheseAssetsPairsReceiveSignal;
              }
            }
          })
        // set replicationOption
        Object.values(SignalOptions.ADVANCED.REPLICATION)
          .forEach(option => {
            if (option.value.tradeSide === state.settings.tradeSide) {
              state.signal.replicationOption = option
            }
          })
      }
      // set riskReplication form values
      state.riskReplication.option = RiskReplicationOptions[state.settings.presets[3]] || RiskReplicationOptions.ADVANCED
      // set riskMaxLoss form values
      state.riskMaxLoss.option = RiskMaxLossOptions[state.settings.presets[4]] || RiskMaxLossOptions.ADVANCED
      if (state.riskMaxLoss.option === RiskMaxLossOptions.ADVANCED) {
        Object.values(RiskMaxLossOptions.ADVANCED)
          .forEach(option => {
            if(option.value.closeAllTradesAtOverallLossChoice === state.settings.closeAllTradesAtOverallLossChoice) {
              state.riskMaxLoss.advancedOption = option
              state.riskMaxLoss.value = state.settings[option.value.closeAllTradesAtOverallLossChoice]
            }
          })
      }
    },
  },
  actions: {
    toggleSyncManually({commit}) {
      commit('TOGGLE_SYNC_MANUALLY')
    },
    setInProgress({commit}, value) {
      commit('SET_INPROGRESS', value)
    },
    openConfirmationModal({commit}){
      commit('SET_CONFIRMATION_MODAL', true)
    },
    closeConfirmationModal({commit}){
      commit('SET_CONFIRMATION_MODAL', false)
    },
    toggleExpanded({commit}, value) {
      commit('TOGGLE_EXPANDED', value)
    },
    setAccountInfo({commit}, value) {
      commit('SET_ACCOUNT_INFO', value)
    },
    setCurrentSettings({commit}, value) {
      commit('SET_CURRENT_SETTINGS', value)
    },
    setCurrentMethod({commit,dispatch}, option) {
      commit('SET_CURRENT_METHOD', option)
      dispatch('setCurrentSettings', option.value || {})
    },
    setCurrentMethodAdvanced({state,commit,dispatch}, option) {
      commit('SET_CURRENT_METHOD_ADVANCED', option)
      commit('SET_CURRENT_METHOD_VALUE', option.default)
      dispatch('setCurrentSettings', option.getFilledObject(state.allocation.value))
    },
    setCurrentMethodValue({state,commit,dispatch}, value) {
      commit('SET_CURRENT_METHOD_VALUE', value)
      dispatch('setCurrentSettings', state.allocation.advancedMethod.getFilledObject(value))
    },
    setCurrentSync({state,commit,dispatch}, option) {
      commit('SET_CURRENT_SYNC', option)
      const value = option.value || {}
      if(option === SyncOptions.ADVANCED) {
        value.copyTradesSince = state.accountInfo.defaultCopyTradesSince
      }
      dispatch('setCurrentSettings', value)
    },
    setCurrentSignal({commit,dispatch}, option) {
      commit('SET_CURRENT_SIGNAL', option)
      dispatch('setCurrentSettings', option.value || {})
    },
    setCurrentSignalOpenClose({commit,dispatch}, option) {
      commit('SET_CURRENT_SIGNAL_OPENCLOSE', option)
      dispatch('setCurrentSettings', option.value || {})
    },
    setCurrentSignalAsset({commit,dispatch}, option) {
      commit('SET_CURRENT_SIGNAL_ASSET', option)
      commit('SET_CURRENT_SIGNAL_ASSET_ONLY_VALUE', "")
      commit('SET_CURRENT_SIGNAL_ASSET_EXCEPT_VALUE', "")
      dispatch('setCurrentSettings', option.value || {})
    },
    setCurrentAssetOnlyValue({state,commit,dispatch}, value) {
      commit('SET_CURRENT_SIGNAL_ASSET_ONLY_VALUE', value)
      dispatch('setCurrentSettings', state.signal.assetOption.getFilledObject(value))
    },
    setCurrentAssetExceptValue({state,commit,dispatch}, value) {
      commit('SET_CURRENT_SIGNAL_ASSET_EXCEPT_VALUE', value)
      dispatch('setCurrentSettings', state.signal.assetOption.getFilledObject(value))
    },
    setCurrentSignalReplication({commit,dispatch}, option) {
      commit('SET_CURRENT_SIGNAL_REPLICATION', option)
      dispatch('setCurrentSettings', option.value || {})
    },
    setCurrentRiskReplication({commit,dispatch}, option) {
      commit('SET_CURRENT_RISK_REPLICATION', option)
      dispatch('setCurrentSettings', option.value || {})
    },
    setCurrentRiskMaxLoss({commit,dispatch}, option) {
      commit('SET_CURRENT_RISK_MAX_LOSS', option)
      dispatch('setCurrentSettings', option.value || {})
    },
    setCurrentRiskMaxLossAdvanced({state,commit,dispatch}, option) {
      commit('SET_CURRENT_RISK_MAX_LOSS_ADVANCED', option)
      dispatch('setCurrentSettings', option.getFilledObject(state.riskMaxLoss.value))
    },
    setCurrentRiskMaxLossAdvancedValue({state,commit,dispatch}, value) {
      commit('SET_CURRENT_RISK_MAX_LOSS_ADVANCED_VALUE', value)
      dispatch('setCurrentSettings', state.riskMaxLoss.advancedOption.getFilledObject(value))
    },
    setAdminFields({commit}, value) {
      commit('SET_ADMIN_FIELDS', value)
    },
    setStratId({commit}, value) {
      commit('SET_STRAT_ID', value)
    },
    setAccId({commit}, value) {
      commit('SET_ACC_ID', value)
    },
    refreshViewState({commit}) {
      commit('REFRESH_VIEW_STATE')
    },
    refreshInfo({state, dispatch}, value) {
      localStorage.setItem(
        'defaultParamsSettings',
        JSON.stringify(value['default_settings'] ? value['default_settings'] : {})
      )
      dispatch('setInProgress', false)
      dispatch('setStratId', value['strat_id'] || {})
      dispatch('setAccId', value['acc_id'] || {})
      dispatch('setAccountInfo', getAccountInfo(value['account_info']))
      dispatch('setCurrentSettings', value['account_settings'] || {})
      dispatch(
        'setAdminFields',
        Object.assign(
          {},
          value['admin_fields'] || {},
          {defaultSuffix: value['admin_fields'].assetSuffix === null}
        )
      )
      dispatch('refreshViewState')
    },
    resetToDefautSettings({state, dispatch}) {
      dispatch('setCurrentSettings', JSON.parse(localStorage.getItem('defaultParamsSettings') || {}))
      dispatch('refreshViewState')
    },
  },
}
