export const RiskMaxLossOptions = {
  NONELOSS: {
    value: {
      "noNewTradesDrawdownAbove": 100,
      "closeAllTradesAtOverallLoss": false,
      "closeAllTradesAtOverallLossChoice": "lossPercentToCloseProfit",
      "lossPercentToCloseProfit": 100,
      "lossMoneyToCloseProfit": null,
    },
  },
  HALFLOSS: {
    value: {
      "noNewTradesDrawdownAbove": 40,
      "closeAllTradesAtOverallLoss": true,
      "closeAllTradesAtOverallLossChoice": "lossPercentToCloseProfit",
      "lossPercentToCloseProfit": 50,
      "lossMoneyToCloseProfit": null,
    },
  },
  QUARTERLOSS: {
    value: {
      "noNewTradesDrawdownAbove": 20,
      "closeAllTradesAtOverallLoss": true,
      "closeAllTradesAtOverallLossChoice": "lossPercentToCloseProfit",
      "lossPercentToCloseProfit": 25,
      "lossMoneyToCloseProfit": null,
    },
  },
  ADVANCED: {
    PERCENT: {
      value: {
        "closeAllTradesAtOverallLoss": true,
        "closeAllTradesAtOverallLossChoice": "lossPercentToCloseProfit",
        "lossPercentToCloseProfit": null,
        "lossMoneyToCloseProfit": null,
      },
      default: 100.0,
      min: 1.0,
      max: 100.0,
      step: 1,
      precision: 1,
      getFilledObject(value) {
        return Object.assign({}, this.value, {lossPercentToCloseProfit: value})
      },
    },
    VALUE: {
      value: {
        "closeAllTradesAtOverallLoss": true,
        "closeAllTradesAtOverallLossChoice": "lossMoneyToCloseProfit",
        "lossPercentToCloseProfit": null,
        "lossMoneyToCloseProfit": null,
      },
      default: undefined,
      min: 0.01,
      max: undefined,
      step: 0.01,
      precision: 2,
      getFilledObject(value) {
        return Object.assign({}, this.value, {lossMoneyToCloseProfit: value})
      },
    },
    TRADES_DRAWDOWN_ABOVE: {
      value: {},
      default: 100.0,
      min: 1.0,
      max: 100.0,
      step: 0.1,
      precision: 1,
      getFilledObject(value) {
        return {}
      },
    },
  },
}
