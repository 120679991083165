<template>
  <div class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate"
       :class="[switchClass,
          {'bootstrap-switch-disabled': disabled},
          {'bootstrap-switch-readonly': readonly},
          {'bootstrap-switch-indeterminate': indeterminate}
       ]">
    <div class="bootstrap-switch-container" @click="triggerToggle()">
      <span class="bootstrap-switch-handle-on " :class="`bootstrap-switch-default`">
        <slot name="on">
            {{ onText }}
        </slot>
      </span>
      <span class="bootstrap-switch-label"></span>
      <span class="bootstrap-switch-handle-off" :class="`bootstrap-switch-default`">
        <slot name="off">
            {{ offText }}
        </slot>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PathSwitch',
  props: {
    value: {
      type: [Array, Boolean],
      description: 'Switch value'
    },
    disabled: {
      type: [Boolean, String],
      description: 'Whether switch is disabled'
    },
    onText: {
      type: String,
      description: 'switch on text'
    },
    offText: {
      type: String,
      description: 'switch off text'
    },
    readonly: {
      type: [Boolean, String],
      description: 'Whether switch is readonly'
    },
    indeterminate: {
      type: [Boolean, String],
      description: 'Whether switch is indeterminate'
    },
    type: {
      type: String,
      description: 'Switch type (primary|info|danger|default|warning|success)',
      default: 'default'
    },
    path: {
      type: [String, Number, Array],
      description: "Is the path",
      default: ""
    },
    default: {
      type: Boolean,
      description: "Default value when disabled or readonly",
      default: false
    },
  },
  computed: {
    switchClass() {
      let base = 'bootstrap-switch-';
      let state = this.checkState() ? 'on' : 'off';
      return base + state
    },
    model: {
      get() {
        if (this.disabled || this.readonly) {
          return this.default;
        }
        return this.value
      },
      set(value) {
        if (this.disabled || this.readonly) {
          this.$emit('input', this.default)
          return this.default;
        }
        this.$emit('input', value)
      }
    }
  },
  methods: {
    checkState() {
      if (this.disabled || this.readonly) {
        return this.default;
      }
      if (this.path instanceof Array) {
        return this.model.includes(this.path[0])
      } else {
        return this.model.includes(this.path)
      }
    },
    changeValue(value) {
      if (this.model.includes(value)) {
        const index = this.model.indexOf(value);
        this.model.splice(index, 1);
      } else {
          this.model.push(value)
      }
      this.$emit('input', this.model)
    },
    triggerToggle() {
      if (this.disabled || this.readonly) {
        return;
      }
      if (this.path instanceof Array) {
        this.path.forEach(p => this.changeValue(p));
      } else {
        this.changeValue(this.path)
      }
    }
  }
}
</script>
<style>
</style>
