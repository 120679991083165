import crypto from "crypto-js";

class SecureStorage {
  constructor() {
    //RSA 1024
    this.secret = `
        MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCHUVdTuuiXriBm7Sf/+ubiJByx
        gYjT4GcxSh4EKbb0T+777AasbpY0nsrQz0MkoTnUt87HkyJNs72iZZEAojmlc0ea
        6OxgPvQ0Blx9+53k9XhZmyDehdWePeZAppS6vsLw2wx3EpY0RGeKZBci1wXqtz68
        sKnNKhY9NyS0d7H2lQIDAQAB
        `;
    this.getStoreItem = localStorage.getItem;
    this.setStoreItem = localStorage.setItem;
  }
  init(debug) {
    if (debug) {
      return;
    }
    this.applySecureStorage(this.getStoreItem, this.setStoreItem, this.secret);
    document.addEventListener("load", function() {
      if (!localStorage.custom) {
        applySecureStorage(this.getStoreItem, this.setStoreItem, this.secret);
      }
    });
  }
  applySecureStorage(getStoreItem, setStoreItem, secret) {
    Storage.prototype.getItem = function(key) {
      try {
        return crypto.AES.decrypt(
          getStoreItem.apply(this, arguments),
          secret
        ).toString(crypto.enc.Utf8);
      } catch {
        return getStoreItem.apply(this, arguments);
      }
    };
    Storage.prototype.setItem = function(key, value) {
      arguments[1] = crypto.AES.encrypt(value, secret).toString();
      return setStoreItem.apply(this, arguments);
    };
    Storage.prototype.custom = true;
  }
}

export default new SecureStorage();
