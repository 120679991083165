export default {
  namespaced: true,
  state: {
    copy_all_trades: true
  },
  mutations: {
    ['SET_COPY_ALL_TRADES'](state, value) {
      state.copy_all_trades = value
    }
  },
  actions: {
    setCopyAllTrades({commit}, value) {
      commit('SET_COPY_ALL_TRADES', value)
    }
  }

}
