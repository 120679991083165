<template>
  <div>
    <modal :show.sync="showModal" :required="true" headerClasses="justify-content-center" modalClasses="modal-lg">
      <h4 slot="header" class="title title-up"> {{ this.$t('howcanwehelp') }} </h4>
      <div class="form-group">
        <div class="row">
          <span> {{ this.$t('fullname') }} </span>
          <fg-input v-model="modalData.name"
                    v-validate="modelValidation.name"
                    class="col-md-12 input-modal"
                    :error="getError(this.$t('fullname'))"
                    :placeholder="this.$t('nametxt')"
                    type="text"
                    addon-left-icon="nc-icon nc-single-02"
                    :name="this.$t('fullname')"/>
        </div>
        <div class="row">
          <span> {{ this.$t('email') }} </span>
          <fg-input v-model="modalData.userid"
                    v-validate="modelValidation.email"
                    :error="getError(this.$t('email'))"
                    placeholder="email@domain.com"
                    type="email"
                    :name="this.$t('email')"
                    addon-left-icon="nc-icon nc-email-85"
          ></fg-input>
        </div>
        <div class="row">
          <span> {{ this.$t('subject') }} </span>
          <fg-input v-model="modalData.subject"
                    v-validate="modelValidation.subject"
                    :error="getError(this.$t('subject'))"
                    :name="this.$t('subject')"
                    :placeholder="this.$t('subject')"
                    class="col-md-12 input-modal"
                    addon-left-icon="fa fa-pencil"
                    type="text"/>
        </div>
        <div :class="['row', isValidDescription ? '' : 'has-danger']">
          <span> {{ this.$t('description') }} </span>
          <textarea v-model="modalData.text64"
                    :class="['form-control', isValidDescription ? '' : 'form-control-danger']"
                    placeholder="(required)"
                    rows="5"
                    type="text"
                    :name="this.$t('description')">
          </textarea>
        </div>
      </div>

      <div class="card-body" style="padding-left: 15%;">
        <vue-hcaptcha
          sitekey="7736ab28-5f0f-4eb9-810c-ca6f46e64b30"
          @verify="verify"
          ref="recaptcha"
        ></vue-hcaptcha>
<!--        <vue-recaptcha sitekey="6LfzrYoUAAAAAFmq-la0WpABBiYmfmSuNAKWi1gT"-->
<!--                       @verify="verify"-->
<!--                       ref="recaptcha">-->
<!--        </vue-recaptcha>-->
      </div>
      <template slot="footer">
        <p-button type="success" link :disabled="!captchaVerified" @click.native="modalValidate()">
          <i class="fa fa-envelope"></i>
          {{ this.$t('submit') }}
        </p-button>
        <p-button type="default" link @click.native="closeSupportModal()">
          {{ this.$t('cancel') }}
        </p-button>
      </template>
    </modal>

    <p-button type="info" icon round v-on:click="callSupport()" style="margin: 0;">
      <el-tooltip :content="this.$t('contactus')" :open-delay="100" placement="top">
        <i class="fa fa-envelope"></i>
      </el-tooltip>
    </p-button>
  </div>
</template>

<script>
import Vue from "vue";
import {Button, Modal} from "src/components/UIComponents";
import {Tooltip} from "element-ui";
// import VueRecaptcha from 'vue-recaptcha';
import VueHcaptcha from '@hcaptcha/vue-hcaptcha'

import swal from "sweetalert2";
import ar from 'vee-validate/dist/locale/ar';
import en from 'vee-validate/dist/locale/en';
import es from 'vee-validate/dist/locale/es';
import it from 'vee-validate/dist/locale/it';
import ro from 'vee-validate/dist/locale/ro';
import ru from 'vee-validate/dist/locale/ru';
import pt from 'vee-validate/dist/locale/pt_PT';


export default {
  name: "PublicSupport",
  components: {
    [Button.name]: Button,
    Modal,
    [Tooltip.name]: Tooltip,
    VueHcaptcha
    // VueRecaptcha
  },
  computed: {
    isValidDescription: function () {
      return this.modalData.text64.length
    },
  },
  data() {
    return {
      showModal: false,
      modalData: {
        userid: '',
        name: '',
        subject: '',
        text64: this.$t('ticketdescription_support')
      },
      modelValidation: {
        name: {required: true},
        email: {required: true, email: true},
        subject: {required: true},
        text64: {required: true, textarea: true},
      },
      captcha_token: null,
      captchaVerified: false,
    }
  },
  methods: {
    callSupport() {
      this.showModal = true;
    },
    cleanModal() {
      this.modalData = {
        userid: '',
        name: '',
        subject: '',
        text64: this.$t('ticketdescription_support')
      }
      this.captchaVerified = false;
      this.captcha_token = null;
      this.errors.clear();
      // this.$refs.recaptcha.reset();
    },
    closeSupportModal() {
      this.cleanModal()
      this.showModal = false;
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    modalValidate() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          this.$toast.warning(this.$t('emptyfields'));
          return
        } else {
          this.sendEmailToSupport()
        }
      });
    },
    verify(resp) {
        this.captcha_token = resp;
        this.captchaVerified = true;
    },
    sendEmailToSupport() {
      const ticket_created = resp => {
        swal(this.$t('successtxt'), this.$t('ticketcreated'), "success").then(this.cleanModal)
          .then(this.closeSupportModal)
      }

      const ticket_faild = resp => {
        swal(this.$t('attentiontxt'), this.$t('errorconnection'), "warning").then(this.cleanModal)
          .then(this.closeSupportModal)
      }

      const ticket = {
        "userid": this.modalData.userid,
        "name": this.modalData.name,
        "subject": this.modalData.subject,
        "text64": btoa(this.modalData.text64),
        "captcha_token": this.captcha_token
      }

      this.$createTicket(ticket).then(ticket_created, ticket_faild);
    },
    update(locale) {
      this.locale = locale
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
