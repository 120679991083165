import VueCookies from 'vue-cookies'
import APIHelp from './api.js'
import CONSTANTS from './constants.js'

const rules = CONSTANTS.methods.getRules();
const AUTH0URL = CONSTANTS.methods.auth0url();
const MAINAPIURL = CONSTANTS.methods.mainapiurl();
const MAINAPIURLV4 = CONSTANTS.methods.mainapiurlV4();
const WALLETBALANCEAPI = CONSTANTS.methods.walletbalanceapi();
const WALLETURL = CONSTANTS.methods.walleturl();
const FRAUDAPIURL = CONSTANTS.methods.fraudapiurl();
const REGAPIURL = CONSTANTS.methods.regapiurl();
const RDPAPIURL = CONSTANTS.methods.rdpapiurl();
const IPIFYURL = "https://api.ipify.org?format=json";
const ACCAPIURL = CONSTANTS.methods.accapiurl();
const ACCAPIURLV4 = CONSTANTS.methods.accapiurlV4();
const PUBAPIURL = CONSTANTS.methods.pubapiurl();
const PLOTAPIURL = CONSTANTS.methods.plotapiurl();
const WLADMINAPIURL = CONSTANTS.methods.wladminapiurl();
const MT4APIURL = CONSTANTS.methods.mt4apiurl();
const MESSENGERURL = CONSTANTS.methods.messengerurl();
const IPGEOLOCATION = CONSTANTS.methods.ipgeolocationurl();
const MATAPIURL = CONSTANTS.methods.matapiurl();
// TODO: FIX
const WHITELABELAPIURL = CONSTANTS.methods.wladminapiurl()


const COUNTRIES = CONSTANTS.methods.countries();
const COUNTRYPHLIST = CONSTANTS.methods.countryphones();


export default {
  install: Vue => {
    /**
     * Set config defaults when creating the instance
     * @type {{"Content-Type": string}}
     */
    const headers = {
      'Content-Type': 'application/json'
    };
    /**
     * Mount header
     * @param reject
     * @returns {{"Content-Type": string}}
     */
    const authenticateHeader = reject => {
      const at = VueCookies.get('dash-at');
      const it = VueCookies.get('dash-it');
      if (!at || !it) {
        Vue.$toast.error("Access Token Expired, please re-login")
        reject("Access Token Expired, please re-login");
      }
      headers['Authorization'] = "Bearer " + at;
      headers['idtoken'] = it;
      return headers;
    };
    /**
     * Generic method defined to make authorized requests to APIs
     * @param param Object containing URL, request method, callback and reject functions
     */
    const request = param => {
      return new Promise(function (resolve, reject) {
        if (!param.headers) {
          param.headers = authenticateHeader(reject);
        }
        APIHelp.methods.http_it(
          param.url,
          param.data,
          param.method,
          param.headers,
          param.responseType
        ).then(resolve, resp => {
          return reject(resp.response || resp );
        });
      });
    };
    /**
     * Retrieve whitelabel settings
     * @param wl Whitelabel initials
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$wlSettings = wl => {
      return request({
        url: ACCAPIURL + "/" + wl + "/settings",
        method: 'GET',
        data: {},
        headers: headers
      });
    };
    /**
     * New method defined to request broker info list
     * @param params Object {brokerId: Number, user: boolean, copier: boolean}
     * @returns Promise
     */
    Vue.prototype.$getPartnerBrokers_v2 = params => {
      let args = [];
      if (params.brokerId) {
        args.push('brokerId=' + params.brokerId);
      }
      if (params.user) {
        args.push('user=true');
        if (rules.isWhitelabelSession && !rules.isAdmin && !rules.isBroker) {
          args.push("wl=" + rules.whitelabel);
        }
        if (params.copier) {
          args.push("copier=1");
        }
      }
      return request({
        url: ACCAPIURL + "/broker/list?" + args.join('&'),
        method: 'GET',
        data: {}
      });
    };
    Vue.prototype.$getBrokerPlatform = () => {
      let args = []
      let url = ACCAPIURL + "/broker/platform"
      if(rules.isWhitelabelSession) {
        args.push("wl="+rules.whitelabel)
      }
      return request({
        url: url + "?" + args.join('&'),
        method: 'GET',
      })
    }
    /**
     * Get all acounts
     * @param params
     * @returns Promise
     */
    Vue.prototype.$getAllAccounts = params => {
      let args = [];
      let url = ACCAPIURL + "/accounts";
      if (params.brokername) {
        url = ACCAPIURL + "/broker/" + params.brokername + "/accounts/";
        url += params.follower ? "followers" : "nonfollower";
      }
      if (params.eml) {
        url = ACCAPIURL + "/" + params.eml + "/account";
        if (rules.isWhitelabelSession && !rules.isAdmin && !rules.isBroker) {
          args.push("wl=" + rules.whitelabel);
        }
      }
      return request({
        url: url + "?" + args.join('&'),
        method: 'GET',
        data: {}
      });
    };
    /**
     * Get all acounts v2
     * @param params
     * @returns Promise
     */
    Vue.prototype.$getAllAccounts_v2 = params => {
      let args = [];
      let url = ACCAPIURLV4 + "/accounts";
      if (params.brokername) {
        url = ACCAPIURL + "/broker/" + params.brokername + "/accounts/";
        url += params.follower ? "followers" : "nonfollower";
      }
      if (params.eml) {
        url = ACCAPIURLV4 + "/" + params.eml + "/account";
        if (rules.isWhitelabelSession && !rules.isAdmin && !rules.isBroker) {
          args.push("wl=" + rules.whitelabel);
        }
      }
      return request({
        url: url + "?" + args.join('&'),
        method: 'GET',
        data: {}
      });
    };
    /**
     * Method defiend to query marketplace strategies
     * @returns Promise
     */
    Vue.prototype.$getMarketStrategies_v2 = () => {
      let args = [];
      let url = ACCAPIURL + "/marketplace";
      if (rules.isWhitelabelSession && !rules.isAdmin && !rules.isBroker) {
        args.push('wl=' + rules.whitelabel)
      }
      return request({
        url: url + "?" + args.join('&'),
        method: 'GET',
        data: {}
      });
    };

    const marketplaceArgs = params => {
      let args = [];
      if (params.data) {
        if (params.data.perStratName) {
          args.push(
            'strat_name=' + params.data.perStratName
          )
        }
        if (params.data.perName) {
          args.push(
            'trader_name=' + params.data.perName
          )
        }
        if (params.data.perTraderEmail) {
          args.push(
            'trader_email=' + params.data.perTraderEmail
          )
        }
        if (params.data.perTraderNickname) {
          args.push(
            'trader_nickname=' + params.data.perTraderNickname
          )
        }
        if (params.data.perProfitableOnly) {
          args.push(
            'per_profitable=' + params.data.perProfitableOnly
          )
        }
        if (params.data.typeBy && params.data.sortBy) {
          let typeBy = params.data.typeBy
          let sortBy = params.data.sortBy
          args.push(
            'sort_by=' + sortBy
          )
          args.push(
            'type_by=' + typeBy
          )
        }

      }

      return args
    }

    /**
     * Method defined to query marketplace
     * @returns Promise
     * */
    Vue.prototype.$getMarketplace_V2 = params => {
      let args = marketplaceArgs(params);
      let url = ACCAPIURLV4 + "/marketplace_v2/" + params.next_page;
      if (rules.isWhitelabelSession && !rules.isAdmin && !rules.isBroker) {
        args.push('wl=' + rules.whitelabel)
      }

      return request({
        url: url + "?" + args.join('&'),
        method: 'GET',
        data: {}
      });
    };


    /**
     * List watched strategies
     * @param params Object {eml: Userid}
     * @returns Promise
     */
    Vue.prototype.$getWatchlist_v2 = params => {
      let args = marketplaceArgs(params);
      let url = ACCAPIURLV4 + "/watchlist/" + params.next_page;
      if (rules.isWhitelabelSession && !rules.isAdmin && !rules.isBroker) {
        args.push('wl=' + rules.whitelabel)
      }
      return request({
        url: url + "?" + args.join('&'),
        method: 'GET',
        data: {}
      });
    };

    Vue.prototype.$getWatchlist = params => {
      let args = [];
      let url = ACCAPIURL + "/watchlist/" + params.eml;
      if (rules.isWhitelabelSession && !rules.isAdmin && !rules.isBroker) {
        args.push('wl=' + rules.whitelabel)
      }
      return request({
        url: url + "?" + args.join('&'),
        method: 'GET',
        data: {}
      });
    };

    /**
     * List servers for brokers
     * @returns Promise
     */
    Vue.prototype.$getServers = () => {
      let args = [];
      let url = ACCAPIURL + "/servers";
      if (!rules.isBroker && !rules.isAdmin && rules.isWhitelabelSession) {
        args.push('wl=' + rules.whitelabel)
      }
      return request({
        url: url + "?" + args.join('&'),
        method: 'GET',
        data: {}
      });
    };
    /**
     * List brokers allowed to receive deposits on wallet transfer
     * @returns Promise
     */
    Vue.prototype.$getWalletBrokersTransfer = () => {
      let args = [];
      let url = ACCAPIURL + "/broker/transfer";
      if (!rules.isBroker && !rules.isAdmin && rules.isWhitelabelSession) {
        args.push('wl=' + rules.whitelabel)
      }
      return request({
        url: url + "?" + args.join('&'),
        method: 'GET',
        data: {}
      });
    };
    /**
     * List brokers allowed to receive deposits on wallet transfer
     * @returns Promise
     */
    Vue.prototype.$getWalletBrokersTransfer = () => {
      let args = [];
      let url = ACCAPIURL + "/broker/transfer";
      if (!rules.isBroker && !rules.isAdmin && rules.isWhitelabelSession) {
        args.push('wl=' + rules.whitelabel)
      }
      return request({
        url: url + "?" + args.join('&'),
        method: 'GET',
        data: {}
      });
    };
    /**
     * get Whitelabel settings
     * @returns Promise
     */
    Vue.prototype.$getWhitelabelSettings = () => {
      return request({
        url: ACCAPIURL + "/" + rules.whitelabel + "/settings",
        method: 'GET',
        data: {}
      });
    };
    /**
     * Include new account
     * @param params Object {eml, account, newacc, json_data}
     * @returns Promise
     */
    Vue.prototype.$addAccount_v2 = params => {
      let args = [];
      let url = ACCAPIURL + "/" + params.eml + "/account/" + params.account;
      let method = params.newacc ? "POST" : "PUT";
      if (!rules.isBroker && !rules.isAdmin && rules.isWhitelabelSession) {
        args.push('wl=' + rules.whitelabel)
      }
      return request({
        url: url + "?" + args.join('&'),
        method: method,
        data: params.json_data
      });
    };
    /**
     * Get list of users
     * @returns Promise
     */
    Vue.prototype.$listUsers = () => {
      return request({
        url: MAINAPIURL + "/users",
        method: 'GET',
        data: {}
      })
    };
    /**
     * Return plans and addons for VPS
     * @param params Object {type}
     * @returns Promise
     */
    Vue.prototype.$getVPSPlans_v2 = params => {
      let url = MAINAPIURL + "/vps/plans";
      if (params.type != 'VPS') {
        url = MAINAPIURL + "/vps/addons/plans"
      }
      return request({
        url: url,
        method: 'GET',
        data: {}
      });
    };
    /**
     * Retrieve monitor for VPS
     * @param monitor
     * @returns Promise
     */
    Vue.prototype.$get_monitor = monitor => {
      return request({
        url: MAINAPIURL + "/vps/monitor/" + monitor,
        method: 'GET',
        data: {}
      });
    };
    /**
     * Create or update monitor
     * @param monitor Object {monitor, vps}
     * @returns Promise
     */
    Vue.prototype.$manage_monitor = monitor => {
      return request({
        url: MAINAPIURL + "/vps/monitor" + (monitor.id ? '/' + monitor.id : ''),
        method: monitor.id ? 'PUT' : 'POST',
        data: monitor
      });
    };
    /**
     * Delete monitor
     * @param params Object {monitor}
     * @returns Promise
     */
    Vue.prototype.$delete_monitor = params => {
      return request({
        url: MAINAPIURL + "/vps/monitor/" + params.monitor,
        method: 'DELETE',
        data: {}
      });
    };
    /**
     * Check if email is already used for a whitelabel
     * @param params
     * @returns Promise
     */
    Vue.prototype.$check_wl_email = params => {
      return request({
        url: ACCAPIURL + "/wladmin/exists/" + params.email,
        method: 'POST',
        data: {
          whitelabel: params.whitelabel
        }
      });
    };
    /**
     * Create VPS
     * @returns Promise
     */
    Vue.prototype.$createVPS_v2 = (user_data, single, wizardPlan) => {
      let data = {
        "user_id": user_data.email
      };
      if (wizardPlan) {
        data['billplan'] = wizardPlan.plan.title;
        data['location'] = wizardPlan.location.code;
        data['addons'] = wizardPlan.addons;
      }
      if (wizardPlan.user) {
        data['user_id'] = wizardPlan.user;
      }
      return request({
        url: MAINAPIURL + "/vps",
        method: 'POST',
        data: data
      })
    };
    /**
     * Retrieve VPSes list for a user
     * @param params Object {user_data}
     * @returns Promise
     */
    Vue.prototype.$getVPSes_v2 = params => {
      let url = MAINAPIURL + "/vpsuser/" + params.user_data.email;
      if (params.user_data['roles'].indexOf('admin') >= 0) {
        url = MAINAPIURL + "/vps";
      }
      return request({
        url: url,
        method: 'GET',
        data: {}
      })
    };
    /**
     * New Method to retrieve VPSes list
     * @param param Object {user_data}
     * @returns Promise
     */
    Vue.prototype.$getVPSes_v3 = param => {
      let args = []
      if (param) {
        args.push('param=' + param)
      }
      return request({
        url: MAINAPIURL + "/vps/list?" + args.join("&"),
        method: 'GET',
        data: {}
      })
    };
    /**
     * Method defined to get exchange rates
    */
    Vue.prototype.$getExchangeRates = function () {
      return request({
        url: MAINAPIURL + "/exchange/rates",
        method: 'GET',
        data: {}
      })
    };
    /**
     * PayPal user order
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$paypalUserConfirm = param => {
      return request({
        url: MAINAPIURL + '/paypal/user',
        method: 'POST',
        data: param
      });
    };
    /**
     * List discoverable strategies
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$listDiscoverableStrategies = () => {
      return request({
        url: ACCAPIURL + '/strategies/discoverable',
        method: 'GET',
        data: {}
      });
    };
    /**
     * List discoverable strategies for WL
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$listDiscoverableStrategiesForWl = whitelabel => {
      let url = ACCAPIURL + "/" + whitelabel + "/wl/strategies"
      if (!whitelabel) {
        url = ACCAPIURL + "/wl/strategies"
      }
      return request({
        url: url,
        method: 'GET',
        data: {}
      });
    };
    /**
     * Return affiliate URL
     * @param userid
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getAffl = userid => {
      return request({
        url: ACCAPIURLV4 + "/affiliate/get_affiliate_info/" + userid,
        method: 'GET',
        data: {}
      });
    };
    /**
     * List whitelabel VPS
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getListWhitelabelVPS = (data) => {
      return request({
        url: MAINAPIURL + "/wl/vps/list",
        method: 'POST',
        data: data
      });
    };
    /**
     * List whitelabel VPS for admins
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getListWhitelabelVPSAdmin = wl => {
      return request({
        url: MAINAPIURL + "/wl/vps/admin",
        method: 'POST',
        data: {
          whitelabel: wl ? wl : rules.whitelabel
        }
      });
    };
    /**
     * List billplans for whitelabel
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$listWhitelabelBillingPlans = param => {
      let args = [];
      if(!param.isHokoAdmin) {
        args.push('wl=' + (param.wl ? param.wl : rules.whitelabel))
      }
      if (param.active) {
        args.push('active=true')
      }
      return request({
        url: MAINAPIURL + "/wl/billplan?" + args.join('&'),
        method: 'GET',
        data: {}
      });
    }
    /**
     * Get VPS locations
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getVPSLocations = function () {
      return request({
        url: MAINAPIURL + "/vps/locations",
        method: 'GET',
        data: {}
      });
    };
    /**
     * Submiting WL vps creating request
     */
    Vue.prototype.$requestVPS = param => {
      param.data.provision = rules.provision;
      return request({
        url: MAINAPIURL + "/wl/vps",
        method: 'POST',
        data: param.data
      });
    };
    /**
     * Approve or disapprove whitelabel VPS
     */
    Vue.prototype.$vpsWhitelabelResolve = param => {
      return request({
        url: MAINAPIURL + "/wl/vps/" + param.vps + "/" + param.action,
        method: 'PUT',
        data: {
          whitelabel: param.whitelabel,
          rejection_justification: param.rejection_justification,
        }
      });
    };
    /**
     * Approve or disapprove whitelabel VPS (user)
     */
    Vue.prototype.$vpsWhitelabelUserDisapprove = param => {
      return request({
        url: MAINAPIURL + "/" + param.userid + "/vps/" + param.vps + "/disapprove",
        method: 'PUT',
        data: {}
      });
    };
    /**
     * Reset VPS password for HokoCloud VPS
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$resetRDP = function (vps) {
      return request({
        url: MAINAPIURL + "/vpsresetpass/" + vps,
        method: 'PUT',
        data: {}
      });
    };
     /**
     * Reset VPS password for WHITELABEL VPS
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$resetWhitelabelRDP = function (vps) {
      return request({
        url: MAINAPIURL + "/wl/vps/resetpass/" + vps,
        method: 'PUT',
        data: {}
      });
    };
    /**
     * Reset VPS password for Whitelabel VPS
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$resetWhitelabelPasswordVPS = vps => {
      return request({
        url: MAINAPIURL + "/wl/vps/resetpass/" + vps,
        method: 'PUT',
        data: {}
      });
    };
    /**
     * Stop whitelabel VPS
     * @param vps name
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$stopWhitelabelVPS = vps => {
      return request({
        url: MAINAPIURL + "/wl/vps/" + vps,
        method: 'PUT',
        data: {
          action: 'stop'
        }
      });
    };
    /**
     * Reboot whitelabel VPS
     * @param vps name
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$rebootWhitelabelVPS = vps => {
      return request({
        url: MAINAPIURL + "/wl/vps/" + vps,
        method: 'PUT',
        data: {
          action: 'reboot'
        }
      });
    };
    /**
     * Delete whitelabel VPS
     * @param vps id
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$deleteWhitelabelVPS = vps => {
      return request({
        url: MAINAPIURL + "/wl/vps/" + vps,
        method: 'DELETE',
        data: {}
      });
    };
    /**
     * Include a new vps BILLPLAN for a whitelabel
     * @param json_data
     * @returns Promise
     */
    Vue.prototype.$persistWhitelabelBillingPlan = json_data => {
      return request({
        url: MAINAPIURL + "/wl/billplan" + (json_data.id ? "/" + json_data.id : ""),
        method: json_data.id ? "PUT" : "POST",
        data: json_data
      });
    };
    /**
     * Return a whitelabel vps BILLPLAN by vps config id
     * @param billplan
     * @return Promise
     */
    Vue.prototype.$retrieveWhitelabelBillingPlan = billplan => {
      return request({
        url: MAINAPIURL + "/wl/billplan/" + billplan,
        method: 'GET',
        data: {}
      });
    };
    /**
     * Delete a whitelabel vps BILLPLAN by vps config id
     * @param billplan
     * @return Promise
     */
    Vue.prototype.$deleteWhitelabelBillingPlan = billplan => {
      return request({
        url: MAINAPIURL + "/wl/billplan/" + billplan,
        method: 'DELETE',
        data: {}
      });
    };
    /**
     * Retrieve monitor data for WL VPS
     * @param monitor Monitor ID
     * @return Promise
     */
    Vue.prototype.$retrieve_monitor = monitor => {
      return request({
        url: MAINAPIURL + "/wl/vps/monitor/" + monitor,
        method: 'GET',
        data: {}
      });
    };
    /**
     * Update whitelabel VPS monitor
     * @return Promise
     */
    Vue.prototype.$update_wl_vps_monitor = param => {
      return request({
        url: MAINAPIURL + "/wl/vps/monitor/" + param.vm_name,
        method: 'PUT',
        data: param
      });
    };
    /**
     * Retrieve RDP token
     * @param user_data
     * @param rdpname
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getrdpToken = function (user_data, rdpname) {
      return request({
        url: RDPAPIURL +"/user/rdptoken",
        method: 'POST',
        data: {
          "user_id": user_data.email,
          "name": rdpname
        }
      })
    };
    /**
     * Returns RDP url for VPS access
     * @param tokeninfo
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getRDPURL = tokeninfo => {
      return request({
        url: RDPAPIURL + "/user/url",
        method: 'POST',
        data: {"rdptoken": tokeninfo.rdptoken}
      });
    };
    /**
     * Upload policies for whitelabel
     * @param params
     */
    Vue.prototype.$uploadPolicieFile = params => {
      let formData = new FormData();
      formData.append(params.filename, params.files);
      const headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + VueCookies.get('dash-at')
      }
      return request({
        url: ACCAPIURL + "/" + params.whitelabel + "/policies",
        method: 'POST',
        data: formData,
        headers: headers
      });
    };
    /**
     * Delete policies
     * @param whitelabel
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$deletePolicieFile = whitelabel => {
      return request({
        url: ACCAPIURL + "/" + whitelabel + "/policies",
        method: 'DELETE',
        data: {},
      });
    }
    /**
     * List whitelabels
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$list_whitelabels = () => {
      return request({
        url: ACCAPIURL + "/wladmin",
        method: 'GET',
        data: {},
      });
    };
    /**
     * Retrieve Whitelabel
     * @param whitelabel
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$retrieve_whitelabels = whitelabel => {
      return request({
        url: ACCAPIURL + "/wladmin/" + whitelabel,
        method: 'GET',
        data: {},
      });
    };
    /**
     * Insert or update whitelabel
     * @param whitelabel
     * @param json_data
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$persist_whitelabel = (whitelabel, json_data) => {
      return request({
        url: ACCAPIURL + "/wladmin" + (whitelabel ? "/" + whitelabel : ""),
        method: whitelabel ? "PUT" : "POST",
        data: json_data,
      });
    };
    /**
     * Delete whitelabel
     * @param whitelabel
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$delete_whitelabel = whitelabel => {
      return request({
        url: ACCAPIURL + "/wladmin/" + whitelabel,
        method: 'DELETE',
        data: {},
      });
    };
    /**
     * Download policies
     */
    Vue.prototype.$downloadPolicies = (whitelabel, filename, reject) => {
      request({
        url: ACCAPIURL + "/" + whitelabel + "/policies",
        method: 'GET',
        data: {},
        responseType: 'arraybuffer'
      }).then(response => {

        let array = filename.split('.');
        const type = array[array.length-1];

        let blob = new Blob([response], { type: 'application/' + type })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename
        link.click()

      }, reject)
    };
    /**
     * List brokers for whitelabel
     * @param whitelabel
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$listBrokers = whitelabel => {
      let url = ACCAPIURL + "/" + (whitelabel ? whitelabel + "/brokers" : "wladmin/brokers")
      return request({
        url: url,
        method: 'GET',
        data: {},
      });
    }
    /**
     * List brokers for internal billing
     * @param whitelabel
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$listBrokersInternalBilling = () => {
      return request({
        url: ACCAPIURLV4 + '/brokers/internal',
        method: 'GET',
        data: {},
      });
    };
    /**
     * Follow or remove follower from strategy
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$delsertFollower = function (sid, aid, remove, param) {
      return request({
        url: ACCAPIURL + "/strategy/" + sid + "/follower/" + aid,
        method: remove ? 'DELETE' : 'POST',
        data: param,
      });
    };
    /**
     * Retrieve strategy price
     */
    Vue.prototype.$getStrategyPrice = function (sid) {
      return request({
        url: ACCAPIURLV4 + "/strategy/price/" + sid,
        method: 'GET',
        data: {},
      })
    }
    /**
     * Check if an account can follow a strategy
     */
    Vue.prototype.$canFollowStrategy = function (sid, aid) {
      return request({
        url: ACCAPIURL + "/strategy/" + aid + "/can_follow/" + sid,
        method: 'POST',
        data: {},
      });
    }
    /**
     * Check if a list of accounts can follow a strategy
     */
    Vue.prototype.$canBulkFollowStrategy = param => {
      return request({
        url: ACCAPIURL + "/strategy/can_follow/" + param.sid,
        method: 'POST',
        data: {
          accounts: param.accounts
        },
      });
    }
    /**
     * Check broker rule if broker allow followers
     * @aid account id
     */
    Vue.prototype.$validade_broker_rule = param =>  {
      return request({
        url: ACCAPIURLV4 + "/strategy/broker/can_follow/" + param,
        method: 'POST',
        data: {}
      })
    }
    /**
     * Get wallet marketplace entries for all currencies base on type
     * @param type
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getMarketEntries = type =>  {
      let url = MAINAPIURLV4 + "/wallet/marketplace";
      url += type ?  "?type=" + type : "?type=sell"
      return request({
        url: url,
        method: 'GET',
        data: {},
      });
    };
    /**
     * Get all wallet marketplace entries by user email
     * @param userid
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getMarketEntries_V2 = userid => {
      return request({
        url: MAINAPIURLV4 + "/" + userid + "/wallet/marketplace",
        method: 'GET',
        data: {},
      });
    };
    /**
     * Post an entrie for wallet Marketplace
     * @param {*} user_data
     * @param {*} json_data
     * @param {*} newentry
     */
    Vue.prototype.$postMarketEntry = (user_data, json_data, newentry) => {
      return request({
        url: MAINAPIURL + "/" + user_data.email + "/wallet/marketplace",
        method: newentry ? "POST" : "PUT",
        data: json_data,
      });
    };
    /**
     * Post an entrie for wallet Marketplace
     * @param {*} json_data
     * @param {*} newentry
     */
    Vue.prototype.$postMarketEntry_V2 = (json_data, newentry) => {
      return request({
        url: MAINAPIURLV4 + "/" + json_data.userid + "/wallet/marketplace",
        method: newentry ? "POST" : "PUT",
        data: json_data,
      });
    };
    /**
     * DELETE an entrie for wallet Marketplace
     * @param param
     */
    Vue.prototype.$delMarketEntry = param => {
      return request({
        url: MAINAPIURLV4 + "/" + param.userid + "/wallet/marketplace",
        method: 'DELETE',
        data: param
      });
    };
    /**
     * Update user info
     * @param user_id
     * @param json_data
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$updateUserInfo = (user_id, json_data) => {
      return request({
        url: MAINAPIURL + "/user/" + user_id,
        method: 'PUT',
        data: json_data,
      });
    };
    /**
     * Retrieve user info
     * @param user_id
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getUserInfo = function (user_id) {
      return request({
        url: MAINAPIURL + "/user/" + user_id,
        method: 'GET',
        data: {},
      });
    };
    /**
     * Check if user exists
     * @param {*} user_email
     */
    Vue.prototype.$checkIfUserExists = (user_email) => {
      return request({
        url: MAINAPIURL + "/user/" + user_email + "/exists",
        method: 'GET',
        data: {}
      });
    };
    /**
     * Check if nickname exists
     * @param nickname
     */
    Vue.prototype.$checkIfNicknameExists = nickname => {
      return request({
        url: `${MAINAPIURLV4}/user/${nickname}/nickname_exists`,
        method: 'GET',
        data: {}
      });
    };
    /**
     * Get wallet balance list or user
     * @param userid
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getWalletCredit = userid => {
      return request({
        url: WALLETBALANCEAPI + `/${userid}/wallet`,
        method: 'GET',
        data: {}
      });
    };
    /**
     * Get wallet balance list or user
     * @param userid
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getUserProducts = userid => {
      return request({
        url: MAINAPIURLV4 + `/${userid}/products`,
        method: 'GET',
        data: {}
      });
    };
    /**
     * Topup users (only available for admins)
     * @param user_id
     * @param json_data
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$topUpUser = function (user_id, json_data) {
      return request({
        url: MAINAPIURLV4 + "/wallet/" + user_id,
        method: 'POST',
        data: json_data
      });
    };
    /**
     * Get wallet history and details
     * @param type
     * @param mo
     * @param year
     * @param userid
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getWallet = function (type, mo, year, userid) {
      let url = MAINAPIURL + "/wallet/" + type;
      if (userid) {
        url += "/" + userid;
      }
      if (mo && year) {
        url += "/" + year + "-" + mo + "-01";
      }
      return request({
        url: url,
        method: 'GET',
        data: {}
      });
    };
    /**
     * List payouts for user
     * @param userid
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getPayouts = userid => {
      return request({
        url: MAINAPIURL + "/wallet/payouts/" + userid,
        method: 'GET',
        data: {}
      });
    };
  /**
     * List payouts for user v2
     * @param userid
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getPayouts_v2 = userid => {
      return request({
        url: ACCAPIURL + "/" + userid +  "/payouts" ,
        method: 'GET',
        data: {}
      });
    };
    /**
     * List pending payouts for user
     * @param userid
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getComissions = userid => {
      return request({
        url: MAINAPIURL + "/commissions/" + userid,
        method: 'GET',
        data: {}
      });
    };
    /**
     * Get wallet projections
     * @param userid
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getWalletProjection = userid => {
      let url = MAINAPIURL + "/wallet/projection";
      if (userid) {
        url += "/" + userid;
      }
      return request({
        url: url,
        method: 'GET',
        data: {}
      });
    };
    /**
     * Transfer bance between users
     * @param user_id
     * @param json_data
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$transferBalance = function (user_id, json_data) {
      return request({
        url: MAINAPIURL + "/wallet/" + user_id + "/transfer",
        method: 'POST',
        data: json_data
      });
    };
    /**
     * Post charge to user
     * @param user_id
     * @param json_data
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$postChargeUser = function (user_id, json_data) {
      return request({
        url: MAINAPIURL + "/wallet/" + user_id + "/debit",
        method: 'POST',
        data: json_data
      });
    };
    /**
     * Update withdrawalw
     * @param userid
     * @param tranid
     * @param op
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$opWithdrawRequest = (userid, tranid, op) => {
      let url = MAINAPIURL + "/wallet/withdrawals/" + op;
      if (rules.isAdmin) {
        url += "/" + tranid;
      } else {
        url += "/" + userid + "/" + tranid;
      }
      return request({
        url: url,
        method: 'POST',
        data: {}
      });
    };
    /**
     * Send withdraw request
     * @param json_data
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$sendWithdrawRequest = json_data => {
      return request({
        url: MAINAPIURL + "/wallet/withdrawals",
        method: 'POST',
        data: json_data
      });
    };
    /**
     * Get user's withdrawl
     * @param userid
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getUserWithdrawal = userid => {
      let url = MAINAPIURL + "/wallet/withdrawals";
      if (userid) {
        url += "/" + userid;
      }
      return request({
        url: url,
        method: 'GET',
        data: {}
      });
    };
    /**
     * List broker's accounts
     */
    Vue.prototype.$listBrokerAccounts = param => {
      let args = [];
      if (param.paginate) {
        let paginate = param.paginate;
        args.push('page=' + paginate.currentPage)
        args.push('limit=' + paginate.perPage)
      }
      if (param.order) {
        let order = param.order;
        args.push('orderby=' + order.orderBy)
        args.push('order=' + order.order)
      }
      if (param.filter) {
        let filter = param.filter;
        args.push('user=' + filter.user)
        args.push('connect=' + filter.connect)
      }
      return request({
        url: ACCAPIURL + "/" + param.broker + "/accounts?" + args.join("&"),
        method: 'GET',
        data: {}
      });
    };
     /**
     * List broker's charts
     */
    Vue.prototype.$listBrokerChart = param => {
      return request({
        url: ACCAPIURL + "/" + param.broker + "/accounts/chart",
        method: 'GET',
        data: {}
      });
    };
    /**
     * List broker's strategies
     */
    Vue.prototype.$listBrokerStrategies = param => {
      let args = [];
      if (param.paginate) {
        let paginate = param.paginate;
        args.push('page=' + paginate.currentPage)
        args.push('limit=' + paginate.perPage)
      }
      if (param.order) {
        let order = param.order;
        args.push('orderby=' + order.orderBy)
        args.push('order=' + order.order)
      }
      if (param.filter) {
        let filter = param.filter;
        args.push('strat=' + filter.strat)
      }
      return request({
        url: ACCAPIURL + "/" + param.broker + "/strategies?" + args.join("&"),
        method: 'GET',
        data: {}
      });
    };
    /**
     * List pending topups
     * @param op
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getTopUps = op => {
      let url = MAINAPIURL + "/topups/pending";
      if (op) {
        url += "/" + op;
      }
      return request({
        url: url,
        method: 'GET',
        data: {}
      });
    };
    /**
     * Delete topups for traders and affiliates
     * @param tid
     * @param type
     * @param deletion
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$delTopUps = (tid, type, deletion) => {
      let url = MAINAPIURL + "/topups/pending";
      let method = deletion ? "DELETE" : "PUT";
      url += type === 'affiliate' ? "/affiliate_pending" : "/trader_pending"
      url += "/" + tid;
      return request({
        url: url,
        method: method,
        data: {}
      });
    };
    /**
     * List billings for VPSs
     * @param mo
     * @param year
     * @param userid
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getBilling = (mo, year, userid) => {
      let url = MAINAPIURL;
      if (userid) {
        url += "/" + userid;
      }
      url += "/billing"
      if (mo && year) {
        url += "/" + year + "-" + mo + "-01";
      }
      return request({
        url: url,
        method: 'GET',
        data: {}
      });
    };
    /**
     * Get banners for brokers
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getBanners = () => {
      return request({
        url: MAINAPIURL + "/banners",
        method: 'GET',
        data: {}
      });
    };
    /**
     * Manage VPS
     * @param vps
     * @param op
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$manageVPS = (vps, op) => {
      return request({
        url: MAINAPIURL + "/vps/" + vps,
        method: 'PUT',
        data: {"action": op}
      });
    };
    /**
     * Load public equity strat chart
     * @param strat_id
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$loadPubEqStratChart = strat_id => {
      return request({
        url: PLOTAPIURL + "/public/equity/" + strat_id,
        method: 'GET',
        data: {},
        headers: {}
      });
    };
    /**
     * Load equity plot data from account
     * @param account
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getPlotDataEquity = account => {
      return request({
        url: PLOTAPIURL + "/" + account + "/equity",
        method: 'GET',
        data: {}
      });
    };
    /**
     * Load strat chart
     * @param strat_id
     * @param next_idx
     * @param mb Minimum Balance
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$loadStratChart = (strat_id, next_idx, mb) => {
      return new Promise(function (resolve, reject) {
        return request({
          url: PLOTAPIURL + "/" + strat_id + "/marketplace",
          method: 'GET',
          data: {}
        }).then(resp => {
          resp['strat_id'] = strat_id;
          resp['next_idx'] = next_idx;
          resp['minimum_balance'] = mb;
          resolve(resp)
        }, reject)
      })
    };
    /**
     * Get plot data for balance
     * @param account
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getPlotData = account => {
      return request({
        url: PLOTAPIURL + "/" + account + "/balance",
        method: 'GET',
        data: {}
      });
    };
    /**
     * Load strat chart
     * @param params Object {strat_id}
     * @returns Promise
     */
    Vue.prototype.$loadPubBalStratChart_v2 = params => {
      return request({
        url: PLOTAPIURL + "/public/balance/" + params.strat_id,
        method: 'GET',
        data: {},
        headers: {}
      })
    };
    /**
     * Update broker name for the server
     * @param {*} bsinfo broker info
     */
    Vue.prototype.$upBrokerServers = function (bsinfo) {
      let url = ACCAPIURL + "/broker/servers";
      let json_data = {};
      if (bsinfo !== undefined && bsinfo !== null) {
        url += "/" + bsinfo.id;
        json_data = {
          "broker": bsinfo.broker,
          "server": bsinfo.server,
          "multiplier": bsinfo.multiplier,
          "timezone": bsinfo.timezone
        };
      }
      return request({
        url: url,
        method: 'PUT',
        data: json_data
      });
    };
    /**
     * Method defined to get all Brokers servers
     */
    Vue.prototype.$getBrokerServers = function () {
      return request({
        url: ACCAPIURL + "/broker/servers",
        method: 'GET',
        data: {}
      });
    };
    /**
     * Method defined to get all Brokers servers
     */
    Vue.prototype.$getAllTimezones = function () {
      return request({
        url: ACCAPIURLV4 + "/proxy/timezones",
        method: 'GET',
        data: {}
      });
    };
    /**
     * Method defined to register a user as a premium member
     * @param {*} json_data
     */
    Vue.prototype.$sendEarnMore = json_data => {
      return request({
        url: ACCAPIURL + "/earn_more_email",
        method: "POST",
        data: json_data
      });
    };
    /**
     * Method defined to trigger an email informing that a user clicked on a partner broker link
     * @param {*} json_data
     */
    Vue.prototype.$sendPartnerBrokerEmail = json_data => {
      return request({
        url: ACCAPIURL + "/partner_broker_email",
        method: "POST",
        data: json_data
      });
    };
    /**
     * Method defined to Delete a Broker
     * @param {*} bid
     */
    Vue.prototype.$delPartnerBrokers = bid => {
      return request({
        url: ACCAPIURL + "/broker/info/" + bid,
        method: "DELETE",
        data: {}
      });
    };
    /**
     * Method defined to update a broker
     * @param {*} bid
     * @param {*} json_data
     */
    Vue.prototype.$upPartnerBrokers = (bid, json_data) => {
      return request({
        url: ACCAPIURL + "/broker/info/" + bid,
        method: "PUT",
        data: json_data
      });
    };
    /**
     * Method defined to get Overall Performance (for a user)
     * @param {*} eml
     */
    Vue.prototype.$getPerformance = eml => {
      return request({
        url: ACCAPIURL + "/" + eml + "/performance",
        method: "GET",
        data: {}
      });
    };
    /**
     * Method defined to get details of account (by internal ID)
     * @param {*} aid
     */
    Vue.prototype.$getAccountDetails = (aid) => {
      return request({
        url: ACCAPIURL + "/account/" + aid,
        method: "GET",
        data: {}
      });
    };
    /**
     * Check if user can Create Public Strategy
     * @param acc_id
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$canCreatePublicStrategy = acc_id => {
      return request({
        url: ACCAPIURL + "/" + acc_id + "/canCreatePublicStrategy",
        method: "GET",
        data: {}
      });
    };
     /**
      * Check if user can Create Public/Private Strategy
      * @returns {Promise<unknown>}
      * @param param
      */
    Vue.prototype.$checkStrategyVisibility = param => {
      return request({
        url: ACCAPIURLV4 + "/strategy/" + param.account + "/canbe/" + param.type,
        method: "GET",
        data: {}
      });
    };
    /**
     * Method defined to delete an account
     * @param {*} param
     */
    Vue.prototype.$delAccount = param => {
      return request({
        url: ACCAPIURL + "/" + param.userid + "/account/" + param.account,
        method: "DELETE",
        data: param
      });
    };
    /**
     * Manual Validation
     * @param account
     */
    Vue.prototype.$manualValidation = param => {
      return request({
        url: ACCAPIURLV4 + "/manual/validation/" + param,
        method: 'POST',
      })
    }
    /**
     * Manual Revalidation
     * @param account
     */
    Vue.prototype.$manualRevalidation = param => {
      return request({
        url: ACCAPIURLV4 + "/manual/revalidation/" + param,
        method: 'POST',
      })
    }
    /**
     * Manual Revalidation All
     */
    Vue.prototype.$revalidateManualAll = param => {
      return request({
        url: ACCAPIURLV4 + "/manual/revalidation/all",
        method: 'POST',
      })
    }
     /**
     * Method defined to nofify account removed from validation
     * @param {*} param
     */
    Vue.prototype.$notifyAccountRemovedFromValidations = param => {
      return request({
        url: ACCAPIURLV4 + "/notify/account/removal",
        method: "POST",
        data: param
      })
    }
     /**
     * Method defined to nofify account removed from validation
     * @param {*} param
     */
    Vue.prototype.$notifyAccountRevalidationFailed = param => {
      return request({
        url: ACCAPIURLV4 + "/notify/account/revalidation",
        method: "POST",
        data: param
      })
    }
    /**
     * Method defined to create a new broker
     * @param {*} param
     */
    Vue.prototype.$addBroker = json_data => {
      return request({
        url: ACCAPIURL + "/broker/servers",
        method: "POST",
        data: json_data
      });
    };
    /**
     * Get All strategies overview
     */
    Vue.prototype.$getStrategiesOverview = () => {
      return request({
        url: ACCAPIURL + "/strategiesOverview",
        method: "GET",
        data:{}
      });
    };
    /**
     * Method defined to get strategies
     * @param {*} eml email
     * @param {*} sid strategie id
     */
    Vue.prototype.$getStrategies = (eml, sid) => {
      let url = ACCAPIURL + "/strategies";
      if (eml) {
        url += "/" + eml
      }
      if (sid) {
        url += "/" + sid
      }
      return request({
        url: url,
        method: "GET",
        data: {}
      });
    };
    /**
     * Method defined to get strategies to change details
     * @param {*} eml email
     * @param {*} sid strategie id
     */
    Vue.prototype.$getStrategyForUser = (eml, sid) => {
      let url = `${ACCAPIURLV4}/strategies/${eml}/${sid}`;
      return request({
        url: url,
        method: "GET",
        data: {}
      });
    };
    /**
     * Method defined to get all public strategies
     * @param {*} sid
     */
    Vue.prototype.$getStrategyDetail = sid => {
      let url = ACCAPIURL + "/marketplace";
      if (sid) {
        url += "/" + sid;
      }
      return request({
        url: url,
        method: "GET",
        data: {}
      });
    };
    /**
     * Method defined to get Mat overview
     * @param {*} server
     */
    Vue.prototype.$getMATs = function (server) {
      let url = ACCAPIURL + "/matoverview";
      if (server) {
        url += "/" + server;
      }
      return request({
        url: url,
        method: "GET",
        data: {}
      });
    };
    /**
     * Method defined to set ON or OFF the MAT auto-create or auto-delete scripts
     * @param {*} json_data
     */
    Vue.prototype.$setMATsAuto = (json_data) => {
      return request({
        url: ACCAPIURLV4 + "/strategy/mat/cd",
        method: "POST",
        data: json_data
      });
    };
    /**
     * Method defined to get ON or OFF the MAT auto-create or auto-delete scripts
     */
    Vue.prototype.$getMATsAuto = () => {
      return request({
        url: ACCAPIURLV4 + "/strategy/mat/cd",
        method: "GET",
        data: {}
      });
    };
    /**
     * Method defined to get list of followers of a strategy
     * @param {*} eml
     * @param {*} sid
     */
    Vue.prototype.$getStrategyFollowers = (eml, sid) => {
      return request({
        url: ACCAPIURL + "/strategies/" + eml + "/" + sid + "/followers",
        method: "GET",
        data: {}
      });
    };
    /**
     * Method defined to Delete or Insert a strategy from watchlist
     * @param {*} eml
     * @param {*} sid
     * @param {*} remove
     */
    Vue.prototype.$delsertStrategy = (update, params) => {
      let url = ACCAPIURL + "/watchlist/" + params.userid + "/" + params.sid;
      let method = update.isWatching ? "DELETE" : "POST";
      return request({
        url: url,
        method: method,
        data: {}
      });
    };

    /**
     * Method defined to Delete or Insert a strategy from watchlist
     * @param {*} eml
     * @param {*} sid
     * @param {*} remove
     */
    Vue.prototype.$delAllStrategy = (data, params) => {
      let url = ACCAPIURL + "/watchlist/" + params.userid;
      let method = "DELETE";
      return request({
        url: url,
        method: method,
        data: {},
      });
    };

    /**
     * Method defined to delete a strategy
     * @param {*} eml
     * @param {*} sid
     */
    Vue.prototype.$delStrategy = (eml, sid) => {
      let url = ACCAPIURL + "/strategies";
      if (eml) {
        url += "/" + eml;
      }
      if (sid) {
        url += "/" + sid;
      }
      return request({
        url: url,
        method: "DELETE",
        data:{}
      });
    };
    /**
     * Method defined to modify follower pricing
     * @param {*} json_data
     * @param {*} sid
     * @param {*} aid
     */
    Vue.prototype.$upPrice = (json_data, sid, aid) => {
      return request({
        url: ACCAPIURL + "/follower/pricing/" + sid + "/" + aid,
        method: "PUT",
        data: json_data
      });
    };
    /**
     * Method defined to modify followers admin - max order update
     * @param {*} json_data
     * @param {*} sid
     */
    Vue.prototype.$upMaxOrder = (json_data, sid) => {
      return request({
        url: ACCAPIURLV4 + "/accounts/followers/" + sid,
        method: "POST",
        data: json_data
      });
    };
    /**
     * Method defined to check if strategy name is available
     * @param {*} strat_name
     */
    Vue.prototype.$checkStratName = strat_name => {
      return request({
        url: ACCAPIURL + '/strategy/checkname/' + strat_name,
        method: 'GET'
      })
    };
    /**
     * Create or Update a Strategy
     * @param {*} eml
     * @param {*} json_data
     * @param {*} sid
     * @param {*} aid
     */
    Vue.prototype.$upsertStrategy = (eml, json_data, sid, aid) => {
      let url = ACCAPIURL + "/strategies";
      let method = "POST";
      if (eml) {
        url += "/" + eml;
        method = "PUT";
      } else {
        url = ACCAPIURL + "/strategy";
      }
      if (sid) {
        url += "/" + sid;
        method = "PUT";
      }
      if (aid) {
        url += "/" + aid;
      }
      return request({
        url: url,
        method: method,
        data: json_data
      });
    };
    /**
     * Method defined to get new platforms
     */
    Vue.prototype.$getNewPlatforms = function () {
      return request({
        url: ACCAPIURL + "/changelog/newplatform",
        method: "GET",
        data: {}
      });
    };
    /**
     * Method defined to delete new platforms
     * @param {*} id
     * @param {*} eml
     */
    Vue.prototype.$newPlatformsDone = (id, eml) => {
      return request({
        url: ACCAPIURL + "/changelog/newplatform/" + eml + "/" + id,
        method: "DELETE",
        data: {}
      });
    };
    /**
     * Method defined to get Strategy Change
     */
    Vue.prototype.$getParamsChanges = function () {
      return request({
        url: ACCAPIURL + "/changelog/stratchange",
        method: "GET",
        data: {}
      });
    };
    /**
     * Method defined to delete stratchange
     * @param {*} id
     * @param {*} eml
     */
    Vue.prototype.$paramsChangesDone = function (id, eml) {
      return request({
        url: ACCAPIURL + "/changelog/stratchange/" + eml + "/" + id,
        method: "DELETE",
        data:{}
      });
    };
    /**
     * Method defined to get Deleted Plataforms
     */
    Vue.prototype.$getDeletes = function () {
      return request({
        url: ACCAPIURL + "/changelog/delplatform",
        method: "GET",
        data:{}
      });
    };
    /**
     * Method defined to DELETE
     * @param {*} id
     * @param {*} eml
     */
    Vue.prototype.$deletesDone = function (id, eml) {
      return request({
        url: ACCAPIURL + "/changelog/delplatform/" + eml + "/" + id,
        method: "DELETE",
        data: {}
      });
    };
    /**
     * Method defined to get Disconnections
     */
    Vue.prototype.$getDisconnects = function () {
      return request({
        url: ACCAPIURL + "/heartbeat",
        method: "GET",
        data: {}
      });
    };
    /**
     * Method defined to get pending accounts
     */
    Vue.prototype.$getValidations = function () {
      return request({
        url: ACCAPIURL + "/accounts/pending",
        method: "GET",
        data: {}
      });
    };
    /**
     * Method defined to set status failed on pending accounts
     */
    Vue.prototype.$setFailedStatus = userid => {
      return request({
        url: ACCAPIURL + "/accounts/pending/" + userid,
        method: "PUT",
        data: {}
      });
    };
    /**
     * Method defined to get Revalidation status
     * @param {*} master
     */
    Vue.prototype.$getReValidations = master => {
      let url = ACCAPIURL + "/failedRevalidation";
      url += master ? "/masters" : "/accounts";
      return request({
        url: url,
        method: "GET",
        data: {}
      });
    };
    /**
     * Method defined to get Revalidation status
     */
    Vue.prototype.$getReValidations_v2 = function() {
      return request({
        url: ACCAPIURLV4 + "/changelog/revalidation",
        method: "GET",
        data: {}
      });
    };
    /**
     * Method defined to get Revalidation status
     * @param data (account, password, server, type, symbol)
     */
    Vue.prototype.$addAccountToPriorityRev = data => {
      return request({
        url: ACCAPIURLV4 + "/changelog/revalidation",
        method: "POST",
        data: data
      });
    };
    /**
     * Method defined to force all entries to revalidation
     */
    Vue.prototype.$revalidateAll = function () {
      return request({
        url: ACCAPIURLV4 + "/changelog/revalidation/all",
        method: "POST",
      })
    }
    /**
     * Method defined to log a new Sync Strategy
     * @param data (account, password, server, type, symbol)
     */
    Vue.prototype.$addToSyncTrades = data => {
      return request({
        url: ACCAPIURLV4 + "/changelog/synctrades",
        method: "POST",
        data: data
      })
    }
    /**
     * Method defined to force all entries to revalidation
     */
    Vue.prototype.$forceAccountToValidation = function (account) {
      return request({
        url: ACCAPIURLV4 + "/validation/force/"+ account,
        method: "POST",
      })
    }
    /**
     * Method defined to get Assets
     */
    Vue.prototype.changeLogsAssets = function () {
      return request({
        url: ACCAPIURL + "/changelog/assets",
        method: "GET",
        data: {}
      });
    };
    /**
     * Method defined to delete change log asset
     * @param {*} id
     * @param {*} eml
     */
    Vue.prototype.$changeLogAssetDone = function (id, eml) {
      let url = ACCAPIURL + "/changelog/assets/" + eml + "/" + id;
      return request({
        url: url,
        method: "DELETE",
        data: {}
      });
    };
    /**
     * Method defined to get public strategy
     * @param {*} surl
     */
    Vue.prototype.$getPublicStrategy = function (surl) {
      return request({
        url: PUBAPIURL + "/" + surl,
        method: "GET",
        data: {},
        headers: {},
      });
    };
    /**
     * Method defined to modify traded assets matching follower
     * @param params
     */
    Vue.prototype.$updateAssetFollower = params => {
      let url = ACCAPIURL + "/assets/follower/" + params.sid + "/" + params.aid + "/v2";
      return request({
        url: url,
        method: "PUT",
        data: {
          "tradedAssetsMatching": params.tradedAssets,
          "assetSuffix": params.suffix,
          "defaultSuffix": params.defaultSuffix,
          "assetPrefix": params.prefix,
          "tradeComment": params.tradeComment,
          "allButTheseAssetsPairsReceiveSignal": params.allButTheseAssetsPairsReceiveSignal,
          "onlyTheseAssetsPairsReceiveSignal": params.onlyTheseAssetsPairsReceiveSignal,
          "assetsPairsToReceiveSignals": params.assetsPairsToReceiveSignals,
        }
      });
    };
    /**
     * Method defined to modify traded assets (strategy)
     * @param {*} sid
     * @param {*} assets
     */
    Vue.prototype.$updateAsset = (sid, assets) => {
      return request({
        url: ACCAPIURL + "/assets/" + sid + "/v2",
        method: "PUT",
        data: {
          "tradedAssets": assets
        }
      });
    };
    /**
     * Get user info
     * @param param
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$user_info = param => {
      return new Promise((resolve, reject) => {
        const _resolve = resp => {
          resolve({
            'access_token': param.access_token,
            'id_token': param.id_token,
            'user_info': resp
          })
        };
        headers['Authorization'] = "Bearer " + param.access_token;
        return request({
          url: AUTH0URL + "/userinfo",
          method: 'POST',
          data: {},
          headers: headers,
        }).then(_resolve, reject)
      })
    };
    /**
     * Delete banner
     * @param banner
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$deleteBanner = function (banner) {
      return request({
        url: MAINAPIURL + "/banner/" + banner['id'],
        method: 'DELETE',
        data: {}
      });
    };
    /**
     * Get registrations
     * @param userid
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getRegistrations = userid => {
      let url = REGAPIURL + "/failed";
      if (userid) {
        url = REGAPIURL + "/info/" + userid;
      }
      return request({
        url: url,
        method: 'GET',
        data: {}
      });
    };
    /**
     * Approve registration
     * @param userid
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$approveRegistration = function (userid) {
      return request({
        url: REGAPIURL + "/approve/" + userid,
        method: 'POST',
        data: {}
      });
    };
    /**
     *
     * @param user_id
     * @param json_data
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$updateUserInfoWiz = (user_id, json_data) => {
      return request({
        url: AUTH0URL + "/api/v2/users/" + user_id,
        method: 'PATCH',
        data: {
          "user_metadata": json_data
        }
      });
    };
    /**
     * Create ticket
     * @param ticket
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$createTicket = function (ticket) {
      return request({
        url: MAINAPIURL + "/ticket",
        method: 'POST',
        data: ticket,
        headers: {}
      });
    };
    /**
     * Create ticket v2 - no captcha
     * @param ticket
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$createTicket_v2 = function (ticket) {
      return request({
        url: MAINAPIURL + "/ticket/v2",
        method: 'POST',
        data: ticket,
        headers: {}
      });
    };
    /**
     * Get visitor IP
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getVisitorIP = function () {
      return request({
        url: IPIFYURL,
        method: 'GET',
        data: {},
        headers: {}
      });
    };
    /**
     * Get visitor location
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getVisitorLocation = function () {
      return request({
        url: IPGEOLOCATION,
        method: 'GET',
        data: {},
        headers: {}
      });
    };
    /**
     * Check if email is approved
     * @param user_data
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$approvedRegister = function (user_data) {
      return new Promise(function (resolve, reject) {
        let url = FRAUDAPIURL + "/approved/" + user_data['email'];
        APIHelp.methods.http_it(url, null, "GET", null).then(function (resp) {
          if (resp['approved']) {
            resolve(user_data);
          } else {
            reject(user_data);
          }
        }, function (error) {
          reject(error.response);
        });
      });
    };
    /**
     * Get account history
     * @param aid
     * @param days
     * @param followerv
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getAccountHistory = (aid, days, followerv) => {
      let url = MT4APIURL + "/tradeInfo/account/" + aid;
      if (followerv) {
        url = MT4APIURL + "/tradeInfo/strategy/" + aid;
      }
      if (days) {
        url += "?days=" + days;
      }
      return request({
        url: url,
        method: 'GET',
        data: {},
        headers: headers
      })
    };
    /**
     * Method defined to list all whitelabel logos
     */
    Vue.prototype.$getWlLogoList = function () {
      return request({
        url: ACCAPIURL + '/whitelabel/logos',
        method: 'GET',
        data: {}
      })
    };
    /**
     * Get user's followers accounts
     */
    Vue.prototype.$getFollowers = param => {
      const args = [];
      if (param && param.sort) {
        args.push('sort=' + param.sort)
      }
      if (param && param.order) {
        args.push('order=' + param.order)
      }
      if (param && param.term) {
        args.push('term=' + param.term)
      }
      return request({
        url: ACCAPIURL + '/' + param.userid + '/followers?' + args.join('&'),
        method: 'GET',
        data: {}
      })
    };
    /**
     * Get new reset password link
     * @param userid
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$requestNewLink = userid => {
      return request({
        url: MAINAPIURL + '/' + userid + '/genlink',
        method: 'POST',
        data: {}
      })
    };
    /**
     * Remove user from Hokocloud platform
     */
    Vue.prototype.$removeUser = userid => {
      let user = userid.toLowerCase()
      return request({
        url: MAINAPIURL + '/' + user + '/remove',
        method: 'DELETE',
        data: {}
      })
    };
    /**
     * Validate if an user can be removed from Hokocloud platform
     */
    Vue.prototype.$validateUserRemovalBroker = userid => {
      return request({
        url: MAINAPIURL + '/' + userid + '/validate/remove',
        method: 'POST',
        data: {}
      })
    };
    /**
     * Get Top ups types
     * @param param
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getTopupsTypes = param => {
      return request({
        url: MAINAPIURL + '/ledger/types' + (param ? "/" + param : ""),
        method: 'GET',
        data: {}
      })
    };
    /**
     * Update an User affiliate
     * @param param
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$updateUserAffiliate = param => {
      return request({
        url: MAINAPIURL + "/" + param.user_id + "/affiliate",
        method: 'PUT',
        data: {
          new_affiliate: param.new_affiliate,
          affiliate_per: param.affiliate_per
        }
      })
    };
    /**
     * Update last login for a user
     */
    Vue.prototype.$updateLastLogin = param => {
      return request({
        url: MAINAPIURL + "/" + param.userid + "/welcome",
        method: 'PUT',
        data: param
      })
    };
    /**
     * Get dashboard skeleton
     */
    Vue.prototype.$getWidgets = () => {
      return request({
        url: ACCAPIURLV4 + '/widgets',
        method: 'GET',
        data: {}
      })
    };
    /**
     * Get widget data
     */
    Vue.prototype.$getWidgetData = url => {
      return request({
        url: ACCAPIURLV4 + '/widgets/' + url,
        method: 'GET',
        data: {}
      })
    };
    /**
     * Revert stment
     */
    Vue.prototype.$revertStatement = (type, tranId) => {
      return request({
        url: MAINAPIURL + `/wallet/${tranId}/${type}`,
        method: 'DELETE',
        data: {}
      })
    };
    /**
     * Get templates of type promo
     */
    Vue.prototype.$getPromoTempates = param => {
      return request({
        url: MESSENGERURL + '/template/search',
        method: 'POST',
        data: param,
      })
    };
    /**
     * Create a new template of type promo
     */
    Vue.prototype.$createPromoTempates = param => {
      return request({
        url: MESSENGERURL + '/template/',
        method: 'POST',
        data: param,
      })
    };
    /**
     * Edit a new template of type promo
     */
    Vue.prototype.$editPromoTempates = param => {
      return request({
        url: MESSENGERURL + '/template/' + param.id,
        method: 'PATCH',
        data: param,
      })
    };
    /**
     * Sends a promo email
     */
    Vue.prototype.$testPromoEmail = param => {
      return request({
        url: MESSENGERURL + '/promo_message/test/' + param.template,
        method: 'POST',
        data: param,
      })
    };
    /**
     * Sends a promo email to all the users registered on accstrat
     */
    Vue.prototype.$sendPromoEmail = param => {
      return request({
        url: MESSENGERURL + '/promo_message/' + param.template,
        method: 'POST',
        data: param,
      })
    };
    /**
     * Sends a promo email to a mailist at csv file
     */
    Vue.prototype.$sendPromoEmailToCsv = (template, formData) => {
      return request({
        url: MESSENGERURL + '/promo_message/csv/' + template.template + '/' + template.lang,
        method: 'POST',
        data: formData,
        headers: Object.assign(authenticateHeader(null), {'Content-Type': 'multipart/form-data'}),
      })
    };
    /**
     * Force to resent a csv mail to a given list
     */
    Vue.prototype.$forcePromoEmailToCsvList = (listname) => {
      return request({
        url: MESSENGERURL + '/promo_message/csv/force_send/' + listname,
        method: 'POST',
        data: {},
      })
    };
    /**
     * Get parameters from account
     */
    Vue.prototype.$getAccountsParams_v4 = param => {
      return request({
        url: ACCAPIURLV4 + '/strategy/follower/params/' + param.strat_id + '/' + param.acc_id,
        method: 'GET',
        data: {},
      })
    };
    /**
     * Get parameters from account, strategy master view
     */
    Vue.prototype.$getAccountsParamsAsMasterView_v4 = param => {
      return request({
        url: ACCAPIURLV4 + '/strategy/follower/params/view/' + param.strat_id + '/' + param.acc_id,
        method: 'GET',
        data: {},
      })
    };
    /**
     * Migrate an Account to a Mat
     */
    Vue.prototype.$setAccountInMAT = param => {
      return request({
        url: ACCAPIURLV4 + '/mats/account/'+ param.account + '/' + param.mat,
        method: 'PUT',
        data: {},
      })
    };
     /**
     * Migrate a MAT server to a new MAT server
     */
    Vue.prototype.$setMatToNewMAT = param => {
      return request({
        url: ACCAPIURLV4 + '/mats/mat/'+ param.oldMat + '/' + param.newMat,
        method: 'PUT',
        data: {},
      })
    };
     /**
     * Realocate a Strategy from a MAT server to a new MAT server
     */
    Vue.prototype.$realocateStrategyToNewMAT = param => {
      return request({
        url: ACCAPIURLV4 + '/mats/mat/'+ param.oldMat + '/strategy/'+ param.stratId + '/' + param.newMat,
        method: 'PUT',
        data: {},
      })
    };
     /**
     * Delete mat relocation changelog
     */
    Vue.prototype.$delMatRelocationChangelog = param => {
      return request({
        url: ACCAPIURLV4 + '/changelog/relocate/' + param.id,
        method: 'DELETE',
      })
    };
    /**
     * (change status to loaded) new server init changelog
     */
    Vue.prototype.$updateNewServerInitChangeLog = param => {
      return request({
        url: ACCAPIURLV4 + '/changelog/newServers/' + param.id,
        method: 'PUT',
      })
    }
     /**
     * Generic list for componentized modules
     */
    Vue.prototype.$search = param => {
      return request({
        url: param.apiUrl + param.url + '?' + param.payload,
        method: 'GET',
      })
    };
     /**
     * List accounts location
     */
    Vue.prototype.$listAccountsLocation = () => {
      return request({
        url: ACCAPIURLV4 + '/mats/accounts',
        method: 'GET',
        data: {},
      })
    };
    /**
     * List accounts for generate setfiles
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$listSetfieldCandidates = () => {
      return request({
        url: ACCAPIURLV4 + '/setfiles/candidates',
        method: 'GET',
        data: {},
      })
    }
     /**
     * Generate setfiles by master account
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$generateSetfiles = (param, type) => {
      return request({
        url: ACCAPIURLV4 + '/setfiles/generate/' + type,
        method: 'POST',
        data: param,
      })
    }
    /**
     * Force generate setfiles FOLLOWER
     */
    Vue.prototype.$generateFollowerSetFiles = param => {
      return request({
        url: ACCAPIURLV4 + '/setfiles/follower/force',
        method: 'POST',
        data: param,
      })
    };
    /**
     * Force generate setfiles MASTER
     */
    Vue.prototype.$generateMasterSetFiles = param => {
      return request({
        url: ACCAPIURLV4 + '/setfiles/master/force',
        method: 'POST',
        data: param,
      })
    };
    Vue.prototype.$generateSetFilesV2 = param => {
      return request({
        url: ACCAPIURL + '/setfiles/master/' + param.type,
        method: 'POST',
        data: param
      })
    }
    /**
     * List all data from Blacklist
     */
    Vue.prototype.getBlackList = () => {
      return request( {
        url: ACCAPIURLV4 + '/strategy/blacklist',
        method: 'GET'
      })
    }
    /**
     * Remove item from blacklist
     */
    Vue.prototype.removeBlacklistItem = (item) => {
      return request( {
        url: ACCAPIURLV4 + '/strategy/blacklist/'+ item,
        method: 'DELETE'
      })
    }
    /**
     * Create item for blacklist
     */
    Vue.prototype.insertNewBlacklistItem = param => {
      return request({
        url: ACCAPIURLV4 + '/strategy/blacklist',
        method: 'POST',
        data: param,
      })
    }
    /**
     * Get MAT_GROUPS
     */
    Vue.prototype.$getMatGroups = () => {
      return request({
        url: MATAPIURL + '/groups',
        method: 'GET',
        data: {}
      })
    }
    /**
     * Update MAT creation settings
     */
    Vue.prototype.UpdateMatSettings = param => {
      return request({
        url: MATAPIURL + '/version',
        method: 'PUT',
        data: param
      })
    }
    /**
     * Method defined to get user information for adm manager
     */
    Vue.prototype.managerUsers = param => {
      return request({
        url: MAINAPIURLV4 + '/users' + '?' + param.payload,
        method: 'GET'
      })
    }
     /**
     * Method defined to add an existing strategy from marketplace to a whitelabel
     */
    Vue.prototype.addStrategyWhitelabel = param => {
      return request({
        url: ACCAPIURL +'/' +  param.wlId + '/strategy/' + param.stratId,
        method: 'POST'
      })
    }
     /**
     * Method defined to remove an existing strategy from marketplace to a whitelabel
     */
    Vue.prototype.removeStrategyWhitelabel = param => {
      return request({
        url: ACCAPIURL +'/' +  param.wlId + '/strategy/' + param.stratId,
        method: 'DELETE'
      })
    }
    /**
     * Get all the parameters from a wlplan by it's name
     */
    Vue.prototype.$getByNameWlPlan = name => {
      return request({
        url: WLADMINAPIURL + '/whitelabel_plan/wlplan/' + name,
        method: 'GET',
      })
    };
    /**
     * Get WL by name
     */
    Vue.prototype.$getWlByName = name => {
      return  request({
        url: WLADMINAPIURL + '/whitelabel/wlname/' + name,
        method: 'GET',
      })
    }
    /**
     * Delete Whitelabel
     */
    Vue.prototype.$deleteWhitelabel = param => {
      return  request({
        url: WLADMINAPIURL + '/whitelabel/remove/wl/' + param,
        method: 'DELETE',
      })
    }
    /**
     * Search wlplans by name
     */
    Vue.prototype.$getSearchWlPlans = param => {
      return request({
        url: WLADMINAPIURL + '/wlplan/search',
        method: 'GET',
        data: param,
      })
    };
    /**
     * Create a new plan template
     * @param json_data
     * @returns Promise
     */
    Vue.prototype.$createWhitelabelPlan = param => {
      return request({
        url: WLADMINAPIURL + '/whitelabel_plan/wlplan/',
        method: "POST",
        data: param,
      })
    };
     /**
     * Update a new plan template
     * @param json_data
     * @returns Promise
     */
    Vue.prototype.$updateWhitelabelPlan = param => {
      return request({
        url: `${WLADMINAPIURL}/whitelabel_plan/wlplan/${param.name}`,
        method: "PATCH",
        data: param.list,
      })
    };
    /**
     * Delete a plan template
     * @param name
     * @returns Promise
     */
    Vue.prototype.$deleteWhitelabelPlan = name => {
      return request({
        url: WLADMINAPIURL + '/whitelabel_plan/wlplan/' + name,
        method: "DELETE",
      })
    };
    /**
     * Send reset password
     * @param json_data
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$sendreset = json_data => {
      return request({
        url: MAINAPIURLV4 + "/users/password",
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'captcha-type': 'hcaptcha',
        },
        data: {
          "userid": json_data['userid'],
          "captcha_token": json_data['captcha_token'],
          "whitelabel": rules.whitelabel,
        },
      });
    };
    /**
     * Verify user for password reset
     * @param userid
     * @param token
     * @returns Promise
     */
    Vue.prototype.$verifyUser = function (userid, token) {
      return new Promise(function (resolve, reject) {
        return request({
          url: MAINAPIURLV4 + "/users/verify",
          method: 'POST',
          headers: headers,
          data: {
            "userid": userid,
            "token": token
          },
        }).then(function (resp) {
          if (resp['success']) {
            resolve(resp);
          } else {
            reject("Verification Failed");
          }
        }, function (error) {
          reject(error.response);
        })
      });
    };
    /**
     * Request password reset
     * @param newpassword
     * @param userid
     * @param token
     * @param captcha_token
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$resetPassword = function (newpassword, userid, token, captcha_token) {
      return request({
        url: MAINAPIURLV4 + "/users/password",
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'captcha-type': 'hcaptcha',
        },
        data: {
          "userid": userid,
          "token": token,
          "newpassword": newpassword,
          "captcha_token": captcha_token,
        },
      });
    };
    /**
     * Register user
     * @param form
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$register = form => {
      const ph = form['phone'];
      const cph = form['countryph'].split("_");
      let createUserJSON = {
        "email": form['email'],
        "firstName": form['firstName'],
        "lastName": form['lastName'],
        "birth_date": form['birth_date'],
        "phone": cph[1] + ph,
        "countryph": form["countryph"],
        "zip": form['zip'],
        "country": form['country'],
        "city": form['city'],
        "address": form['address'],
        "lang": form["lang"],
        "subteleg": form["telegsubscribed"],
        "captcha_token": form['captcha_token']
      };
      if (form['afl_id']) {
        createUserJSON['afl_id'] = form['afl_id'];
      }
      if (form['strat_id']) {
        createUserJSON["strat_id"] = form['strat_id'];
      }
      if (form['whitelabel']) {
        createUserJSON["whitelabel"] = form['whitelabel'];
      }
      return request({
        url: MAINAPIURLV4 + "/users",
        method: 'POST',
        data: createUserJSON,
        headers: {
          'Content-Type': 'application/json',
          'captcha-type': 'hcaptcha',
        }
      });
    };
    /**
     * Method defined to validate recaptcha
     * @param token
     */
    Vue.prototype.$validadeRecaptcha = function (token) {
      return request({
        url: MAINAPIURLV4 + "/captcha",
        method: 'POST',
        data: token,
        headers: headers,
      });
    };
    /**
     * Method defined to remove accounts from strategy, in bulk mode
     */
    Vue.prototype.$bulkRemoveAccountsFromStrategy = function (param) {
      return request({
        url: ACCAPIURLV4 + "/strategy/bulk/remove/follower/" + param.strat_id,
        method: 'DELETE',
        data: param,
        headers: headers,
      })
    }
    /**
     * Method defined to delete accounts, in bulk mode
     */
    Vue.prototype.$bulkDeleteAccounts = function (param) {
      return request({
        url: ACCAPIURLV4 + "/" + param.userid +"/accounts",
        method: 'DELETE',
        data: param,
        headers: headers,
      })
    }
    /**
     * API for list blacklist items
     */
    Vue.prototype.$listBlacklist = param => {
      return request({
        url: ACCAPIURLV4 + param.url + '?' + param.payload,
        method: 'GET',
      })
    };
    /**
     * Get user's ledger settings
     * DEPRECATED
     */
    Vue.prototype.$getLedgerSettings = param => {
      return request({
        url: MAINAPIURLV4 + '/' + param + '/ledger',
        method: 'GET',
      })
    };
    /**
     * Update user's ledger settings
     * DEPRECATED
     */
    Vue.prototype.$updateLedgerSettings = param => {
      return request({
        url: MAINAPIURLV4 + '/' + param.userid + '/ledger',
        method: 'PUT',
        data: param,
      })
    };
    /**
     * Retrieve strategy info for charge on ledger product marketplace
     */
    Vue.prototype.$getStratInfoLedgerProductMarketplace = param => {
      return request({
        url: `${MAINAPIURLV4}/billing/marketplace/get_strategy_info/${param.strategy_id}`,
        method: 'GET',
      })
    };
    /**
     * Approve a charge on ledger product marketplace
     */
    Vue.prototype.$approveLedgerProductMarketplace = param => {
      return request({
        url: `${MAINAPIURLV4}/billing/marketplace/approve`,
        method: 'PUT',
        data: param,
      })
    };
    /**
     * Update performance fee for a charge on ledger product marketplace
     */
    Vue.prototype.$updateLedgerProductMarketplace = param => {
      return request({
        url: `${MAINAPIURLV4}/billing/marketplace/update`,
        method: 'PUT',
        data: param,
      })
    };
    /**
     * Open a dispute for a charge on ledger product marketplace
     */
    Vue.prototype.$disputeLedgerProductMarketplace = param => {
      return request({
        url: `${MAINAPIURLV4}/billing/marketplace/dispute`,
        method: 'PUT',
        data: param,
      })
    };
    /**
     * Recalculate a charge on ledger product marketplace
     */
    Vue.prototype.$recalculateLedgerProductMarketplace = param => {
      return request({
        url: `${MAINAPIURLV4}/${param.performance_id}/performance/recalculate`,
        method: 'PUT',
        data: {
          "new_percentage": param.performance_fee
        },
      })
    };
    /**
     * Waive a charge on ledger product marketplace
     */
    Vue.prototype.$waiveLedgerProductMarketplace = param => {
      return request({
        url: `${MAINAPIURLV4}/${param.performance_id}/performance/waive`,
        method: 'PUT',
        data: {},
      })
    };
    /**
     * Get products pricing info
     */
    Vue.prototype.$getPricingInfo = () => {
      return request({
        url: `${ACCAPIURLV4}/info/pricing`,
        method: 'GET',
      })
    }
    Vue.prototype.getWlPanel = param =>  {
      return request({
        url: 'http://localhost:3000/wlpanel',
        method: 'GET',
      })
    }
     /**
     * This method is defined to get followers from a strategy, hoko admin only
     */
    Vue.prototype.getStrategyFollowersHokoAdmin = param => {
      return request({
        url: `${ACCAPIURLV4}/strategy/followers/accounts/`+ param,
        method: 'GET',
      })
    }
     /**
      * This method is defined to get current_price for currency reference
      * @param {string} param - Currency reference
     */
    Vue.prototype.$getWalletExchange = param => {
      return request({
        url: `${WALLETURL}/ledger/currency/${param}`,
        method: 'GET',
      })
    }
     /**
      * This method is defined to get exchange_rate from Sr Money
      * @param {string} param - OutValue to submit
     */
    Vue.prototype.$getWalletExchange = param => {
      return request({
        url: `${WALLETURL}/integration/sm/exchange/${param}`,
        method: 'GET',
      })
    }
     /**
     * This method is defined to get order providers and its methods
     */
    Vue.prototype.$getWalletMethods = () => {
      return request({
        url: `${WALLETURL}/method`,
        method: 'GET',
      })
    }
     /**
     * This method is defined to submit a order to new wallet service
     */
    Vue.prototype.$createWalletOrder = param => {
      return request({
        url: `${WALLETURL}/order`,
        method: 'POST',
        data: param,
      })
    }
     /**
     * Resolve Internal payments for broker by sending an array of accounts
     * and an action code
     */
    Vue.prototype.$resolveInternalBrokerPaymentBulk = param => {
      return request({
        url: `${MAINAPIURLV4}/broker/internal/payment`,
        method: 'POST',
        data: param
      })
    }
    /**
     * Resolve Internal payments for broker v2
     */
    Vue.prototype.$resolveInternalBrokerPayment = param => {
      return request({
        url: `${MAINAPIURLV4}/${param.broker}/billing/${param.type}/${param.status}`,
        method: 'POST',
        data: param,
      })
    }
    /**
     * Get Whitelabels
     */
    Vue.prototype.$getWhitelabels = () => {
      return request({
        url: `${WHITELABELAPIURL}/whitelabel`,
        method: 'GET',
      })
    }
     /**
     * Create WL
     */
    Vue.prototype.$createWhitelabel_v2 = param => {
      return request({
        url: `${WHITELABELAPIURL}/whitelabel/`,
        method: 'POST',
        data: param,
      })
    }
    /**
     * Update WL v2
     */
    Vue.prototype.$updateWhitelabel_v2 = param => {
      return request({
        url: `${WHITELABELAPIURL}/whitelabel/` + param.whitelabel,
        method: 'PATCH',
        data: param,
      })
    }
    /**
     * Get Plan name and type from whitelabel plan
     */
    Vue.prototype.$getWhitelabelPLanList = () =>  {
      return request({
          url: `${WHITELABELAPIURL}/whitelabel_plan/wlplan/list/`,
          method: 'GET',
      })
    }
    /**
     *
     *
     */
    Vue.prototype.$updateTransferLimit = param => {
      return request({
        url: `${MAINAPIURLV4}/${param.userid}/transfer/change_limit`,
        method: 'PUT',
        data: param,
      });
    }
    /**
     * Get Support Schedule
     */
    Vue.prototype.$getScheduleSupport = () => {
      return request({
        url: `${ACCAPIURLV4}/schedule/support`,
        method: 'GET'
      });
    }
    /**
     * Get script Schedule
     */
    Vue.prototype.$getScheduleScriptAccStrat = () => {
      return request({
        url: `${ACCAPIURLV4}/schedule/script`,
        method: 'GET'
      });
    }
    /**
     * Get script Schedule
     */
    Vue.prototype.$getScheduleScriptBroker = () => {
      return request({
        url: `${MAINAPIURLV4}/schedule/script`,
        method: 'GET'
      });
    }
    /**
     * Get Server Symbols
     */
    Vue.prototype.$getServerSymbols = param => {
      return request({
        url: `${ACCAPIURLV4}/servers/symbols/${param.server}`,
        method: 'GET',
      });
    }
    /**
     * Force server discovery
     */
    Vue.prototype.$forceServerDiscovery = param => {
      return request({
        url: `${ACCAPIURLV4}/servers/force_discovery/${param.server}`,
        method: 'POST',
      });
    }
    /**
     * Update symbols for server
     */
    Vue.prototype.$updateServerSymbols = param => {
      return request({
        url: `${ACCAPIURLV4}/servers/symbols/${param.server}`,
        method: 'PATCH',
        data: param,
      });
    }
    /**
     * Get Balance and Equity initials
     */
    Vue.prototype.$getInitialBalanceEquity = param => {
      return request({
        url: ACCAPIURL + "/changelog/newplatform/" + param + "/initial",
        method: 'GET',
      })
    }
    /**
     * Update Balance and Equity initials
     */
    Vue.prototype.$updateInitialOrder = param => {
      return request({
        url: ACCAPIURLV4 + "/accounts/followers/" + param.follower_id,
        method: 'POST',
        data: param
      })
    }
    /**
     * Get Acc Net data
     */
    Vue.prototype.$getAccNet = param => {
      return request({
        url: `${ACCAPIURLV4}/accounts/net/${param}`,
        method: 'GET',
        data: {}
      })
    };
    /**
     * Do something with the values
     */
    Vue.prototype.$dumbmethodapi = param =>  {
      return request({
        url: `${ACCAPIURLV4}/accounts/net`,
        method: 'POST',
        data: param,
      })
    };
    /**
     * Verify if an account can force revalidation, if already is on queue or not
     */
    Vue.prototype.$canRevalidate = account => {
      return request({
          url: ACCAPIURLV4 + "/can/revalidate/"+ account,
          method: 'GET',
          data:{}
        })
    };
    /**
     * get broker settings
     * @param broker
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getBrokerSettings = broker => {
      return request({
        url: ACCAPIURL + '/broker/'+ broker + '/settings',
        method: 'GET',
        data: {}
      })
    };
    /**
     * Create a token end send to user email for validation
     * @param email
     */
    Vue.prototype.$createUserToken = param => {
      return request({
        url: MAINAPIURLV4 + "/user/token",
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: param
      })
    }
    /**
     * Validate VerificationCode sent to user
     * @param token
     * @param email
     */
    Vue.prototype.$validateUserVerificationCode = param => {
      return request({
        url: MAINAPIURLV4 + "/user/token/validate",
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: param
      })
    }
    /**
     * Send email to user to notificate that an account is disconnected
     */
    Vue.prototype.$sendDisconnectedEmail = param => {
      return request({
        url: ACCAPIURLV4 + "/changelog/disconnected/" + param.userid,
        method: 'POST',
        data: param
      })
    }
    /**
     * Delete changelogs from manual sync requests
     */
    Vue.prototype.$deleteSyncTradesEntry = param => {
      return request({
        url: ACCAPIURLV4 + "/changelog/synctrades/" + param.userid + "/" + param.row_id,
        method: 'DELETE',
      })
    }
    /**
     *
     */
    Vue.prototype.$canSyncManually = acc_id => {
      return request({
        url: ACCAPIURLV4 + "/strategy/can_sync/" + acc_id,
        method: 'GET',
      })
    }
    /**
     * Get brokers and platforms
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getBrokers = function() {
      let args = [];
      let url = ACCAPIURLV4 + "/brokers";
      if (!rules.isBroker && !rules.isAdmin && rules.isWhitelabelSession) {
        args.push('wl=' + rules.whitelabel)
      }
      return request({
        url: url + "?" + args.join('&'),
        method: 'GET',
      })
    }
    /**
     * Get Servers by broker
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$getServerByBroker = broker => {
      return request({
        url: ACCAPIURLV4 + "/servers/"+ broker,
        method: 'GET',
      })
    }
    /**
     * Gives a list of countries
     */
    Vue.prototype.$country_list = function () {
      return COUNTRIES;
    };
    /**
     * Gives a list of country's phones
     */
    Vue.prototype.$country_phone_list = function () {
      return COUNTRYPHLIST;
    };
  }
};
