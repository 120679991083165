<template>
  <div>
    <div id="blankdiv" v-if="!startverify">
    </div>
    <div v-if="startverify" class="wrapper wrapper-full-page">
      <div class="full-page login-page">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">

                <card type="login" class="text-center">

                  <div slot="header">
                    <div class="col-md-12" >
                      <RemoteImg :src="logo_login" size="150px"></RemoteImg>
                    </div>
                    <h6 class="header text-center">
                      {{ msg }}
                    </h6>
                  </div>

                  <div class="text-center">
                    <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;" v-if="inprogress"></i>

                    <p v-if="emlverified == false" class="text-danger">{{ $t('unverified') }}</p>
                    <p v-if="emlverified">
                      {{ $t('verified') }}
                      {{ seccount }}
                    </p>

                    <p v-if="emlverified">
                      <p-button slot="footer" type="default" round outline v-on:click="goLogin">
                        {{ $t('continue_text') }}
                      </p-button>
                    </p>

                    <p-progress
                      v-if="emlverified"
                      :value="countdown"
                      type="success"
                      :height="15"
                      class="progress-bar-animated">
                    </p-progress>
                    <br/>

                  </div>

                </card>

            </div>
          </div>
        </div>
        <div class="col-md-12">
          <app-footer></app-footer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {Card,Progress} from 'src/components/UIComponents';
  import AppNavbar from './Layout/AppNavbar'
  import AppFooter from './Layout/AppFooter2'

  export default {
    components: {
      Card,
      AppNavbar,
      AppFooter,
      [Progress.name]: Progress
    },
    data() {
      return {
        logo_login: '/static/img/hoko-new-logo.png',
        startverify: false,
        error: false,
        error_msg: null,
        emlverified: null,
        seccount: 10,
        msg: this.$t('emailverification'),
        inprogress: true,
        countdown: 100,
        verified: this.$t('verified'),
        unverified: this.$t('unverified')
      }
    },
    methods: {
      goLogin () {
        location.href = "/reset_password/"+ this.$route.params.userid +'/'+ this.$route.params.token;
      },
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      setDefaultBGColor() {
        document.getElementsByTagName('body')[0].style.backgroundColor = '#210896';
      },
      noWlHandler(resp, resolve) {
        localStorage.clear();
        document.getElementsByTagName('body')[0].style.backgroundColor = '#210896';
        try {
          document.getElementById('favicon96').href = '/static/img/icons/hokocloud.ico';
        } catch (s) {
        }
        try {
          document.getElementById('favicon32').href = '/static/img/icons/hokocloud.ico';
        } catch (s) {
        }
        try {
          document.getElementById('favicon16').href = '/static/img/icons/hokocloud.ico';
        } catch (s) {
        }
        try {
          document.getElementsByTagName('title')[0].innerHTML = 'HokoCloud Dashboard';
        } catch (s) {
        }
        this.verifyUser();
      },
      successWlHandler(resp, resolve) {
        if (!resp.success) {
          this.noWlHandler();
          return;
        }
        const settings = resp.data;
        localStorage.setItem('wlSettings', JSON.stringify(settings));
        this.logo_login = settings.logo_login;
        this.dashboadName = settings.fullname
        const bodyEl = document.getElementsByTagName('body')[0];
        bodyEl.style.backgroundColor = settings.bgcolor;
        this.showImg = true;
        if(settings.bgimg_login) {
          bodyEl.style.backgroundImage = `url('${settings.bgimg_login}')`;
        }
        const rootEl = document.querySelector(':root');
        if(settings.bgcolor_btn1) {
          rootEl.style.setProperty('--btnColor1', settings.bgcolor_btn1);
        }
        if(settings.bgcolor_btn2) {
          rootEl.style.setProperty('--btnColor2', settings.bgcolor_btn2);
        }
        try {
          document.getElementById('favicon96').href = '/static/img/icons/' + settings.whitelabel.toLowerCase() + '.ico'
        } catch (s) {
        }
        try {
          document.getElementById('favicon32').href = '/static/img/icons/' + settings.whitelabel.toLowerCase() + '.ico'
        } catch (s) {
        }
        try {
          document.getElementById('favicon16').href = '/static/img/icons/' + settings.whitelabel.toLowerCase() + '.ico'
        } catch (s) {
        }
        try {
          document.getElementsByTagName('title')[0].innerHTML = settings.fullname;
        } catch (s) {
        }
        this.verifyUser();
      },
      loadWhitelabel() {
        let wl = this.$route.params.wl;
        if (!wl) {
          wl = window.location.hostname;
        }
        this.$wlSettings(wl).then(this.successWlHandler, this.noWlHandler);
      },
      userVerified(resp) {
        this.inprogress = false;
        this.emlverified = true;
        this.msg = this.$t('emailconfirmed');
        this.seccount = 10;
        // Countdown
        const countdown_animation = resp => {
          if (this.seccount >= 0) {
            if (this.countdown % 10 == 0) {
              this.seccount -= 1;
            }
          }
          if (this.countdown <= 2) {
            clearInterval(count_timer);
            location.href = "/reset_password/"+ this.$route.params.userid + '/'+ this.$route.params.token;
          }
          this.countdown -= 2;
        };

        let count_timer = setInterval(countdown_animation, 220);
      },
      userUnverified(error) {
        this.startverify = true;
        this.inprogress = false;
        this.msg = this.$t('emailunconfirmed');
        this.emlverified = false;
      },
      verifyUser() {
        this.startverify = true;
        this.$verifyUser(
          this.$route.params.userid.toLowerCase(),
          this.$route.params.token
        ).then(this.userVerified, this.userUnverified);
      }
    },
    mounted () {
      this.loadWhitelabel()
    }
  }
</script>
<style scoped lang="scss">
  .content::v-deep .remoteImg {
    margin-bottom: 15px;
  }
</style>
<style>
  #blankdiv {
    margin: 0;
    background-color: #ffffff;
    height: 100vh;
    width: 100vw;
  }
</style>
