<template>
  <div id="main" :class="{'nav-open': $sidebar.showSidebar}">
    <router-view name="header"></router-view>
    <transition name="fade"
                mode="out-in">
      <router-view></router-view>
    </transition>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
  // Loading some plugin css asynchronously
  import 'sweetalert2/dist/sweetalert2.css'
  import 'vue-toast-notification/dist/theme-default.css';
  export default {}
</script>
<style lang="scss">

</style>
