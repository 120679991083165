<template>

</template>

<script>

import swal from "sweetalert2";
import CONSTANTS from "@/assets/constants";

const rules = CONSTANTS.methods.getRules();
const user_data = JSON.parse(localStorage.getItem('user-info'));

export default {
    data() {
      return {
        txt: {
          title: this.$t('profile_update_require'),
          message: this.$t('profile_upgrade_require_desc'),
        },
        type: 'warning',
      }
    },
    methods: {
      userInfoLoaded(resp) {
        if (!resp.success) {
          return;
        }
        let data = resp.data[0];
        if (!rules.isAdmin && data['profile_updated'] === 0 && this.$route.path === '/dash/main') {
          swal({
            title: this.txt.title,
            text: this.txt.message,
            type: this.type,
            allowOutsideClick: false
          })
            .then(() => {
              window.location = '/dash/profile'
            })
        }
      },
    },
    mounted() {
      // Get User Info
      this.$getUserInfo(user_data.email).then(this.userInfoLoaded, function(err) {
        console.log(err);
      });
    },
}

</script>
