<template>
  <!-- In progress -->
  <div v-if="inprogress" class="text-center">
    <h2>{{ inprogresstxt }}</h2>
    <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
    <p></p>
  </div>

  <form v-else ref="formValidation">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6 col-sm-6">
          <div class="row">
            <div class="col-md-12">
              <h5> {{ txt.settings }} </h5>
              <hr>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <span>{{txt.nametxt}}</span>
                  <fg-input
                    v-model="form.name"
                    type="text"
                    v-validate="modelValidations.name"
                    name="name"
                    :error="getError('name')">
                  </fg-input>
                  <p></p>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <span>{{ txt.choosevpsplan }}</span>

                  <select v-model="form.parent_prod_id"
                          class="form-control"
                          @change="updateCost"
                          style="margin-bottom: 15px"
                          v-validate="modelValidations.plans"
                          error="please select a plan"
                          >

                    <option value="undefined" disabled selected>
                      {{ $t('choosevpsplan') }}
                    </option>

                    <option v-for="option in plans"
                            :value="option.id">
                      {{ option.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <span>{{ txt.choosevpslocation }}</span>

                  <select v-model="form.location"
                          class="form-control"
                          style="margin-bottom: 10px">

                    <option v-for="option in location"
                            :value="option.code"
                            >
                      {{ option.description }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5 col-sm-5" style="margin-left: 10px">
          <div class="row">
            <div class="col-md-12">
              <h5> {{txt.advancedsettings}} </h5>
              <hr>
            </div>
            <div class="card-body">
              <p-checkbox v-model="form.fixed_location">
                {{txt.fixedlocation}}
              </p-checkbox>
              <p-checkbox v-model="form.approval_required" >
                {{txt.approvalrequired}}
              </p-checkbox>
              <p-checkbox v-model="form.active">
                {{txt.active}}
              </p-checkbox>
            </div>
            <div class="col-md-12">
              <h5> {{txt.addons}} </h5>
              <hr>
            </div>
            <div class="card-body">
              <p-checkbox
                v-model="add1.check"
                @input="addOrRemoveAddons(add1)">
                {{add1.label}}
              </p-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
  import Vue from "vue";
  import {Select, Option} from 'element-ui'
  import money from 'v-money'
  import VeeValidate from 'vee-validate'
  import Constants from 'src/assets/constants'
  import swal from "sweetalert2";

  Vue.use(Select);
  Vue.use(Option);
  Vue.use(money);
  Vue.use(VeeValidate);

  const rules = Constants.methods.getRules()

  export default {
    name: 'WhitelabelVpsForm',
    props: {
      reference: {
        type: [Object],
        description: "Whitelabel vps data"
      },
      whitelabel: {
        type: [String],
        description: "Whitelabel tag"
      },
      is_manage: {
        type: [Boolean],
        description: "Flag defined to inform if the form is being used for manage whitelabel page"
      },
      refAddons: {
        type: [Object],
        description: "Object with addons cost"
      },
      location: {
        type: [Array],
        description: "Array with locations"
      },
      plans: {
        type: [Array],
        description: "Array with billing plans"
      },
    },
    components: {
      [Select.name]: Select,
      [Option.name]: Option
    },
    data () {
      return {
        inprogress: true,
        inprogresstxt: this.$t('loadingtxt'),
        txt: {
          nametxt: this.$t('nametxt'),
          choosevpsplan: this.$t('choosevpsplan'),
          choosevpslocation: this.$t('vpspreferredlocation'),
          cost: this.$t('cost'),
          usercost: this.$t('clienttxt')+ " "+ this.$t('cost'),
          discount: this.$t('discount'),
          discountpercent: this.$t('discount')+ " %",
          settings: this.$t('settings'),
          advancedsettings: this.$t('advancedsettings'),
          approvalrequired: this.$t('approvalrequired'),
          fixedlocation: this.$t('fixedlocation'),
          active: this.$t('active'),
          addons: this.$t('addons'),
        },
        locDescription: '',
        totalCost: 0,
        form: {
          active: 1,
          addoncost: 0,
          addons: "",
          approval_required: 0,
          fixed_location: 0,
          discount_percent: 0.0,
          discount_val: 0.0,
          location: "lon",
          name: "",
          parent_prod_id: undefined,
          product: "VPS",
          unitcost: 0.0,
          usercost: 0.0,
          whitelabel: this.whitelabel
        },
        addons: [],
        checkbox: {
          value: false,
          label: "approval_required"
        },
        checkbox2: {
          value: false,
          label: "active"
        },
        add1: {
          check: false,
          label: this.$t('monitored'),
          product: "MONITOR"
        },
        money: {
          prefix: 'U$D ',
          precision: 2,
          decimal: '.',
        },
        modelValidations:{
          name:  { required: true },
          plans: { required: true },
          locDescription: { required: true}
        },
        isAdmin: rules.isAdmin,
      }
    },
    methods: {
      getData() {
        this.form.fixed_location = this.form.fixed_location ? 1 : 0
        this.form.approval_required = this.form.approval_required ? 1 : 0
        this.form.active = this.form.active ? 1 : 0

        if (this.form.name == "" || this.form.parent_prod_id == undefined) {
          swal(this.$t("attentiontxt"), this.$t("emptyfields"), "warning");
        } else {
          return this.form;
        }

      },
      validate() {
        return this.$validator.validateAll()
          .then(isValid => {
            this.$emit('on-submit', this.$refs.formValidation, isValid);
            return new Promise(function (resolve, reject) {
              if (isValid) {
                resolve()
              } else {
                reject()
              }
            })
          })
      },
      addOrRemoveAddons(addon) {
        let addons = this.form.addons.split(';')

        if (addons.includes(addon.label)) {
          addons = addons.filter(item => item != addon.label)
          this.form.addoncost -= this.refAddons[addon.product];
        } else {
          addons.push(addon.label)
          this.form.addoncost += this.refAddons[addon.product];
        }
        addons = addons.filter( e => e != '')
        this.form.addons = addons.join(';')
      },
      failop(resp) {
        console.log(resp)
      },
      getError(fieldName) {
        fieldName = this.$t(fieldName);
        return this.errors.first(fieldName);
      },
      persistSuccessHandler(response) {
        console.log(response)
      },
      persistErrorHandler(response) {
        console.log(response)
      },
      clear() {
        this.form = {
          active: 1,
          addoncost: 0,
          addons: "",
          approval_required: 0,
          discount_percent: 0.0,
          discount_val: 0.0,
          location: "lon",
          name: "",
          parent_prod_id: undefined,
          product: "VPS",
          unitcost: 0.0,
          usercost: 0.0,
          whitelabel: this.whitelabel,
          fixed_location: 0,
        };
      },
      loadReference(){
        this.showHideInprogress()
        if (!this.reference) {
          this.clear()
          return;
        }
        this.form = this.reference;
        if (this.form.active){
          this.checkbox2.value = true;
        }
        if (this.form.approval_required) {
          this.checkbox.value = true;
        }
        this.form.addoncost = 0;
        if (this.reference.addons) {
          if (this.reference.addons.includes(";")) {
            let addons = this.reference.addons.split(';')
            addons.forEach(this.checkAddons);
          } else {
            this.checkAddons(this.reference.addons)
          }
        }
        this.updateTotalCost()
      },
      checkAddons(element){
        if (element == this.add1.label) {
          this.add1.check = true
          this.form.addoncost += this.refAddons[this.add1.product];
        }
      },
      showHideInprogress(){
        this.inprogress = !this.inprogress
      },
      onChangeDiscount(event){
        let value = event.target.value
        this.form.discount_percent = ( value * 100 ) / this.form.unitcost
      },
      onChangeDiscountPercent(event){
        let value = event.target.value
        this.form.discount_val = (value * this.form.unitcost) / 100
      },
      updateTotalCost() {
        this.totalCost = this.form.unitcost + (this.form.addoncost || 0);
      },
      updateCost() {
        if (this.form.parent_prod_id) {
          this.form.unitcost = this.plans.filter(item => item.id == this.form.parent_prod_id)[0].cost
        } else {
          this.form.unitcost = 0;
        }
      }
    },
    mounted() {
      this.loadReference()
    },
    watch: {
      "form.unitcost": function () {
        this.updateTotalCost()
      },
      "form.addoncost": function () {
        this.updateTotalCost()
      },
    }
  }
</script>

<style>
</style>
