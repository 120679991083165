export default {
  namespaced: true,
  state: {
    showAccModal: false,
    changeTermAgree: false,
    accounts: [],
    accountsToFollow: [],
    strategy: null,
    syncAccounts: false,
  },
  mutations: {
    ['SET_ACC_MODAL'](state, value) {
      state.showAccModal = value
    },
    ['SET_ACCOUNTS'](state, value) {
      state.accounts = value
    },
    ['SET_STRATEGY'](state, value) {
      state.strategy = value
    },
    ['SET_TERMS'](state, value) {
      state.changeTermAgree = value
    },
    ['SET_ACCOUNT_TO_FOLLOW'](state, value) {
      let _index = state.accountsToFollow.indexOf(state.accountsToFollow.find(el => el.id == value.id))
      if (value.selected) {
        state.accountsToFollow.push(value)
      } else {
        state.accountsToFollow.splice(_index, 1)
      }
    },
    ['SET_SYNC_ACCOUNTS'](state, value) {
      state.syncAccounts = value
    },
    ['CLEAN_ACCOUNT_TO_FOLLOW'](state, value) {
      state.accountsToFollow.splice(0, state.accountsToFollow.length)
    }
  },
  actions: {
    openAccModal({commit}, id) {
      commit('SET_ACC_MODAL', true)
      commit('SET_STRATEGY', id)
    },
    openSyncAccounts({commit}) {
      commit('SET_SYNC_ACCOUNTS', true)
    },
    closeSyncAccounts({commit}) {
      commit('SET_SYNC_ACCOUNTS', false)
    },
    closeAccModal({commit}) {
      commit('SET_ACC_MODAL', false, 0)
    },
    setAccounts({commit}, accounts) {
      commit('SET_ACCOUNTS', accounts)
    },
    setStrategy({commit}, id) {
      commit('SET_STRATEGY', id)
    },
    setAccountToFollow({commit}, acc) {
      commit('SET_ACCOUNT_TO_FOLLOW', acc)
    },
    setTerms({commit}, value) {
      commit('SET_TERMS', value)
    },
    cleanAccountsToFollow({commit}) {
      commit('CLEAN_ACCOUNT_TO_FOLLOW')
    },
    clean({commit, dispatch}) {
      dispatch('setAccounts', [])
      dispatch('cleanAccountsToFollow', [])
      dispatch('setStrategy', null)
      dispatch('closeAccModal')
    },
  }
}
