export default {
  namespaced: true,
  state: {
    showForceRevalidationModal: false,
    account: {
      accountNumber: '',
      password: '',
      type: '',
      platform: '',
      server: '',
      userid: '',
      symbol: ''
    }
  },
  mutations: {
    ['SET_MODAL_STATE'](state, value)   {
      state.showForceRevalidationModal = value
    },
    ['SET_ACCOUNT'](state, value) {
      state.account = {
        accountNumber: value.accountNumber,
        password: value.password,
        type: value.type,
        platform: value.platform,
        server: value.server,
        userid: value.userid,
        symbol: value.symbol
      }
    }
  },
  actions: {
    openForceRevalidationModal({commit}) {
      commit('SET_MODAL_STATE', true)
    },
    closeForceRevalidationModal({commit}) {
      commit('SET_MODAL_STATE', false, 0)
    },
    setAccount({commit}, value) {
      commit('SET_ACCOUNT', value)
    }
  }
}
