import {i18n} from "src/assets/i18n-plugin";
import {getDefaultPassedDate, getZeroNow} from "src/util/DateUtils";

export const SyncOptions = {
  ALL: {
    value: {
      "copyOpenTrades": true,
      "copyTradesSince": getDefaultPassedDate(),
      "blockSignalsWithPriceDeviation": false,
      "maxPip": null,
    },
    label() {
      return i18n.t('param_sync_all')
    },
    info() {
      return i18n.t('param_sync_all_info')
    },
  },
  NONE: {
    value: {
      "copyOpenTrades": false,
      "copyTradesSince": getZeroNow(),
      "blockSignalsWithPriceDeviation": false,
      "maxPip": null,
    },
    label() {
      return i18n.t('param_sync_none')
    },
    info() {
      return i18n.t('param_sync_none_info')
    },
  },
  BESTPRICE: {
    value: {
      "copyOpenTrades": true,
      "copyTradesSince": getDefaultPassedDate(),
      "blockSignalsWithPriceDeviation": true,
      "maxPip": 0.1,
    },
    label() {
      return i18n.t('param_sync_bestprice')
    },
    info() {
      return i18n.t('param_sync_bestprice_info')
    },
  },
  NONEOLD: {
    value: {
      "copyOpenTrades": true,
      "copyTradesSince": getZeroNow(),
      "blockSignalsWithPriceDeviation": false,
      "maxPip": null,
    },
    label() {
      return i18n.t('param_sync_noneold')
    },
    info() {
      return i18n.t('param_sync_noneold_info')
    },
  },
  ADVANCED: {
    value: {
      "copyOpenTrades": true,
      "copyTradesSince": getDefaultPassedDate(),
      "blockSignalsWithPriceDeviation": false,
      "maxPip": null,
    },
    MAX_PIP: {
      default: 5.0,
      min: 0.1,
      max: 1000.0,
      step: 0.1,
      precision: 1,
    },
    label() {
      return i18n.t('param_sync_advanced_since')
    },
  },
}
