<template>
  <div class="wrapper">

    <side-bar type="sidebar" :sidebar-links="$sidebar.sidebarLinks">
      <user-menu></user-menu>
    </side-bar>

    <div class="main-panel">
      <NewNavbar></NewNavbar>

      <dashboard-content @click.native="toggleSidebar"/>

      <content-footer></content-footer>
    </div>

    <check-user-avatar/>

  </div>
</template>
<style lang="scss">

</style>
<script>
  // import TopNavbar from './TopNavbar.vue'
  import NewNavbar from './TopNavbar2.vue'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import UserMenu from 'src/components/UIComponents/SidebarPlugin/UserMenu.vue'
  import CheckUserAvatar from "@/components/Dashboard/Views/Components/CheckUserAvatar.vue";

  export default {
    components: {
      // TopNavbar,
      NewNavbar,
      ContentFooter,
      DashboardContent,
      UserMenu,
      CheckUserAvatar,
    },
    methods: {
      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      }
    },
  }

</script>
