<template>
  <modal :show.sync="showModalConfirmPerformance" headerCLasses="justify-content-center" modalClasses="modal-lg" :required="true">
    <h4 slot="header" class="title title-up">
      {{ $t('confirmYourAction') }}
    </h4>
    <div class="col" v-if="!allDone">
      <div class="alert alert-info text-center">
        <a class="info">
        <i class="fa fa-info-circle"></i>
          {{ $t('you_can_select_more_rows_txt') }}
      </a>
      </div>
      <div class="text-center mt-5 mb-5" v-if="!allDone">
        <el-radio-group v-model="action" class="row move-dot-up" v-if="performanceType == 'debit'">
         <el-radio class="col-12 mt-2 mb-2" label="DEBITFAILED" size="large"><b>{{ $t('failedtxt') }} {{ $t('credittxt') }}</b></el-radio>
         <el-radio class="col-12 mt-2 mb-2" label="DEBITSUCCESS" size="large"><b>{{ $t('successtxt') }} {{ $t('credittxt') }}</b></el-radio>
        </el-radio-group>
        <el-radio-group v-model="action" class="row move-dot-up" v-if="performanceType == 'credit'">
         <el-radio class="col-12 mt-2 mb-2" label="CREDITFAILED" size="large"><b>{{ $t('failedtxt') }} {{ $t('credittxt') }}</b></el-radio>
         <el-radio class="col-12 mt-2 mb-2" label="CREDITSUCCESS" size="large"><b>{{ $t('successtxt') }} {{ $t('credittxt') }}</b></el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="row" v-if="response_action.length > 0 && allDone">
        <div class="col-md-6" v-for="row in response_action" >
          <div class="card">
            <div class="card-header text-center" :class="row.success ? 'success-bulk-operation' : 'failed-bulk-operation'">
              <i :class="row.success ? 'fa fa-check-circle fa-2x' : 'fa fa-exclamation-circle fa-2x'"> </i>
              <div :class="row.success ? 'alert-success' : 'alert-danger'">
                <h5 v-if="!row.success"> {{ $t('failedoptxt') }} </h5>
                <h5 v-else> {{ $t('successtxt') }} </h5>
                </div>
            </div>
            <div class="row card-body mb-4">
              <div class="col-md-6">
                <b>{{ $t('followertxt') }}</b>
                <br>
                {{row.follower_account}}
              </div>
              <div class="col-md-6">
                <b>{{ $t('provider') }}</b>
                <br>
                {{row.trader_account}}
              </div>
               <div class="col-md-6 mt-3 mb-3">
                <b>{{ $t('strategytxt') }}</b>
                <br>
                {{row.strat_name}}
              </div>
              <div class="col-md-6  mt-3 mb-3">
                <b>{{ $t('referencedate') }}</b>
                <br>
                {{row.date_reference}}
              </div>
              <div class="col-md-6  mt-3 mb-3">
                <b> Transaction Code</b>
                <br>
                {{row.id}}
              </div>
              <div class="col-md-6  mt-3 mb-3">
                <b>{{ valueText }}</b>
                <br>
                <b class="text-success">{{row.value}}</b>
              </div>
              <div class="col-md-12">
                <h6>{{ $t('status') }}:</h6> {{row.msg}}
              </div>
            </div>
<!--            <div class="card-footer">-->
<!--              <small>Code: {{row.id}}</small>-->
<!--            </div>-->
          </div>
          <hr>
        </div>
      </div>
    <template slot="footer" v-if="!allDone">
      <div class="py-3 mx-auto" v-if="modalinprogress">
        <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
      </div>
      <div class="left-side" v-if="modalinprogress === false">
        <p-button type="success" @click.prevent="performAction(0)" link>{{ $t('confirm') }}</p-button>
      </div>
      <div class="divider" v-if="modalinprogress === false"></div>
      <div class="right-side" v-if="modalinprogress === false">
        <p-button type="danger" @click.prevent="closeModalAction" link>{{ $t('cancel') }}</p-button>
      </div>
    </template>
    <template slot="footer" v-else>
        <p-button type="success" link @click.prevent="closeModalAction">{{ $t('ok') }}</p-button>
    </template>
  </modal>
</template>

<script>
import {Modal} from "../../../../../UIComponents";
import Vue from "vue";
import {Radio, RadioGroup} from "element-ui";

Vue.use(RadioGroup);
Vue.use(Radio);

export default {
  name: "ApproveCreditDebitModal",
  components: {
    Modal,
  },
  props: {
    performanceType: {
      type: String,
      default: '',
      description: "credit or debit"
    },
    entries: {
      type: Array,
    },
    selectedBroker: {
      type: String,
    },
    showModalConfirmPerformance: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      modalinprogress: false,
      action: '0',
      response_action: [],
      allDone: false,
      valueText: ''
    }
  },
  methods: {
    response() {
      return response
    },
    closeModalAction() {
      this.allDone = false
      this.response_action = []
      this.action = '0'
      this.$emit('on-close-modal-broker-performance')
    },
    getValue(row){
      let _value = ''
      if(this.performanceType == 'credit')  {
        this.valueText = this.$t('amount_to_credit')
        _value = `${row.trader_part_value_bc} (${row.follower_currency}) (${parseFloat(row.trader_part_percentage * 100).toFixed(0)}%)`
      }else if (this.performanceType == 'debit') {
        this.valueText = this.$t('perf_fee')
        _value = `${row.effective_performance_value_bc} (${row.follower_currency}) (${parseFloat(row.effective_performance_percent * 100).toFixed(0)}%)`
      }
      return _value
    },
    performAction(idx) {
      if (idx < this.entries.length) {
        this.modalinprogress = true
        let param = {
          'performance_id': this.entries[idx].id,
          'status': this.action,
          'type': this.performanceType,
          'broker': this.selectedBroker
        }
        let success = resp => {
          this.response_action[idx] = {
            'id': this.entries[idx].id,
            'value': this.getValue(this.entries[idx]),
            'date_reference': this.entries[idx].date_reference,
            'follower_account': this.entries[idx].follower_account,
            'strat_name': this.entries[idx].strategy_name,
            'success': true,
            'msg': resp.msg ? this.$t(resp.msg) : this.$t('ERROR_UNEXPECTED')
          }

          if (idx === this.entries.length - 1) {
            this.actionDone()
          } else {
            this.performAction(idx+1)
          }

        }
        let fail = resp => {
          this.response_action[idx] = {
            'id': this.entries[idx].id,
            'value': this.getValue(this.entries[idx]),
            'trader_account': this.entries[idx].trader_account,
            'date_reference': this.entries[idx].date_reference,
            'follower_account': this.entries[idx].follower_account,
            'strat_name': this.entries[idx].strategy_name,
            'success': false,
            'msg': resp.data.msg ? this.$t(resp.data.msg) : this.$t('ERROR_UNEXPECTED')
          }
          if (idx === this.entries.length - 1) {
            this.actionDone()
          } else {
            this.performAction(idx+1)
          }
        }

        this.$resolveInternalBrokerPayment(param).then(success, fail)
      }
    },
    actionDone() {
      this.modalinprogress = false
      this.allDone = true
    }
  }
}
</script>

<style scoped>
.move-left {
  left: 10%;
      font-size: 15px;
}
.move-dot-up {
  font-size: 15px;
}
.success-bulk-operation {
  .fa {
    color: #28a745;
  }
}
.failed-bulk-operation {
  .fa {
    color: #FF3636;
  }
}

</style>
