<template>
  <modal
    :show.sync="modalreset"
    :required="true"
    headerClasses="justify-content-center">
    <h4 slot="header" class="title title-up">
      {{ $t('resetpassword') }}
    </h4>
    <div class="row">
      <p>
        {{ $t('resetpasswordtxt') }}
      </p>
    </div>
    <div class="row">
      <fg-input
        v-model="form.resetuid"
        v-validate="modalResetValidation.resetuid"
        :error="getError('resetuid')"
        addon-left-icon="nc-icon nc-email-85"
        placeholder="email@domain.com"
        type="email"
        :disabled="modalinprogress"
        :name="resetuid"></fg-input>
    </div>
    <div class="row text-center">
      <div class="col-md-12">
        <vue-hcaptcha
          v-if="showcaptcha && !modalinprogress"
          sitekey="7736ab28-5f0f-4eb9-810c-ca6f46e64b30"
          ref="recaptcha"
          @verify="verify"
        ></vue-hcaptcha>
      </div>
    </div>

    <template slot="footer">
      <div class="py-3 mx-auto" v-if="modalinprogress">
        <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: var(--btnColor1);"></i>
      </div>
      <div class="left-side" v-if="!modalinprogress">
        <p-button
          @click.prevent="validate('reset')"
          class="btn-rebrand-primary"
          link
          :disabled="!this.captcha_token">
          {{ $t('resettxt') }}
        </p-button>
      </div>
      <div class="divider" v-if="!modalinprogress"></div>
      <div class="right-side" v-if="!modalinprogress">
        <p-button class="btn-rebrand-secondary" v-on:click="cancelReset" link>
          {{ $t('cancel') }}
        </p-button>
      </div>
    </template>
  </modal>
</template>


<script>
import { Modal } from 'src/components/UIComponents'
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
const timeoutcount = 5000;

export default {
  name: 'resetpasswordmodal',
  components: {
    Modal,
    VueHcaptcha,
  },
  data() {
    return {
      form: {
        resetuid: '',
      },
      showcaptcha: false,
      captcha_token: null,
      resetuid: this.$t('resetuid'),
      modalinprogress: false,
      modalreset: false,
      modalResetValidation: {
        resetuid: {
          required: true,
          email: true
        }
      },
      modalerror_msg: null,
      modalerror: false,
    };
  },
  methods: {
    getError (fieldName) {
      return this.errors.first(this.$t(fieldName));
    },
    goReset () {
      this.showcaptcha = true;
      this.modalreset = true;
    },
    cancelReset() {
      this.showcaptcha = false;
      this.modalreset = false;
    },
    closeModalAlert () {
      this.modalerror = false;
    },
    verify(resp) {
      this.captcha_token = resp;
    },
    submit() {
      this.modalinprogress = true
      this.$sendreset({
        userid: this.form.resetuid,
        captcha_token: this.captcha_token,
      }).then(this.successHandler, this.failHandler)
    },
    successHandler(resp) {
      this.$toast.success(this.$t('emailresetsent'));
      this.form.resetuid = '';
      this.modalinprogress = false;
      this.modalreset = false;
      this.captcha_token = null;
    },
    failHandler(error) {
      this.modalinprogress = false;
      this.modalreset = false;
      this.$toast.error(this.$t('somethingwentwrong'));
    },
    validate() {
      this.$validator.validateAll()
        .then(this.submit, this.fail)
    }
  },
  mounted() {
  }
}
</script>

<style>

</style>
