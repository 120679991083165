<template>
  <div v-if="sessionLoading">
    <p-progress
      :value="100"
      type="info"
      class="progress-bar-animated">
    </p-progress>
  </div>
  <div v-else-if="!sessionLoading && !wlSession" class="rebrand-back">
    <login-banner></login-banner>
    <language-select class="language-select"></language-select>
    <div class="rebrand-box">
      <div class="rebrand-left-box">
        <img class="img" src="/static/img/hoko-logo-tiny.png">
      </div>
      <div class="rebrand-right-box">
        <div class="text-center wait-canvas" v-if="inprogress">
          <p style="vertical-align: middle; min-height: 450px">
            <span class="display: inline-block;">
              <i class="fa fa-cog fa-spin fa-2x fa-huge" style="color: #51bcda; display: block"></i>
              {{ $t('pleasewait') }}
            </span>
          </p>
        </div>
        <div class="text-center" v-if="showthankyou" style="min-height: 450px">
          <i class="fa fa-check fa-2x fa-huge" style="color: #28a745"></i>
          <h4>
            {{ $t('thankyouregister') }}
          </h4>
          <h4>{{ $t('redirectfromregister') }} {{ seccount }}</h4>
        </div>
        <div class="text-center" v-if="!showthankyou && !inprogress">
          <h5 class="rebrand-h5">
            {{ $t('start_your_hoko_journey') }}
          </h5>

          <div class="row">
            <div class="col-12 rebrand-space-bt-fields">
              <el-select class="select"
                         style="width: 100%;"
                         size="large"
                         :disabled="showCaptcha"
                         :placeholder="userLanguage"
                         v-on:change="updateLang"
                         v-model="form.lang">
                <el-option v-for="option in languages"
                           class="select"
                           :value="option.code"
                           :label="option.name"
                           :key="option.code">
                  <span style="margin-right: 10px;"> <img
                    :src="`/static/img/flags/${getLocale(option.code)}.png`">  </span>
                  <span>{{ option.name }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <fg-input class="rebrand-space-bt-fields"
                    v-model="form.firstName"
                    addon-left-icon="nc-icon nc-single-02"
                    v-if="!loading"
                    :disabled="showCaptcha"
                    :placeholder="firstName"
                    :name="firstName"
                    v-validate="modelValidations.firstname"
                    :error="getError(firstName)"></fg-input>

          <fg-input class="rebrand-space-bt-fields"
                    v-model="form.lastName"
                    v-if="!loading"
                    :disabled="showCaptcha"
                    addon-left-icon="nc-icon nc-circle-10"
                    :placeholder="lastName"
                    :name="lastName"
                    v-validate="modelValidations.lastname"
                    :error="getError(lastName)"></fg-input>

          <fg-input v-if="!loading"
                    :name="birthDate"
                    :error="getError(birthDate)"
                    v-model="form.birth_date"
                    v-validate="modelValidations.birth_date"
                    class="rebrand-space-bt-fields date-pick-rebrand"
                    :disabled="showCaptcha">
            <date-picker
              id="rebrand-datapick"
              :fieldname="birthDate"
              :placeholder="birthDate"
              v-model="form.birth_date"
              v-validate="modelValidations.birth_date">
            </date-picker>
          </fg-input>

          <fg-input v-model="form.address"
                    class="rebrand-space-bt-fields"
                    v-if="!loading"
                    :disabled="showCaptcha"
                    addon-left-icon="nc-icon nc-map-big"
                    :placeholder="address"
                    type="text"
                    :name="address"
                    v-validate="modelValidations.address"
                    :error="getError(address)"></fg-input>

          <fg-input v-model="form.city"
                    class="rebrand-space-bt-fields"
                    v-if="!loading"
                    :disabled="showCaptcha"
                    addon-left-icon="nc-icon nc-compass-05"
                    :placeholder="city"
                    type="text"
                    :name="city"
                    v-validate="modelValidations.city"
                    :error="getError(city)"></fg-input>

          <div class="row">
            <div class="col-6">
              <fg-input class="rebrand-space-bt-fields"
                        v-model="form.zip"
                        v-if="!loading"
                        :disabled="showCaptcha"
                        addon-left-icon="nc-icon nc-pin-3"
                        :placeholder="zip"
                        type="text"
                        :name="zip"
                        v-validate="modelValidations.zip"
                        :error="getError(zip)"></fg-input>
            </div>
            <div class="col-6">
              <select
                class="form-control rebrand-space-bt-fields"
                :disabled="showCaptcha"
                @change="selectLang(form.country)"
                v-model="form.country">
                <option value="undefined" selected disabled>
                  {{ country }}
                </option>
                <option v-for="option in countries"
                        :value="option.code">
                  {{ option.name }}
                </option>
              </select>

              <div class="text-danger invalid-feedback mt-2" style="display: block;" v-if="nocountry">
                {{ $t('countryneeded') }}
              </div>

            </div>
          </div>

          <fg-input v-model="form.email"
                    class="rebrand-space-bt-fields"
                    v-if="!loading"
                    :disabled="showCaptcha"
                    addon-left-icon="nc-icon nc-email-85"
                    :placeholder="email"
                    type="email"
                    :name="email"
                    v-validate="modelValidations.email"
                    :error="getError(email)"></fg-input>

          <div class="row rebrand-space-bt-fields">
            <div class="col-4">
              <select
                class="form-control"
                :disabled="showCaptcha"
                v-model="form.countryph">
                <option value="undefined" selected disabled>
                  {{ countryPhone }}
                </option>
                <option
                  v-for="(code, country) in countryphones"
                  :value="`${country}_+${code}`">
                  {{ country }} +{{ code }}
                </option>
              </select>
            </div>
            <div class="col-8">
              <fg-input v-model="form.phone"
                        addon-left-icon="nc-icon nc-mobile"
                        v-if="!loading"
                        :disabled="showCaptcha"
                        :placeholder="phone"
                        type="number"
                        :name="phone"
                        v-validate="modelValidations.phone"
                        :error="getError(phone)"></fg-input>
            </div>
          </div>

          <p-checkbox
            class="text-left rebrand-space-bt-fields"
            :disabled="showCaptcha"
            v-model="form.telegsubscribed"
            v-if="!hideTelegramNotificaton">
            {{ $t('subscribemetotelegram') }}
          </p-checkbox>

          <p-checkbox
            style="text-transform: capitalize;"
            :disabled="showCaptcha"
            class="text-left rebrand-space-bt-fields"
            v-model="form.acceptTerms">
            {{ $t('iagree') }}
            <a :href="$i18n.locale == 'pt' ? 'https://hokocloud.com/privacidade/?lang=pt-pt' : 'https://hokocloud.com/terms/'" target="_blank">{{ $t('tanda') }}</a>.
          </p-checkbox>

          <div class="text-danger invalid-feedback mt-2 rebrand-space-bt-fields"
               style="display: block;"
               v-if="notagreed">
            {{ $t('tandaneeded') }}
          </div>

          <div v-if="showCaptcha" class="col-md-12 text-center rebrand-space-bt-fields">
            <vue-hcaptcha
              sitekey="7736ab28-5f0f-4eb9-810c-ca6f46e64b30"
              @verify="verify"
            ></vue-hcaptcha>
          </div>

          <p-button
            v-if="!showCaptcha"
            nativeType="submit"
            @click.prevent="validate"
            slot="footer"
            round
            outline
            block
            class="col-md-12 btn-rebrand-secondary rebrand-space-bt-fields"
          >
            {{ $t('get_started') }}
          </p-button>

          <div class="rebrand-space-bt-fields" style="text-align: center;">
            <a v-on:click="prevPage()"
               style="
            margin-bottom: 35px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            margin: -20px auto;
            color: black;
          ">{{ $t('already_have_account') }}</a>
          </div>

        </div>
      </div>
    </div>
    <login-footer-disclaimer></login-footer-disclaimer>
  </div>
  <div v-else-if="!sessionLoading && wlSession">
    <div v-if="firstLoading" class="text-center margin-top-register">
      <h2>{{ $t('loadingtxt') }}</h2>
      <i class="fa fa-cog fa-spin fa-huge fa-fw"></i>
    </div>
    <div v-else class="register-page">
      <!-- <app-navbar></app-navbar> -->
      <div class="container" v-if="!showthankyou">
        <div class="card card-signup" style="margin-top: 3rem;">
          <div class="card-header text-center">
            <div style="float: left;">
              <p-button type="default" icon round @click.prevent="prevPage()" style="margin: 0;">
                <el-tooltip :content="this.$t('back')" :open-delay="100" placement="top">
                  <i class="fa fa-arrow-circle-left"></i>
                </el-tooltip>
              </p-button>
            </div>
            <div class="row" style="float: right;">
              <language-select style="margin-right: 20px"></language-select>
              <p-button type="info" icon round v-on:click="goTelegram()" style="margin: 0;">
                <el-tooltip :content="this.$t('subscribemetotelegram')" :open-delay="100" placement="top">
                  <i class="fa fa-telegram"></i>
                </el-tooltip>
              </p-button>
              <span>&nbsp</span>
              <PublicSupport
                :key="forceRender"
                ref="supportModal">
              </PublicSupport>
              <span>&nbsp</span>
            </div>
          </div>
          <div class="mt-2 text-center">
            <RemoteImg :src="logoLogin" is-login box-style="background-color: var(--btnColor2;"></RemoteImg>
            <hr class="mt-0">
            <h5 class="card-title">
              {{ $t('signup') }}
            </h5>
            <hr>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <RegisterPanel
                  :key="forceRender"
                  ref="panel">
                </RegisterPanel>
              </div>
              <div class="col-md-6" v-if="!inprogress">
                <div class="row" style="margin-bottom: 11px">
                  <div class="col-12">
                    <el-select class="select"
                               style="width: 100%;"
                               size="large"
                               :disabled="showCaptcha"
                               :placeholder="userLanguage"
                               v-on:change="updateLang"
                               v-model="form.lang">
                      <el-option v-for="option in languages"
                                 class="select"
                                 :value="option.code"
                                 :label="option.name"
                                 :key="option.code">
                        <span style="margin-right: 10px;"> <img
                          :src="`/static/img/flags/${getLocale(option.code)}.png`">  </span>
                        <span>{{ option.name }}</span>
                      </el-option>
                    </el-select>
                  </div>
                </div>

                <fg-input v-model="form.firstName"
                          addon-left-icon="nc-icon nc-single-02"
                          v-if="!loading"
                          :disabled="showCaptcha"
                          :placeholder="firstName"
                          :name="firstName"
                          v-validate="modelValidations.firstname"
                          :error="getError(firstNameTxt)"></fg-input>

                <fg-input v-model="form.lastName"
                          v-if="!loading"
                          :disabled="showCaptcha"
                          addon-left-icon="nc-icon nc-circle-10"
                          :placeholder="lastName"
                          :name="lastName"
                          v-validate="modelValidations.lastname"
                          :error="getError(lastName)"></fg-input>

                <fg-input v-if="!loading"
                          :name="birthDate"
                          :error="getError(birthDate)"
                          v-model="form.birth_date"
                          v-validate="modelValidations.birth_date"
                          :disabled="showCaptcha">
                  <date-picker
                    :fieldname="birthDate"
                    :placeholder="birthDate"
                    v-model="form.birth_date"
                    v-validate="modelValidations.birth_date">
                  </date-picker>
                </fg-input>

                <fg-input v-model="form.address"
                          v-if="!loading"
                          :disabled="showCaptcha"
                          addon-left-icon="nc-icon nc-map-big"
                          :placeholder="address"
                          type="text"
                          :name="address"
                          v-validate="modelValidations.address"
                          :error="getError(address)"></fg-input>

                <fg-input v-model="form.city"
                          v-if="!loading"
                          :disabled="showCaptcha"
                          addon-left-icon="nc-icon nc-compass-05"
                          :placeholder="city"
                          type="text"
                          :name="city"
                          v-validate="modelValidations.city"
                          :error="getError(city)"></fg-input>

                <div class="row">
                  <div class="col-6">
                    <fg-input v-model="form.zip"
                              v-if="!loading"
                              :disabled="showCaptcha"
                              addon-left-icon="nc-icon nc-pin-3"
                              :placeholder="zip"
                              type="text"
                              :name="zip"
                              v-validate="modelValidations.zip"
                              :error="getError(zip)"></fg-input>
                  </div>
                  <div class="col-6">
                    <select
                      class="form-control"
                      :disabled="showCaptcha"
                      @change="selectLang(form.country)"
                      v-model="form.country">
                      <option value="undefined" selected disabled>
                        {{ country }}
                      </option>
                      <option v-for="option in countries"
                              :value="option.code">
                        {{ option.name }}
                      </option>
                    </select>

                    <div class="text-danger invalid-feedback mt-2" style="display: block;" v-if="nocountry">
                      {{ $t('countryneeded') }}
                    </div>

                  </div>
                </div>

                <fg-input v-model="form.email"
                          v-if="!loading"
                          :disabled="showCaptcha"
                          addon-left-icon="nc-icon nc-email-85"
                          :placeholder="email"
                          type="email"
                          :name="email"
                          v-validate="modelValidations.email"
                          :error="getError(email)"></fg-input>

                <div class="row">
                  <div class="col-4">
                    <select
                      class="form-control"
                      :disabled="showCaptcha"
                      v-model="form.countryph">
                      <option value="undefined" selected disabled>
                        {{ countryPhone }}
                      </option>
                      <option
                        v-for="(code, country) in countryphones"
                        :value="`${country}_+${code}`">
                        {{ country }} +{{ code }}
                      </option>
                    </select>
                  </div>
                  <div class="col-8">
                    <fg-input v-model="form.phone"
                              addon-left-icon="nc-icon nc-mobile"
                              v-if="!loading"
                              :disabled="showCaptcha"
                              :placeholder="phone"
                              type="number"
                              :name="phone"
                              v-validate="modelValidations.phone"
                              :error="getError(phone)"></fg-input>
                  </div>
                </div>

                <p-checkbox
                  class="text-left"
                  :disabled="showCaptcha"
                  v-model="form.telegsubscribed"
                  v-if="!hideTelegramNotificaton">
                  {{ $t('subscribemetotelegram') }}
                </p-checkbox>

                <p-checkbox
                  :disabled="showCaptcha"
                  class="text-left"
                  v-model="form.acceptTerms">
                  {{ $t('iagree') }}
                  <a :href="$i18n.locale == 'pt' ? 'https://hokocloud.com/privacidade/?lang=pt-pt' : 'https://hokocloud.com/terms/'" target="_blank">{{ $t('tanda') }}</a>.
                </p-checkbox>

                <div class="text-danger invalid-feedback mt-2"
                     style="display: block;"
                     v-if="notagreed">
                  {{ $t('tandaneeded') }}
                </div>

                <div v-if="showCaptcha" class="col-md-12 text-center">
                  <vue-hcaptcha
                    sitekey="7736ab28-5f0f-4eb9-810c-ca6f46e64b30"
                    @verify="verify"
                  ></vue-hcaptcha>
                </div>

                <p-button
                  v-if="!showCaptcha"
                  nativeType="submit"
                  @click.prevent="validate"
                  slot="footer"
                  type="info"
                  class="col-md-12"
                >
                  {{ $t('register') }}
                </p-button>

                <small class="col-md-12">{{ $t('already_registered') }}</small>

                <p-button
                  @click.prevent="prevPage()"
                  slot="footer"
                  type="default"
                  class="col-md-12"
                >
                  {{ $t('login') }}
                </p-button>

              </div>
              <div class="col-md-6 text-center wait-canvas">
<!--                <p style="vertical-align: middle">-->
<!--                <span class="display: inline-block;">-->
<!--                  <i class="fa fa-cog fa-spin fa-2x fa-huge" style="color: #51bcda; display: block"-->
<!--                     v-if="inprogress"></i>-->
<!--                  {{ $t('pleasewait') }}-->
<!--                </span>-->
<!--                </p>-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container" v-if="showthankyou">
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6" style="margin-top: 3rem;">
            <card type="signup" class="text-center">
              <template slot="header">
                <h5 class="card-title">
                  {{ $t('signup') }}
                </h5>
              </template>
              <p>
                {{ $t('thankyouregister') }}
              </p>
              <p>
                {{ $t('redirectfromregister') }}
                {{ seccount }}
              </p>
              <p>
                <p-button slot="footer" type="default" round outline v-on:click="goLogin">
                  {{ $t('login') }}
                </p-button>
              </p>
              <p-progress :value="countdown" type="success" :height="15" class="progress-bar-animated">
              </p-progress>
              <br/>
            </card>
          </div>
        </div>
      </div>
       <div class="container">
        <Disclaimer v-if="wlSession" :disclaimer-content="disclaimer"></Disclaimer>
        <AppFooter></AppFooter>
      </div>
    </div>
  </div>
</template>
<script>
import AppNavbar from './Layout/AppNavbar'
import AppFooter from './Layout/AppFooter2'
import {Card, Checkbox, Button, InfoSection, Progress, Modal} from 'src/components/UIComponents';
import {Select, Option, Tooltip} from 'element-ui'
import RegisterPanel from "./RegisterPanel";
import PublicSupport from "src/components/Dashboard/Views/Pages/PublicSupport.vue"
import VueHcaptcha from '@hcaptcha/vue-hcaptcha'
import DatePicker from "@/components/UIComponents/Inputs/DatePicker";
import LanguageSelect from "@/components/Dashboard/Views/Components/LanguageSelect.vue";
import swal from 'sweetalert2'

import VueCookies from 'vue-cookies'

import ar from 'vee-validate/dist/locale/ar';
import en from 'vee-validate/dist/locale/en';
import es from 'vee-validate/dist/locale/es';
import it from 'vee-validate/dist/locale/it';
import ro from 'vee-validate/dist/locale/ro';
import ru from 'vee-validate/dist/locale/ru';
import pt from 'vee-validate/dist/locale/pt_PT';
import zh from 'vee-validate/dist/locale/zh_CN';
import de from 'vee-validate/dist/locale/de';
import vi from 'vee-validate/dist/locale/vi';
import Disclaimer from "@/components/Dashboard/Views/Pages/Layout/Disclaimer.vue";
import Constants from "@/assets/constants";
import LoginBanner from "@/components/Dashboard/Views/Pages/login-banner.vue";
import LoginFooterDisclaimer from "@/components/Dashboard/Views/Pages/login-footer-disclaimer.vue";

export default {
  props: {
    logo: {
      type: String,
      default: '',
      description: 'Hoko Logo'
    }
  },
  components: {
    LoginFooterDisclaimer,
    LoginBanner,
    Disclaimer,
    DatePicker,
    Card,
    AppNavbar,
    AppFooter,
    InfoSection,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
    [Progress.name]: Progress,
    [Option.name]: Option,
    [Select.name]: Select,
    RegisterPanel,
    Modal,
    PublicSupport,
    VueHcaptcha,
    LanguageSelect,
    [Tooltip.name]: Tooltip,
  },
  computed: {
    rules: function () {
      return Constants.methods.getRules();
    },
    firstName: function () {
      return this.firstNameTxt || this.$t('firstname');
    },
    lastName: function () {
      return this.lastNameTxt || this.$t('lastname');
    },
    birthDate: function () {
      return this.birthdatetxt || this.$t('birthdatetxt');
    },
    email: function () {
      return this.emailTxt || this.$t('email');
    },
    address: function () {
      return this.addrTxt || this.$t('address');
    },
    phone: function () {
      return this.phoneTxt || this.$t('phone');
    },
    city: function () {
      return this.cityTxt || this.$t('city');
    },
    zip: function () {
      return this.zipTxt || this.$t('zip');
    },
    country: function () {
      return this.countryTxt || this.$t('country');
    },
    userLanguage: function () {
      return this.userLang || this.$t('user_lang');
    },
    account: function () {
      return this.accountTxt || this.$t('account');
    },
    countryPhone: function () {
      return this.countryph || (this.$t('country') + " " + this.$t('codetxt'));
    },
  },
  data() {
    return {
      firstLoading: false,
      loading: false,
      form: {
        firstName: '',
        lastName: '',
        birth_date: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        zip: '',
        country: 'US',
        acceptTerms: false,
        account: '',
        ip: '',
        afl_id: null,
        strat_id: null,
        lang: "en",
        telegsubscribed: true,
        countryph: 'US_+1',
        captcha_token: null,
        whitelabel: '',
      },
      showCaptcha: false,
      wlSession: false,
      wlSettings: {},
      logoLogin: '',
      firstNameTxt: '',
      lastNameTxt: '',
      birthdatetxt: '',
      emailTxt: '',
      phoneTxt: '',
      addrTxt: '',
      cityTxt: '',
      zipTxt: '',
      countryTxt: '',
      userLang: '',
      accountTxt: '',
      countryph: '',
      modelValidations: {
        firstname: {required: true, regex:/^[^\d/*\-+.]+$/u},
        lastname: {required: true, regex: /^[^\d/*\-+.]+$/u},
        birth_date: {required: true},
        address: {required: true},
        city: {required: true},
        zip: {required: true},
        country: {required: true},
        email: {required: true},
        phone: {required: true, min: 7},
      },
      notagreed: false,
      inprogress: false,
      showregister: true,
      showthankyou: false,
      countdown: 100,
      seccount: 10,
      freeVPStxt: this.$t('freeVPStxt'),
      freeVPSDescriptionTxt: this.$t('freeVPSDescriptionTxt'),
      brokerAgTxt: this.$t('brokerAgTxt'),
      brokerAgDescriptionTxt: this.$t('brokerAgDescriptionTxt'),
      tradeCopyTxt: this.$t('tradeCopyTxt'),
      tradeCopyDescriptionTxt: this.$t('tradeCopyDescriptionTxt'),
      unbiasedTxt: this.$t('unbiasedTxt'),
      unbiasedDescriptionTxt: this.$t('unbiasedDescriptionTxt'),
      error: false,
      error_msg: null,
      countries: this.$country_list(),
      nocountry: false,
      nolang: false,
      languages: [
        {
          'code': 'en',
          'name': 'English'
        },
        {
          'code': 'pt',
          'name': 'Portuguese'
        },
        {
          'code': 'it',
          'name': 'Italian'
        },
        {
          'code': 'ar',
          'name': 'Arabic'
        },
        {
          'code': 'es',
          'name': 'Spanish'
        },
        {
          'code': 'zh',
          'name': 'Chinesse'
        },
        {
          'code': 'de',
          'name': 'Germany'
        },
        {
          'code': 'fr',
          'name': 'French'
        }, {
          'code': 'ru',
          'name': 'Russian'
        },
        {
          'code': 'vi',
          'name': 'Vietnam'
        },
        {
          'code': 'ro',
          'name': 'Romanian'
        }
      ],
      countryphones: {},
      forceRender: false,
      hideTelegramNotificaton: false,
      componentKey: false,
      disclaimer: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: 'Today',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: 'Yesterday',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: 'A week ago',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      sessionLoading: true,
    }
  },
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    getLocale(locale) {
      if (locale === 'en') {
        return 'GB';
      }
      if (locale === 'ar') {
        return 'AE';
      }
      return locale.toUpperCase()
    },
    selectLang(lang) {
      this.form.countryph = lang + '_+' + this.countryphones[Object.keys(this.countryphones).filter(key => key.startsWith(lang))]
    },
    goTelegram() {
      window.open("https://t.me/HokoSupport", "_blank");
    },
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
    },
    closeAlert() {
      this.error = false;
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    goLogin() {
      location.href = "/" + this.form.whitelabel;
    },
    prevPage() {
      if (this.wlSession) {
        this.$router.push({name: 'Login', params: {wl: this.wlSettings.whitelabel}})
      } else {
        this.$router.push("/login")
      }
    },
    verify(resp) {
      this.form.captcha_token = resp;
    },
    captcha(isValid) {
      if (!isValid) {
        return
      }
      this.notagreed = !this.form.acceptTerms;
      this.showCaptcha = this.form.acceptTerms;
    },
    submit() {
      this.inprogress = true;
      this.$register(this.form)
        .then(this.successRegisterHandler, this.errorRegisterHandler);
    },
    successRegisterHandler(resp) {
      if (!resp.success) {
        this.errorRegisterHandler(resp);
        return;
      }
      this.inprogress = false;
      this.showthankyou = true;

      let count_timer = setInterval(resp => {
        if (this.seccount >= 0 && this.countdown % 10 == 0) {
          this.seccount -= 1;
        }
        if (this.countdown <= 2) {
          clearInterval(count_timer);
          location.href = "/" + this.form.whitelabel;
        }
        this.countdown -= 2;
      }, 220)
    },
    errorRegisterHandler(resp) {
      this.showCaptcha = false;
      this.inprogress = false;
      if (!resp || !resp.msg) {
        swal(this.$t('warningtxt'), this.$t('ERROR_FAIL_REGISTER_USER'));
        return;
      }
      swal(this.$t('warningtxt'), this.$t(resp.msg));
    },
    validate() {
      this.$validator.validateAll()
        .then(isValid => this.captcha(isValid))
    },
    updateLang() {
      this.$refs.panel.update(this.form.lang);
      this.$refs.supportModal.update(this.form.lang);
    },
    loadCountryPhoneList() {
      const _tmp = this.$country_phone_list();
      let _tmpordered = {};
      Object.keys(_tmp).sort().forEach(function (key) {
        _tmpordered[key] = _tmp[key];
      });
      this.countryphones = Object.assign({}, _tmpordered);
    }
  },
  beforeDestroy() {
    this.closeMenu()
  },
  beforeMount() {
    this.firstLoading = true;

    const noWlHandler = () => {
      localStorage.clear();
      this.logoLogin = "/static/img/logo.png";
      this.sessionLoading = false;
    };

    const applyWlSettings = (settings) => {
      if (settings) {
        this.wlSettings = settings;
        this.wlSession = true;
        this.logoLogin = settings.logo_login;
        this.form.whitelabel = settings.whitelabel;
        this.hideTelegramNotificaton = settings.hide_telegram_notification;
        this.disclaimer = settings.disclaimer;
        const bodyEl = document.getElementsByTagName('body')[0];
        const rootEl = document.querySelector(':root');
        this.showImg = true;
        if (settings.bgcolor) {
          rootEl.style.setProperty('--bgColor', settings.bgcolor);
        }
        if (settings.bgimg_login) {
          bodyEl.style.backgroundImage = `url('${settings.bgimg_login}')`;
        }
        if (settings.bgcolor_btn1) {
          rootEl.style.setProperty('--btnColor1', settings.bgcolor_btn1);
        }
        if (settings.bgcolor_btn2) {
          rootEl.style.setProperty('--btnColor2', settings.bgcolor_btn2);
        }
        try {
          document.getElementById('favicon96').href = '/static/img/icons/' + settings.whitelabel.toLowerCase() + '.ico'
        } catch (s) {
        }
        try {
          document.getElementById('favicon32').href = '/static/img/icons/' + settings.whitelabel.toLowerCase() + '.ico'
        } catch (s) {
        }
        try {
          document.getElementById('favicon16').href = '/static/img/icons/' + settings.whitelabel.toLowerCase() + '.ico'
        } catch (s) {
        }
        try {
          document.getElementsByTagName('title')[0].innerHTML = settings.fullname;
        } catch (s) {
        }
        this.sessionLoading = false;
      } else {
        noWlHandler();
      }
    }

    const success = (resp) => {
      if (!resp.success) {
        noWlHandler();
        return;
      }
      applyWlSettings(resp.data)
    };

    const settings = localStorage.getItem('wlSettings');

    if (settings) {
      applyWlSettings(JSON.parse(settings));
    } else {
      let wl = this.$route.params.wl || window.location.hostname;
      if (wl) {
        this.$wlSettings(wl).then(success, noWlHandler);
      } else {
        noWlHandler();
      }
    }
  },
  mounted() {
    this.firstLoading = false;

    if (this.$route.params.afl_id) {
      this.form.afl_id = this.$route.params.afl_id;
    }
    if (this.$route.params.strat_id) {
      this.form.strat_id = this.$route.params.strat_id;
    }
    this.loadCountryPhoneList()
  },
  watch: {
    'form.captcha_token': function (newVal) {
      this.submit()
    },
  }
}
</script>
<style lang="scss" scoped>
div {
  color: #6c757d !important;
}

.full-page > .content {
  padding-top: 9vh !important;
}

div.el-select {
  div.el-input {
    input:hover {
      color: #6c757d !important;
    }
  }
}

.elpickerregister {
  margin-bottom: 10px;
  width: 100%;
}

.card-header::v-deep .remoteImg {
  margin: auto 70px auto auto;
  @media screen and (max-width: 480px) {
    margin: 30px auto auto 10px;
    transform: translateX(-50%);
  }
}
</style>
<style scoped lang="scss">
.elpickerregister > input::placeholder, .form-group .el-date-editor .el-input__inner::placeholder {
  color: #6c757d !important;
  opacity: 1;
}

.wait-canvas {
  padding-top: 50px;
}

.mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}

.margin-top-register {
  margin-top: 300px;
}

.mb-15 {
  margin-bottom: 15px;
}

.rebrand-left-box::v-deep {
  @media screen and (max-width: 768px) {
    display: none;
  }
  background-color: #210896;
  background-image: url(/static/img/banner/start-bg-tiny.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 32px 0px 0px 32px;
  width: 50%;

  .img {
    width: 149px;
    height: 42px;
    position: relative;
    top: 45%;
    left: 40%;
    //transform: translateY(-50%);
  }


}

#rebrand-datapick::v-deep {
  .mx-icon-calendar {
    left: -30px !important;
  }
}

.rebrand-back {
  @media screen and (max-width: 542px) {
    padding: 0px;
  }
  //padding: 50px 15px 100px 15px;
}

.rebrand-box {
  margin-bottom: 20px;
  @media screen and (max-width: 542px) {
    margin: 0px 10px 0px 10px;
    padding: 40px 20px 40px 20px;
    width: auto;
  }
  //box-shadow: 0px 4px 4px 0px rgb(55 51 53 / 25%);
  box-shadow: 0px 4px 4px 0px RGB(55 51 53 / 25%);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-radius: 32px 32px 32px 32px;
  border-color: #210896;
  max-width: 1080px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.rebrand-right-box {
  background-color: #fff;
  border-radius: 0px 32px 32px 0px;
  padding: 60px 38px 60px 38px;
  width: 50%;
  @media screen and (max-width: 768px) {
    border-radius: 32px 32px 32px 32px;
    width: 100%;
    padding: 0px;
  }
}

.rebrand-h5 {
  font-size: 31px;
  text-transform: uppercase;
  letter-spacing: -1.59px;
  color: #210896;
  font-family: Poppins, Sans-serif;
  font-weight: 900;
  padding-bottom: 30px;
  margin-bottom: 0px;
  word-spacing: 8px;
}

.rebrand-space-bt-fields {
  margin-bottom: 20px;
}

.language-select {
  width: 100%;
  padding: 0 0 0 67%;
  margin-top: -45px;
  text-align: justify;
  display: flex;
  float: right;
}

</style>
