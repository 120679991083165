<template>

  <div class="sidebar"
       :data-color="backgroundColor"
       :data-active-color="activeColor">
    <div class="logo">
      <a class="simple-text logo-mini"
         href="/">
          <div :class="rules.isWhitelabelSession ? 'logo-img' : 'logo-img-rebranding'">
            <RemoteImg :src="logo"></RemoteImg>
          </div>
      </a>
      <a id="whitelabel_name" class="simple-text logo-normal"
         href="/">
          {{ appname }}
      </a>
    </div>

    <!-- Modal to Add VPS -->
    <modal :show.sync="modaladdvps" headerClasses="justify-content-center">
      <h5 slot="header" class="title title-up">{{ $t('createnewvps') }}</h5>
      <p></p>
      <template slot="footer">
        <div class="left-side">
          <p-button link>{{ $t('continue_text') }}</p-button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="danger" link>{{ $t('cancel') }}</p-button>
        </div>
      </template>
    </modal>

    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot>

      </slot>
      <ul class="nav">
        <slot name="links">
          <sidebar-item v-for="(link, index) in sidebarLinks"
                        :key="link.name + index"
                        :link="link">

            <sidebar-item v-for="(subLink, index) in link.children"
                          :key="subLink.name + index"
                          @openmodal="openmodal(subLink)"
                          :link="subLink">
            </sidebar-item>
          </sidebar-item>
        </slot>

      </ul>
    </div>
  </div>
</template>
<script>
  import 'perfect-scrollbar/dist/css/perfect-scrollbar.css'
  import { Modal } from 'src/components/UIComponents'
  import Constants from 'src/assets/constants'

  export default {
    components: {
      Modal
    },
    data() {
      return {
        modaladdvps: false, appname: Constants.methods.appname(),
        logo: '/static/img/HokoCloud_Icon.png',
      }
    },
    computed: {
    rules: function () {
      return Constants.methods.getRules();
      },
    },
    props: {
      title: {
        type: String,
        default: 'HokoCloud',
        description: 'Sidebar title'
      },
      backgroundColor: {
        type: String,
        default: 'black',
        validator: (value) => {
          let acceptedValues = ['white', 'brown', 'black']
          return acceptedValues.indexOf(value) !== -1
        },
        description: 'Sidebar background color (white|brown|black)'
      },
      activeColor: {
        type: String,
        default: 'success',
        validator: (value) => {
          let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
          return acceptedValues.indexOf(value) !== -1
        },
        description: 'Sidebar active text color (primary|info|success|warning|danger)'
      },
      sidebarLinks: {
        type: Array,
        default: () => [],
        description: 'Sidebar links. Can be also provided as children components (sidebar-item)'
      },
      autoClose: {
        type: Boolean,
        default: true
      },
    },
    provide() {
      return {
        autoClose: this.autoClose
      }
    },
    methods: {
      async initScrollBarAsync () {
        const PerfectScroll = await import('perfect-scrollbar');
        PerfectScroll.initialize(this.$refs.sidebarScrollArea)
      },
      openmodal(modalpath) {
        this[modalpath.path] = true;
      }
    },
    mounted () {
      const settings = localStorage.getItem('wlSettings');
      if (settings) {
        let json = JSON.parse(settings);
        this.logo = json.logo_menu;
        document.getElementById('whitelabel_name').innerHTML = json.wlname;
      }

      this.initScrollBarAsync();
    },
    beforeDestroy () {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false
      }
    }
  }

</script>
<style>
  @media (min-width: 992px) {
    .navbar-search-form-mobile,
    .nav-mobile-menu{
      display: none;
    }
  }
</style>
