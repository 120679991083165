<template>
  <div>

    <div v-if="register_items.length == 0"
         class="col-md-12"
         v-for="entry in entries">
      <h6><i :class="entry.icon"></i> {{ $t(entry.title) }} </h6>
      <p style="text-align: justify">{{ $t(entry.description)  }}</p>
    </div>

    <div v-if="register_items.length > 0"
         class="col-md-12"
         v-for="entry in register_items">

      <div v-if="mode == 'edit'">
        <p-button type="success"><i class="fa fa-pencil"></i></p-button>
        <p-button type="danger"><i class="fa fa-trash-o"></i></p-button>
      </div>

      <h6><i :class="entry.icon"></i> {{ entry.title[locale] }} </h6>
      <p style="text-align: justify">
        {{ entry.description[locale] }}
      </p>
    </div>


<!--    <div v-if="wlSession" class="col-md-12" style="margin-top: 3rem;">-->
<!--      <p style="text-align: center">-->
<!--        <img src="/static/img/logo.png" alt="" class="logo-small">-->
<!--      </p>-->
<!--      <p style="text-align: center">-->
<!--        {{ $t('poweredhokocloud') }}-->
<!--      </p>-->
<!--    </div>-->

  </div>
</template>

<script>
  const wl = localStorage.getItem('wlSettings');
  const settings = wl ? JSON.parse(wl) : null;
  export default {
    name: "RegisterPanel",
    props: {
      mode: {
        type: [String],
        description: "Indicate the mode for compoent, read or edit",
        default: 'read'
      }
    },
    data() {
      return {
        locale: this.$i18n.locale,
        wlSession: false,
        entries: [
          {
            icon: 'nc-icon nc-trophy',
            title: 'unbiasedTxt',
            description: 'unbiasedDescriptionTxt'
          },
          {
            icon: 'fa fa-line-chart',
            title: 'brokerAgTxt',
            description: 'brokerAgDescriptionTxt'
          },
          {
            icon: 'fa fa-connectdevelop',
            title: 'tradeCopyTxt',
            description: 'tradeCopyDescriptionTxt'
          }
        ],
        register_items: [],
        bkp: [],
        decj: ""
      }
    },
    methods: {
      update(locale) {
        this.locale = locale;
      }
    },
    mounted() {
      this.wlSession = !!wl;
      this.register_items = wl ? settings.register_items : []
    }
  }
</script>

<style scoped>
  .logo-small {
    width: 100px;
  }
</style>
