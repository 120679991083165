<template>
  <div>
    <div>
      <div class="container mt-3">
        <div class="row">
          <div class="card col-lg-12 col-md-12 col-sm-12 ml-auto"
               style="background-color: #f4f3ef;  border-radius: 20px;">
            <div v-if="inprogress" class="text-center text-black">
              <h4>{{ this.inprogresstxt }} {{ $t('strategytxt') }}. {{ $t('pleasewait') }}</h4>
              <i class="fa fa-spinner fa-spin fa-fw" style="font-size: 60px;"></i>
              <br/>
            </div>
            <div class="card" v-if="!inprogress && !isMobile()" style="margin-top: 15px">
              <div class="card-header">
                <div class="row" id="row-btn">
                  <div class="col-6">
                    <RemoteImg
                      :src="logo_login"
                      box-style="text-align: left !important; margin: 0.75rem;"
                      size="auto; max-height: 115px;"
                    ></RemoteImg>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div class="col-12">
                        <p-button
                          v-if="!isWlSession"
                          class="btn-rebrand-primary"
                          round
                          outline
                          v-on:click="goLogin"
                          style="float: right; min-width: 16rem !important;"
                        >{{ $t('logintofollow') }}
                        </p-button>
                        <p-button
                          v-else
                          :style="'backgroundColor: ' + this.settings.bgcolor_btn1"
                          v-on:click="goLogin"
                          style="float: right;"
                        >{{ $t('logintofollow') }}
                        </p-button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <p-button
                          v-if="!isWlSession"
                          class="btn-rebrand-secondary"
                          round
                          outline
                          v-on:click="register"
                          style="float: right; min-width: 16rem !important;"
                        >{{ $t('registertofollowtxt') }}
                        </p-button>
                        <p-button
                          v-else
                          :style="'backgroundColor: ' + this.settings.bgcolor_btn2"
                          v-on:click="register"
                          style="float: right;"
                        >{{ $t('registertofollowtxt') }}
                        </p-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body" style="padding: 1rem 1.5rem !important;">
                <hr/>
                <div class="row">
                  <div class="col-6" style="vertical-align: top;">
                  </div>
                  <div class="col-6">
                    <div class="float-right">
                      <language-select></language-select>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="description" v-if="!fail_load_strategy">
                      <h5 class="info-title">
                        <i class="nc-icon nc-sound-wave"></i>
                        {{ $t('strategytxt') }}: {{ strategy_name }} ({{ strategy_price }})
                      </h5>
                    </div>
                    <div class="description mt-5 mb-5" v-else>
                      <h5 class="info-title">
                        {{ $t('public_strategy_page_private_found') }}
                      </h5>
                    </div>
                  </div>
                </div>
                <br/>
                <p class="lead" v-if="!fail_load_strategy">
                  {{ (strategy_desc) ? strategy_desc : $t('notavailable') }}
                </p>
              </div>

            </div>
            <div class="card" v-else-if="!inprogress && isMobile()">
              <div class="card-header">
                <div class="row">
                  <div class="col">
                  </div>
                  <div class="col">
                    <div class="text-center">
                      <RemoteImg :src="logo_login" customStyle="max-width: 100px;"></RemoteImg>
                    </div>
                  </div>
                  <div class="col">
                    <div class="text-right">
                      <language-select></language-select>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div class="col-xs-3">
                  <p-button
                    class="btn-rebrand-primary"
                    round
                    outline
                    v-on:click="goLogin"
                    style="float: left;" size="sm">
                    {{ $t('logintofollow') }}
                  </p-button>
                </div>
                <div class="col-xs-3">
                  <p-button
                    class="btn-rebrand-secondary"
                    round
                    outline v-on:click="register" style="float: right;" size="sm">
                    {{ $t('registertofollowtxt') }}
                  </p-button>
                </div>
              </div>
              <div class="card-body" v-if="!fail_load_strategy">
                <div class="description">
                  <h5 class="info-title">
                    {{ $t('strategytxt') }}: {{ strategy_name }} ({{ strategy_price }})
                  </h5>
                </div>
                <p class="lead">
                  {{ (strategy_desc) ? strategy_desc : $t('notavailable') }}
                </p>
              </div>
              <div class="card-body" v-else>
                <div class="description mt-3 mb-3">
                  <h5 class="info-title">
                    {{ $t('public_strategy_page_private_found') }}
                  </h5>
                </div>
              </div>
              <div class="card-footer">
              </div>
            </div>
            <stats-charts style="overflow: hidden;"
                          :typePills="isWlSession ? 'default' : 'rebrand'"
                          :activeUsersChart="activeUsersChart"
                          :equityUsersChart="equityUsersChart"
                          :inprogress="inprogress"
                          :showdata="true"
                          :eqReportingStatus="eqReportingStatus"
                          :balReportingStatus="balReportingStatus"
                          :eqChartInfo="eqChartInfo"
                          :balChartInfo="balChartInfo"
            ></stats-charts>
          </div>
        </div>
      </div>
      <!--      <login-footer-disclaimer></login-footer-disclaimer>-->
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import Storage from 'vue-web-storage';
import AppNavbar from './Layout/AppNavbar'
import LoginRegisterFollow from './Layout/LoginRegisterFollow'
import AppFooter from './Layout/AppFooter2'
import {Card} from 'src/components/UIComponents'
import {Tooltip} from 'element-ui';
import StatsCharts from 'src/components/UIComponents/Charts/StatisticsCharts';
import LoginFooterDisclaimer from "@/components/Dashboard/Views/Pages/login-footer-disclaimer.vue";
import LanguageSelect from "@/components/Dashboard/Views/Components/LanguageSelect.vue";

const maxSteps = 10;
const tooltipOptions = {
  tooltipFillColor: "rgba(0,0,0,0.5)",
  tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
  tooltipFontSize: 14,
  tooltipFontStyle: "normal",
  tooltipFontColor: "#fff",
  tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
  tooltipTitleFontSize: 14,
  tooltipTitleFontStyle: "bold",
  tooltipTitleFontColor: "#fff",
  tooltipYPadding: 6,
  tooltipXPadding: 6,
  tooltipCaretSize: 8,
  tooltipCornerRadius: 6,
  tooltipXOffset: 10,
};
Vue.use(require('vue-moment'));
Vue.use(Storage, {
  prefix: 'hokocloud_',
  drivers: ['session'],
});

export default {
  props: {
    logo: {
      type: String,
      default: '/static/img/hoko-new-logo.png',
      description: 'Hoko Logo'
    }
  },
  components: {
    LoginFooterDisclaimer,
    Card,
    AppNavbar,
    LoginRegisterFollow,
    AppFooter,
    StatsCharts,
    LanguageSelect,
    [Tooltip.name]: Tooltip
  },
  data() {
    return {
      form: {
        ip: '',
        afl_id: null,
        wlId: ''
      },
      pillType: "",
      strategy_name: null,
      strategy_desc: null,
      strategy_id: null,
      strategy_price: 0,
      activeUsersChart: {
        labels: [],
        drawdown: {
          data: [],
          options: {
            tooltips: tooltipOptions,
            scales: {
              yAxes: [{
                ticks: {
                  callback: function (value, index, values) {
                    return value + '%';
                  }
                },
                gridLines: {display: true}
              }],
              xAxes: [{
                time: {unit: 'day', stepSize: 1},
                type: 'time', ticks: {beginAtZero: true, stepSize: 1, maxTicksLimit: 10},
                gridLines: {display: true}
              }]
            }
          }
        },
        stats: {},
        percentualVolatilityEndurance: {
          data: [],
          options: {
            tooltips: tooltipOptions,
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  suggestedMax: 100,
                  callback: function (value, index, values) {
                    return value + '%';
                  },
                  reverse: true
                },
                gridLines: {display: true}
              }],
              xAxes: [{
                time: {unit: 'day', stepSize: 1},
                type: 'time', ticks: {beginAtZero: true, stepSize: 1, maxTicksLimit: 10},
                gridLines: {display: true}
              }]
            }
          }
        },
        color: '#6bd098',
        accumulatedBalance: {
          datasets: [
            {
              borderColor: '#51cbce',
              backgroundColor: '#51cbce',
              data: [],
              pointRadius: 0,
              fill: false
            }
          ]
        },
        dailyBalanceGrowth: {
          datasets: [
            {
              borderColor: '#51cbce',
              backgroundColor: '#51cbce',
              data: [],
              pointRadius: 0,
              fill: false
            }
          ]
        },
        effectiveLeverageUsed: {
          data: [],
          options: {
            tooltips: tooltipOptions,
            scales: {
              yAxes: [{
                gridLines: {
                  display: true
                }
              }],
              xAxes: [{
                time: {unit: 'day', stepSize: 1},
                type: 'time', gridLines: {display: true}
              }]
            }
          }
        },
        options: {
          tooltips: tooltipOptions,
          scales: {
            yAxes: [{
              ticks: {
                callback: function (value, index, values) {
                  return value + '%';
                }
              },
              gridLines: {display: true}
            }],
            xAxes: [{
              time: {unit: 'day', stepSize: 1},
              type: 'time', ticks: {beginAtZero: true, stepSize: 1, maxTicksLimit: 10},
              gridLines: {display: true}
            }]
          }
        },
        monthlyReturnBalance: {
          labels: [],
          datasets: [
            {
              borderColor: '#fcc468',
              fill: true,
              backgroundColor: '#fcc468',
              hoverBorderColor: '#fcc468',
              borderWidth: 8, data: []
            }
          ],
          options: {
            tooltips: tooltipOptions,
            scales: {
              yAxes: [{
                ticks: {
                  callback: function (value, index, values) {
                    return value + '%';
                  }
                },
                gridLines: {display: true}
              }],
              xAxes: [{
                gridLines: {display: true}
              }]
            }
          }
        }
      },
      equityUsersChart: {
        labels: [],
        effectiveDrawdown: {
          data: [],
          options: {
            tooltips: tooltipOptions,
            scales: {
              yAxes: [{
                ticks: {
                  callback: function (value, index, values) {
                    return value + '%';
                  }
                },
                gridLines: {display: true}
              }],
              xAxes: [{
                time: {unit: 'day', stepSize: 1},
                type: 'time', ticks: {beginAtZero: true, stepSize: 1, maxTicksLimit: 10},
                gridLines: {display: true}
              }]
            }
          }
        },
        stats: {},
        percentualVolatilityEndurance: {
          data: [],
          options: {
            tooltips: tooltipOptions,
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  suggestedMax: 100,
                  callback: function (value, index, values) {
                    return value + '%';
                  },
                  reverse: true
                },
                gridLines: {display: true}
              }],
              xAxes: [{
                time: {unit: 'day', stepSize: 1},
                type: 'time', ticks: {beginAtZero: true, stepSize: 1, maxTicksLimit: 10},
                gridLines: {display: true}
              }]
            }
          }
        },
        color: '#6bd098',
        effectiveLeverageUsed: {
          data: [],
          options: {
            tooltips: tooltipOptions,
            scales: {
              yAxes: [{
                gridLines: {
                  display: true
                }
              }],
              xAxes: [{
                time: {unit: 'day', stepSize: 1},
                type: 'time',
                gridLines: {
                  display: true
                }
              }]
            }
          }
        },
        options: {
          tooltips: tooltipOptions,
          scales: {
            yAxes: [{
              ticks: {
                callback: function (value, index, values) {
                  return value + '%';
                }
              },
              gridLines: {display: true}
            }],
            xAxes: [{
              time: {
                unit: 'day',
                stepSize: 1
              },
              type: 'time',
              ticks: {
                beginAtZero: true,
                stepSize: 1,
                maxTicksLimit: 10
              },
              gridLines: {
                display: true
              }
            }]
          }
        },
        monthlyReturnEquity: {
          labels: [],
          datasets: [
            {
              borderColor: [],
              fill: true,
              backgroundColor: [],
              hoverBorderColor: [],
              borderWidth: 8,
              data: [],
              fillColor: []
            }
          ],
          options: {
            tooltips: tooltipOptions,
            scales: {
              yAxes: [{
                ticks: {
                  callback: function (value, index, values) {
                    return value + '%';
                  }
                },
                gridLines: {
                  display: true
                }
              }],
              xAxes: [{
                gridLines: {
                  display: true
                }
              }]
            }
          }
        },
        accumulatedEquityGrowth: {
          datasets: [
            {
              borderColor: '#51cbce',
              backgroundColor: '#51cbce',
              data: [],
              pointRadius: 0,
              fill: false
            }
          ]
        },
        dailyEquityGrowth: {
          datasets: [
            {
              borderColor: '#51cbce',
              backgroundColor: '#51cbce',
              data: [],
              pointRadius: 0,
              fill: false
            }
          ]
        }
      },
      error: false,
      error_msg: null,
      inprogress: true,
      balChartInfo: {},
      eqChartInfo: {},
      balReportingStatus: null,
      eqReportingStatus: null,
      logo_login: null,
      wlBrokerId: "",
      inprogresstxt: this.$t('loadingtxt'),
      settings: null,
      isWlSession: false,
      fail_load_strategy: false,
    }
  },
  methods: {
    register() {
      if (this.settings) {
        localStorage.setItem('wlSettings', JSON.stringify(this.settings));
      }
      window.open("/register/" + this.form.afl_id + "/" + this.strategy_id);
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    loadedEqPlots(resp) {
      if (!resp.success) {
        this.inprogress = false;
        return;
      }

      let data = resp.data;
      let today = this.$moment('timezone', 'Europe/London', 'LLLL ss').format('YYYY-MM-DD');

      for (var i = 0; i < data.plot.length; i++) {
        let p = data.plot[i];
        let dtday = this.$moment(p.unix_dt * 1000).format("YYYY-MM-DD");
        // Exclude today's
        if (today !== dtday) {
          this.equityUsersChart.labels.push(p.unix_dt * 1000);
          this.equityUsersChart.accumulatedEquityGrowth.datasets[0].data.push(p.accumulatedEquityGrowth * 100);
          this.equityUsersChart.dailyEquityGrowth.datasets[0].data.push(p.dailyEquityGrowth * 100);
          this.equityUsersChart.effectiveDrawdown.data.push(p.effectiveDrawdown * 100);
          this.equityUsersChart.effectiveLeverageUsed.data.push(p.effectiveLeverageUsed);
          if (i > 0) {
            this.equityUsersChart.percentualVolatilityEndurance.data.push(p.percentualVolatilityEndurance * 100);
          }
        }
        //monthlyReturnBalance
        if (p.monthlyReturnEquity !== null) {
          let mo = this.$moment(p.unix_dt * 1000).format("MMM YY");
          //this.activeUsersChart.monthlyReturnBalance.labels.push(p.unix_dt * 1000);
          this.equityUsersChart.monthlyReturnEquity.labels.push(mo);
          const moReturnEq = p.monthlyReturnEquity * 100;
          let mainColor = '#51cbce';
          if (moReturnEq < 0) {
            mainColor = '#ef8157';
          }
          this.equityUsersChart.monthlyReturnEquity.datasets[0].data.push(moReturnEq);
          this.equityUsersChart.monthlyReturnEquity.datasets[0].borderColor.push(mainColor);
          this.equityUsersChart.monthlyReturnEquity.datasets[0].backgroundColor.push(mainColor);
          this.equityUsersChart.monthlyReturnEquity.datasets[0].hoverBorderColor.push(mainColor);
        }
      }
      // Modify Step Size
      if (this.equityUsersChart.labels.length > maxSteps) {
        let stepSize = parseInt(this.equityUsersChart.labels.length / maxSteps);
        this.equityUsersChart.options.scales.xAxes[0].time.stepSize = stepSize;
        this.equityUsersChart.effectiveLeverageUsed.options.scales.xAxes[0].time.stepSize = stepSize;
      }
      // Statistics
      this.equityUsersChart.stats = Object.assign({}, data.stats);
      // Chart Info
      this.eqChartInfo = {
        "lastupdate": this.$t('lastupdate') + ": " + data.lastupdate,
        "orders_fail": data.orders_fail,
        "symbol_price_fail": data.symbol_price_fail,
        "trade_history_fail": data.trade_history_fail
      };
      this.eqReportingStatus = this.$getChartFlagInfo(this.balChartInfo);
      // Store into Local Storage
      localStorage.setItem('hc_bal_strat_info_' + data.strat_id, JSON.stringify({
        activeUsersChart: Object.assign({}, this.activeUsersChart),
        balChartInfo: Object.assign({}, this.balChartInfo),
        balReportingStatus: this.balReportingStatus,
        equityUsersChart: Object.assign({}, this.equityUsersChart),
        eqChartInfo: Object.assign({}, this.eqChartInfo),
        eqReportingStatus: this.eqReportingStatus,
        lastupdated: this.$moment('timezone', 'Europe/London', 'LLLL ss').add(1, 'h').unix()
      }));
      // Show Data
      this.inprogress = false;
    },
    loadedPlot(resp) {
      if (!resp.success) {
        this.$loadPubEqStratChart(this.strategy_id)
          .then(this.loadedEqPlots, this.errorLoadEqPlots)
      } else {

        let data = resp.data;
        let today = this.$moment('timezone', 'Europe/London', 'LLLL ss').format('YYYY-MM-DD');

        for (let i = 0; i < data.plot.length; i++) {
          let p = data.plot[i];
          let dtday = this.$moment(p.unix_dt * 1000).format("YYYY-MM-DD");
          // Exclude today's
          if (today !== dtday) {
            this.activeUsersChart.labels.push(p.unix_dt * 1000);
            this.activeUsersChart.accumulatedBalance.datasets[0].data.push(p.accumulatedBalance * 100);
            this.activeUsersChart.dailyBalanceGrowth.datasets[0].data.push(p.dailyBalanceGrowth * 100);
            this.activeUsersChart.drawdown.data.push(p.drawdown * 100);
            this.activeUsersChart.effectiveLeverageUsed.data.push(p.effectiveLeverageUsed);
            if (i > 0) {
              this.activeUsersChart.percentualVolatilityEndurance.data.push(p.percentualVolatilityEndurance * 100);
            }
          }
          //monthlyReturnBalance
          if (p.monthlyReturnBalance !== null) {
            let mo = this.$moment(p.unix_dt * 1000).format("MMM YY");
            //this.activeUsersChart.monthlyReturnBalance.labels.push(p.unix_dt * 1000);
            this.activeUsersChart.monthlyReturnBalance.labels.push(mo);
            this.activeUsersChart.monthlyReturnBalance.datasets[0].data.push(p.monthlyReturnBalance * 100);
          }
        }
        // Modify Step Size
        if (this.activeUsersChart.labels.length > maxSteps) {
          let stepSize = parseInt(this.activeUsersChart.labels.length / maxSteps);
          this.activeUsersChart.options.scales.xAxes[0].time.stepSize = stepSize;
          this.activeUsersChart.effectiveLeverageUsed.options.scales.xAxes[0].time.stepSize = stepSize;
        }
        // Statistics
        this.activeUsersChart.stats = Object.assign({}, data.stats);
        // Chart Info
        this.balChartInfo = {
          "lastupdate": this.$t('lastupdate') + ": " + data.lastupdate,
          "orders_fail": data.orders_fail,
          "symbol_price_fail": data.symbol_price_fail,
          "trade_history_fail": data.trade_history_fail
        };
        this.balReportingStatus = this.$getChartFlagInfo(this.balChartInfo);

        // Get Equity Plot
        this.$loadPubEqStratChart(this.strategy_id)
          .then(this.loadedEqPlots, this.errorLoadEqPlots);
      }
    },
    errorLoadEqPlots(resp) {
      this.loadedEqPlots({
        "plot": [],
        "lastupdate": this.$t('notavailable'),
        "orders_fail": this.$t('notavailable'),
        "symbol_price_fail": this.$t('notavailable'),
        "trade_history_fail": this.$t('notavailable')
      });
    },
    loadStrategy(resp) {
      Vue.$sessionStorage.set('strat_id', resp.strategy.strat_id);
      Vue.$sessionStorage.set('afl_id', resp.strategy.affiliateID);
      this.form.afl_id = resp.strategy.affiliateID;
      this.strategy_id = resp.strategy.strat_id;

      this.strategy_name = resp.strategy.strat_name;
      this.strategy_desc = resp.strategy.strat_desc;

      this.strategy_price = (parseFloat(resp.strategy.price) * 100).toString() + "%";
      // Check local storage before loading strategy
      let now = this.$moment('timezone', 'Europe/London', 'LLLL ss').unix();
      let loadFromLocalStorage = false;
      let chartinfo = localStorage.getItem('hc_bal_strat_info_' + resp.strategy.strat_id);
      if (chartinfo) {
        chartinfo = JSON.parse(chartinfo);
        if (now <= chartinfo.lastupdated) {
          loadFromLocalStorage = true;
          this.activeUsersChart = Object.assign({}, chartinfo.activeUsersChart);
          this.activeUsersChart.options.scales.yAxes[0].ticks = {
            callback: function (value, index, values) {
              return value + '%';
            }
          };
          this.balChartInfo = Object.assign({}, chartinfo.balChartInfo);
          this.balReportingStatus = chartinfo.balReportingStatus;

          this.equityUsersChart = Object.assign({}, chartinfo.equityUsersChart);
          this.equityUsersChart.options.scales.yAxes[0].ticks = {
            callback: function (value, index, values) {
              return value + '%';
            }
          };
          this.eqChartInfo = Object.assign({}, chartinfo.eqChartInfo);
          this.eqReportingStatus = chartinfo.eqReportingStatus;
          // Show Data
          this.inprogress = false;
        }
      }
      // Load from API
      if (!loadFromLocalStorage) {
        const _err = (err) => {
          const cresp = {
            "plot": [],
            "lastupdate": this.$t('notavailable'),
            "orders_fail": this.$t('notavailable'),
            "symbol_price_fail": this.$t('notavailable'),
            "trade_history_fail": this.$t('notavailable'),
            "strat_id": resp.strategy.strat_id
          };
          this.loadedPlot(cresp);
        };
        // Get Account by SID
        this.$loadPubBalStratChart_v2({strat_id: resp.strategy.strat_id})
          .then(this.loadedPlot, _err);
      }
    },
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
    },
    closeAlert() {
      this.error = false;
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    },
    getError(fieldName) {
      fieldName = this.$t(fieldName);
      return this.errors.first(fieldName);
    },
    goLogin() {
      if (!this.$route.params.wl) {
        window.open("/login?strat_id="+this.strategy_id);
      } else {
        window.open("/" + this.form.wlId);
      }
    },
    failredirect() {
      this.fail_load_strategy = true;
      this.inprogress = false;
    }
  },
  beforeDestroy() {
    this.closeMenu()
  },
  mounted() {

    this.logo_login = this.logo;

    this.$getPublicStrategy(this.$route.params.surl)
        .then(this.loadStrategy, this.failredirect);

    const defaultbackground = () => {
      this.isWlSession = false;
      document.getElementsByTagName('body')[0].style.backgroundColor = '#D4FC8C'
    }

    let wl = this.$route.params.wl;
    if (!wl) {
      wl = window.location.hostname;
    }

    this.form.wlId = wl
    const success = resp => {
      if (!resp.success) {
        defaultbackground();
        return;
      }
      this.isWlSession = true;
      this.settings = resp.data;
      this.logo_login = this.settings.logo_login;

      const bodyEl = document.getElementsByTagName('body')[0];
      bodyEl.style.backgroundColor = this.settings.bgcolor;
      if (this.settings.bgimg_login) {
        bodyEl.style.backgroundImage = `url('${this.settings.bgimg_login}')`;
      }
      try {
        document.getElementById('favicon96').href = '/static/img/icons/' + settings.whitelabel.toLowerCase() + '.ico'
      } catch (s) {
      }
      try {
        document.getElementById('favicon32').href = '/static/img/icons/' + settings.whitelabel.toLowerCase() + '.ico'
      } catch (s) {
      }
      try {
        document.getElementById('favicon16').href = '/static/img/icons/' + settings.whitelabel.toLowerCase() + '.ico'
      } catch (s) {
      }
      try {
        document.getElementsByTagName('title')[0].innerHTML = settings.fullname;
      } catch (s) {
      }
    }

    this.$wlSettings(wl).then(success, defaultbackground);

    document.body.classList.remove('tab-space')
  }
}
</script>
<style scoped lang="scss">
.description {
  position: relative;
  top: 0.25rem;
}

.btn-wl-primary {
  //background-color: #fff;
}

.btn-wl-secondary {
  //background-color: #fff;
}

</style>
