export default {
  namespaced: true,
  state: {
    showRemoveAccFromStrategyModal: false,
    accounts: [],
    strategy: null,
  },
  getters: {

  },
  mutations: {
    ['SET_REMOVE_ACCOUNTS_FROM_STRATEGY_MODAL'](state, value) {
      state.showRemoveAccFromStrategyModal = value
    },
    ['SET_ACCOUNTS_AVAILABLE_TO_REMOVE'](state, value) {
      state.accounts = value
    },
    ['SET_STRATEGY_TO_REMOVE_ACCOUNTS'](state, value) {
      state.strategy = value
    }
  },
  actions: {
    openRemoveAccounstFromStrategyModal({commit}) {
      commit('SET_REMOVE_ACCOUNTS_FROM_STRATEGY_MODAL', true)
    },
    closeRemoveAccounstFromStrategyModal({commit}) {
      commit('SET_REMOVE_ACCOUNTS_FROM_STRATEGY_MODAL', false)
    },
    setAccountsAvailableToRemove({commit}, accounts) {
      commit('SET_ACCOUNTS_AVAILABLE_TO_REMOVE', accounts)
    },
    setStrategyToRemoveAccounts({commit}, id) {
      commit('SET_STRATEGY_TO_REMOVE_ACCOUNTS', id)
    },
    clean({commit, dispatch}) {
      dispatch('openRemoveAccounstFromStrategyModal')
      dispatch('closeRemoveAccounstFromStrategyModal')
    },
  }
}
