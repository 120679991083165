import {i18n} from 'src/assets/i18n-plugin.js'
export const AllocationOptions = {
  PROPORTIONAL: {
    value: {
      "allocationMethod": "balRatio",
      "balRatio": 1.00,
      "eqRatio": null,
      "lotMultiplier": null,
      "lotMoneyRatioX": null,
      "lotMoneyRatioY": null,
      "fixedLots": null,
    },
    label() {
      return i18n.t('param_allocation_proportional')
    },
    info() {
      return i18n.t('param_allocation_proportional_info')
    },
  },
  DOUBLE: {
    value: {
      "allocationMethod": "balRatio",
      "balRatio": 2.00,
      "eqRatio": null,
      "lotMultiplier": null,
      "lotMoneyRatioX": null,
      "lotMoneyRatioY": null,
      "fixedLots": null,
    },
    label() {
      return i18n.t('param_allocation_double')
    },
    info() {
      return i18n.t('param_allocation_double_info')
    },
  },
  HALF: {
    value: {
      "allocationMethod": "balRatio",
      "balRatio": 0.50,
      "eqRatio": null,
      "lotMultiplier": null,
      "lotMoneyRatioX": null,
      "lotMoneyRatioY": null,
      "fixedLots": null,
    },
    label() {
      return i18n.t('param_allocation_half')
    },
    info() {
      return i18n.t('param_allocation_half_info')
    },
  },
  SAME: {
    value: {
      "allocationMethod": "lotMultiplier",
      "balRatio": null,
      "eqRatio": null,
      "lotMultiplier": 1.00,
      "lotMoneyRatioX": null,
      "lotMoneyRatioY": null,
      "fixedLots": null,
    },
    label() {
      return i18n.t('param_allocation_same')
    },
    info() {
      return i18n.t('param_allocation_same_info')
    },
  },
  ADVANCED: {
    BAL_RATIO: {
      value: {
        "allocationMethod": "balRatio",
        "balRatio": null,
        "eqRatio": null,
        "lotMultiplier": null,
        "lotMoneyRatioX": null,
        "lotMoneyRatioY": null,
        "fixedLots": null,
      },
      default: 1.00,
      min: 0.01,
      max: 4.00,
      step: 0.01,
      precision: 2,
      getFilledObject(value) {
        return Object.assign({}, this.value, {balRatio: value})
      },
      label() {
        return i18n.t('param_allocation_advanced_balanceratio')
      },
      info() {
        return i18n.t('param_allocation_advanced_balanceratio_info')
      }
    },
    EQ_RATIO: {
      value: {
        "allocationMethod": "eqRatio",
        "balRatio": null,
        "eqRatio": null,
        "lotMultiplier": null,
        "lotMoneyRatioX": null,
        "lotMoneyRatioY": null,
        "fixedLots": null,
      },
      default: 1.00,
      min: 0.01,
      max: 4.00,
      step: 0.01,
      precision: 2,
      getFilledObject(value) {
        return Object.assign({}, this.value, {eqRatio: value})
      },
      label() {
        return i18n.t('param_allocation_advanced_equityratio')
      },
      info() {
        return i18n.t('param_allocation_advanced_equityratio_info')
      }
    },
    LOT_MULTIPLIER: {
      value: {
        "allocationMethod": "lotMultiplier",
        "balRatio": null,
        "eqRatio": null,
        "lotMultiplier": null,
        "lotMoneyRatioX": null,
        "lotMoneyRatioY": null,
        "fixedLots": null,
      },
      default: 1.00,
      min: 0.01,
      max: 400.00,
      step: 0.01,
      precision: 2,
      getFilledObject(value) {
        return Object.assign({}, this.value, {lotMultiplier: value})
      },
      label() {
        return i18n.t('param_allocation_advanced_multiplyer')
      },
      info() {
        return i18n.t('param_allocation_advanced_multiplyer_info')
      }
    },
    MONEY_RATIO: {
      value: {
        "allocationMethod": "lotMoneyRatio",
        "balRatio": null,
        "eqRatio": null,
        "lotMultiplier": null,
        "lotMoneyRatioX": 0.01,
        "lotMoneyRatioY": null,
        "fixedLots": null,
      },
      default: 1000,
      min: 250,
      max: 10000,
      step: 1,
      precision: 0,
      getFilledObject(value) {
        return Object.assign({}, this.value, {lotMoneyRatioY: value})
      },
      label() {
        return i18n.t('param_allocation_advanced_moneyratio')
      },
      info() {
        return i18n.t('param_allocation_advanced_moneyratio_info')
      }
    },
    FIXED: {
      value: {
        "allocationMethod": "fixedLots",
        "balRatio": null,
        "eqRatio": null,
        "lotMultiplier": null,
        "lotMoneyRatioX": null,
        "lotMoneyRatioY": null,
        "fixedLots": null,
      },
      default: 0.01,
      min: 0.01,
      max: 100.00,
      step: 0.01,
      precision: 2,
      getFilledObject(value) {
        return Object.assign({}, this.value, {fixedLots: value})
      },
      label() {
        return i18n.t('param_allocation_advanced_fixed')
      },
      info() {
        return i18n.t('param_allocation_advanced_fixed_info')
      }
    },
  }
}
