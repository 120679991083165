import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  DropdownAlt,
  FgInput,
  FgNumeric,
  FgPass,
  FgAutocomplete,
  Module,
  Radio,
  RemoteImg,
} from 'src/components/UIComponents';

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 * Ideally, only small components that are re-used many times across your application should be registered here.
 * For plugins and bigger components local registration is preferable because it will allow you to do code splitting easier :)
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component(Button.name, Button)
    Vue.component(Card.name, Card)
    Vue.component(Checkbox.name, Checkbox)
    Vue.component(Dropdown.name, Dropdown)
    Vue.component(DropdownAlt.name, DropdownAlt)
    Vue.component(FgInput.name, FgInput)
    Vue.component(FgNumeric.name, FgNumeric)
    Vue.component(FgPass.name, FgPass)
    Vue.component(FgAutocomplete.name, FgAutocomplete)
    Vue.component(Module.name, Module)
    Vue.component(Radio.name, Radio)
    Vue.component(RemoteImg.name, RemoteImg)
  }
}

export default GlobalComponents
