export const RiskReplicationOptions = {
  DISABLED: {
    value: {
      "maxNumberOpenPositions": 1000,
      "maxLotSize": 100.00,
      "minLotSize": 0.01,
      "maxAccountMarginUsed": 100,
    },
  },
  ADVANCED: {
    MAX_NUMBER_OPEN_POSITIONS:{
      default: 1000,
      min: 1,
      max: 1000,
      step: 1,
      precision: 0,
    },
    MIN_LOT_SIZE: {
      default: 0.01,
      min: 0.01,
      max: 10.00,
      step: 0.01,
      precision: 2,
    },
    MAX_LOT_SIZE: {
      default: 100.00,
      min: 0.01,
      max: 1000.00,
      step: 0.01,
      precision: 2,
    },
    MAX_ACCOUNT_MARGIN: {
      default: 100,
      min: 1,
      max: 100,
      step: 1,
      precision: 0,
    }
  },
}
