import {i18n} from 'src/assets/i18n-plugin.js'

export default {
  install: (Vue) => {

    Vue.prototype.$truncate = function(text, stop, clamp) {
      if (stop === undefined || stop === null) {
        stop = 6;
      }
      if (text === undefined || text === null) {
        text = "";
      }
      return text.slice(0, stop) + (stop < text.length ? clamp || ' ...' : '')
    };

    Vue.prototype.$setStrategyChartInstance = function(compClass, propsData) {
      let ComponentClass = Vue.extend(compClass);
      let instance = new ComponentClass({
          propsData: propsData
      });
      return instance;
    };

    Vue.prototype.$setStrategyStatsInstance = function(compClass, propsData) {
      let ComponentClassStat = Vue.extend(compClass);
      let instanceStat = new ComponentClassStat({
          propsData: propsData
      });
      instanceStat.$slots.notavailable = [ this.$t('notavailable') ];
      instanceStat.$slots.accumulatedBalance = [ this.$t('totalpercentualgain') ];
      instanceStat.$slots.accumulatedBalance_tt = this.$t('totalpercentualgain_tt');
      instanceStat.$slots.equityToFollow = [ this.$t('equityToFollow') ];
      instanceStat.$slots.equityToFollow_tt = this.$t('equityToFollow_tt');
      instanceStat.$slots.monthsSinceInception = [ this.$t('monthsSinceInception') ];
      instanceStat.$slots.monthsSinceInception_tt = this.$t('monthsSinceInception_alt_tt');
      instanceStat.$slots.maxEffectiveDrawdown = [ this.$t('maximumLoss') ];
      instanceStat.$slots.maxEffectiveDrawdown_tt = this.$t('maximumLoss_tt');
      instanceStat.$slots.maxEffectiveLeverageUsed = [ this.$t('maxLeverage') ];
      instanceStat.$slots.maxEffectiveLeverageUsed_tt = this.$t('maxLeverage_tt');
      instanceStat.$slots.riskRatio = [ this.$t('riskRatio') ];
      instanceStat.$slots.riskRatio_tt = this.$t('riskRatio_alt_tt');
      return instanceStat;
    };

    Vue.prototype.$getChartFlagInfo = function(info) {
      let out = "";
      if (info.orders_fail) {
        out += this.$t('orders_fail');
      }
      if (info.symbol_price_fail) {
        if (out.length > 0) {
          out += ";";
        }
        out += this.$t('symbol_price_fail');
      }
      if (info.trade_history_fail) {
        if (out.length > 0) {
          out += ";";
        }
        out += this.$t('trade_history_fail');
      }
      if (out.length === 0) {
        out = this.$t('updatedtxt');
      }
      return out;
    };

    Vue.prototype.$setTranslatingInProgress = function(){
        this.$root.$on("showTranslatingInProgress", () => {
        this.inprogresstxt = this.$t('translating') + '. '+ this.$t('pleasewait')
        this.inprogress = true;
      })
    };

    /***
     * Validate response.success and response.msg, shows toast if failed and return false or else return true
     * @param response - response to be validated
     * @returns {boolean} - true if success else false
     */
    Vue.prototype.$validateResponse = function(response) {
      if(!response.success) {
        if(response.msg) {
          Vue.$toast.warning(i18n.t(response.msg));
        } else {
          Vue.$toast.error(i18n.t('unkownerror'));
        }
        return false;
      }
      return true;
    }
  }
};
