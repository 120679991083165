<template>
  <div class="footer-background">
    <div class="container">
      <div class="footer-background pt-5">
        <div v-if="isWlSession" class="row">
          <div class="col-md-6">
            <a href="https:www.hokocloud.com" target="_blank">
              <img class="img" src="/static/img/hoko-logo-tiny.png" style="max-width: 110px; margin-right: 30px;">
            </a>

            <a :href="getPlayStoreURI" target="_blank">
              <img src="/static/img/play-store-icon.png" class="img" style="max-width: 150px;" />
            </a>
          </div>
          <div class="col-md-6 text-right icon-social-class">
            <a class="icon-social-class" href="https://t.me/HokoSupport" target="_blank">
              <i class="fa fa-telegram fa-2x"></i>
            </a>
            <a class="icon-social-class"
               href="https://api.whatsapp.com/send/?phone=35796306373&text=Hi+HokoCloud&app_absent=0" target="_blank">
              <i class="fa fa-whatsapp fa-2x"></i>
            </a>
            <a class="icon-social-class" href="https://www.facebook.com/hokocloud/" target="_blank">
              <i class="fa fa-facebook fa-2x"></i>
            </a>
            <a class="icon-social-class" href="https://www.instagram.com/hokocloud.official" target="_blank">
              <i class="fa fa-instagram fa-2x"></i>
            </a>
            <a class="icon-social-class" href="https://twitter.com/hokocloud" target="_blank">
              <i class="fa fa-twitter fa-2x"></i>
            </a>
            <a class="icon-social-class" href="https://www.linkedin.com/company/hokocloud/" target="_blank">
              <i class="fa fa-linkedin fa-2x"></i>
            </a>
          </div>
        </div>
        <div v-if="isWlSession" class="row section-mid">
          <div class="col-md-6">
            <a href="https://hokocloud.com/about/" target="_blank">
              <span class="span-disclaimer"> About </span>
            </a>
            <a href="https://hokocloud.com/traders/" target="_blank">
              <span class="span-disclaimer"> Traders </span>
            </a>
            <a href="https://hokocloud.com/brokers/" target="_blank">
              <span class="span-disclaimer"> Brokers </span>
            </a>
            <a href="https://hokocloud.com/partners/" target="_blank">
              <span class="span-disclaimer"> Partners </span>
            </a>
            <a href="https://hokocloud.com/contact/" target="_blank">
              <span class="span-disclaimer"> Contact </span>
            </a>
          </div>
          <div class="col-md-6 text-right">
            <a href="https://hokocloud.com/privacy/" target="_blank">
              <span class="span-disclaimer-right"> Privacy </span>
            </a>
            <a href="https://hokocloud.com/terms/" target="_blank">
              <span class="span-disclaimer-right"> Terms </span>
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p class="footer-disclaimer">Trading CFDs involves substantial risk and there is always the potential for
              loss. Past performance is not indicative of future results.
            </p>
            <p class="footer-disclaimer">Following a strategy does not result in perfectly proportional results for
              followers, as followers might
              decide not to take all of the trading signals provided by the Traders or not being able to trade the
              recommended number of contracts due to various factors such as account sizes, spreads, swaps, different
              pricing between brokers, replication parameters, among others.
            </p>
            <p class="footer-disclaimer">This website does not make any representation whatsoever that trading systems
              might be or are suitable or
              that they would be profitable for you. Please realize the risk involved with trading CFD investments and
              consult an investment professional before proceeding. The trading systems herein described have been
              developed for sophisticated traders who fully understand the nature and the scope of the risks that are
              associated with trading. Should you decide to trade on any or all of these systems’ signals, it is your
              decision.
            </p>
            <p class="footer-disclaimer text-center mt-5">
              © HokoCloud {{ year }} – A brand of Caprico Ltd. <small>v {{ appVersion }} </small>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Constants from "@/assets/constants";
const rules = Constants.methods.getRules();

export default {
  name: "login-footer-disclaimer",
  computed: {
    appVersion() {
      return this.$store.getters.appVersion
    },
    isWlSession() {
      return rules.isWLSession();
    },
    getPlayStoreURI() {
      return Constants.methods.playStoreURI()
    },
  },
  data() {
    return {
      year: this.$moment().format('YYYY'),
    }
  }
}
</script>

<style scoped lang="scss">
.footer-background {
  background-color: #373435;

  .col-md-6 {
    @media screen and (max-width: 542px) {
      margin: 10px;
      width: 100%;
      text-align: center !important;
    }
  }

  .container {
    max-width: 1351px;
    padding-right: 0px;
    padding-left: 0px;
  }
}

.section-mid {
  margin: 20px 0 40px 0px;
  border-style: solid;
  border-width: 1px 0px 1px 0px;
  border-color: #4C4849;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 30px 0px 30px 0px;

  @media screen and (max-width: 542px) {
    margin: 20px 0 20px 0px;
  }

  .col-md-6 {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.footer-disclaimer {
  color: #FEFEFE;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;
  text-align: justify;
  @media screen and (max-width: 542px) {
    font-family: sans-serif;
    margin: 10px;
    font-size: 13px;
    line-height: 18px;
  }
}

.icon-social-class {
  .fa-2x {
    padding: 5px;
    @media screen and (max-width: 542px) {
      padding: 15px;
    }
  }

  color: var(--btnColor2);
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  color: var(--btnColor2);
}

li {
  float: left;
  margin-left: 8px;
  margin-right: 8px;
}

.span-disclaimer {
  font-family: "Poppins", Sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 16px;
  color: var(--btnColor2);
  margin-left: 0px;
  margin-right: 10px;

}

.span-disclaimer-right {
  font-family: "Poppins", Sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 16px;
  color: var(--btnColor2);
  margin-left: 10px;
  margin-right: 0px;
}

</style>
