<template>
  <div>
    <div class="row mb-15 mt-1" v-if="isAdmin">
      <div class="col-md-1">
        &nbsp;
      </div>
      <div class="col-md-7 col-xs-7">
        <fg-input
          :placeholder="`${$t('userid')}`"
          type="email"
          name="Email"
          v-on:keyup="searchUserForWalletKeyUp"
          v-model="searchUser">
        </fg-input>
      </div>
      <div class="col-md-2 col-xs-2">
        <button class="btn btn-xs btn-primary fixer" v-on:click="searchUserForWallet">
          <i class="fa fa-search"></i>
          {{ $t('searchtxt') }}
        </button>
      </div>
    </div>
    <div class="row" v-if="isAdmin">
      <div class="col-md-1">
        &nbsp;
      </div>
      <div class="col-md-10 col-xs-10">
        <div class="alert alert-info">
           <b>
              <i class="el-icon-user"></i>
              {{ $t('walletdetailsfor') }} {{ fullName }}
           </b>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1">
        &nbsp;
      </div>
      <div class="col-md-10">
        <div class="text-center">
          <h5 class="text-center">{{ $t('userwalletinfo') }}</h5>
          <table class="table">
            <tr>
              <td class="text-left">
                <b>{{ $t('balance') }}</b>
              </td>
              <td class="text-right">
                <b :class="parseFloat(balance).toFixed(2) > 0 ? 'text-success' : 'text-danger'">
                  $ {{ parseFloat(balance).toFixed(2) }}
                </b>
              </td>
            </tr>
          </table>
          <table class="table">
            <tr>
              <td class="text-left">
                <b>{{ $t('pendings') }}</b>
              </td>
              <td class="text-right">
                <b class="text-danger">
                  $ {{ parseFloat(pendings).toFixed(2) }}
                </b>
              </td>
            </tr>
          </table>
          <table class="table">
            <tr>
              <td class="text-left">
                <b>{{ $t('walletFinal') }}</b>
              </td>
              <td class="text-right">
                <b :class="parseFloat(walletFinal).toFixed(2) > 0 ? 'text-success' : 'text-danger'">
                  $ {{ parseFloat(walletFinal).toFixed(2) }}
                </b>
              </td>
            </tr>
          </table>
          <table class="table">
            <tr>
              <td class="text-left">
                <b>{{ $t('dailycost') }}</b>
              </td>
              <td class="text-right">
                <b class="text-info">
                  $ {{ parseFloat(daily_cost).toFixed(2) }}
                </b>
              </td>
            </tr>
          </table>
          <table class="table">
            <tr>
              <td class="text-left">
                <b>{{ $t('daysofservices') }}</b>
              </td>
              <td class="text-right">
                <b class="text-info">
                  <span>{{ parseFloat(daysOfService).toFixed(0) }}</span>
                </b>
              </td>
            </tr>
          </table>
          <table class="table">
            <tr>
              <td class="text-left">
                <b>
                  {{ $t('suggesteddeposit') }}
                  <span class="text-info"><i class="fa fa-info-circle"></i></span>
                </b>
              </td>
              <td class="text-right">
                <b class="text-info">
                  <span v-if="suggestedDeposit > 0">$ {{suggestedDeposit}}</span>
                  <span v-else >$ 0</span>
                </b>
              </td>
            </tr>
          </table>
          <small>
            <span class="text-info"><i class="fa fa-info-circle"></i></span>
            {{ $t('forafullmonthofservices') }}
          </small>
        </div>
      </div>
    </div>
    <div class="row mt-50">
      <div class="col-md-1">
        &nbsp;
      </div>
      <div class="col-md-10 ">
        <div class="text-center">
          <h5 class="text-center">{{ $t('userproductsandservices') }}</h5>
        </div>
        <div class="text-center font-small" :class="isAdmin ? 'scroll-2' : 'scroll-1'">
          <table v-if="!loading" class="table">
            <tr v-if="!showProducts">
              <td class="text-center" colspan="1">
                <i class="fa fa-info-circle"></i>
                {{ $t('noproductsorservices') }}
              </td>
            </tr>
            <tr v-if="showProducts" v-for="product in products">
              <td class="text-left">
                <span v-if="product.product == 'VPS'">
                  {{ $t('vps') }}: {{ product.label }}
                </span>
                <span v-if="product.product == 'VPS-MONITOR'">
                  {{ $t('vpsmonitor') }}: {{ product.label }}
                </span>
                <span v-if="product.product == 'WLVPS-CLIENT' || product.product == 'WLVPS-PROVIDER'">
                  {{ $t('sponsoredvps') }}: {{ product.label }}
                </span>
                 <span v-if="product.product == 'MASTER'">
                  {{ $t('strategytxt') }}: {{ product.label }}
                </span>
                <span v-if="['FOLLOWER-BILLED', 'FOLLOWER-DEMO', 'FOLLOWER-SPONSORED'].includes(product.product)">
                  {{ $t('copytradingproduct') }}: {{ product.label }}
                </span>
                <span v-if="product.product == 'FOLLOWER-SPONSOR'">
                  {{ $t('sponsoredcopytradingproduct') }}: {{ product.label }}
                </span>
              </td>
              <td class="text-right text-success" v-if="product.daily_cost > 0 || product.product == 'MASTER'">
                <b>{{ product.daily_cost }} (HKO)</b>
              </td>
              <td class="text-right text-warning" v-else-if="product.daily_cost == 0">
                <b>{{ $t('sponsoredtxt') }}</b>
              </td>
            </tr>
          </table >

          <i v-if="loading" class="fa fa-cog fa-spin fa-fw fa-loading"></i>

        </div>
      </div>
    </div>

  </div>
</template>

<script>

const uinfo = localStorage.getItem('user-info');
const user_data = uinfo ? JSON.parse(uinfo) : {};
const rules = Constants.methods.getRules()
import Constants from "src/assets/constants";

export default {
  name: "BillingStatus",
  props: {
    update: {
      type: Boolean,
    },
  },
  computed: {
    showProducts() {
      return !!this.products.length
    }
  },
  data() {
    return {
      loading: true,
      balance: 0,
      daily_cost: 0,
      pendings: 0,
      walletFinal: 0,
      daysOfService: 0,
      suggestedDeposit: 0,
      fullName: null,
      products: [],
      isAdmin: rules.isAdmin,
      searchUser: null,
      userReference: null,
    }
  },
  methods: {
    getUserEmail() {
      if (this.isAdmin && !!this.userReference) {
        return this.userReference
      }
      return user_data.email
    },
    searchUserForWalletKeyUp(e) {
      if (e.keyCode == 13) {
        this.searchUserForWallet();
      }
    },
    searchUserForWallet() {
      this.$getWalletCredit(this.searchUser)
        .then(this.handleFindUser, this.handleFindUser)
    },
    handleFindUser(resp) {
      if (resp && !resp.success) {
        this.userReference = null;
        this.$toast.warning('User not found')
      } else {
        this.userReference = this.searchUser;
      }
      this.successWalletHandler(resp)
      this.updateProducts();
    },
    updateWallet() {
      this.$getWalletCredit(this.getUserEmail())
        .then(this.successWalletHandler, this.errorWalletHandler)
    },
    successWalletHandler(resp) {
      if (!resp.success) {
        this.errorHandler(resp)
        return;
      }
      this.balance = resp.data.balance;
      this.daily_cost = resp.data.dailyCharge;
      this.pendings = resp.data.pendings;
      this.walletFinal = resp.data.walletFinal;
      this.daysOfService = resp.data.daysOfService;
      this.suggestedDeposit  = resp.data.suggestedDeposit;
      this.fullName  = resp.data.fullName;
    },
    errorWalletHandler(resp) {
    },
    updateProducts() {
      this.$getUserProducts(this.getUserEmail())
        .then(this.successProductHandler, this.errorProductHandler)
    },
    successProductHandler(resp) {
      if (!resp.success) {
        this.errorHandler(resp)
        return;
      }
      this.products = resp.data;
      this.loading = false;
    },
    errorProductHandler(resp) {
      this.products = [];
      this.loading = false;
    },
  },
  mounted() {
    this.updateProducts();
    this.updateWallet();
  },
  watch: {
    'update': function (value) {
      if (value) {
        this.updateProducts();
        this.updateWallet();
      }
    }
  }
}
</script>

<style scoped>
.table {
  margin-bottom: 0.05rem;
}

.mt-50 {
  margin-top: 50px;
}
.mb-15 {
  margin-top: -20px;
  margin-bottom: 15px;
}
.scroll-1 {
  overflow-y: auto;
  max-height: 400px;
}
.scroll-2 {
  overflow-y: auto;
  max-height: 300px;
}
.font-small {
  font-size: 12px;
}
.fa-loading {
  font-size: 20px;
  color: #51bcda;
}
.fixer {
  margin-top: 1px;
  margin-left: -20px;
}
</style>
