<template>
  <footer>
    <div class="row">
      <div class="col-md-12 copyright text-justify mb-4" :style="`color: ${fontColor} !important; font-size: smaller;`">
        {{ disclaimerContent }}
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Disclaimer",
  props: {
    disclaimerContent: {
      type: String,
    },
    fontColor: {
      stype: String,
      default: 'white'
    }
  }
}
</script>

<style scoped>

</style>
