<template>
    <date-picker
      v-on="$listeners"
      v-bind="$attrs"
      :lang="lang"
      :name="fieldName"
      format="YYYY-MM-DD"
      input-class="mx-input"
      popup-class="el-date-editor .el-input__inner"
      :value-type="dateFormat">
    </date-picker>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: "DatePicker.vue",
  components: {
    DatePicker,
  },
  props: {
    fieldName: {
      type: String,
      description: 'Name field for v-validations',
    },
    dateFormat: {
      type: String,
      description: 'Date format',
      default: "YYYY-MM-DD"
    }
  },
  data() {
    return {
      lang: {
        formatLocale: {
          monthsShort: [
            this.$t('JanShort'),
            this.$t('FevShort'),
            this.$t('MarShort'),
            this.$t('AprShort'),
            this.$t('MayShort'),
            this.$t('JunShort'),
            this.$t('JulShort'),
            this.$t('AugShort'),
            this.$t('SepShort'),
            this.$t('OctShort'),
            this.$t('NovShort'),
            this.$t('DecShort'),
          ],
          weekdaysMin: [
            this.$t('SundayShort'),
            this.$t('MondayShort'),
            this.$t('TuesdayShort'),
            this.$t('WednesdayShort'),
            this.$t('ThursdayShort'),
            this.$t('FridayShort'),
            this.$t('SaturdayShort'),
          ],
        }
      }
    }
  }
}

</script>

<style scoped lang="scss">
.mx-datepicker.disabled {
  display: inline-block !important;

}

</style>
