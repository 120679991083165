import moment from "moment";

export function getDefaultPassedDate() {
  const currentDate = new Date();
  return moment(currentDate)
    .set({
      year: currentDate.getFullYear()-3,
      month: 0,
      date: 1,
      hour: 0,
      minute: 1
    }).format("YYYY.MM.DD HH:mm")
}

export function getZeroNow() {
  const currentDate = new Date();
  return moment(currentDate).set({
      year: currentDate.getFullYear(),
      month: currentDate.getMonth(),
      date: currentDate.getDate(),
      hour: 0,
      minute: 1
    }).format("YYYY.MM.DD HH:mm")
}

export function getZeroTimeDateFromDate(date) {
  return date.setHours(0,0,0,0);
}
