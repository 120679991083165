<template>
  <module
    ref="module"
    id="changelogs-new-server"
    titleIcon="fa fa-server"
    :title="$t('servertxt')"
    :filters.sync="filters"
    api-url="ACCAPIURL_V4"
    url="/changelog/newServers"
  >
    <template slot="table-columns">
      <el-table-column prop="broker" :label="$t('broker')">
        <template slot-scope="{ row }">
          <span>{{ row.broker }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="server" :label="$t('servertxt')">
        <template slot-scope="{ row }">
          <span>{{ row.server }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="server" :label="$t('status')">
        <template slot-scope="{ row }">
          <span> Server.ini is missing </span>
        </template>
      </el-table-column>
    </template>
    <template slot="filter-field">
      <fg-input v-model="filters.broker" :label="$t('broker')"></fg-input>
      <fg-input v-model="filters.server" :label="$t('servertxt')"></fg-input>
    </template>
  </module>
</template>

<script>
export default {
  name: "NewServerIni",
  components: {},
  data() {
    return {
      filters: {},
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped></style>
