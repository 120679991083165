<template>
  <div v-if="loading" style="background-color: whitesmoke">
    <p-progress
      :value="100"
      type="default"
      class="progress-bar-animated">
    </p-progress>
  </div>
  <div v-else class="login-page" :style.sync="bgColorReg">
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <div class="content">
          <div class="container">
            <div class="col-lg-6 col-md-8 col-sm-12 col-12 mx-auto">
              <info-section direction="vertical" class="mt-5">
                <div class="circles d-block">
                  <p class="logo mx-auto d-block">
                    <RemoteImg :src="logoLogin" size="250px"></RemoteImg>
                  </p>
                  <span class="circle big"></span>
                  <span class="circle med"></span>
                  <span class="circle small"></span>
                </div>
                <h1 class="text-white"><i class="fa fa-warning"></i> {{ $t('not_found') }}</h1>
                <small class="text-white">
                  {{ $t('not_found_info1') }}
                  <br>
                  <br>
                  {{ $t('not_found_info2') }}
                </small>
                <br>
                <a
                  class="btn btn-round btn-rebrand-primary btn-lg btn-round btn-block my-5"
                  href="/"
                >
                  {{ $t('not_found_confirm') }}
                </a>
              </info-section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Card, Checkbox, Button, InfoSection, Progress} from 'src/components/UIComponents';
import LoginFooterDisclaimer from "@/components/Dashboard/Views/Pages/login-footer-disclaimer";

export default {
  components: {
    Card,
    LoginFooterDisclaimer,
    InfoSection,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
    [Progress.name]: Progress
  },
  data() {
    return {
      color: " var(--btnColor2)",
      image: "",
      settings: undefined,
      loading: true,
    }
  },
  computed: {
    bgColorReg() {
      return `background-color: ${this.color}; ${this.image}`;
    },
    logoLogin() {
      return this.settings ? this.settings.logo_login : '';
    },
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    },
    configurePageBySettings() {
      if (this.settings && this.settings.bgcolor) {
        this.color = this.settings.bgcolor;
      }
      if (this.settings && this.settings.bgimg_login) {
        this.image = `url('${this.settings.bgimg_login}'); background-repeat:no-repeat; background-position: center;`;
      } else {
        this.image = "";
      }
      const bodyEl = document.getElementsByTagName('body')[0];
      bodyEl.style.backgroundColor = this.color
      if (this.settings && this.settings.bgimg_login) {
        bodyEl.style.backgroundImage = `url('${this.settings.bgimg_login}')`;
      }
      try {
        document.getElementById('favicon96').href = '/static/img/icons/' + settings.whitelabel.toLowerCase() + '.ico'
      } catch (s) {
      }
      try {
        document.getElementById('favicon32').href = '/static/img/icons/' + settings.whitelabel.toLowerCase() + '.ico'
      } catch (s) {
      }
      try {
        document.getElementById('favicon16').href = '/static/img/icons/' + settings.whitelabel.toLowerCase() + '.ico'
      } catch (s) {
      }
      try {
        document.getElementsByTagName('title')[0].innerHTML = settings.fullname;
      } catch (s) {
      }

      this.loading = false;
    }
  },
  mounted() {
    this.settings = JSON.parse(localStorage.getItem('wlSettings'));
    if (!this.settings) {
      let wl = this.$route.params.wl;
      if (!wl) {
        wl = window.location.hostname;
      }
      const success = resp => {
        this.settings = resp.data;
        this.configurePageBySettings()
      }
      const fail = resp => {
        this.loading = false;
      }
      this.$wlSettings(wl).then(success, fail);
    } else {
      this.configurePageBySettings()
    }
  },
  beforeDestroy() {
    this.closeMenu()
  },
}
</script>

<style scoped lang="scss">
@import './src/assets/sass/paper/variables';

.login-page::v-deep {
  text-align: center;

  h1 {
    color: var(--btnColor2);
    -webkit-filter: grayscale(100%) invert(95%);
    filter: grayscale(100%) invert(95%);
    margin-top: 2rem;
  }

  small {
    color: var(--btnColor2);
    -webkit-filter: grayscale(100%) invert(95%);
    filter: grayscale(100%) invert(95%);
    font-size: small;
  }

  .logo {
    position: relative;
    z-index: 100;
    margin-top: 8rem;

    img {
      z-index: 150;
    }
  }

  .circles:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100px;
    position: absolute;
    top: -100px;
    left: 0;
    transform: skewY(-4deg);
    -webkit-transform: skewY(-4deg);
  }

  .circles {
    position: relative;
    text-align: center;
    margin-top: -150px;
  }

  .circles .circle.small {
    z-index: 1;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: var(--btnColor2);
    -webkit-filter: grayscale(100%) invert(95%);
    filter: grayscale(100%) invert(95%);
    position: absolute;
    top: 0;
    left: 50%;
    animation: 6s smallmove infinite cubic-bezier(1, .22, .71, .98);
    -webkit-animation: 6s smallmove infinite cubic-bezier(1, .22, .71, .98);
    animation-delay: 1.1s;
    -webkit-animation-delay: 1.2s;
  }

  .circles .circle.med {
    z-index: 1;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background: var(--btnColor2);
    -webkit-filter: grayscale(100%) invert(95%);
    filter: grayscale(100%) invert(95%);
    position: absolute;
    top: 0;
    left: 10%;
    animation: 7s medmove infinite cubic-bezier(.32, .04, .15, .75);
    -webkit-animation: 7s medmove infinite cubic-bezier(.32, .04, .15, .75);
    animation-delay: 0.2s;
    -webkit-animation-delay: 0.4s;
  }

  .circles .circle.big {
    z-index: 1;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background: var(--btnColor2);
    -webkit-filter: grayscale(100%) invert(95%);
    filter: grayscale(100%) invert(95%);
    position: absolute;
    top: 0;
    right: 10%;
    animation: 8s bigmove infinite;
    -webkit-animation: 8s bigmove infinite;
    animation-delay: 3s;
    -webkit-animation-delay: 1s;
  }

  @-webkit-keyframes smallmove {
    0% {
      top: -12px;
      left: 45%;
      opacity: 0.1;
    }
    25% {
      top: 55px;
      left: 40%;
      opacity: 0.2;
    }
    50% {
      top: 18px;
      left: 50%;
      opacity: 0.3;
    }
    75% {
      top: -16px;
      left: 40%;
      opacity: 0.2;
    }
    100% {
      top: -12px;
      left: 45%;
      opacity: 0.1;
    }
  }
  @keyframes smallmove {
    0% {
      top: -12px;
      left: 45%;
      opacity: 0.1;
    }
    25% {
      top: 55px;
      left: 40%;
      opacity: 0.2;
    }
    50% {
      top: 18px;
      left: 50%;
      opacity: 0.3;
    }
    75% {
      top: -16px;
      left: 40%;
      opacity: 0.2;
    }
    100% {
      top: -12px;
      left: 45%;
      opacity: 0.1;
    }
  }
  @-webkit-keyframes medmove {
    0% {
      top: -12px;
      left: 20%;
      opacity: 0.15;
    }
    25% {
      top: 48px;
      left: 60%;
      opacity: 0.2;
    }
    50% {
      top: 20px;
      left: 50%;
      opacity: 0.4;
    }
    75% {
      top: -18px;
      left: 40%;
      opacity: 0.3;
    }
    100% {
      top: -12px;
      left: 20%;
      opacity: 0.15;
    }
  }
  @keyframes medmove {
    0% {
      top: -12px;
      left: 20%;
      opacity: 0.15;
    }
    25% {
      top: 48px;
      left: 60%;
      opacity: 0.2;
    }
    50% {
      top: 20px;
      left: 50%;
      opacity: 0.4;
    }
    75% {
      top: -18px;
      left: 40%;
      opacity: 0.3;
    }
    100% {
      top: -12px;
      left: 20%;
      opacity: 0.15;
    }
  }
  @-webkit-keyframes bigmove {
    0% {
      top: -10px;
      right: 20%;
      opacity: 0.2;
    }
    25% {
      top: 32px;
      right: 35%;
      opacity: 0.3;
    }
    50% {
      top: 15px;
      right: 50%;
      opacity: 0.5;
    }
    75% {
      top: -1px;
      right: 35%;
      opacity: 0.4;
    }
    100% {
      top: -10px;
      right: 20%;
      opacity: 0.2;
    }
  }
  @keyframes bigmove {
    0% {
      top: -10px;
      right: 20%;
      opacity: 0.2;
    }
    25% {
      top: 32px;
      right: 35%;
      opacity: 0.3;
    }
    50% {
      top: 15px;
      right: 50%;
      opacity: 0.5;
    }
    75% {
      top: -1px;
      right: 35%;
      opacity: 0.4;
    }
    100% {
      top: -10px;
      right: 20%;
      opacity: 0.2;
    }
  }
}
</style>
