<template>
  <div class="row">
    <div class="col-md-12 text-center">
      <div class="w-100 text-center" :style="boxStyle">
        <img :class="imgClass" v-if="ready" :src="finalScr" :alt="alt" :style="finalSize"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RemoteImg',
  props: {
    src: {
      type: [String],
      description: 'Path to the image on image service',
      default: '',
    },
    alt: {
      type: String,
      description: 'Alt for img field',
      default: '',
    },
    baseUrl: {
      type: String,
      description: 'Base url from remote image service',
      default: 'https://hoko.ams3.digitaloceanspaces.com/',
    },
    boxStyle: {
      type: String,
      description: 'Inline style for image box',
      default: '',
    },
    size: {
      type: String,
      description: 'Max width do be passed to the image',
      default: '100%'
    },
    ready: {
      type: Boolean,
      description: 'Logo is ready to show',
      default: true
    },
    isLogin: {
      type: Boolean,
      description: 'Logo is for login screen',
      default: false
    },
  },
  computed: {
    finalScr: {
      get() {
        const name = this.src ? this.src : '/static/img/hoko-new-logo.png';
        return name.includes('static') ? name : `${this.baseUrl}${name}?a=${new Date().getTime()}`;
      },
    },
    finalSize: {
      get() {
        return `max-width: ${this.size};`;
      }
    },
    imgClass: {
      get() {
        return `remoteImg ${this.isLogin ? 'login' : ''}`;
      }
    },
  },
  data() {
    return {
    };
  },
  methods: {

  },
}
</script>

<style scoped lang="scss">
.remoteImg.login {
  max-height: 65px;
  line-height: 2rem;
  vertical-align: middle;
}
</style>
