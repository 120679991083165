import {getDefaultPassedDate} from "@/util/DateUtils";

export default {
  namespaced: true,
  state: {
    showParameterPreview: false,
    preview_settings: {
      copyOpenTrades: false,
      copyTradesSince: getDefaultPassedDate(),
      blockSignalsWithPriceDeviation: false,
      maxPip: 5.00,
      maxNumberOpenPositions: 1000,
      minLotSize: 0.01,
      maxLotSize: 100.00,
      maxAccountMarginUsed: 100,
      noNewTradesDrawdownAbove: 100,
      assetsPairsToReceiveSignals: 'all',
      allButTheseAssetsPairsReceiveSignal: null,
      onlyTheseAssetsPairsReceiveSignal: null,
      presets: [],
    },
  },
  mutations:  {
    ['SET_MODAL_PARAMETER_PREVIEW_STATE'](state, value) {
      state.showParameterPreview = value
    },
    ['SET_CURRENT_SETTINGS'](state, value) {
      state.preview_settings = {...state.settings, ...value}
    }
  },
  actions: {
    openParameterPreviewModal({commit}) {
      commit('SET_MODAL_PARAMETER_PREVIEW_STATE', true)
    },
    closeParameterPreviewModal({commit}) {
      commit('SET_MODAL_PARAMETER_PREVIEW_STATE', false)
    },
    setCurrentSettings({commit}, value) {
      commit('SET_CURRENT_SETTINGS', value)
    },
    refreshInfo({state, dispatch}, value) {
      dispatch('setCurrentSettings', value['account_settings'] || {})
    }
  }
}
