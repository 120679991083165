<template>
  <navbar :show-navbar="showNavbar">
    <ul class="navbar-nav navbar-left">
      <li class="nav-item">
        <button class="btn btn-link" @click="toggleSidebar">
          <div class="hamburger"></div>
          <div class="hamburger"></div>
          <div class="hamburger"></div>
        </button>
      </li>
    </ul>



    <ul class="navbar-nav navbar-right custom-float-right">

      <li class="nav-item">
        <button class="btn btn-balance btn-round" v-on:click="drawer = true">
          $ {{ balance }}
          <i class="fa fa-info-circle"></i>
        </button>
      </li>
      <li class="nav-item pal1">
        <img :src="`/static/img/flags/${getLocale()}.png`" alt="flag">
      </li>
      <li class="nav-item pal2">
        <drop-down
          tag="li"
          position="right"
          direction="none"
          class="nav-item btn-rotate dropdown">
          <a class="dropdown-item" @click.prevent="setLocale('en')" href="#">
            <img src="\static\img\flags\GB.png" /> English
          </a>
          <a class="dropdown-item" @click.prevent="setLocale('ar')" href="#">
            <img src="\static\img\flags\AE.png" /> Arabic
          </a>
          <a class="dropdown-item" @click.prevent="setLocale('es')" href="#">
            <img src="\static\img\flags\ES.png" /> Spanish
          </a>
          <a class="dropdown-item" @click.prevent="setLocale('pt')" href="#">
            <img src="\static\img\flags\PT.png" /> Portuguese
          </a>
          <a class="dropdown-item" @click.prevent="setLocale('fr')" href="#">
            <img src="\static\img\flags\FR.png" /> French
          </a>
           <a class="dropdown-item" @click.prevent="setLocale('zh')" href="#">
            <img src="\static\img\flags\ZH.png" /> Chinesse
          </a>
          <a class="dropdown-item" @click.prevent="setLocale('zhs')" href="#">
            <img src="\static\img\flags\ZH.png" /> Chinesse (simplified)
          </a>
           <a class="dropdown-item" @click.prevent="setLocale('de')" href="#">
            <img src="\static\img\flags\DE.png" /> Germany
          </a>
          <a class="dropdown-item" @click.prevent="setLocale('it')" href="#">
            <img src="\static\img\flags\IT.png" /> Italian
          </a>
          <a class="dropdown-item" @click.prevent="setLocale('ro')" href="#">
            <img src="\static\img\flags\RO.png" /> Romanian
          </a>
          <a class="dropdown-item" @click.prevent="setLocale('vi')" href="#">
            <img src="\static\img\flags\VI.png" /> Vietnam
          </a>
          <a class="dropdown-item" @click.prevent="setLocale('ru')" href="#">
            <img src="\static\img\flags\RU.png" /> Russian
          </a>
        </drop-down>
      </li>
      <li>
        <button class="btn btn-info btn-icon btn-link" v-on:click="goTelegram()" >
          <i class="fa fa-telegram telegram"></i>
        </button>
        <button class="btn btn-danger btn-icon btn-link" v-on:click="logout">
          <i class="fa fa-sign-out"></i>
        </button>
      </li>
    </ul>


    <Drawer
      ref="drawer"
      :size="getDrawerSize"
      :title="`${$t('billingcomponents')}`"
      :withHeader="true"
      :wrapperClosable="true"
      :visible.sync="drawer">
      <BillingStatus :update="drawer"></BillingStatus>
    </Drawer>
    <NavbarNotification></NavbarNotification>
  </navbar>
</template>

<script>
import { Navbar } from 'src/components/UIComponents'
import VueCookies from 'vue-cookies'
import {Drawer} from 'element-ui'
import BillingStatus from "./BillingStatus";
import swal from "sweetalert2";
import NavbarNotification from "@/components/Dashboard/Layout/navbar-notification.vue";

const uinfo = localStorage.getItem('user-info');
const lang = localStorage.getItem('user-lang')
const user_data = uinfo ? JSON.parse(uinfo) : {};
const timercountdown = 15000;

export default {
  name: "NewNavbar",
  components: {
    NavbarNotification,
    Navbar,
    Drawer,
    BillingStatus,
  },
  computed: {
    getDrawerSize() {
      if (this.windowSize >= 1000) {
        return '25%'
      } else if (this.windowSize > 600 && this.windowSize < 1000) {
        return '40%'
      } else {
        return '100%'
      }
    }
  },
  data() {
    return {
      user: user_data,
      showNavbar: true,
      drawer: false,
      windowSize: window.innerWidth,
      balance: '0.0',
      balanceFloat: 0.0,
      locale: JSON.parse(uinfo)['user_metadata']['lang'] || 'en',
      first_load: true,
      pending: 0.0,
    }
  },
  methods: {
    cleanStrategiesFromLocalStorage() {
      let arr = [];
      Object.entries(localStorage).filter(entry => entry[0].startsWith('hc_mplace_')).forEach(hc => arr.push(hc))
      for (var i = 0; i < arr.length; i++) {
        localStorage.removeItem(arr[i][0]);
      }
    },
    setLocale(locale) {
      this.cleanStrategiesFromLocalStorage()
      this.$i18n.locale = locale;
      this.locale = locale;
      this.previous_lang = localStorage.getItem('user-lang')
      localStorage.setItem('user-lang', locale);

      this.$root.$emit("showTranslatingInProgress")
      this.user.user_metadata.lang = locale

      const json_data = {
        "address": this.user.user_metadata.address,
        "city": this.user.user_metadata.city,
        "state": this.user.user_metadata.state,
        "zip_code": this.user.user_metadata.zip_code,
        "country": this.user.user_metadata.country,
        "bio": this.user.user_metadata.bio,
        "first_name": this.user.user_metadata.first_name,
        "last_name": this.user.user_metadata.last_name,
        "lang": this.user.user_metadata.lang,
        "picture": this.user.picture,
        "eml": this.user.email,
        "phone": this.user.user_metadata.phone,
        "birth_date": this.user.user_metadata.birth_date,
        "oldLang": this.previous_lang,
      };

      const upinfo_done = err => {
        if (err) {
          console.log("update user info Error", err)
        }
        location.reload()
      }

      this.$updateUserInfo(this.user.user_id, json_data).then((resp) => {
        upinfo_done(false);
      }, function (resp){
        upinfo_done(true);
      });
    },
    minimizeSidebar() {
      let sideBarCollapsed = VueCookies.get("sideBarCollapsed") == 'true'
      VueCookies.set("sideBarCollapsed", !sideBarCollapsed, null, null, null, true, 'Lax')
      this.$sidebar.toggleMinimize()
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      let sideBarCollapsed = VueCookies.get("sideBarCollapsed") == 'true'
      VueCookies.set("sideBarCollapsed", !sideBarCollapsed, null, null, null, true, 'Lax')
      let sideBarCollapsed2 = this.$sidebar.toggleMinimize()
    },
    walletUpdateCycle() {
      this.$getWalletCredit(user_data.email)
        .then(this.loadCredit, this.failop);

      if (this.first_load) {
        this.first_load = false;
        setInterval(this.walletUpdateCycle, timercountdown);
      }
    },
    loadCredit(resp) {
      if (!resp.success) {
        return
      }

      // if (this.pending > 0 && resp.data.pendings == 0) {
      //   this.$toast.success("LIKIDADO");
      // }
      this.pending = resp.data.pendings;
      let wallet = resp.data;
      this.balance = wallet.walletFinal;
      this.balanceFloat = parseFloat(wallet.walletFinal);
      this.dailyCharge = wallet.dailyCharge;
      this.dailyChargeFloat = parseFloat(wallet.dailyCharge);
      VueCookies.set('dash-wallet', this.walletcredit, null, null, null, true, 'Lax');
    },
    logout() {
      this.auth.logout();
    },
    failop (error) {
      this.auth.logout();
      this.auth.redirect();
    },
    goTelegram() {
      window.open("https://t.me/HokoSupport", "_blank");
    },
    getLocale() {
      if (this.locale.toUpperCase() == 'EN') {
        return 'GB';
      }
      if (this.locale.toUpperCase() == 'AR') {
        return 'AE';
      }
      if (this.locale.toUpperCase() == 'ZHS') {
        return 'ZH';
      }
      return this.locale.toUpperCase()
    },
  },
  mounted() {
    if ((lang) && this.locale != lang) {
      this.locale = lang
    } else {
      this.$i18n.locale = this.locale;
    }
    localStorage.setItem('user-lang', this.locale);
    let sideBarCollapsed = VueCookies.get("sideBarCollapsed")
    if (sideBarCollapsed == 'true') {
      this.$sidebar.toggleMinimize()
    }
    window.addEventListener('resize', e => {
      this.windowSize = window.innerWidth;
    });
    this.walletUpdateCycle();
  }
}
</script>

<style lang="scss" scoped>
.navbar .navbar-nav .nav-item:first-child {
  margin-top: unset;
}
.btn-balance {
  border: solid 1px green;
  background: white;
  color: black;
  font-size: 13px;
  margin-right: 15px;
}
.btn-balance:hover {
  background: green !important;
  color: white !important;
}
.btn-balance:active,
.btn-balance:focus,
.btn.btn-balance.btn-round:focus {
  background: white !important;
  color: black !important;
}
.btn-round {
  padding: 8px;
  border-radius: 5px;
  font-size: 14px;
}
.btn-icon {
  font-size: 20px!important;
}
.hamburger {
  width: 25px;
  height: 2px;
  background-color: black;
  margin: 5px 0;
}
.btn:hover, .btn:active, .btn:focus {
  .hamburger {
    background-color: white !important;
  }
}
.custom-float-right {
  position: absolute;
  right: 0!important;
  margin-right: 10px;
}
.navbar {
  height: 60px!important;
}
.navbar-nav {
  flex-direction: row;
}
.pal1 {
  padding-top: 15px;
}
.pal2 {
  padding-top: 8px;
}

</style>

<!--# TODO timeout   -->
<!--# TODO testar   -->
