<template>
  <div class="btn-group" v-if="!hideOption" >
    <el-tooltip :content="$t('ineedhelp')" :open-delay="100" placement="top">
      <p-button v-if="getVideoList" type="danger" v-on:click="modalTutorial = true">
        <i class="fa fa-question-circle"></i><span class="ml-2" v-if="!isMobile()">{{ $t('ineedhelp')}} </span>
      </p-button>
    </el-tooltip>
    <modal :show.sync="modalTutorial" required modalClasses="modal-lg" headerClasses="justify-content-center">
      <h4 slot="header" class="title title-up justify-content-center">{{ headerText }}</h4>
      <div class="col-md-10 mx-auto">
        <div class="mb-2" style="text-align: center" v-for="(video,index) in getVideoList">
          <LazyYoutube
            ref="lazyVideo"
            :src="video"
            max-width="720px"
            aspect-ratio="16:9"
            thumbnail-quality="standard"
          />
          <div class="row-md-10 mt-3 mb-3" v-if="publicPath">
            <a style="text-align: center" :href="`${publicPath[index]}`" download="download">
              <p-button type="info"><i class="fa fa-file-pdf-o" aria-hidden="true"></i> TUTORIAL</p-button></a>
          </div>
        </div>
      </div>
      <template slot="footer">
        <p-button type="success" @click.prevent="modalTutorial = false" link> {{ $t('gotit') }}</p-button>
      </template>
    </modal>
  </div>
</template>

<script>

import {Modal} from 'src/components/UIComponents';
import {LazyYoutube} from "vue-lazytube";
import {i18n} from "@/assets/i18n-plugin";
import {Tooltip} from 'element-ui';
import Constants from "@/assets/constants";

const rules = Constants.methods.getRules();

export default {
  name: "video-tutorial",
  components: {
    Modal,
    LazyYoutube,
    [Tooltip.name]: Tooltip,
  },
  computed: {
    getVideoList() {
      if(i18n.locale == 'pt') {
        this.publicPath = this.$route.meta.pdftutorialPt
        return this.$route.meta.tutorialPt
      } else if (i18n.locale == 'ar') {
        return this.$route.meta.tutorialAr
      }
      this.publicPath = this.$route.meta.pdftutorialEn
      return this.$route.meta.tutorialEn
    }
  },
  data() {
    return {
      modalTutorial: false,
      headerText: 'Tutorial',
      hideOption: false,
      publicPath: this.$route.meta.pdftutorialEn
    }
  },
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      );
    },
  },
  mounted() {
    if (rules.isWhitelabelSession) {
      if (rules.hide_need_help) {
        this.hideOption = true;
      }
    }
  },
  watch: {
    "modalTutorial": function(val) {
      if (!val && this.$refs["lazyVideo"]) {
        this.$refs["lazyVideo"]['stopVideo']();
      }
    }
  }
}
</script>

<style scoped>

</style>
