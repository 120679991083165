<template>
  <form ref="formValidation">
    <div v-if="showEmailAlert" class="row">
      <div class="col-md-12">
        <div class="alert alert-warning">
          <i class="fa fa-warning"></i>
          {{ $t('wlemailnotavailable', [this.whitelabel.wladmin]) }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 col-sm-6">
        <div class="row">
          <div class="col-md-12">
            <h5>{{$t('whitelabelprofile') }}</h5>
          </div>
        </div>
        <div class="row">
          <fg-input
            :label="txt.initials"
            :placeholder="txt.initials"
            class="col-md-4"
            type="text"
            :disabled="disable_initials"
            name="initial"
            maxlength="10"
            v-validate="modelValidations.whitelabel"
            :error="getError('initial')"
            v-model="whitelabel.whitelabel">
          </fg-input>
          <fg-input
            :label="txt.fullname"
            :placeholder="txt.fullname"
            maxlength="50"
            class="col-md-8"
            type="text"
            name="full name"
            v-validate="modelValidations.fullname"
            :error="getError('full name')"
            v-model="whitelabel.fullname">
          </fg-input>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label>{{ $t('wllink')}} <small v-if="copied">{{ $t('copied') }}!</small></label>
            <a class="clickable" v-on:click="cp_clipboard">
              {{ clipboard }}
            </a>
            <input id="clipit" type="text" v-model="clipboard" style="display: none">
          </div>
        </div>

        <div class="row" style="margin-top: 6px">
          <fg-input
            :label="txt.displaymenuname"
            :placeholder="txt.displaymenuname"
            class="col-md-12"
            maxlength="50"
            type="text"
            name="display menu name"
            v-validate="modelValidations.wlmenuname"
            :error="getError('display menu name')"
            v-model="whitelabel.wlmenuname">
          </fg-input>

          <!-- Style Customization-->
          <fg-input
            :label="txt.backgroundimg"
            class="col-md-12"
            maxlength="250"
            type="text"
            v-model="whitelabel.bgimg_login">
          </fg-input>
          <div class="form-group col-md-12 has-label form__field">
            <label>
              {{ txt.backgroundcolod }}
            </label>
            <div class="form__input">
              <swatches
                v-model="whitelabel.bgcolor_login"
                show-fallback
                fallback-input-type="color"
                colors="text-advanced">
                <input
                  aria-describedby="addon-right addon-left"
                  maxlength="7"
                  type="text"
                  v-model="whitelabel.bgcolor_login"
                  slot="trigger"
                  class="form-control valid form__input__element">
              </swatches>
            </div>
          </div>
          <div class="form-group col-md-6 has-label form__field">
            <label>
              {{ txt.backgroundbtn1 }}
            </label>
            <div class="form__input">
              <swatches
                v-model="whitelabel.bgcolor_btn1"
                show-fallback
                fallback-input-type="color"
                colors="text-advanced">
                <input
                  aria-describedby="addon-right addon-left"
                  maxlength="7"
                  type="text"
                  v-model="whitelabel.bgcolor_btn1"
                  slot="trigger"
                  class="form-control valid form__input__element">
              </swatches>
            </div>
          </div>
          <div class="form-group col-md-6 has-label form__field">
            <label>
              {{ txt.backgroundbtn2 }}
            </label>
            <div class="form__input">
              <swatches
                v-model="whitelabel.bgcolor_btn2"
                show-fallback
                fallback-input-type="color"
                colors="text-advanced">
                <input
                  aria-describedby="addon-right addon-left"
                  maxlength="7"
                  type="text"
                  v-model="whitelabel.bgcolor_btn2"
                  slot="trigger"
                  class="form-control valid form__input__element">
              </swatches>
            </div>
          </div>
        </div>

        <div class="row">
          <fg-input
            :label="txt.login_title_txt"
            class="col-md-12"
            type="text"
            name="title"
            v-model="whitelabel.login_title">
          </fg-input>

          <fg-input
            :label="txt.login_subtitle_txt"
            class="col-md-12"
            type="text"
            name="title"
            v-model="whitelabel.login_subtitle">
          </fg-input>

          <div class="form group col-12 mb-3">
            <label>{{ txt.login_text_txt }}</label>
            <textarea
            class="form-control"
            rows="10"
            col="200"
            type="text"
            v-model="whitelabel.login_text">
          </textarea>
          </div>
        </div>



        <div class="row">
          <div class="col-md-12">
            <h5>{{ $t('logo') }}</h5>
            <div class="img-div text-center">
              <vue-dropzone
                id="logoLogin"
                :options="dropOptions"
                @vdropzone-file-added="onLogoLoginAdded"
                @vdropzone-complete="onLogoLoginUploaded"
                @vdropzone-max-files-exceeded="onLogoLoginRetry"
                ref="dropzoneLogoLogin"
              >
              </vue-dropzone>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <h5>{{ $t('sidebarLogo') }}</h5>
            <div class="img-div text-center">
              <vue-dropzone
                id="logoMenu"
                :options="dropOptions"
                @vdropzone-file-added="onLogoMenuAdded"
                @vdropzone-complete="onLogoMenuUploaded"
                @vdropzone-max-files-exceeded="onLogoMenuRetry"
                ref="dropzoneLogoMenu"
              ></vue-dropzone>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <h5>
              {{ $t('uploadcustompolicies') }}
            </h5>
          </div>
        </div>
        <div class="row" style="margin-bottom: 10px">
          <div class="col-md-12">
            <input type="file"
                   id="filePolicies"
                   accept=".pdf,.zip,.rar,.7z"
                   v-on:change="informPolicies" />
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-control">
              <div class="row">
                <div class="col-md-12">
                  <label v-if="!!whitelabel.policies">{{ whitelabel.policies }}</label>
                  <label v-if="!whitelabel.policies">{{ $t('nopolicies') }}</label>
                </div>
              </div>
              <p-button v-if="!!whitelabel.policies" type="danger" v-on:click="removePolicie">
                <i class="fa fa-trash-o"></i>
                {{ $t('deletes') }}
              </p-button>
              <p-button type="success" v-if="isPolicieStored" v-on:click="downloadPolicies">
                <i class="fa fa-download"></i>
                {{ $t('downloadtxt') }}
              </p-button>
            </div>
          </div>
        </div>


      </div>
      <div class="col-md-8 col-sm-6">
        <div class="row">
          <div class="col-md-12">
            <h5>{{ $t('settings') }}</h5>
          </div>
        </div>
        <tabs tab-content-classes="tab-space">

          <!-- permissions -->
          <tab-pane :title="txt.permissions" id="permissions" key="permissions">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <div class="alert alert-info">
                      <a class="info">
                        <i class="fa fa-info-circle"></i>
                      </a>
                      {{ $t('sytautatmotl') }}
                    </div>
                  </div>
                </div>

                <div class="row">

                  <div class="col-md-12">

                    <el-table :data="tableModules" class="settingsTables">

                      <el-table-column prop="text" :label="txt.module">
                      </el-table-column>

                      <el-table-column :label="txt.active" width="200px">
                        <template slot-scope="scope" slot="header">
                          <div style="text-transform: none!important;">
                            <p-switch
                              v-model="includeAllModules"
                              :onText="txt.yes"
                              :offText="txt.no"
                            ></p-switch>
                          </div>
                        </template>

                        <template slot-scope="props">
                          <div>
                            <PathSwitch
                              v-model="whitelabel.routes"
                              :default="true"
                              :path="props.row.value"
                              :onText="txt.yes"
                              :offText="txt.no"
                            ></PathSwitch>
                          </div>
                        </template>
                      </el-table-column>


                      <el-table-column width="200px">
                        <template slot-scope="scope" slot="header">
                          <div style="text-transform: none!important;">
                           <span>
                             {{ $t('mainpagetxt').toUpperCase() }}
                             <i class="fa fa-info-circle"></i>
                           </span>
                          </div>
                        </template>
                        <template slot-scope="props">
                          <div>
                            <FirstPageSwitch
                              v-model="whitelabel.first_page"
                              :readonly="!whitelabel.routes.includes(props.row.value)"
                              :path="props.row.value"
                              :onText="txt.yes"
                              :offText="txt.no"
                            ></FirstPageSwitch>
                          </div>
                        </template>
                      </el-table-column>

                    </el-table>

                  </div>
                </div>
              </div>
            </div>
          </tab-pane>

          <!-- Brokers -->
          <tab-pane :title="txt.brokers" id="brokers" key="brokers">
            <div class="alert alert-info">
              <a class="info">
                <i class="fa fa-info-circle"></i>
              </a>
              {{ $t('brokersinfo') }}
            </div>
            <div class="card-body">
              <el-table :data="tableBrokers" class="settingsTables">

                <el-table-column :label="txt.broker" width="130px">
                  <template slot-scope="props">
                    <div class="broker-logo logo-size-1">
                      <img :src="getLogo(props.row.logo)" :alt="txt.broker">
                    </div>
                  </template>
                </el-table-column>

                <el-table-column prop="name" :label="txt.name">
                </el-table-column>

                <el-table-column
                  label="Url"
                  prop="strat_url"
                  v-if="is_manage"
                  width="500px">
                  <WhitelabelBrokerUrl
                    slot-scope="props"
                    :broker="props.row.id"
                    :url="props.row.url"
                    :default="props.row.link"
                    @update="updateBrokerUrl"
                  ></WhitelabelBrokerUrl>
                </el-table-column>

                <el-table-column :label="txt.active"  width="100px">
                  <template slot-scope="scope" slot="header">
                    <div style="text-transform: none!important;">
                      <p-switch
                        v-model="includeAllBrokers"
                        :onText="txt.yes"
                        :offText="txt.no"
                      ></p-switch>
                    </div>
                  </template>

                  <template slot-scope="props">
                    <PathSwitch
                      v-model="whitelabel.brokers"
                      :path="props.row.id"
                      :onText="txt.yes"
                      :offText="txt.no"
                      ref="swit"
                    ></PathSwitch>
                  </template>
                </el-table-column>

              </el-table>

            </div>
          </tab-pane>

          <!-- strategies -->
          <tab-pane :title="txt.strategies" id="strategies" key="strategies">
            <div class="alert alert-info">
              <a class="info">
                <i class="fa fa-info-circle"></i>
              </a>
              {{ $t('strategiesinfo') }}
            </div>
            <div class="card-body">
              <el-table :data="tableStrategies" class="settingsTables">

                <el-table-column width="90" content="Info">
                  <template slot-scope="props">
                    <p-button type="info" size="sm" social  v-on:click="more(props.row)">
                      <i class="fa fa-info-circle"></i>
                    </p-button>
                  </template>
                </el-table-column>

                <el-table-column :label="txt.strategy" prop="strat_name" >
                </el-table-column>

                <el-table-column :label="txt.master" prop="master_account" >
                </el-table-column>

                <el-table-column :label="txt.description" prop="description" >
                </el-table-column>

                <el-table-column :label="txt.active" width="100px">

                  <template slot-scope="scope" slot="header">
                    <div style="text-transform: none!important;">
                      <p-switch
                        v-model="includeAllStrategies"
                        :onText="txt.yes"
                        :offText="txt.no"
                      ></p-switch>
                    </div>
                  </template>

                  <template slot-scope="props">
                    <PathSwitch
                      v-model="whitelabel.strategies"
                      :path="props.row.id"
                      :onText="txt.yes"
                      :offText="txt.no"
                    ></PathSwitch>
                  </template>
                </el-table-column>

              </el-table>
            </div>
          </tab-pane>

          <!-- advanced settings -->
          <tab-pane :title="txt.advancedsettings" id="wlConfig" key="wlConfig">

            <modal :show.sync="modalsAS" headerClasses="justify-content-center">

              <h4 slot="header" class="title title-up">{{txt.advancedsettingsinfo}}</h4>

              <p slot="default"> {{ this.modalMessage }} </p>

              <template slot="footer" >
                <p-button type="info" @click.prevent="modalsAS = false" link>{{ $t('gotit') }}</p-button>
              </template>
            </modal>

            <div class="alert alert-info">
              <a class="info">
                <i class="fa fa-info-circle"></i>
              </a>
              {{ $t('advancedsettingstab') }}
            </div>
            <div class="card-body">
              <el-table :data="wlBehavior" >
                <el-table-column width="90" content="Info">
                  <template slot-scope="props">
                    <p-button type="info" size="sm" social  @click.native="asModal(props.row)">
                        <i class="fa fa-info-circle"></i>
                    </p-button>
                  </template>
                </el-table-column>

                <el-table-column :label="txt.description" prop="info" >
                </el-table-column>

                <a class="info">
                 <i class="fa fa-info-circle"></i>
                 </a>

                <el-table-column :label="txt.active" width="100px">
                  <template slot-scope="props">
                    <PathSwitch
                      v-model="behavior"
                      :path="props.row.value"
                      :onText="txt.yes"
                      :offText="txt.no"
                      @input="inputASbehavior(props.row)"
                    ></PathSwitch>
                  </template>
                </el-table-column>

              </el-table>
            </div>
            <div class="card-body">
              <div class="col-md-12">
                <h5>
                  {{ $t('disclaimer') }}
                </h5>
              </div>
              <div class="form-group col-12">
                <textarea v-model="whitelabel.disclaimer" rows="8" cols="100" style="max-height: 450px;"
                          class="form-control"></textarea>
              </div>
            </div>
          </tab-pane>
          <!-- Custom Text  -->
          <!-- <tab-pane title="Custom text" id="text" key="text" v-if="customText"> -->
          <tab-pane :title="txt.registerpage" id="text" key="text">
            <div class="col-md-12">
              <div class="alert alert-info">
                <a class="info">
                  <i class="fa fa-info-circle"></i>
                </a>
                {{ txt.registerpageinfo }}
              </div>
            </div>
            <WhitelabelCustomText
              :key="componentKey"
              ref="formCustomText"
              :is_manage="true"
              v-bind:reference="register_items">
            </WhitelabelCustomText>
          </tab-pane>

          <tab-pane title="Admin" id="adminTab" key="tadminTabext" v-if="is_manage">
            <div class="row">
              <div class="col-md-12">
                <fg-input
                  :label="txt.whitelabeladmemail"
                  class="col-md-12"
                  maxlength="100"
                  type="email"
                  name="Email"
                  v-validate="modelValidations.email"
                  :error="getError('Email')"
                  @change="check_email"
                  v-model="whitelabel.wladmin">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <fg-input
                  :label="txt.provisionurl"
                  class="col-md-12"
                  maxlength="100"
                  type="text"
                  :name="txt.provisionurl"
                  v-validate="modelValidations.provision_url"
                  :error="getError(txt.provisionurl)"
                  v-model="whitelabel.provision_url">
                </fg-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <fg-input
                  :label="txt.domain"
                  class="col-md-12"
                  maxlength="100"
                  type="text"
                  :name="txt.domain"
                  v-model="whitelabel.domain">
                </fg-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <fg-input
                  :label="txt.altdomain"
                  class="col-md-12"
                  maxlength="100"
                  type="text"
                  :name="txt.altdomain"
                  v-model="whitelabel.altdomain">
                </fg-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <fg-input
                  :label="txt.prefix"
                  class="col-md-12"
                  maxlength="3"
                  type="text"
                  :name="txt.prefix"
                  v-validate="modelValidations.vps_prefix"
                  :error="getError(txt.prefix)"
                  v-model="whitelabel.vps_prefix">
                </fg-input>
              </div>
              <div class="col-md-6">
                <fg-input
                  :label="txt.snapshot"
                  class="col-md-12"
                  maxlength="25"
                  type="email"
                  name="Email"
                  v-model="whitelabel.snapshot">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="col-md-12">
                  <label>{{ txt.type }}</label>
                  <select v-model="whitelabel.type"
                          class="form-control">
                      <option value="trader" selected >{{ txt.trader }}</option>
                      <option value="broker">{{ txt.broker }}</option>

                  </select>
                </div>
              </div>
            </div>
          </tab-pane>
        </tabs>
      </div>
    </div>
  </form>

</template>
<script>
  import Vue from "vue";
  import UserCard from "../UserProfile/UserCard";
  import {Select, Table, TableColumn, Upload} from "element-ui";
  import "sweetalert2/dist/sweetalert2.css";
  import PathSwitch from "./PathSwitch";
  import PSwitch from "../../../../UIComponents/PSwitch";
  import VeeValidate from 'vee-validate'
  import {TabPane, Tabs, Modal} from "../../../../UIComponents";
  import vueDropzone from "vue2-dropzone";
  import VueCookies from "vue-cookies";
  import Swatches from "vue-swatches"
  import "vue-swatches/dist/vue-swatches.min.css"
  import WhitelabelBrokerUrl from "./WhitelabelBrokerUrl";
  import WhitelabelCustomText from "./WhitelabelCustomText";
  import FirstPageSwitch from './FirstPageSwitch.vue';
  import CONSTANTS from "@/assets/constants";

  Vue.use(Table);
  Vue.use(TableColumn);
  Vue.use(VeeValidate);
  Vue.use(Select)

  const logo_base_url = "https://hoko.ams3.digitaloceanspaces.com/";
  const upload_url = CONSTANTS.methods.mainapiurl() + "/upwhitelabel/";
  const auth_headers = {
    'Authorization': 'Bearer '+ VueCookies.get('dash-at'),
    'idtoken': VueCookies.get('dash-it')
  };

  export default {
    name: 'whitelabelform',
    props: {
      reference: {
        type: [Object],
        description: "Whitelabel data"
      },
      is_manage: {
        type: [Boolean],
        description: "Flag defined to inform if the form is being used for manage whitelabel page"
      }
    },
    components: {
      [Upload.name]: Upload,
      [PathSwitch.name]: PathSwitch,
      FirstPageSwitch,
      PSwitch,
      vueDropzone,
      TabPane,
      Tabs,
      UserCard,
      Swatches,
      WhitelabelBrokerUrl,
      Modal,
      WhitelabelCustomText,
    },
    data() {
      return {
        componentKey: false,
        modals: {
            classic: false
          },
        imageUrl: 'static/img/default-avatar.png',
        logologin: "",
        logomenu: "",
        account: "",
        sname: "",
        sdesc: "",
        sid: "",
        copied: false,
        clipboard: "https://dash.hokocloud.com/",
        showEmailAlert: false,
        isEmailAvailable: true,
        includeAllModules: false,
        includeAllBrokers: false,
        includeAllStrategies: false,
        whitelabel: {
          whitelabel: "",
          fullname: "",
          wladmin: "",
          wlmenuname: "",
          logo_login: "",
          logo_menu: "",
          provision_url: "https://dash.hokocloud.com/api/v1/provision",
          vps_prefix: "WL",
          routes: [],
          brokers: [],
          strategies: [],
          bgimg_login: "",
          bgcolor_login: "",
          bgcolor_btn1: "",
          bgcolor_btn2: "",
          auto_add_brokers: 0,
          auto_add_strategies: 0,
          create_strategies: 0,
          show_all_default_marketplace: 0,
          hide_free_replication: 0,
          hide_telegram_notification: 0,
          hide_info_broker: 0,
          hide_premium: 0,
          hide_need_help: 0,
          brokerUrls: [],
          register_items: [],
          policies: "",
          disclaimer: "",
          first_page: "default",
          snapshot: "",
          type: "trader",
          login_title: "",
          login_subtitle: "",
          login_text: "",
          use_initial_disclaimer: 1
        },
        isPolicieStored: false,
        behavior: [],
        txt: {
          advancedsettings: this.$t('advancedsettings'),
          initials: this.$t('initials'),
          fullname: this.$t('fullname'),
          backgroundimg: this.$t('backgroundimg'),
          backgroundcolod: this.$t('backgroundcolod'),
          backgroundbtn1: this.$t('backgroundbtn1'),
          backgroundbtn2: this.$t('backgroundbtn2'),
          brokers: this.$t('brokers'),
          broker: this.$t('brokertxt'),
          displaymenuname: this.$t('displaymenuname'),
          whitelabeladmemail: this.$t('whitelabeladmemail'),
          permissions: this.$t('permissions'),
          module: this.$t('module'),
          yes: this.$t('yestxt'),
          no: this.$t('notxt'),
          active: this.$t('active'),
          uploadanewlogo: this.$t('uploadanewlogo'),
          uploadnewsideLogo: this.$t('uploadnewsideLogo'),
          name: this.$t('nametxt'),
          strategies: this.$t('strategies'),
          brokersinfo: this.$t('brokersinfo'),
          strategiesinfo: this.$t('strategiesinfo'),
          advancedsettingstab: this.$t('advancedsettingstab'),
          advancedsettingsinfo: this.$t('advancedsettingsinfo'),
          strategy: this.$t('strategytxt'),
          description: this.$t('description'),
          provisionurl: this.$t('provisionurl'),
          url: this.$t('urltxt'),
          prefix: 'VPS ' + this.$t('prefix'), // TODO move to babel
          registerpage: this.$t('registerpage'),
          registerpageinfo: this.$t('registerpageinfo'),
          snapshot: 'VPS Snapshot',  // TODO move to babel
          master: this.$t('master'),
          type: this.$t('type'),
          trader: this.$t('tradertxt'),
          domain: this.$t('domaintxt'),
          altdomain: this.$t('altDomaintxt'),
          login_title_txt: this.$t('login_title_txt'),
          login_subtitle_txt: this.$t('login_subtitle_txt'),
          login_text_txt: this.$t('login_text_txt'),
        },
        modelValidations:{
          whitelabel: { required: true, max: 10 },
          email: { required: false, email: true, max: 100 },
          fullname: { required: true, max: 50 },
          wlmenuname: { required: true, max: 50 },
          provision_url: { required: true, max: 250 },
          vps_prefix: { required: true, max: 3 },
          bgcolor_login: { required: true, max: 7, min: 7 }
        },
        tableModules: [
          {
            text: this.$t('myaccountsinfo'),
            value: "/accounts/main",
          },
          {
            text: this.$t('partnerbrokersinfo'),
            value: "/accounts/open",
          },
          {
            text:  this.$t('watchliststrategiesinfo'),
            value: "/strategies/watchlist",
          },
          {
            text:  this.$t('marketplacestrategiesinfo'),
            value: "/strategies/market",
          },
          {
            text:  this.$t('requestvpsmodule'),
            value: "/vps/request",
          },
          {
            text:  this.$t('wallettopupinfo'),
            value: ["/billing/funding", "/billing/orders"],
          },
          {
            text:  this.$t('walletstatementinfo'),
            value: "/billing/inout",
          },
          {
            text:  this.$t('walletstransferinfo'),
            value: "/billing/transfer",
          },
          {
            text: this.$t('partnerinfo'),
            value: "/affiliate",
          },
          {
            text: this.$t('partnerfollowers'),
            value: "/affiliate/followers",
          },
          {
            text:  this.$t('supportfaqsinfo'),
            value: "/support/main",
          },
          {
            text:  this.$t('allowLedgerMarketplacePage'),
            value: ["/billing/marketplace", "/billing/marketplace-legacy"],
          },
          // {
          //   text:  this.$t('allowPerformanceHistoryPage'),
          //   value: "/billing/performancehistory",
          // },
          {
              text: this.$t('pricing_explanation'),
              value: '/support/explanation'
          },
          {
              text: this.$t('banner_page_explanation'),
              value: '/affiliate/banner'
          }
        ],
        wlBehavior:[
          {
            info: this.$t('wlbehavebrokerinfo'),
            value: "newBrokers",
            text: this.$t('wlbehavebrokertxt')
          },
          {
            info: this.$t('wlbehavestrategiesinfo'),
            value: "newStrategie",
            text: this.$t('wlbehavestrategiestxt')
          },
          {
            info: this.$t('wlbehaviorcreatestrategie'),
            value: "createStrategies",
            text: this.$t('wlbehaviorcreatestrategieinfo')
          },
          {
            info: this.$t('wlbehavemarketplaceinfo'),
            value: "showAllBehave",
            text: this.$t('wlbehavemarketplaceinfo')
          },
          {
            info: this.$t('wlbehavefreetradereplicationinfo'),
            value: "hideFreeReplication",
            text: this.$t('wlbehavefreetradereplicationtxt')
          },
          {
            info: this.$t('wlbehavehidetelegramnotificationinfo'),
            value: "hideTelegramNotification",
            text: this.$t('wlbehavehidetelegramnotificationtxt')
          },
          {
            info: this.$t('wlhideinfomationperbrokerinfo'),
            value: "hideInfoBroker",
            text: this.$t('wlhideinfomationperbrokertxt')
          },
          {
            info: this.$t('wlbehaviorhidePremiuminfo'),
            value: "hidePremium",
            text: this.$t('wlbehaviorhidePremiumtxt')
          },
          {
            info: this.$t('inform_new_user_registered_info'),
            value: "informWhenNewUserRegistered",
            text: this.$t('inform_new_user_registered_text')
          },
          {
            info: this.$t('wlhideineedhelp_info'),
            value: "hideNeedHelp",
            text: this.$t('wlhideineedhelp_text')
          },
          {
            info: this.$t('use_disclaimer_info'),
            value: 'use_initial_disclaimer',
            text: this.$t('use_disclaimer_text')
          }
        ],
        modalMessage: "",
        modalsAS: false,
        tableBrokers: [],
        tableStrategies: [],
        disable_initials: false,
        customText: false,
        register_items: null,
        dropOptions: {
          headers: auth_headers,
          url: upload_url,
          maxFilesize: 1,
          maxFiles: 1,
          createImageThumbnails: false,
          addRemoveLinks: false,
          autoProcessQueue: true,
          acceptedFiles: "image/png,image/jpg,image/jpeg,image/gif",
          dictDefaultMessage: "<i class='nc-icon nc-cloud-upload-94' style='font-size: 35px;'></i>"+this.$t('uploadtxt'),
        },
      };
    },
    methods: {
      forceRender() {
        this.componentKey +=1
      },
      clean() {
        this.logologin = "";
        this.logomenu = "";
        this.clipboard = "https://dash.hokocloud.com/";
        this.copied = false;
        this.isEmailAvailable = true;
        this.isPolicieStored = false;
        if (this.reference) {
          this.whitelabel = this.reference;
          this.whitelabel.brokerUrls = [];
          this.isPolicieStored = !!this.reference.policies;
          if (this.whitelabel.policies) {
            let array = this.whitelabel.policies.split("/")
            this.whitelabel.policies = array[array.length-1];
          }
          if (this.whitelabel.auto_add_brokers) {
            this.behavior.push("newBrokers")
          }
          if (this.whitelabel.auto_add_strategies) {
            this.behavior.push("newStrategie")
          }
          if (this.whitelabel.create_strategies) {
            this.behavior.push("createStrategies")
          }
          if (this.whitelabel.show_all_default_marketplace) {
            this.behavior.push("showAllBehave")
          }
          if (this.whitelabel.hide_free_replication) {
            this.behavior.push("hideFreeReplication")
          }
          if (this.whitelabel.hide_telegram_notification) {
            this.behavior.push("hideTelegramNotification")
          }
          if (this.whitelabel.hide_info_broker) {
            this.behavior.push("hideInfoBroker")
          }
          if (this.whitelabel.hide_premium) {
            this.behavior.push("hidePremium")
          }
          if (this.whitelabel.inform_when_new_user_registered) {
            this.behavior.push("informWhenNewUserRegistered")
          }
          if (this.whitelabel.hide_need_help) {
            this.behavior.push("hideNeedHelp")
          }
          if (this.whitelabel.use_initial_disclaimer) {
            this.behavior.push("use_initial_disclaimer")
          }
          if (this.whitelabel.register_items){
            this.register_items = this.whitelabel.register_items
          }
        } else {
          this.includeAllModules = true
          this.includeAllBrokers = true
          this.includeAllStrategies = false
          this.whitelabel = {
            whitelabel: "",
            fullname: "",
            wladmin: "",
            wlmenuname: "",
            logo_login: "",
            logo_menu: "",
            provision_url: "https://dash.hokocloud.com/api/v1/provision",
            vps_prefix: "WL",
            policies: "",
            first_page: "default",
            routes: [
              "/accounts/main",
              "/accounts/open",
              "/strategies/watchlist",
              "/strategies/market",
              "/vps/request",
              "/billing/funding",
              "/billing/orders",
              "/billing/inout",
              "/billing/transfer",
              "/affiliate",
              "/affiliate/followers",
              "/support/main",
              "/support/pricing",
              "/billing/marketplace",
              "/billing/marketplace-legacy",
              "/billing/performancehistory",
              "/support/explanation",
              "/affiliate/banner"
            ],
            brokers: [],
            strategies: [],
            auto_add_brokers: 0,
            auto_add_strategies: 0,
            create_strategies: 0,
            show_all_default_marketplace: 0,
            bgcolor_login: "",
            brokerUrls:[],
            strategiesOverview: [],
            snapshot: "",
            type: "trader",
            register_items: [],
            disclaimer: "",
            use_initial_disclaimer: 1
          };
        }
        this.disable_initials = !!this.whitelabel.whitelabel

        this.mountDropzoneLogo('dropzoneLogoLogin', this.whitelabel.logo_login);
        this.mountDropzoneLogo('dropzoneLogoMenu', this.whitelabel.logo_menu);
      },
      getData() {
        if (this.is_manage) {
          this.whitelabel.brokerUrls = this.whitelabel.brokerUrls.filter(brokerUrl => brokerUrl.url != 'N/A');
        } else {
          Vue.delete(this.whitelabel,'brokerUrls');
        }
        this.whitelabel.register_items = this.$refs.formCustomText.getData()
        return this.whitelabel;
      },
      load(whitelabel) {
        this.whitelabel = whitelabel;
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      validate() {
        const self = this;
        return new Promise(function (resolve, reject) {
          if (self.is_manage && !self.isEmailAvailable) {
            reject('notallowed');
          } else {
            return self.$validator.validateAll()
              .then(isValid => {
                self.$emit('on-submit', self.$refs.formValidation, isValid);
                resolve(isValid)
              });
          }
        });
      },
      updateFlag(response) {
        this.isEmailAvailable = response.success
        this.showEmailAlert = !response.success;

      },
      check_email() {
        this.isEmailAvailable = false;
        this.showEmailAlert = false;
        this.$check_wl_email({
          email: this.whitelabel.wladmin,
          whitelabel: this.whitelabel.whitelabel
        }).then(this.updateFlag, () => {})
      },
      updateBrokersTable(){
        this.$listBrokers(this.whitelabel.whitelabel)
          .then(response => { this.tableBrokers = response.data; }, this.requestError);
      },
      loadMktData(resp){
        this.tableStrategies = resp.data
      },
      loadStrategiesOverview(resp){
        this.strategiesOverview = resp.data
      },
      loadStrategies(){
        this.$listDiscoverableStrategiesForWl(this.whitelabel.whitelabel)
          .then(this.loadMktData, this.requestError)
        this.$getStrategiesOverview()
          .then(this.loadStrategiesOverview, this.requestError);
      },
      getLogo(logo) {
        if (!logo) {
          return "";
        }
        return "https://hoko.ams3.digitaloceanspaces.com/brokerlogos/" + logo + ".png"
      },
      more(row){
        if (this.whitelabel.domain) {
           window.open("https://"+this.whitelabel.domain+`/public/${row.url}/${this.whitelabel.whitelabel}`, '_blank')
        } else {
           window.open(`/public/${row.url}/${this.whitelabel.whitelabel}`, '_blank');
        }
      },
      asModal(row){
        this.modalMessage = row.text
        this.modalsAS = true
      },
      inputASbehavior(row) {
        if (row.value == "newStrategie") {
          this.whitelabel.auto_add_strategies = this.whitelabel.auto_add_strategies ? 0 : 1
        } else if (row.value == "newBrokers") {
          this.whitelabel.auto_add_brokers = this.whitelabel.auto_add_brokers ? 0 : 1
        } else if (row.value == "createStrategies") {
          this.whitelabel.create_strategies = this.whitelabel.create_strategies ? 0 : 1
        } else if (row.value == "showAllBehave") {
          this.whitelabel.show_all_default_marketplace = this.whitelabel.show_all_default_marketplace ? 0 : 1
        } else if (row.value == "hideFreeReplication") {
          this.whitelabel.hide_free_replication = this.whitelabel.hide_free_replication ? 0 : 1
        } else if (row.value == "hideTelegramNotification") {
          this.whitelabel.hide_telegram_notification = this.whitelabel.hide_telegram_notification ? 0 : 1
        } else if (row.value == "hideInfoBroker") {
          this.whitelabel.hide_info_broker = this.whitelabel.hide_info_broker ? 0 : 1
        } else if (row.value == "hidePremium") {
          this.whitelabel.hide_premium = this.whitelabel.hide_premium ? 0 : 1
        } else if (row.value == "customRegisterPage") {
          this.customText = true
        } else if (row.value == "informWhenNewUserRegistered") {
          this.whitelabel.inform_when_new_user_registered = this.whitelabel.inform_when_new_user_registered ? 0 : 1
        } else if (row.value == "hideNeedHelp") {
          this.whitelabel.hide_need_help = this.whitelabel.hide_need_help ? 0 : 1
        } else if (row.value == "use_initial_disclaimer") {
          this.whitelabel.use_initial_disclaimer = this.whitelabel.use_initial_disclaimer ? 0 : 1
        }
      },
      loadStrategieInfoPage(resp){
          this.account = resp.account;
          localStorage.setItem('_tmp-sid-account', JSON.stringify({
            sid: this.sid,
            sname: this.sname,
            sdesc: this.sdesc,
            account: this.account
          }));
        window.open('/admin_strategies/info', '_blank');
      },
      updateBrokerUrl(brokerUrl) {
        if (brokerUrl.url == 'N/A') {
          return
        }
        if (brokerUrl.url == '') {
          brokerUrl.url = " "
        }
        let list = this.whitelabel.brokerUrls.filter(item => item.broker != brokerUrl.broker && item.url != 'N/A')
        list.push(brokerUrl);
        this.whitelabel.brokerUrls = list;
      },
      requestError() {
      },
      cp_clipboard() {
        this.copied = true;
        let cb = document.getElementById("clipit");
        cb.style.display='block';
        cb.select();
        document.execCommand('copy');
        cb.style.display='none';
        setTimeout(() => {
          this.copied = false
        }, 5000);
      },
      informPolicies() {
        const file = document.getElementById('filePolicies');
        this.whitelabel.policies = file.value;
      },
      submitFiles() {
        const self = this;
        return new Promise(function (resolve, reject) {
          const file = document.getElementById('filePolicies');
          if (!file.value) {
            resolve('nothing to do')
          }
          self.$uploadPolicieFile({
            filename: file.value,
            files: file.files[0],
            whitelabel: self.whitelabel.whitelabel
          }).then(resolve, reject)
        });
      },
      isNewPolicieFile() {
        return !this.isPolicieStored && !!this.whitelabel.policies;
      },
      removePolicie() {
        if (!this.isPolicieStored) {
          this.whitelabel.policies = "";
          document.getElementById('filePolicies').value = "";
        } else {
          this.$deletePolicieFile(this.whitelabel.whitelabel)
            .then(this.deletePolicieSuccessHandler, this.deletePolicieErrorHandler)
        }
      },
      deletePolicieSuccessHandler() {
        this.whitelabel.policies = "";
        this.isPolicieStored = false;
      },
      deletePolicieErrorHandler() {
        console.log('error')
      },
      downloadPolicies() {
        const reject = () => {
          this.$toast.warning('File not found');
        };
        this.$downloadPolicies(this.whitelabel.whitelabel, this.whitelabel.policies, reject)
      },
      updateFirstPage(value) {
        this.whitelabel.first_page = value;
      },
      getLogoUrl(logoName) {
        return `${logo_base_url}${logoName}?a=${new Date().getTime()}`
      },
      mountDropzoneLogo(dropzone, logoName) {
        if (logoName) {
          this.$nextTick(() => {
            const container = this.$refs[dropzone].$el.querySelector('.dz-default.dz-message');
            const img = document.createElement('img');
            img.src = `${this.getLogoUrl(logoName)}`;
            container.removeChild(container.firstChild);
            container.appendChild(img);
          });
        }
      },
      onUploadRetry(file, dropzone) {
        const dropzoneElement = this.$refs[dropzone];
        dropzoneElement.removeAllFiles();
        dropzoneElement.addFile(file);
      },
      onLogoLoginRetry(file) {
        const self = this;
        new Promise(function (resolve) {
          self.whitelabel.logo_login = '';
          resolve();
        }).then(function () {
          self.onUploadRetry(file, 'dropzoneLogoLogin');
        })
      },
      onLogoMenuRetry(file) {
        this.onUploadRetry(file, 'dropzoneLogoMenu');
      },
      onLogoAdded(file, name, dropzone) {
        if (file.status === "error") {
          this.$toast.warning("Error while uploading file, try again.");
          return;
        }
        const fname = `${this.whitelabel.whitelabel.replace(/\s/g, '')}_logo_${name}`;
        this.$refs[dropzone].setOption('url', upload_url + fname);
      },
      onLogoLoginAdded(file) {
        this.onLogoAdded(file, 'login', 'dropzoneLogoLogin');
      },
      onLogoMenuAdded(file) {
        this.onLogoAdded(file, 'menu', 'dropzoneLogoMenu');
      },
      updateDropzoneImage(file, logo_name) {
        file.previewElement.querySelector('.dz-image img').src=`${this.getLogoUrl(logo_name)}`
      },
      onLogoLoginUploaded(file) {
        if(file.status === 'error') {
          this.$toast.warning(this.$t('ERROR_UNEXPECTED'));
          return;
        }
        const response = JSON.parse(file.xhr.response);
        this.whitelabel.logo_login = response.data.logo_name;
        this.updateDropzoneImage(file, this.whitelabel.logo_login);
      },
      onLogoMenuUploaded(file) {
        if(file.status === 'error') {
          this.$toast.warning(this.$t('ERROR_UNEXPECTED'));
          return;
        }
        const response = JSON.parse(file.xhr.response);
        this.whitelabel.logo_menu = response.data.logo_name;
        this.updateDropzoneImage(file, this.whitelabel.logo_menu);
      },
    },
    mounted () {
      this.$nextTick(() => {
        this.clean();
        this.updateBrokersTable();
        this.loadStrategies();
      });
    },
    watch: {
      'whitelabel.register_items': function (newVal) {
        this.register_items = newVal
        this.componentKey = !this.componentKey
      },
      'whitelabel.whitelabel': function (newVal) {
        this.clipboard = 'https://dash.hokocloud.com/' + newVal;
      },
      'whitelabel.brokerUrls': function () {
      },
      'whitelabel.routes': function(newVal, oldVal) {
        let length = this.tableModules.length;
        if (this.whitelabel.routes.length == length) {
          this.includeAllModules = true;
        } else if (this.whitelabel.routes.length == 0) {
          this.includeAllModules = false;
        }
      },
      'whitelabel.brokers': function (){
        if(this.whitelabel.brokers.length == this.tableBrokers.length){
          this.includeAllBrokers = true
        }
      },
      'whitelabel.strategies': function (){
        if(this.whitelabel.strategies.length == this.tableStrategies.length && this.tableStrategies.length > 0){
          this.includeAllStrategies = true
        }
      },
      'includeAllModules': function (newVal) {
        if (newVal) {
          this.whitelabel.routes = [
            "/accounts/main",
            "/accounts/open",
            "/strategies/watchlist",
            "/strategies/market",
            "/vps/request",
            "/billing/funding",
            "/billing/orders",
            "/billing/inout",
            "/billing/transfer",
            "/affiliate",
            "/affiliate/followers",
            "/support/main",
            "/support/pricing",
            "/billing/marketplace",
            "/billing/marketplace-legacy",
            "/billing/performancehistory",
            "/support/explanation",
            "/affiliate/banner"
          ]
        } else {
          this.whitelabel.first_page = 'default';
          this.whitelabel.routes = []
        }
      },
      'includeAllBrokers': function (newVal) {
        this.whitelabel.brokers = []
        if (!newVal) {
          return;
        }
        for (let broker of this.tableBrokers) {
          this.whitelabel.brokers.push(broker.id);
        }
      },
      'includeAllStrategies': function (newVal) {
        this.whitelabel.strategies = [];
        if (!newVal) {
          return;
        }
        for (let strategies of this.tableStrategies) {
          this.whitelabel.strategies.push(strategies.id)
        }
      },
      'tableBrokers': function (newVal) {
        if (!this.reference) {
          for (let broker of this.tableBrokers) {
            this.whitelabel.brokers.push(broker.id);
          }
        }
      },
      'tableStrategies': function (newVal) {
        if (!this.reference) {
          for (let strategie of this.tableStrategies) {
            this.whitelabel.strategies.push(strategie.strat_id);
          }
        }
      }
    }
  };
</script>
<style lang="scss">
.img-div {
  .vue-dropzone.dropzone:hover {
    .dz-image, .dz-details {
      color: #f6f6f6;
      background: #f6f6f6;
      border: none !important;
      transition: .2s linear;
      img {
        webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        transform: none;
        -webkit-filter: none;
        filter: none;
      }
    }
  }
  .vue-dropzone.dropzone {
    .dz-image, .dz-details {
      background-color: white;
      border: none;
      transition: .2s linear;
      &:hover {
        color: #f6f6f6;
        background-color: #f6f6f6;
        border: none !important;
        transition: .2s linear;
      }
    }
    .dz-preview.dz-file-preview.dz-complete .dz-image {
      opacity: 1 !important;
      border-radius: 0;
      background: inherit;
    }
    .dz-complete {
      .dz-details {
        opacity: 0 !important;
      }
    }
  }
}
</style>
