<template>
  <div id="wldiv">
  </div>
</template>
<script>
  export default {
    mounted() {
      const noWlHandler = function () {
        localStorage.clear();
        window.location = '/register/main'
      };
      const success = (resp) => {
        if (!resp.success) {
          return;
        }
        const settings = resp.data;
        localStorage.setItem('wlSettings', JSON.stringify(settings));

        if (this.$route.params.afl_id) {
          window.location = '/register/' + this.$route.params.afl_id
        } else {
          window.location = '/register/main'
        }
      };
      const wl = this.$route.params.wl;
      if (wl) {
        this.$wlSettings(wl)
          .then(success, noWlHandler);
      } else {
        noWlHandler()
      }
    }
  }
</script>
<style >
  #wldiv {
    margin: 0;
    background-color: #ffffff;
    height: 100vh;
    width: 100vw;
  }
</style>
