<template>
  <div v-if="loading" style="background-color: whitesmoke">
    <p-progress
      :value="100"
      type="info"
      class="progress-bar-animated">
    </p-progress>
  </div>
  <div v-else-if="!loading && !rules.isWhitelabelSession" class="rebrand-background">
    <login-banner></login-banner>
    <div class="container rebrand-card mb-5">
      <div>
        <div class="rebrand-card-content">
          <h5 class="rebrand-h5">
            {{ $t('login') }}
          </h5>
        </div>
        <div>
          <fg-input
            v-model="form.username"
            v-validate="modelValidations.email"
            :error="getError(email)"
            :name="email"
            addon-left-icon="nc-icon nc-email-85"
            placeholder="email@domain.com"
            type="email"
          ></fg-input>

          <fg-pass
            v-model="form.password"
            v-validate="modelValidations.password"
            addon-left-icon="nc-icon nc-key-25"
            :error="getError(password)"
            :placeholder="password"
            :name="password"
            type="password"
            style="margin-bottom: 35px"
          ></fg-pass>
        </div>

        <vue-hcaptcha
          v-if="showcaptcha && inprogress == false"
          sitekey="7736ab28-5f0f-4eb9-810c-ca6f46e64b30"
          ref="recaptcha"
          @verify="verify"
        ></vue-hcaptcha>

        <div class="div-forgot-pass">
          <a class="a-forgot-pass" v-on:click="goReset">{{ $t('forgot_password') }}</a>
        </div>
        <div>
          <p-button
            nativeType="submit"
            @click.prevent="validate"
            class="mb-3 btn-rebrand-primary"
            :disabled="!showlogin"
            round
            outline
            block
            v-if="!inprogress">
            {{ $t('log_in') }}
          </p-button>

          <p-button
            slot="footer"
            type="success"
            round
            block
            class="mb-3 btn-rebrand-secondary"
            v-on:click="goRegister"
            v-if="!inprogress">
            {{ $t('get_started') }}
          </p-button>

        </div>

      </div>
    </div>
    <ResetPasswordModal ref="reset"></ResetPasswordModal>
    <login-footer-disclaimer></login-footer-disclaimer>
  </div>

  <div v-else-if="!loading && rules.isWhitelabelSession" class="login-page">
    <!-- Modal for Reset -->
    <div class="wrapper wrapper-full-page">
      <div class="mt25">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <div class="card card-login">
                <div class="card-header">
                  <div class="row" style="float: right">
                    <img :src="`/static/img/flags/${getLocale()}.png`"
                         class="dropdown-toggle"
                         style="height: fit-content; margin: auto; float: right;"/>
                    <!-- <drop-down tag="li" position="right" direction="none" hideArrow :title="locale.toUpperCase()"> -->
                    <drop-down tag="li" position="right" direction="none" style="float: right">
                      <a class="dropdown-item" @click.prevent="setLocale('en')" href="#">
                        <img src="\static\img\flags\GB.png"/> English
                      </a>
                      <a class="dropdown-item" @click.prevent="setLocale('ar')" href="#">
                        <img src="\static\img\flags\AE.png"/> Arabic
                      </a>
                      <a class="dropdown-item" @click.prevent="setLocale('es')" href="#">
                        <img src="\static\img\flags\ES.png"/> Spanish
                      </a>
                      <a class="dropdown-item" @click.prevent="setLocale('pt')" href="#">
                        <img src="\static\img\flags\PT.png"/> Portuguese
                      </a>
                      <a class="dropdown-item" @click.prevent="setLocale('fr')" href="#">
                        <img src="\static\img\flags\FR.png"/> French
                      </a>
                      <a class="dropdown-item" @click.prevent="setLocale('zh')" href="#">
                        <img src="\static\img\flags\ZH.png"/> Chinesse
                      </a>
                      <a class="dropdown-item" @click.prevent="setLocale('de')" href="#">
                        <img src="\static\img\flags\DE.png"/> Germany
                      </a>
                      <a class="dropdown-item" @click.prevent="setLocale('it')" href="#">
                        <img src="\static\img\flags\IT.png"/> Italian
                      </a>
                      <a class="dropdown-item" @click.prevent="setLocale('ro')" href="#">
                        <img src="\static\img\flags\RO.png"/> Romanian
                      </a>
                      <a class="dropdown-item" @click.prevent="setLocale('vi')" href="#">
                        <img src="\static\img\flags\VN.png"/> Vietnam
                      </a>
                      <a class="dropdown-item" @click.prevent="setLocale('ru')" href="#">
                        <img src="\static\img\flags\RU.png"/> Russian
                      </a>
                    </drop-down>
                  </div>
                </div>
                <div class="card-header">
                  <RemoteImg :ready="showImg" :src="logo_login" size="150px"></RemoteImg>
                </div>
                <div class="card-body" style="">
                  <h5 slot="header" class="header text-center">
                    {{ dashboadName }}
                  </h5>
                  <h6 class="header-debug text-center" v-if="debugMode">DEBUG MODE</h6>
                  <div class="alert alert-danger alert-dismissible fade show" v-if="error">
                    <button type="button" aria-hidden="true" class="close" data-dismiss="alert" aria-label="Close"
                            v-on:click="closeAlert">
                      <i class="nc-icon nc-simple-remove"></i>
                    </button>
                    <span>{{ error_msg }}</span>
                  </div>

                  <fg-input
                    v-model="form.username"
                    v-validate="modelValidations.email"
                    :error="getError(email)"
                    :name="email"
                    addon-left-icon="nc-icon nc-email-85"
                    placeholder="email@domain.com"
                    type="email"></fg-input>

                  <fg-pass
                    v-model="form.password"
                    v-validate="modelValidations.password"
                    addon-left-icon="nc-icon nc-key-25"
                    :error="getError(password)"
                    :placeholder="password"
                    :name="password"
                    type="password"></fg-pass>

                  <p-button type="primary" v-on:click="goReset" link>
                    {{ $t('resetpassword') }}
                  </p-button>

                  <vue-hcaptcha
                    v-if="showcaptcha && inprogress == false"
                    sitekey="7736ab28-5f0f-4eb9-810c-ca6f46e64b30"
                    ref="recaptcha"
                    @verify="verify"
                  ></vue-hcaptcha>

                  <div class="text-center" v-if="inprogress">
                    <i class="fa fa-cog fa-spin fa-2x fa-fw"
                       style="color: #51bcda;">
                    </i>
                  </div>

                  <p-button
                    nativeType="submit"
                    @click.prevent="validate"
                    slot="footer"
                    type="info"
                    round
                    block
                    class="mb-3"
                    :disabled="!showlogin"
                    v-if="!inprogress">
                    {{ $t('login') }}
                  </p-button>

                  <p-button
                    slot="footer"
                    type="success"
                    round
                    block
                    class="mb-3"
                    v-on:click="goRegister"
                    v-if="!inprogress">
                    {{ $t('registerloginpage') }}
                  </p-button>


                </div>
              </div>
            </div>
            <disclaimer v-if="wlSession && rules.disclaimer" :disclaimer-content="rules.disclaimer"></disclaimer>
            <app-footer></app-footer>
          </div>
        </div>
      </div>
    </div>
    <ResetPasswordModal ref="reset"></ResetPasswordModal>
  </div>

</template>
<script>
// import VueRecaptcha from 'vue-recaptcha';
import VueHcaptcha from '@hcaptcha/vue-hcaptcha'
import VueCookies from 'vue-cookies'
import {Modal, Progress} from 'src/components/UIComponents'
import {Card, Checkbox, Button} from 'src/components/UIComponents';
import ResetPasswordModal from "@/components/Dashboard/Views/Pages/ResetPasswordModal";
import AppNavbar from './Layout/AppNavbar'
import AppFooter from './Layout/AppFooter2'
import Constants from "@/assets/constants";

import ar from 'vee-validate/dist/locale/ar';
import en from 'vee-validate/dist/locale/en';
import es from 'vee-validate/dist/locale/es';
import it from 'vee-validate/dist/locale/it';
import ro from 'vee-validate/dist/locale/ro';
import ru from 'vee-validate/dist/locale/ru';
import pt from 'vee-validate/dist/locale/pt_PT';
import zh from 'vee-validate/dist/locale/zh_CN';
import de from 'vee-validate/dist/locale/de';
import vi from 'vee-validate/dist/locale/vi';

import Vue from "vue";
import Disclaimer from "@/components/Dashboard/Views/Pages/Layout/Disclaimer.vue";
import LoginBanner from "@/components/Dashboard/Views/Pages/login-banner";
import LoginFooterDisclaimer from "@/components/Dashboard/Views/Pages/login-footer-disclaimer";

const cookieIp = VueCookies.get('ip') || null;
const cookieLanguage = VueCookies.get('language') || null

export default {
  props: {
    logo: {
      type: String,
      default: '/static/img/logo.png',
      description: 'Hoko Logo'
    }
  },
  components: {
    LoginFooterDisclaimer,
    LoginBanner,
    Disclaimer,
    Card,
    AppNavbar,
    AppFooter,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button, Modal,
    // VueRecaptcha,
    VueHcaptcha,
    ResetPasswordModal,
    [Progress.name]: Progress
  },
  computed: {
    debugMode: function () {
      return Constants.methods.debugMode();
    },
    rules: function () {
      return Constants.methods.getRules();
    }
  },
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
      modelValidations: {
        password: {
          required: true,
          min: 6
        }
      },
      wlSession: false,
      requesting: true,
      showImg: false,
      logo_login: '',
      error: false,
      error_msg: null,
      inprogress: true,
      resetuid: this.$t('resetuid'),
      email: this.$t('email'),
      password: this.$t('password'),
      alerttype: 'danger',
      showcaptcha: false,
      countloginerr: 0,
      canLogIn: true,
      showlogin: false,
      auth0token: null,
      locale: 'en',
      dashboadName: this.$t('dashboard') + " " + this.$t('login'),
      loading: true,
    }
  },
  methods: {
    getLocale() {
      if (this.locale.toUpperCase() == 'EN') {
        return 'GB';
      }
      if (this.locale.toUpperCase() == 'AR') {
        return 'AE';
      }
      return this.locale.toUpperCase()
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      this.locale = locale;
      if (this.$i18n.locale == 'en') {
        this.$validator.localize('en', en)
      } else if (this.$i18n.locale == 'es') {
        this.$validator.localize('es', es)
      } else if (this.$i18n.locale == 'ro') {
        this.$validator.localize('ro', ro)
      } else if (this.$i18n.locale == 'ru') {
        this.$validator.localize('ru', ru)
      } else if (this.$i18n.locale == 'ar') {
        this.$validator.localize('ar', ar)
      } else if (this.$i18n.locale == 'it') {
        this.$validator.localize('it', it)
      } else if (this.$i18n.locale == 'zh') {
        this.$validator.localize('zh', zh)
      } else if (this.$i18n.locale == 'de') {
        this.$validator.localize('de', de)
      } else if (this.$i18n.locale == 'vi') {
        this.$validator.localize('vi', vi)
      } else if (this.$i18n.locale == 'pt') {
        this.$validator.localize('pt_PT', pt)
      }
    },
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
    },
    closeAlert() {
      this.error = false;
      localStorage.removeItem('needtoverify');
    },

    closeMenu() {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    },
    goReset() {
      this.$refs['reset'].goReset()
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    goBetaDash() {
      location.href = "https://dashbeta.hokocloud.com";
    },
    goRegister() {
      location.href = "/register";
    },
    verify(resp) {
      const success_recaptcha = resp => {
        this.showlogin = true
        this.canLogIn = true;
      }
      const fail_recaptcha = resp => {
        this.$toast.warning('failed recaptcha')
        this.$refs.recaptcha.reset()
        this.canLogIn = false;
      }
      this.$validadeRecaptcha({
        "captcha_token": resp
      }).then(success_recaptcha, fail_recaptcha)
    },
    validate() {
      const login_fail = error => {
        this.inprogress = false;
        if (this.$refs.recaptcha) {
          this.$refs.recaptcha.reset();
        }
        this.countloginerr += 1;
        if (this.countloginerr >= 1) {
          this.countloginerr = 0;
          this.showcaptcha = true;
          this.showlogin = false;
        }
        if (error.error_description === 'Wrong email or password.') {
          this.error = error.error_description;
          this.error_msg = this.$t('wrongemlorpwd');
          this.form.password = '';
        }
      };

      this.$validator.validateAll()
        .then(isValid => {
          // this.error = !isValid;
          if (isValid) {
            this.auth.login(this.form, login_fail);
          }
        })
    },
    applyLang() {
      const language = VueCookies.get('language')
      if (language) {
        if ((['IT', 'ES', 'FR', 'RU', 'RO', 'PT', 'AE', 'VI', 'DE', 'ZH']).includes(language.country_code2)) {
          this.setLocale(language.country_code2)
        } else if (language.country_code2.toUpperCase() == 'BR') {
          this.setLocale('pt')
        } else {
          this.setLocale('en')
        }
      }
    }
  },
  beforeDestroy() {
    this.closeMenu()
  },
  mounted() {
    if (this.$route.query.strat_id) {
      Vue.$sessionStorage.set('strat_id', this.$route.query.strat_id);
    }
    if (this.$route.query.redirect) {
      Vue.$sessionStorage.set('redirect', this.$route.query.redirect);
    }
    this.logo_login = this.logo;
    // Need to verify
    const needtoverify = localStorage.getItem('needtoverify');
    if (needtoverify !== null && needtoverify !== undefined) {
      if (needtoverify) {
        this.error = true;
        this.error_msg = this.$t('verifyemail');
      }
    }
    const self = this;
    const noWlHandler = () => {
      self.requesting = false;
      localStorage.clear();
      self.showImg = true;
      try {
        document.getElementById('favicon96').href = '/static/img/icons/hokocloud.ico';
      } catch (s) {
      }
      try {
        document.getElementById('favicon32').href = '/static/img/icons/hokocloud.ico';
      } catch (s) {
      }
      try {
        document.getElementById('favicon16').href = '/static/img/icons/hokocloud.ico';
      } catch (s) {
      }
      try {
        document.getElementsByTagName('title')[0].innerHTML = 'HokoCloud Dashboard';
      } catch (s) {
      }
      this.loading = false;
    };
    // const settings = localStorage.getItem('wlSettings');
    // console.log(settings)
    // See if this is a broker landing page or not
    let wl = this.$route.params.wl;
    if (!wl) {
      wl = window.location.hostname;
    }
    // Check BrokerIDPage
    if (wl) {
      // Calls to API, check if broker_id_page, i.e. lcg is valid
      const success = resp => {
        if (!resp.success) {
          noWlHandler();
          return;
        }
        this.requesting = false;
        this.wlSession = true;
        const settings = resp.data;
        localStorage.setItem('wlSettings', JSON.stringify(settings));
        this.logo_login = settings.logo_login;
        this.dashboadName = settings.fullname
        const bodyEl = document.getElementsByTagName('body')[0];
        bodyEl.style.backgroundColor = settings.bgcolor;
        this.showImg = true;
        if (settings.bgimg_login) {
          bodyEl.style.backgroundImage = `url('${settings.bgimg_login}')`;
        }
        const rootEl = document.querySelector(':root');
        if (settings.bgcolor_btn1) {
          rootEl.style.setProperty('--btnColor1', settings.bgcolor_btn1);
        }
        if (settings.bgcolor_btn2) {
          rootEl.style.setProperty('--btnColor2', settings.bgcolor_btn2);
        }
        try {
          document.getElementById('favicon96').href = '/static/img/icons/' + settings.whitelabel.toLowerCase() + '.ico'
        } catch (s) {
        }
        try {
          document.getElementById('favicon32').href = '/static/img/icons/' + settings.whitelabel.toLowerCase() + '.ico'
        } catch (s) {
        }
        try {
          document.getElementById('favicon16').href = '/static/img/icons/' + settings.whitelabel.toLowerCase() + '.ico'
        } catch (s) {
        }
        try {
          document.getElementsByTagName('title')[0].innerHTML = settings.fullname;
        } catch (s) {
        }
        this.loading = false;
      };
      this.$wlSettings(wl).then(success, noWlHandler);
    } else {
      noWlHandler();
    }
    this.inprogress = false;
    this.showlogin = true;
    // Remove Cache
    var old_ver = /vue-paper-dashboard-pro/g;
    var new_stuff = /hokocloud-dash/g;
    caches.keys().then(function (keys) {
      for (var i = 0; i < keys.length; i++) {
        if (keys[i].match(old_ver) !== null || keys[i].match(new_stuff) !== null) {
          caches.delete(keys[i]).then(function (boolean) {
            console.log("Previous Versions deleted");
          });
        }
      }
    }, () => {
    });
  },
  created() {
    if (!cookieLanguage) {
      this.$getVisitorLocation().then(resp => {
        VueCookies.set('language', JSON.stringify(resp), null, null, null, false, 'Lax');
        this.applyLang()
      });
    } else {
      this.applyLang()
    }
  }
}
</script>
<style scoped lang="scss">
.header-debug {
  font-size: xx-large;
  font-weight: 900;
  color: lightsalmon;
}

.card .card-header #logo {
  text-align: center !important;
}

.card .card-header #logo img {
  margin: auto;
}

.logo-small {
  width: 100px;
}

.mt25 {
  margin-top: 18vh;
}

.rebrand-background {
  //padding: 170px 15px 170px 15px;

  //@media screen and (max-width: 768px) {
  //  //padding: 35px 10px
  //}
}

.rebrand-card {
  @media screen and (max-width: 542px) {
    margin: 0px 10px 0px 10px;
    padding: 40px 20px 40px 20px;
    width: auto;
  }
  max-width: 542px;
  padding: 60px 40px 40px 40px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-radius: 32px 32px 32px 32px;
  border-color: #210896;
  box-shadow: 0px 4px 4px 0px RGB(55 51 53 / 25%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.rebrand-card-content {
  text-align: center;
  display: block;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.rebrand-h5 {
  @media screen and (max-width: 542px) {
    font-size: 33px;
  }
  color: #210896;
  font-family: 'Poppins', sans-serif;
  letter-spacing: -1.59px;
  font-size: 36px;
  font-weight: 900;
  text-transform: uppercase;

}

.div-forgot-pass {
  text-align: center;
  margin-bottom: 35px;

}

.a-forgot-pass {
  margin-bottom: 35px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin: -20px auto;
  color: black !important;

  &:hover {
    color: #210896 !important;
  }
}
</style>
