import axios from 'axios'

export default {
  methods: {
      http_it(url, json_data, method, headers, responseType=null) {

        return new Promise(function(resolve, reject) {
          var axiosMeta = {
            "method": method, "url": url
          };
          // Check if json_data exists
          if (json_data !== undefined && json_data !== null) {
            axiosMeta['data'] = json_data
          }
          // Check if headers are present
          if (headers !== undefined && headers !== null) {
            axiosMeta['headers'] = headers
          }
          if (responseType) {
            axiosMeta['responseType'] = responseType
          }
          // Make the API Call
          axios(axiosMeta).then(result => {
            var resp = result.data;
            resolve(resp);
          })
          .catch(error => {
            //console.log(error.response);
            reject(error);
          });

        });

      }
  }
}
