<template>
  <div style="display: flex;">
      <img
        :src="`/static/img/flags/${getLocale()}.png`"
        class="dropdown-toggle"
        style="height: auto; margin-top: 8px; margin-bottom: auto;"
      >
      <drop-down class="nav-link-public-strategy" tag="li" position="right" :direction="direction" :title="locale.toUpperCase()">
          <a class="dropdown-item" @click.prevent="setLocale('en')" href="#">
            <img src="\static\img\flags\GB.png"/> English
          </a>
          <a class="dropdown-item" @click.prevent="setLocale('ar')" href="#">
            <img src="\static\img\flags\AE.png"/> Arabic
          </a>
          <a class="dropdown-item" @click.prevent="setLocale('es')" href="#">
            <img src="\static\img\flags\ES.png"/> Spanish
          </a>
          <a class="dropdown-item" @click.prevent="setLocale('pt')" href="#">
            <img src="\static\img\flags\PT.png"/> Portuguese
          </a>
          <a class="dropdown-item" @click.prevent="setLocale('fr')" href="#">
            <img src="\static\img\flags\FR.png"/> French
          </a>
          <a class="dropdown-item" @click.prevent="setLocale('zh')" href="#">
            <img src="\static\img\flags\ZH.png"/> Chinesse
          </a>
         <a class="dropdown-item" @click.prevent="setLocale('zhs')" href="#">
            <img src="\static\img\flags\ZH.png"/> Chinesse (Simplified)
          </a>
          <a class="dropdown-item" @click.prevent="setLocale('de')" href="#">
            <img src="\static\img\flags\DE.png"/> Germany
          </a>
          <a class="dropdown-item" @click.prevent="setLocale('it')" href="#">
            <img src="\static\img\flags\IT.png"/> Italian
          </a>
          <a class="dropdown-item" @click.prevent="setLocale('ro')" href="#">
            <img src="\static\img\flags\RO.png"/> Romanian
          </a>
          <a class="dropdown-item" @click.prevent="setLocale('vi')" href="#">
            <img src="\static\img\flags\VI.png"/> Vietnam
          </a>
          <a class="dropdown-item" @click.prevent="setLocale('ru')" href="#">
            <img src="\static\img\flags\RU.png"/> Russian
          </a>
        </drop-down>
    </div>
</template>

<script>

import Vue from 'vue';
import Storage from 'vue-web-storage';
import { Col, Row} from 'element-ui'

Vue.use(Storage, {
  prefix: 'hokocloud_',
  drivers: ['session'],
},
  Col, Row
);

const user_lang = localStorage.getItem('user-lang');

export default {
  name: "LanguageSelect",
  props: {
    direction: {
      type: String,
      default: 'down',
      description: 'Drop down menu direction (up|down)'
    },
  },
  data() {
    return  {
      locale: user_lang,
    }
  },
  methods: {
    getLocale() {
      if (this.locale.toUpperCase() === 'EN') {
        return 'GB';
      }
      if (this.locale.toUpperCase() === 'AR') {
        return 'AE';
      }
      if (this.locale.toUpperCase() === 'ZHS') {
        return 'ZH'
      }
      return this.locale.toUpperCase()
    },
    setLocale(locale) {
      localStorage.setItem('user-lang', locale);
      this.$i18n.locale = locale;
      location.reload();
    },
  },
  mounted() {
    if (!user_lang) {
      this.locale = 'en';
      localStorage.setItem('user-lang', this.locale);
    } else {
      this.locale = user_lang;
      this.$i18n.locale = this.locale;
      localStorage.setItem('user-lang', this.locale);
    }
  }
}
</script>
