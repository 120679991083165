import {directive} from 'vue-clickaway'
/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install (Vue) {
    Vue.directive('click-outside', directive)
    Vue.directive('replace', {
      inserted(el, binding, vnode) {
        if (!binding.value || el.tagName !== 'INPUT') {
          return;
        }
        const value = binding.value;
        let placeholder = ['', ''];
        if (value instanceof Array && typeof value[0] === 'string' && typeof value[1] === 'string') {
          placeholder = value;
        } else if (typeof value === 'string') {
          placeholder = [value, ''];
        } else {
          console.error('Value for v-replace must be [regexString, replaceString] or "regexString"');
          return;
        }
        const regexString = placeholder[0];
        const replaceString = placeholder[1];
        const regex = new RegExp(regexString, 'g');

        el.addEventListener('beforeinput', (event) => {
          let value = event.target.value + event.data;
          if (event.inputType === 'deleteContentBackward') {
            value = "";
          }
          if (event.target && regex.test(value)) {
            event.preventDefault();
            event.target.value = value.replace(regex, replaceString);
            vnode.elm.dispatchEvent(new CustomEvent('input'));
          }
        });
      },
    });
  }
}

export default GlobalDirectives
