export default {
  namespaced: true,
  state: {
    showBecomeSellerBuyerModal: false,
    user: '',
    marketplaceData: []
  },
  getters: {

  },
  mutations: {
    ['SET_BECOME_SELLER_BUYER_MODAL'](state, value) {
      state.showBecomeSellerBuyerModal = value
    },
    ['SET_USER'](state, value) {
      state.user = value
    },
    ['SET_MARKETPLACE_DATA'](state, value) {
      state.marketplaceData = value
    }
  },
  actions: {
    openBecomeSellerBuyerModal({commit}) {
      commit('SET_BECOME_SELLER_BUYER_MODAL', true)
    },
    closeBecomeSellerBuyerModal({commit}) {
      commit('SET_BECOME_SELLER_BUYER_MODAL', false)
    },
    setUser({commit}, user) {
      commit('SET_USER', user)
    },
    setMarketplaceData({commit}, data) {
      commit('SET_MARKETPLACE_DATA', data)
    },
    clean({commit, dispatch}) {
      dispatch('openBecomeSellerBuyerModal')
      dispatch('closeBecomeSellerBuyerModal')
    }
  }
}
