<template>
  <div class="choice text-center"
       :class="{active: checked}"
       @click="updateValue">
    <input type="checkbox"
           :name="name"
           :disabled="disabled"
           :checked="checked">
    <div class="icon">
      <slot name="icon">
        <i :class="icon"></i>
      </slot>
    </div>
    <slot name="title">
      <h6>{{title}}</h6>
    </slot>
  </div>
</template>
<script>
  export default {
    name: 'circle-checkbox-icon',
    model: {
      data: 'checked'
    },
    props: {
      name: {
        type: String,
        description: 'Input name'
      },
      title: {
        type: String,
        description: 'Icon checkobx title (text)'
      },
      icon: {
        type: String,
        description: 'clickable icon (acts as checkbox)'
      },
      disabled: {
        type: Boolean,
        description: 'Whether is disabled'
      }
    },
    data() {
      return {
        checked: false
      }
    },
    methods: {
      updateValue() {
        this.$emit('input', { name: this.name, status: !this.checked });
      },
      update(val) {
        this.checked = val;
      }
    }
  }
</script>
<style scoped lang="scss">
  input[type=checkbox] {
    display: none;
  }
  .icon:hover {
    cursor: pointer;
    border: solid 1px #2ca8ff;
  }
  .icon {
    text-align: center;
    vertical-align: middle;
    height: 116px;
    width: 116px;
    border-radius: 50%;
    color: #66615B;
    margin: 0 auto 20px;
    border: 1px solid #E3E3E3;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    i {
      font-size: 30px;
      line-height: 116px;
      position: absolute;
      left: 0;
      right: 0;
    }
  }
  .choice.active {
    .icon {
      border-color: #2ca8ff;
      background-color: #2ca8ff;
      color: white;
    }
  }
</style>
