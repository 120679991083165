export default {
  namespaced: true,
  state: {
    whitelabel: {
      whitelabel: '',
      fullname: '',
      wladmin: '',
      wlmenuname: '',
      type: '',
      broker: [],
      strategies: [],
      parameters: []
    }
  },
  getters: {

  },
  mutations: {
    ['SET_WHITELABEL'](state, value) {
      state.whitelabel = Object.assign({}, state.whitelabel, value)
    }
  },
  actions: {
    setCurrentWhitelabel({commit}, value) {
      commit('SET_WHITELABEL', value)
    }
  }
}
