export default {
  namespaced: true,
  state: {
    isStrategies: true,
    openFilterDrawer: false,
    strategies: [{
      'svg_error': false,
    }],
    strategiesInWhitelabel: [],
  },
  getters: {
    getStrategies(state) {
      return state.strategies
    },
  },
  mutations: {
    ['UPDATE_CARDS'](state, value) {
      let _index = state.strategies.indexOf(state.strategies.find(c => c.strat_id == value.strat_id))

      if (!state.isStrategies) {
        state.strategies.splice(_index, 1)
      } else {
        state.strategies[_index].watchlist = !value.watchlist
      }
    },
    ['SET_IS_STRATEGY'](state, value) {
      state.isStrategies = value
    },
    ['SET_STRATEGIES'](state, value) {
      state.strategies = value
    },
    ['SHOW_FILTER_DRAWER'](state) {
      state.openFilterDrawer = !state.openFilterDrawer
    },
    ['SET_STRATEGIES_IN_WL'](state, value) {
      state.strategiesInWhitelabel.push(value)
    },
  },
  actions: {
    setIsStrategy({commit}, value) {
      commit('SET_IS_STRATEGY', value)
    },
    setStrategies({commit}, value) {
      commit('SET_STRATEGIES', value)
    },
    setStrategiesInWhitelabel({commit}, value) {
      commit('SET_STRATEGIES_IN_WL', value)
    },
    updateCards({commit}, value) {
      commit('UPDATE_CARDS', value)
    },
    showFilterDrawer({commit}) {
      commit('SHOW_FILTER_DRAWER')
    },
  }
}
