<template>
  <div class="row">
      <div class="col-md-12">
        <input type="text"
               class="form-control"
               v-model="brokerLink.url">
      </div>
  </div>
</template>
<script>
  export default {
    name: 'WhitelabelBrokerUrl',
    props: {
      url: {
        type: [String],
        description: 'Broker URL'
      },
      default: {
        type: [String],
        description: 'Default URL'
      },
      broker: {
        type: Number,
        description: 'Broker Id'
      }
    },
    components: {
    },
    computed: {
    },
    data() {
      return {
        brokerLink: {
          broker: 0,
          url: ''
        }
      };
    },
    methods: {
    },
    mounted() {
      this.brokerLink = {
        broker: this.broker,
        url: this.url ? this.url : this.default
      };
      this.$emit('updated', this.brokerLink)
    },
    watch: {
      'brokerLink.url': function () {
        this.$emit('update', this.brokerLink)
      }
    }
  }
</script>
<style>
</style>
