<template>
  <div class="card card-user">
    <div class="image">
      <img src="/static/img/background/business-chart.jpg">
    </div>
    <div class="card-body">
      <div class="author">
        <a href="#">
          <img class="avatar border-gray" :src="profile_img">
          <h5 class="title">{{ name }}</h5>
        </a>
        <p class="description">
          {{ email }}
        </p>
      </div>
      <p class="description text-justify">
        {{ bio }}
      </p>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'user-card',
    props:{
      email: {
        type: String,
        description: 'Profile Email'
      },
      profile_img: {
        type: String,
        description: 'URL Image of Profile'
      },
      name: {
        type: String,
        description: 'Profile Name'
      },
      bio: {
        type: String,
        description: 'Profile Bio'
      }
    }
  }

</script>
<style>

</style>
