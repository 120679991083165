<template>
  <div class="banner-rebrand">
    <a href="https:www.hokocloud.com" target="_blank">
      <RemoteImg :ready="ready" :src="logoLogin" is-login></RemoteImg>
    </a>
  </div>
</template>

<script>
export default {
  name: "login-banner",
  props: {
    logo: {
      type: String,
      default: '/static/img/hoko-new-logo.png',
      description: 'Topbar image'
    },
    ready: {
      type: Boolean,
      description: 'Logo is ready to show',
      default: true
    },
  },
  computed: {
    logoLogin: function() {
      return this.logo;
    },
  }
}
</script>

<style scoped lang="scss">
.banner-rebrand {
    height: 81px;
    margin-bottom: 75px;
    background-color: var(--btnColor2);
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: var(--btnColor1);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 8px 0px 8px 0px;
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
}

</style>
