<template>
  <module
    ref="module"
    id="whitelabelAdmin"
    titleIcon="fa fa-cog"
    :title="$t('whitelabeladminpanel')"
    :use-default-list="false"
  >
    <div slot="toolbar-global">
          <p-button class="mr-2" type="success" style="min-width: 200px;" v-on:click="save()">
            <i slot="label" class="fa fa-floppy-o"></i>
            {{ $t('savechanges')}}
          </p-button>
          <p-button type="danger" style="min-width: 200px;" v-on:click="clear()">
            <i slot="label" class="fa fa-trash-o"></i>
            {{ $t('discardchanges')}}
          </p-button>
    </div>
    <div v-if="!whitelabel_data"  class="col-md-12" slot="global">
      <div class="text-center">
        <h2>{{ $t('loadingwhitelabeladmin') }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>
    </div>
    <div v-if="!!whitelabel_data" class="col-md-12" slot="global">
      <whitelabelForm
        ref="form"
        :is_manage="false"
        v-bind:reference="whitelabel_data"
      ></whitelabelForm>
    </div>
  </module>
</template>

<script>
import Vue from "vue";
import swal from 'sweetalert2'
import UserCard from "../UserProfile/UserCard";
import { PSwitch } from "src/components/UIComponents";
import { Table, TableColumn } from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import WhitelabelForm from 'src/components/Dashboard/Views/Dashboard/Whitelabel/WhitelabelForm.vue'
import Constants from "@/assets/constants";

const rules = Constants.methods.getRules();

Vue.use(Table);
Vue.use(TableColumn);

export default {
  components: {
    PSwitch,
    UserCard,
    WhitelabelForm
  },
  data() {
    return {
      whitelabel_data: null,
      checkedNames: []
    };
  },
  methods: {
    clear() {
      this.whitelabel_data = null;
      const settings = localStorage.getItem('wlSettings');
      const json = JSON.parse(settings);
      this.$retrieve_whitelabels(json.whitelabel)
        .then(this.retrieveHandler, this.requestError);
    },
    save() {
      this.$refs.form.validate()
        .then(this.submit, console.error);
    },
    submit(isValid) {
      if (!isValid) {
        this.error();
      }
      const data = this.$refs.form.getData();
      const settings = localStorage.getItem('wlSettings');
      const json = JSON.parse(settings);
      this.$persist_whitelabel(json.whitelabel, data)
        .then(this.persistSuccessHandler, this.persistErrorHandler)
    },
    persistSuccessHandler(response) {
      if (!response.success) {
        this.error();
      }
      if (this.$refs.form.isNewPolicieFile()) {
        this.$refs.form.submitFiles().then(() => {}, () => {});
      }
      this.clear();
      swal(this.$t('successtxt'), this.$t("wlupdated"), "success")
    },
    persistErrorHandler() {
      swal(this.$t('attentiontxt'), this.$t('rdpdownmsg'), "error")
    },
    retrieveHandler(response) {
      if (!response.success) {
        return;
      }
      this.whitelabel_data = response.data
    },
    requestError() {
    },
    error(){
      //TODO
    }
  },
  mounted() {
    this.$nextTick(function () {
      let param = '';
      if(rules.isWhitelabelSession) {
        const settings = localStorage.getItem('wlSettings');
        const json = JSON.parse(settings);
        param = json.whitelabel;
      } else {
        param = rules.userWhitelabel;
      }

      this.$retrieve_whitelabels(param)
        .then(this.retrieveHandler, this.requestError);
    })
  }
}
</script>
<style>
</style>
