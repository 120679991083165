<template>
  <div>
  </div>
</template>
<script>
  import VueCookies from 'vue-cookies'
  import jwt_decode from 'jwt-decode'
  export default {
    name: 'callback',
    methods: {
      inIframe () {
        try {
          return window.self !== window.top;
        } catch (e) {
          return true;
        }
      },
      success_login(response) {
        this.auth.setStorage(response)
          .then(this.user_info_loaded, this.failop);
      },
      user_info_loaded(auth) {
        let user_metadata = auth.user['http://hokocloud/user_metadata'];
        let app_metadata = auth.user['http://hokocloud/app_metadata'];
        let user_info = auth.user['http://hokocloud/user_info']

        localStorage.setItem('user-info', JSON.stringify({
          'email': user_info.email,
          'email_verified': user_info.email_verified,
          'picture': user_info.picture,
          'user_id': auth.user.sub,
          'user_metadata': user_metadata,
          'app_metadata': app_metadata,
          'roles': app_metadata.roles
        }));

        localStorage.setItem('user-lang', user_metadata.lang);

        const successop = resp => {
          location.href = '/'
        };

        if (!this.inIframe()) {
          VueCookies.set('dash-at', auth.result.accessToken, null, null, null, false, 'Lax');
          VueCookies.set('dash-it', auth.result.idToken, null, null, null, false, 'Lax');
        } else {
          VueCookies.set('dash-at', auth.result.accessToken, null, null, null, true, 'None');
          VueCookies.set('dash-it', auth.result.idToken, null, null, null, true, 'None');
        }

        this.$updateLastLogin({
          userid: user_info.email,
          last_login: user_info.last_login,
          first_logon: user_metadata.first_logon,
          lang: user_metadata.lang,
          whitelabel: user_metadata.whitelabel,
          user_id: auth.user.sub
        }).then(successop, this.failop)
      },
      failop(err) {
        location.href = "/";
      }
    },
    created () {
      if (this.$route.query.sso) {
        this.success_login({
          accessToken: this.$route.query.accessToken,
          scope: this.$route.query.scope,
          expiresIn: this.$route.query.expiresIn,
          tokenType: this.$route.query.tokenType,
          state: this.$route.query.state,
          idToken: this.$route.query.idToken,
          idTokenPayload: jwt_decode(this.$route.query.idToken),
        });
      } else {
        this.auth
            .handleAuthentication()
            .then(this.success_login, this.failop);
      }
    }
  }
</script>
<style>
</style>
