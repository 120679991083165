<template>
  <div>
    <div v-if="loading" style="background-color: whitesmoke">
      <p-progress
        :value="100"
        type="info"
        class="progress-bar-animated">
      </p-progress>
    </div>
    <div v-if="isMobile() && !loading">
      <login-banner :ready="showImg" :logo="logo_login"></login-banner>

      <div style="position: absolute; right: 10px; z-index: 1000;">
        <language-select-login direction="down"></language-select-login>
      </div>

      <div class="col-md-12">
          <h1 class="login-title-mob"> {{ title }} </h1>
          <h3 class="login-subtitle-mob"> {{ subtitle }} </h3>
          <div class="login-text-mob" v-html="text">
          </div>
          <hr class="mt-5">
          <div class="col-md-12 text-center mt-4 mb-4">
            <div class="btn-group" role="group">
              <p-button
                @click.prevent="login"
                class="btn-rebrand-primary"
                :disabled="!showlogin"
                round
                outline
                v-if="!inprogress">
                {{ $t('log_in') }}
              </p-button>
              <p-button
                round
                class="btn-rebrand-secondary"
                v-on:click="goRegister"
                v-if="!inprogress">
                {{ $t('get_started') }}
              </p-button>
            </div>
          </div>
        </div>
      <login-footer-disclaimer></login-footer-disclaimer>
    </div>
    <div v-else-if="!loading" class="rebrand-background">
      <login-banner :ready="showImg" :logo="logo_login"></login-banner>
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <h1 class="login-title"> {{ title }} </h1>
          <h3 class="login-subtitle"> {{ subtitle }} </h3>
          <div class="login-text" v-html="text">
          </div>
          <hr class="mt-5">
          <div class="col-md-12 text-center mt-4 mb-4">
            <div class="btn-group" role="group">
              <p-button
                @click.prevent="login"
                class="btn-rebrand-primary"
                :disabled="!showlogin"
                round
                outline
                v-if="!inprogress">
                {{ $t('log_in') }}
              </p-button>
              <p-button
                round
                class="btn-rebrand-secondary"
                v-on:click="goRegister"
                v-if="!inprogress">
                {{ $t('get_started') }}
              </p-button>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <language-select-login direction="down"></language-select-login>
        </div>
      </div>
      <login-footer-disclaimer></login-footer-disclaimer>
    </div>
  </div>
</template>
<script>

import CONSTANTS from "@/assets/constants";
import VueHcaptcha from '@hcaptcha/vue-hcaptcha'
import VueCookies from 'vue-cookies'
import {Modal, Progress} from 'src/components/UIComponents'
import {Card, Checkbox, Button} from 'src/components/UIComponents';
import ResetPasswordModal from "@/components/Dashboard/Views/Pages/ResetPasswordModal";
import LanguageSelectLogin from "@/components/Dashboard/Views/Components/LanguageSelectLogin.vue";
import AppNavbar from './Layout/AppNavbar'
import AppFooter from './Layout/AppFooter2'
import Constants from "@/assets/constants";

import ar from 'vee-validate/dist/locale/ar';
import en from 'vee-validate/dist/locale/en';
import es from 'vee-validate/dist/locale/es';
import it from 'vee-validate/dist/locale/it';
import ro from 'vee-validate/dist/locale/ro';
import ru from 'vee-validate/dist/locale/ru';
import pt from 'vee-validate/dist/locale/pt_PT';
import zh from 'vee-validate/dist/locale/zh_CN';
import zhs from 'vee-validate/dist/locale/zh_TW';
import de from 'vee-validate/dist/locale/de';
import vi from 'vee-validate/dist/locale/vi';

import Vue from "vue";
import Disclaimer from "@/components/Dashboard/Views/Pages/Layout/Disclaimer.vue";
import LoginBanner from "@/components/Dashboard/Views/Pages/login-banner";
import LoginFooterDisclaimer from "@/components/Dashboard/Views/Pages/login-footer-disclaimer";

const cookieIp = VueCookies.get('ip') || null;
const cookieLanguage = VueCookies.get('language') || null
const ssourl = CONSTANTS.methods.SSOURL()

export default {
  props: {
    logo: {
      type: String,
      default: '/static/img/hoko-new-logo.png',
      description: 'Hoko Logo'
    }
  },
  components: {
    LoginFooterDisclaimer,
    LoginBanner,
    Disclaimer,
    Card,
    AppNavbar,
    AppFooter,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button, Modal,
    // VueRecaptcha,
    VueHcaptcha,
    ResetPasswordModal,
    LanguageSelectLogin,
    [Progress.name]: Progress
  },
  computed: {
    debugMode: function () {
      return Constants.methods.debugMode();
    },
    rules: function () {
      return Constants.methods.getRules();
    },
    title: function () {
      return this.loginTitle || this.$t('login_title');
    },
    subtitle: function () {
      return this.loginSubtitle || this.$t('login_subtitle');
    },
    text: function () {
      return this.loginText || this.$t('login_text');
    },
  },
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
      modelValidations: {
        password: {
          required: true,
          min: 6
        }
      },
      wlSession: false,
      requesting: true,
      showImg: false,
      logo_login: '',
      url: 'https://hokocloud.com/',
      loginTitle: "",
      loginSubtitle: "",
      loginText: "",
      whitelabel: undefined,
      error: false,
      error_msg: null,
      inprogress: true,
      resetuid: this.$t('resetuid'),
      email: this.$t('email'),
      password: this.$t('password'),
      alerttype: 'danger',
      showcaptcha: false,
      countloginerr: 0,
      canLogIn: true,
      showlogin: false,
      auth0token: null,
      locale: 'en',
      dashboadName: this.$t('dashboard') + " " + this.$t('login'),
      loading: true,
    }
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
      this.locale = locale;
      if (this.$i18n.locale === 'en') {
        this.$validator.localize('en', en);
      } else if (this.$i18n.locale === 'es') {
        this.$validator.localize('es', es);
      } else if (this.$i18n.locale === 'ro') {
        this.$validator.localize('ro', ro);
      } else if (this.$i18n.locale === 'ru') {
        this.$validator.localize('ru', ru);
      } else if (this.$i18n.locale === 'ar') {
        this.$validator.localize('ar', ar);
      } else if (this.$i18n.locale === 'it') {
        this.$validator.localize('it', it);
      } else if (this.$i18n.locale === 'zh') {
        this.$validator.localize('zh', zh);
      } else if (this.$i18n.locale === 'zhs') {
        this.$validator.localize('zhs', zhs);
      } else if (this.$i18n.locale === 'de') {
        this.$validator.localize('de', de);
      } else if (this.$i18n.locale === 'vi') {
        this.$validator.localize('vi', vi);
      } else if (this.$i18n.locale === 'pt') {
        this.$validator.localize('pt_PT', pt);
      }
    },
    toggleNavbar() {
      document.body.classList.toggle('nav-open');
    },
    closeAlert() {
      this.error = false;
      localStorage.removeItem('needtoverify');
    },
    closeMenu() {
      document.body.classList.remove('nav-open');
      document.body.classList.remove('off-canvas-sidebar');
    },
    goReset() {
      this.$refs['reset'].goReset();
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    goRegister() {
      location.href = "/register";
    },
    verify(resp) {
      const success_recaptcha = resp => {
        this.showlogin = true;
        this.canLogIn = true;
      }
      const fail_recaptcha = resp => {
        this.$toast.warning('failed recaptcha');
        this.$refs.recaptcha.reset();
        this.canLogIn = false;
      }
      this.$validadeRecaptcha({
        "captcha_token": resp
      }).then(success_recaptcha, fail_recaptcha);
    },
    login() {
      let url = `${ssourl}${this.locale ? `?lang=${this.locale}` : ''}${this.whitelabel ? `&whitelabel=${this.whitelabel}` : ''}`;
      if (location.host.indexOf('localhost') > -1) {
        if (url.indexOf('?') > -1) {
          url = `${url}&returnTo=localhost`;
        } else {
          url = `${url}?returnTo=localhost`;
        }
      }
      window.location = url;
    },
    applyLang() {
      const language = localStorage.getItem('user-lang');
      if (language) {
        if ((['it', 'es', 'fr', 'ru', 'ro', 'pt', 'ae', 'vi', 'de', 'zh', 'zhs', 'ar']).includes(language)) {
          this.setLocale(language);
        } else {
          this.setLocale('en');
        }
      }
    },
    isMobile() {
      return Constants.methods.isMobile()
    },
  },
  beforeDestroy() {
    this.closeMenu();
  },
  mounted() {
    if (this.$route.query.strat_id) {
      Vue.$sessionStorage.set('strat_id', this.$route.query.strat_id);
    }
    if (this.$route.query.redirect) {
      Vue.$sessionStorage.set('redirect', this.$route.query.redirect);
    }
    this.logo_login = this.logo;
    // Need to verify
    const needtoverify = localStorage.getItem('needtoverify');
    if (needtoverify !== null && needtoverify !== undefined) {
      if (needtoverify) {
        this.error = true;
        this.error_msg = this.$t('verifyemail');
      }
    }
    const self = this;
    const noWlHandler = () => {
      self.requesting = false;
      localStorage.clear();
      self.showImg = true;
      try {
        document.getElementById('favicon96').href = '/static/img/icons/hokocloud.ico';
      } catch (s) {}
      try {
        document.getElementById('favicon32').href = '/static/img/icons/hokocloud.ico';
      } catch (s) {}
      try {
        document.getElementById('favicon16').href = '/static/img/icons/hokocloud.ico';
      } catch (s) {}
      try {
        document.getElementsByTagName('title')[0].innerHTML = 'HokoCloud Dashboard';
      } catch (s) {}
      this.loading = false;
    };
    // See if this is a broker landing page or not
    let wl = this.$route.params.wl;
    if (!wl) {
      wl = window.location.hostname;
    }
    // Check BrokerIDPage
    if (wl) {
      // Calls to API, check if broker_id_page, i.e. lcg is valid
      const success = resp => {
        if (!resp.success) {
          noWlHandler();
          return;
        }
        this.requesting = false;
        this.wlSession = true;
        const settings = resp.data;
        localStorage.setItem('wlSettings', JSON.stringify(settings));
        this.whitelabel = settings.whitelabel;
        this.logo_login = settings.logo_login;
        this.url = settings.domain ? `https://${settings.domain}` : `${this.url}/${settings.whitelabel}`;
        this.dashboadName = settings.fullname
        const bodyEl = document.getElementsByTagName('body')[0];
        const rootEl = document.querySelector(':root');
        this.showImg = true;
        this.loginTitle = settings.login_title || this.loginTitle;
        this.loginSubtitle = settings.login_subtitle || this.loginSubtitle;
        if (settings.login_text && settings.login_text.length) {
          settings.login_text = settings.login_text.replace(/<script.*?>(.|\n|\r)*?<\/script>/ig,'');
        }
        this.loginText = settings.login_text || this.loginText;
        if (settings.bgcolor) {
          rootEl.style.setProperty('--bgColor', settings.bgcolor);
        }
        if (settings.bgimg_login) {
          bodyEl.style.backgroundImage = `url('${settings.bgimg_login}')`;
        }
        if (settings.bgcolor_btn1) {
          rootEl.style.setProperty('--btnColor1', settings.bgcolor_btn1);
        }
        if (settings.bgcolor_btn2) {
          rootEl.style.setProperty('--btnColor2', settings.bgcolor_btn2);
        }
        try {
          document.getElementById('favicon96').href = '/static/img/icons/' + settings.whitelabel.toLowerCase() + '.ico';
        } catch (s) {}
        try {
          document.getElementById('favicon32').href = '/static/img/icons/' + settings.whitelabel.toLowerCase() + '.ico';
        } catch (s) {}
        try {
          document.getElementById('favicon16').href = '/static/img/icons/' + settings.whitelabel.toLowerCase() + '.ico';
        } catch (s) {}
        try {
          document.getElementsByTagName('title')[0].innerHTML = settings.fullname;
        } catch (s) {}
        if (settings.use_initial_disclaimer !== 1) {
          this.login()
        } else {
          this.loading = false;
        }
      };
      this.$wlSettings(wl).then(success, noWlHandler);
    } else {
      noWlHandler();
    }
    this.inprogress = false;
    this.showlogin = true;
    // Remove Cache
    var old_ver = /vue-paper-dashboard-pro/g;
    var new_stuff = /hokocloud-dash/g;
    caches.keys()
          .then(function (keys) {
            for (var i = 0; i < keys.length; i++) {
              if (keys[i].match(old_ver) !== null || keys[i].match(new_stuff) !== null) {
                caches.delete(keys[i]).then(function (boolean) {
                  console.log("Previous Versions deleted");
                });
              }
            }
          }, () => {});
    this.applyLang()
  },
}
</script>
<style scoped lang="scss">
.header-debug {
  font-size: xx-large;
  font-weight: 900;
  color: lightsalmon;
}
.logo-small {
  width: 100px;
}
.login-title {
  font-family: "Poppins", Sans-serif;
  margin-bottom: 0;
  color: var(--btnColor1);
  font-weight: 900;
  width: 100%;
  //padding: 0 30%;
  text-align: center;
}
.login-title-mob {
  font-family: "Poppins", Sans-serif;
  margin-bottom: 0;
  color: var(--btnColor1);
  font-weight: 900;
  width: 100%;
  //padding: 0 30%;
  text-align: center;
}
.login-subtitle {
  font-family: "Poppins", Sans-serif;
  color: var(--bgColor) !important;
  -webkit-filter: grayscale(100%) invert(95%);
  filter: grayscale(100%) invert(95%);
  width: 100%;
  //padding: 0 20%;
  text-align: center;
  margin: 1.5rem 0 3rem 0;
}
.login-subtitle-mob {
  font-family: "Poppins", Sans-serif;
  color: var(--bgColor) !important;
  -webkit-filter: grayscale(100%) invert(95%);
  filter: grayscale(100%) invert(95%);
  width: 100%;
  //padding: 0 20%;
  text-align: center;
  margin: 1.5rem 0 3rem 0;
}
.login-text {
  color: var(--bgColor) !important;
  -webkit-filter: grayscale(100%) invert(95%);
  filter: grayscale(100%) invert(95%);
  width: 100%;
  //padding: 0 25%;
  font-size: large;
  text-align: justify;
  margin-top: 2rem;
}
.login-text-mob {
  color: var(--bgColor) !important;
  -webkit-filter: grayscale(100%) invert(95%);
  filter: grayscale(100%) invert(95%);
  width: 100%;
  padding: 0 5%;
  font-size: large;
  text-align: justify;
  margin-top: 2rem;
}
.language-select {
  width: 100%;
  padding: 0 0 0 75%;
  margin-top: -45px;
  text-align: justify;
  display: flex;
  float: right;
}
</style>
